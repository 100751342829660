import {Text, Tip} from "grommet"

const TipIfContent = ({content, children})=>{
    if (content){
        return (
            <Tip content={<Text size="xsmall">{content}</Text>}>
                {children}
            </Tip>
        )
    }
    else{
        return ( children)
    }


}

export {TipIfContent};