import { Routes, Route, Navigate } from 'react-router-dom';
import {  Box, ResponsiveContext, Grid, Collapsible, Text, Notification } from 'grommet'
import './App.css';

import { getAnalytics } from "firebase/analytics";



import { AppHeader } from "./components/AppHeader"
import { AppSidebar } from "./components/AppSidebar"
import { useState, useContext, useEffect } from "react";

import { theme } from "./theme";
import { TopicsPage } from './pages';
import { TextDocumentsPage } from './pages';
import { ProjectsPage } from './pages/ProjectsPage';
import { ProjectSettingsPage } from './pages/ProjectSettingsPage';
import { StatusNavBar } from './components/StatusNavBar';
import { ModelPage } from './pages/ModelPage';
import { ModelsPage } from './pages/ModelsPage';
import { IntegrationsPage } from './pages/IntegrationsPage';



import { 
  getAuth,
  onAuthStateChanged
} from "firebase/auth";
import {firebaseApp} from "./firebase"


import { useAppContext } from './AppContext';



import { LoginPage } from './pages/LoginPage';
import { NotFound404 } from './pages/NotFound404';
import { CallbackHandlingPage } from './pages/Callback';
import { ServingPage } from './pages/ServingPage';
import { ModelTestPage } from './pages/ModelTestPage';
import { BacklocTextDocumentsPage as BacklogTextDocumentsPage } from './pages/TextDocumentsPage';
import { IntegrationModuleSettings } from './modules/IntegrationModuleSettings/IntegrationModuleSettingsPage';



// const analytics = getAnalytics(app);
// const useApp = () => useBetween(auth);
// const useAuth = () => useBetween(auth);
// const useAnalytics = () => useBetween(analytics);


export default function App() {
  
  const [navbarVisible, setNavbarVisible] = useState(false);
  const size = useContext(ResponsiveContext);
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const [sideMenuTab, setSideMenuTab] = useState(undefined);

  

  // onAuthStateChanged(auth, (currentUser) => {
  //   setCurrentUser(currentUser);
  // });
  
  // let { currentUser, setCurrentUser } = useSharedProjectState();
  const {currentUser,error,setError, info, setInfo, lastTaskTimestamp} = useAppContext();
  useEffect(()=>{
    if (lastTaskTimestamp){
      setSideMenuOpen(true);
      setSideMenuTab("tasks");
    }
  },[lastTaskTimestamp])
  
  
  const [minimizedNavbar, setMinimizedNavbar] = useState();



  useEffect(()=>{if (size === 'medium'){setMinimizedNavbar(true) }},[size])
  return (

    <Box fill className='app' >
      
      <Grid
        fill
        rows={["xxsmall", 'auto']}
        columns={[ (size!=="small"?
          "80px"
          :
          (navbarVisible?"80px":"0px")),
           'flex'
          ]}
        areas={[
          { name: 'header', start: [0, 0], end: [1, 0] },
          { name: 'navbar', start: [0, 1], end: [0, 1] },
          { name: 'main', start: [1, 1], end: [1, 1] },
        //  { name: 'statusNavBar', start: [2, 1], end: [2, 1] },
        ]}
      >
        
          {currentUser ? (
            <>
            <Routes>
              <Route path="/projects/" element={<AppHeader menuToggle={()=>setSideMenuOpen(!navbarVisible)} 

              gridArea="header"/>
            } />
                <Route 
                path="/:project_id/*" 
                element={
                <AppHeader 
                menuToggle={()=>setNavbarVisible(!navbarVisible)}
                gridArea="header" 
                sideBarToggle={()=>setSideMenuOpen(!sideMenuOpen)}  
                
                
                />} />
            </Routes>
            
            <Routes>
              <Route path="/:project_id/*"  element=
              {(navbarVisible || size!=="small") && <AppSidebar   gridArea="navbar" />}
               />
            </Routes>
            
              <Box gridArea="main" direction='row'>
              <Routes>
                  <Route exact path="/projects/search" element={<ProjectsPage />} />
                  <Route exact path="/projects/new" element={<ProjectSettingsPage />} />
                  <Route exact path="/:project_id/projects/search" element={<ProjectsPage />} />
                  <Route exact path="/:project_id/settings" element={<ProjectSettingsPage />} />
                  <Route exact path="/:project_id/topics" element={<TopicsPage />} />
                  <Route exact path="/:project_id/models" element={<ModelsPage />} />
                  <Route exact path="/:project_id/models/:model_id" element={<ModelPage />} />
                  <Route exact path="/:project_id/texts" element={<TextDocumentsPage />} />
                  <Route exact path="/:project_id/backlog" element={<BacklogTextDocumentsPage/>} />
                  <Route exact path="/:project_id/integration" element={<IntegrationsPage />} />
                  
                  <Route exact path="/serving/test/:node_name" element={<ModelTestPage />} />
                  <Route exact path="/serving/test/:node_name/:model_name" element={<ModelTestPage />} />
                  
                  <Route exact path="/serving" element={<ServingPage />} />

                  <Route exact path="/login" element={<LoginPage/>} />
                  <Route exact path="/login/google_auth_callback" element={<CallbackHandlingPage/>} />
                  <Route exact path="/" element={<Navigate to="/projects/search" />}/>
                  <Route exact path="/projects" element={<Navigate replace to="/projects/search" />} />

                  <Route exact path="/integration/:connector" element={<IntegrationModuleSettings/>} />
                  
                        
                  <Route exact path="/*" element={<NotFound404/>}/>

              </Routes>
              
              <Collapsible open={sideMenuOpen} direction="horizontal">
                <StatusNavBar activeTab={sideMenuTab} onActiveTabChanged={setSideMenuTab} />
                   {/* <Routes>

                       <Route path="/:project_id/*" element={} />
                  </Routes> */}
                </Collapsible>

              </Box>
          </>
          ):(
            <>
            {/* {(sideMenuOpen || size!=="small")  && <AppSidebar  gridArea="sidebar" />} */}
            <AppHeader menuToggle={()=>setNavbarVisible(!navbarVisible)} gridArea="header"/>
            <Box gridArea="main" direction='row'>
            <Routes>
              {/* <Route exact path="/login" element={<LoginPage/>} /> */}
              <Route  path="/*" element={<LoginPage/>} />
                
              
            </Routes>
            <Collapsible open={sideMenuOpen} direction="horizontal">
                <StatusNavBar activeTab={sideMenuTab} onActiveTabChanged={setSideMenuTab} />
                   {/* <Routes>

                       <Route path="/:project_id/*" element={} />
                  </Routes> */}
                </Collapsible>
            </Box>
          
            </>
          )}
          
          
      </Grid>
    
  
      {error &&  <Notification
            status="critical"
            title='Error'
            toast
            message={error}
            onClose={() => setError(null)}
            
            global
          />}

    </Box>



  );
}
