import {Box, Spinner} from "grommet"
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom";
import { getApi } from "../ApiService";

const CallbackHandlingPage=()=>{
    const [searchParams, setSearchParams] = useSearchParams();   
    const [message, setMessage] = useState();
    useEffect(()=>{
        if (searchParams && searchParams.has("state")){

            getApi().googleAuthCallback(Object.fromEntries( searchParams.entries()))
            .then((data)=>{
                const customEvent = new CustomEvent('callbackFinished', { detail: {} });
                
                window.opener.dispatchEvent(customEvent);
                if (data.final_redirect && data.final_redirect.includes("close=true"))
                {
                    try{
                        window.close();
                    }catch{}
                    setMessage("You can close this window now");
                }
                else{
                    document.location.href=data.final_redirect
                }
            });
        }
        
    },[searchParams])

    return (<Box fill align="center" justify="center"><Spinner/></Box>)
}

export {CallbackHandlingPage};