import { Box, DateInput, DropButton, Spinner, Text, Tip } from "grommet"
import { Edit, FormClose, StatusGood, Validate } from "grommet-icons";
import { useEffect, useState } from "react"
import { Schedule } from "grommet-icons";

function addSeconds(date: Date, seconds: number) {
    date.setSeconds(date.getSeconds() + seconds);
    return date;
}

const getRelativeDate = (month?: number | undefined, days?: number | undefined) => {
    let now = new Date();

    now.setHours(0, 0, 0, 0)

    let result = now;
    if (month !== undefined) {
        result = new Date(now.getFullYear(), now.getMonth() + month, days ? now.getDay() : 1);
    }
    if (days) {
        result = new Date(new Date().setDate(now.getDate() + days))
    }
    return result
}

interface DateRangeParams {
    onRangeChanged: (range: { since: Date, until: Date }) => any
    options?: undefined | { [label: string]: () => { since: Date, until: Date } }
}

const DateRangePicker = ({ onRangeChanged, options = undefined }: DateRangeParams
) => {



    const now = new Date();

    now.setHours(0, 0, 0, 0)

    const _options = options || {
        "Last 7 days": () => ({ since: getRelativeDate(undefined, -7), until: now }),
        "Last 30 days": () => ({ since: getRelativeDate(undefined, -30), until: (now) }),
        "Last 90 days": () => ({ since: getRelativeDate(undefined, -90), until: (now) }),
        "This month": () => ({ since: getRelativeDate(-0), until: new Date() }),
        "Previous month": () => ({ since: getRelativeDate(-1), until: addSeconds(getRelativeDate(0), -1) }),
        "Custom range": "custom"
    }
    const [currentOption, setCurrentOptions] = useState<string | undefined>()
    const [currentRange, _setCurrentRange] = useState<{ since: Date, until: Date }>()
    const setCurrentRange = (range: { since: Date, until: Date }) => {
        _setCurrentRange(range)
        onRangeChanged(range)
    }
    useEffect(() => {
        setCurrentOptions(Object.keys(_options)[0])
        setCurrentRange((_options as any)[Object.keys(_options)[0]]())
    }, [])

    const [dropOpen, setDropOpen] = useState(false)
    const [customMode, setCustomMode] = useState(false)
    const [customModeTempRange, setCustomModeTempRange] = useState<{ since: string, until: string }>()
    return (
        <Box direction="row" align="center" gap="5px">
            {!(customMode && customModeTempRange) ? (
                <Box direction="row" align="center" gap="small">
                    <Text size="small">{` ${currentRange?.since?.toDateString()}`}</Text>
                    <Text size="small">-</Text>
                    <Text size="small">{`${currentRange?.until?.toDateString()} `}</Text>
                    {customMode && <Box    focusIndicator={false} round pad="3px" onClick={()=>setCustomModeTempRange( { since: (currentRange.since).toISOString(), until: (currentRange.until).toISOString() })}> 
                        <Edit />
                    </Box>}
                </Box>
            ) : (
                customMode && (<Box
                    focusIndicator={false} round pad="3px 8px"
                    border
                    align="center"
                    //background={customModeTempRange&&(customModeTempRange.since!=currentRange?.since &&customModeTempRange.until!=currentRange?.until) ?"brand":undefined} 
                  >
                    {customModeTempRange ? (<Box direction="row" gap="small" align="center">
                        <Box direction="row" align="center" >

                            <DateInput

                                style={{ padding: "0px 0px" }}
                                value={[customModeTempRange?.since, customModeTempRange?.until]}
                                onChange={(event: any) => {
                                    try {
                                        setCustomModeTempRange({ since: event.value[0], until: event.value[1] })
                                    } catch { }
                                }}
                                format="dd/mm/yyyy-dd/mm/yyyy"

                                calendarProps={{ size: "small" }} />

                        </Box>
                        <Box direction="row" gap="small" align="center"
                          onClick={() => {
                              setCurrentRange({ since: new Date(customModeTempRange.since), until: new Date(customModeTempRange.until) } )
                              setCustomModeTempRange(null)
                        }}
                        >
                        <StatusGood />

                        Apply
                        </Box>
                    </Box>
                        ) : (
                            <Edit />
                        )
                    }
                    </Box>)
            )}

            {customMode && customModeTempRange ? (
                <Box focusIndicator={false} border direction="row" round="large" pad="5px" onClick={() => setCustomMode(false)}><FormClose /></Box>
            ) : (
                <DropButton
                    dropAlign={{ top: "bottom" }}
                    open={dropOpen}
                    onOpen={() => setDropOpen(true)}
                    onClose={() => setDropOpen(false)}
                    label={
                        <Box direction="row" align="center" justify="center" gap="small">
                            <Schedule />
                            <Text size="small">
                                {customMode ? "Custom range" : currentOption}
                            </Text>
                        </Box>
                    }
                    dropContent={
                        <Box pad="5px" round="2px" gap="2px">
                            {Object.keys(_options).map(o => (
                                <Box onClick={() => {
                                    setCurrentOptions(o)
                                    setDropOpen(false)
                                    let selected = (_options as any)[o]
                                    if (typeof (selected) === "function") {
                                        setCustomMode(false)
                                        setCurrentRange((_options as any)[o]() as any)
                                    }
                                    else {
                                        if (selected == "custom") {
                                            setCustomMode(true)
                                            setCustomModeTempRange({ since: (currentRange.since).toISOString(), until: (currentRange.until).toISOString() })
                                        }
                                    }
                                }
                                }>
                                    {o}
                                </Box>
                            ))}
                        </Box>}
                />)}

        </Box>

    )
}

export { DateRangePicker, getRelativeDate, addSeconds }