

import {  Box, Text,  DataTable, Tip} from 'grommet'

import { ConfusionMatrix } from '../ConfusionMatrix/ConfusionMatrix'

import { useState, useEffect } from "react";
import { MetricCard } from '../MetricCard';
import { Badge } from '../Badge';
import { CaretDownFill, CaretUpFill } from 'grommet-icons';



const ModelEvaluation=({modelInfo, compareWithModelInfo})=>{
    const METRIC_COLS=["f1-score",  "precision",   "recall",   "support",  "prediction_max",   "prediction_min"]
    const AVG_COLS=["f1-score",  "precision",   "recall",   "support"]
    const [sort, setSort] = useState();
    
    function formatValue(val,format){
      if (format=="%"){
      return val && Math.round(val *1000) / 10 + " %"
      }
      else {
        return val
      }

    }

    function renderCell(metricValue, format, compareWith=undefined, size=undefined ){
      let comparsion=undefined;
      if (compareWith !==undefined && compareWith !==null){
        comparsion= Math.round(compareWith*100)!=Math.round(100*metricValue)? (
              <Badge 
              textColor="white" 
              size="xsmall" 
              gap="1px"
              pad="0px 5px 0px 2px"
              background={metricValue>compareWith?"green":"red"} 
              icon={metricValue>compareWith?<CaretUpFill color='white'/>:<CaretDownFill color='white'/>} 
              value={formatValue(metricValue-compareWith,format)} 
              tooltip={`Value for this model (${modelInfo?.model_name}) is ${metricValue}  vs  is ${compareWith} for ${compareWithModelInfo?.model_name}`}/>
            ):undefined
      }

      return (<Box direction='row' gap="small" wrap>
        <Tip content={<Text size="10px">original value  {metricValue} </Text>}>
          <Text  size={size}>{formatValue(metricValue,format)}</Text>
        </Tip>
          {comparsion} 
        </Box>)
      
    }

    const metric_infos= {
      "f1-score":{
        "label":"F1 score",
        "tip":""
      },
      "precision":{
        "label":"Precision",
        "tip":""
      },
      "recall":{
        "label":"Recall",
        "tip":""
      },
      "support":{
        "label":"Examples",
        "tip":""
      },
      "prediction_max":{
        "label":"Max prediction",
        "tip":""
      },
      "prediction_min":{
        "label":"Min prediction",
        "tip":""
      },
    }

    function getHeader(prop){
      return <Tip content={metric_infos[prop].tip}><Text>{metric_infos[prop].label || prop}</Text></Tip>
    }

    function createMetricDetailsTable(metrics, col_filter){
      const data =metrics?.map(m=>({metric:m.substring("final_".length),...(modelInfo.metrics[m]|| [])}))
      const compare_data =compareWithModelInfo && compareWithModelInfo.metrics && metrics?.map(m=>({metric:m.substring("final_".length),...(compareWithModelInfo.metrics[m] || [])}))
      return (
        <DataTable
             columns={[ 
                {property:"metric", header:"" },
                ...(col_filter || METRIC_COLS).map(p=>(
                    {
                      header:getHeader(p),
                      property:p, 
                      render:(v)=>{
                        return renderCell(v[p],p!="support"?"%":null,compare_data && compare_data.find(r=>r.metric==v.metric)[p]) 
                      },
                      //render:(p!="support"?(v)=>renderPercentage(v,p) :undefined),
                      header:p
                    }
                  ))
              ]}
              data={data}
              sort={sort}
              onSort={setSort}
              resizeable
            />
      )
     
    }

    
    
    return (<Box flex="grow">
            <Box direction='row-responsive' width="80vw" wrap>
              <Box pad="small" gap="none" direction="row" wrap flex={false}> 
                {modelInfo?.train_params?.labels_filter?.length&& modelInfo?.train_params?.labels_filter?.length<50 ? <ConfusionMatrix 
                  data={ modelInfo?.metrics?.final_confusion_matrix} 
                  labels={modelInfo?.train_params?.labels_filter} 
                  comapareTo={compareWithModelInfo?.metrics?.final_confusion_matrix} 
                />:(<></>)}
              
              </Box>
              {modelInfo.task_type?.includes("Classification")&&
              <>
                <Box flex="grow">
                  <Text weight={900}>Metric averages</Text>
                  <Box pad="small" gap="none" direction="row" wrap flex={false}> 
                    { createMetricDetailsTable( modelInfo.metrics && Object.getOwnPropertyNames(modelInfo.metrics).filter(m=> m!="final_confusion_matrix" &&  typeof(modelInfo.metrics[m])==="object" && m.startsWith("final_") && m.endsWith(" avg")), AVG_COLS) }
                  </Box>
                </Box>
                <Box flex="grow">
                  <Text weight={900}>Metrics per label</Text>
                  <Box pad="small" gap="none" direction="row" wrap flex={false}> 
                  
                    { createMetricDetailsTable( 
                      modelInfo.metrics && Object.getOwnPropertyNames(modelInfo.metrics).filter(m=>  m!="final_confusion_matrix" && typeof(modelInfo.metrics[m])==="object" && m.startsWith("final_") && !m.endsWith(" avg"))) }
                  </Box>
                </Box>
              </>
              }
              <Box  flex="grow" direction='row' wrap>
                <Box >
                  <MetricCard metricData={modelInfo.metrics?.stats} metricName="Statistics"/>
                </Box>
                <Box >
                  <MetricCard metricData={modelInfo.metrics&& Object.fromEntries(Object.entries(modelInfo.metrics).filter(([key]) => (key.startsWith('eval_') || key.startsWith('final_'))  && typeof(modelInfo.metrics[key])=="number" ))} metricName="Evaluation"/>
                </Box>
              </Box>
              </Box>
          </Box>)
}

export default ModelEvaluation;