import { Box, Button, Text } from "grommet"
import { useEffect, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getApi } from "../../ApiService";
import { AutoUI } from "../../components/AutoUI/AutoUI"
import { getAuth, onAuthStateChanged } from "firebase/auth";

export const IntegrationModuleSettings = ({})=>{
    const [settings, setSettings] = useState();
    const [unsavedChanges, setUnsavedChanges] = useState();
    const [projectModuleStatus, setProjectModuleStatus] = useState();
    const [projectModuleStatusProject, setProjectModuleStatusProject] = useState();
    const [searchParams, setSearchParams] = useSearchParams();
    let { connector } = useParams();
    const auth = getAuth();
    const navigate = useNavigate();

    useEffect(()=>{
        window.addEventListener('message', event => {
            // IMPORTANT: check the origin of the data!
            console.log(event)
            if (event.origin.includes("labelator")||event.origin.includes("://localhost")) {
                // The data was sent from your site.
                // Data sent with postMessage is stored in event.data:
                
                console.log(event.data);
                if (event.data.navigateTo){
                    navigate(event.data.navigateTo);
                }
                if (event.data.getUser){
                    document.getElementById("integration-module-iframe").contentWindow.postMessage({
                        user:JSON.stringify( auth.currentUser.toJSON()),
                        
                        project_id:searchParams.get("project_id")
                    },"*")
                    
                }
                
            } else {
                
                // The data was NOT sent from your site!
                console.error("Invalid original triggered navigate event "+event.origin)
                return;
            }
        });
    },[])

    useEffect(()=>{
        
        let project_id=searchParams.get("project_id")
        if(project_id && connector&& (!projectModuleStatus || projectModuleStatusProject!==project_id)  ){
            
            if (projectModuleStatus&& !(projectModuleStatus.module?.url) ){
                getApi().getIntegrationConnectorSettings(connector,project_id).then(data=>setSettings(data))
            } 
            getApi().getIntegrationModule(connector,project_id).then(data=>{
                setProjectModuleStatus(data)
                setProjectModuleStatusProject(project_id)
            })
        }
        
    },[searchParams, projectModuleStatus])

    function commitUnsavedChanges(){
        let project_id=searchParams.get("project_id")
        getApi().patchIntegraionConnectorSettings(connector, project_id,unsavedChanges).then(()=>{
            setUnsavedChanges(null)
            
        })
    }
    
    function discardUnsavedChanges(){
        setUnsavedChanges(null)
        setSettings(Object.assign({},settings)) //enforce refresh original value in AutoUI
    }

    return( <Box  background="white" fill>

<Box pad="medium">

        
        <Text size="large">{projectModuleStatus?.module.name}</Text>
</Box>
            {projectModuleStatus&& !(projectModuleStatus.module.url) && (
                <Box pad="large">
                    
                    <AutoUI size="medium"
                        value={settings} 
                        ui_hints={projectModuleStatus?.module.settings_ui_hints}
                        onValueChange={(value)=>{
                            setUnsavedChanges(value)
                        }}
                        />
                    {unsavedChanges&&(
                        <Box pad="small" gap="5px" direction='row'> <Button primary size="small" label="Save" onClick={commitUnsavedChanges}/>   <Button secondary  size="small"  label="Cancel" onClick={discardUnsavedChanges}/> </Box>
                    )}
                    
                </Box>
            )}
            {projectModuleStatus&& (projectModuleStatus.module.url) &&(
                <iframe id="integration-module-iframe" allow="clipboard-read; clipboard-write" frameBorder="0" src={`${projectModuleStatus.module.url}login?project_id=${searchParams.get("project_id")}&login=${encodeURIComponent(JSON.stringify(auth.currentUser))}`} height="100%" width="100%"/>
            )}
    </Box>)
}