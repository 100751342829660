
import {  Box,  Layer, FileInput, Text, Spinner, Button } from 'grommet'
import { Close } from 'grommet-icons';
import { useState, useEffect } from "react";



function FileUploadDialog({caption, onFile, onOK, onClose,accept, validateName, multiple, error, children }){
  const [wait, setWait] = useState(false);
  const [files, setFiles] = useState(false);
  function submit(files, handler){
    if ((validateName && !files.every(file=>validateName(file.name)))){
          return;
        }
        if (files){
          setWait(true);
          var res= handler(files)
          if (!res){
            setWait(false);
          }
        }
          
  }

return (<Layer onClickOutside={ onClose} onEsc={onClose}>
  <Box width="500px"  margin="small" gap="small">
    <Box direction="row" justify='between' width="100%">
      <Text size="large">{caption} </Text>
      <Close style={{float:"right"}} onClick={onClose}/>
    </Box>

    {
      wait?(<Spinner size="large" alignSelf="center"/>):
    <Box>
    {children}
    <FileInput
    accept={accept}
    multiple={multiple}
    maxSize={1} onChange={
      (e)=>{
        setFiles(e.target.files)
        submit(e.target.files,onFile)
      }
    }/>
    <Text color="red">{error}</Text>
    <Box direction='row' justify='end' gap="small">
      <Button  primary label="OK" onClick={()=>{submit(files,onOK)}}/>
      <Button label="Cancel" onClick={()=>onClose()}/>
    </Box>
    </Box>
    }
  </Box>
</Layer>)
}

export {FileUploadDialog}