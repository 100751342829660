import { Box, Form, TextInput, FormField, Button, Heading, Grommet, grommet, Text, Tip, CheckBox, Select, Spinner } from 'grommet';
import {Play,Clone,LinkPrevious, FormClock, Copy, FormClose } from "grommet-icons"
import { useEffect, useState } from 'react';
import { getApi } from '../../ApiService';
import { useAppContext } from '../../AppContext';
import { AutoUI } from '../AutoUI/AutoUI';
import { BetterButton } from '../BetterButton';

export const UserInfoCard=({})=>{
    let { currentUser, testPermission,userProfile } = useAppContext();
    const [wait, setWait] =useState()
    const [error, setError] =useState()
    const [passwordData, setPasswordData] =useState()
    
    const [userExtendedStatus,setUserExtendedStatus] =useState();

    function refreshStatus(){
        setWait(true)
            getApi().getAuthStatus((res)=>{
                if (!res.status){
                    setWait(false)
                }
                else{
                    setWait(false)
                    setUserExtendedStatus(res.user)
                }
            })
            .catch(ex=>{
                setWait(false)
            })
    }

    useEffect(()=>refreshStatus(),[])

    function changePassword( password, passwordCheck ){
        if (password && passwordCheck==password){
            setError()
            updateUser(null,password,null, ()=>{
                setPasswordData()
            })
        }
        else{
            if (!password){
                setError("Password can not be empty")
            }
            else if (!passwordCheck){
                setError("Please repeat your password")
            }
            else if (password!=passwordCheck){
                setError("Password and Repeat password don't match up")
            }
        }
    }


    function updateUser(email, password, display_name, callback ){
        setWait(true)
        getApi().updateUser({email, password, display_name }).then(()=>{
            callback&&callback()
            refreshStatus()
        })
        .finally(()=>{
            setWait(false)
        })
        
    }
    if (wait){
        return (<Box pad="20px 50px" align='center' justify='center'><Spinner/></Box>)
    }
    else 
        return (
        <Box gap="5px">
        {/* <AutoUI size="medium" onValueChange={()=>{

        }}
        value={{

            display_name: currentUser.displayName||"",
            email:currentUser.email,
            
            tenant_id:userExtendedStatus && userExtendedStatus.tennant_id

        }}
        ui_hints={{
            display_name:{type:"string", label:"User name"},
            email:{type:"string", label:"Login"},
            tenant_id:{type:"string", readonly:true, label:"Tenant"},
            
        }}
        /> */}
        {currentUser.displayName  ? <Box direction='row'><Text>User name:</Text><Text weight={900}> {currentUser.displayName }</Text></Box>: <></>}
        <Box direction='row' gap="small"><Text>Login:</Text><Text weight={900}> {currentUser.email }</Text></Box>
        <Box direction='row' gap="small"><Text>Tenant:</Text><Text weight={900}>  {userExtendedStatus && userExtendedStatus.tennant_id}</Text></Box>
        
        {passwordData===undefined?<BetterButton  size='small' label='Change password' onClick={()=>setPasswordData({})}/>:
        (
            <Box>
            <Box direction='row' gap="small"> 
                <Box gap="5px"> <Text>New password</Text> <Text>Repeat password</Text></Box>
                <Box > 
                    <TextInput type="password" onChange={e=>setPasswordData({...passwordData,password:e.target.value})}/>
                    <TextInput type="password" onChange={e=>setPasswordData({...passwordData,passwordCheck:e.target.value})}/>
                    </Box>
                
            </Box>
                <Box alignSelf='end' direction='row'>
                <BetterButton primary size='small' label='Save changes'  onClick={()=>changePassword(passwordData.password,passwordData.passwordCheck )}/>
                    <BetterButton  size='small' label='Cancel'  onClick={()=>{
                        setPasswordData(undefined)
                        setError()
                        }}/>
                </Box>
                
            </Box>
            
        )
        }
        {error&&(<Text color="red">{error}</Text>)}
    </Box>)
}