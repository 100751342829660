import {  Box,  Layer,   Spinner, Form, TextInput, FormField, Button, Text, Select } from 'grommet'
import { TextInputWithSuggestions } from '../components/TextInputWithSuggestions';

import {Close} from "grommet-icons"
import { useState, useEffect } from "react";
import { getApi } from '../ApiService';



export const ApplyLabelsActionDialogComponent= ({caption, project_id, visible, onOK, onClose })=>{
  const [wait, setWait] = useState(false);
  const [_visible, setVisible] = useState(true);
  const _onClose=(e)=>{
    setVisible(false);
    onClose(e);
  }
console.log(caption);
useEffect(()=>{
    setVisible(visible);
}, [visible]);

function submit(){
  onOK&&onOK();
  setWait();
  if (state.source_project_name){
    getApi()
    .searchProjects(state.source_project_id,false, (data)=>
    {
      if (data[0].name=state.source_project_name){
        let source_project_id = data[0].id;
        getApi().applyLabelsFromProject(project_id,source_project_id, state.missing_label_behaviour,()=>{
          onClose();
        })

      }
    })
    
  }
}



    
    const [state, _setState] = useState({
        source_project_name:"",
        missing_label_behaviour:"none"
    });
    function setState(val){
        _setState(val);
    }

return (
    _visible?(
<Layer onClickOutside={onClose} onEsc={onClose}>
  <Box width="500px"  margin="small" gap="small">
    <Box direction="row" justify='between' width="100%">
      <Text size="large">{caption} </Text>
      <Close style={{float:"right"}} onClick={_onClose}/>
    </Box>
    {
      wait?
      (<Spinner size="large" alignSelf="center"/>)
      :
      ( <Box>
        <Form 
            value={state}
            onChange={val=>setState(val)}
        >
            <FormField label="Source project">
                <TextInputWithSuggestions 
                name="source_project_name" 
                onSuggestionFilter={(val, callback)=>
                getApi()
                .searchProjects(val,false,(data)=>callback(data.filter(i=>i.id!==project_id).map(i=>i.name)))}/>

            </FormField>
            <FormField label="Behaviour on unknown label">
                <Select
                    options={['none', 'skip', 'add_to_project']}
                    name="missing_label_behaviour"
                />
            </FormField>
        </Form>
    </Box>)
    }
    <Box direction="row" justify="end" gap="xsmall">
        <Button primary label="OK" onClick={submit}/>
        <Button secondary label="Cancel" onClick={_onClose}/>
    </Box>
  </Box>
</Layer>)
:(<></>)
)
}
