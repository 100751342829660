import { Box,  Spinner,  InfiniteScroll, Keyboard, Text, Tip, Button } from "grommet";

import {useState, useEffect, useRef, useMemo, useCallback, useContext} from "react";
import { TextCard, TextCardPlaceholder } from "./TextCard/TextCard";
import { getApi } from "../ApiService";
import {useParams, useNavigate} from "react-router-dom"
import { useQuery } from "./_customHooks";


import { useAppContext } from "../AppContext";
import { Badge } from "./Badge";
import { Add, Aggregate, Checkbox, CheckboxSelected, Down, Performance, Undo } from "grommet-icons";
import { LabelingPreferencesDialog } from "./LabelingPreferencesDialog";
import { BetterDropButton } from "./BetterDropButton";
import { BetterButton } from "./BetterButton";
import { mergeChanges } from "../helpers/helperFunctions";
import { ExpandingButton } from "./ExpandingButton/expandingButton";
import { NewDocument } from "./NewDocument/NewDocument";
import { ConfirmDialog } from "./ConfirmDialog";
import ScrollToTopButton from "./ScrollToTopButton/scrollToTopButton";
import { GenerateSimilarExamplesDialog } from "../dialogs/GenerateSimilarExamplesDialog/GenerateSimilarExamplesDialog";

export const ToolbarOptions={
    labeling_preferences:"labeling_preferences",
    select_all:"select_all"
    

}



export const Texts = ({
    texts,  
    caption,
    similarToDoc,
    onTextsChanges,
    onHideLabeled,
    onSelectionChange, 
    onSelectedLabeled,
    header,
    isLoading, 
    hasNextPage, 
    pageSize=50, 
    filters, 
    loadPreviousPage,
    loadNextPage, 
    onShowSimilarToDoc, 
    onDisableFilters,
    similarToDocSidebarOpen,
    similarSearchOptions,
    eventBus,
    additionalKeywords,
    toolbarOptions,
    autoselectAll,
    projectPredictionInfo,
    onCurrentChange
})=> {
    
    const onShowSimilarToDocInternal=useCallback((doc)=>{
        if (doc){
            setSelection([doc.id])
        }
        onShowSimilarToDoc(doc)
    })

    

    let { project_id } = useParams();
    //const [allLabels, setAllLabels] = useState(undefined);

    const [autoselectSimilar, setAutoselectSimilar] = useState( similarToDoc?true:false)
    
    const {projectInfo, testPermission,applyLabelChanges} =useAppContext();
    const sim_threshold = projectInfo?.labeling_preferences?.similarity_threshold || 0.8;
    const navigate=useNavigate();
    const query=useQuery();
    
    const [lastSelectedDocId, setLastSelectedDocId] = useState(null);
    const [selection, _setSelection] = /*[[], (t)=>selection=t]//*/ useState(undefined);
    const [selectionBckup, setSelectionBckup] = /*[[], (t)=>selection=t]//*/ useState(undefined);

    const selectedBitmap=useMemo(()=>texts?.map(t=>t && selection?.includes(t.id)),[selection,texts])

    const setSelection=(ids)=>{

        if (ids?.length==1)

        if (selection?.length>1 && !(ids?.length>1)){
            setSelectionBckup(selection)
        }
        else {
            setSelectionBckup(undefined)
        }
        _setSelection(ids)
        onSelectionChange && onSelectionChange(ids) 
    }

    const predictedInSelection=useMemo(()=>selection?.length && texts?.find(t=> t && selection.includes(t.id) && t.predicted_labels?.length),[texts, selection])
    
    
    
    
    const [inverseSelection, setInverseSelection] = /*[[], (t)=>selection=t]//*/ useState();

    const similarIds = useMemo(()=> {
        if (similarToDoc )
            return  texts?.filter(t=>t && t.liked!==false && Math.floor(t.score!==undefined ? t.score*100:-1) >=  Math.floor(sim_threshold*100)).map(t=>t.id)
        else
            return null
    },[autoselectSimilar,sim_threshold, texts, similarToDoc])
    const SelectAllSimilar = ()=>{
        let newSelection =similarIds
        
        setLastSelectedDocId(newSelection[newSelection?.length-1]?.id)
        setSelection(newSelection)
    }

    useEffect(()=>{
        if (texts && texts.length>0){
            let ids = texts.filter(t=>t).map(t=>t.liked!==false && t.id)
            if (autoselectSimilar&&similarIds?.length){
                let newSelection =similarIds;
                if (similarIds && similarIds!=selection){
                    if (similarIds.length>selection?.length ||!selection){
                        newSelection=similarIds;
                        if (selection?.length){
                            for(let id of selection){
                                if (!newSelection.includes(id))
                                    newSelection.push(id)
                            }
                        }
                    }
                    else{
                        newSelection=selection?.filter(t=>ids.includes(t))||[];
                        for(let id of similarIds){
                            if (!newSelection.includes(id))
                                newSelection.push(id)
                        }
                    }
                    
                    
                    if (inverseSelection){
                        setSelection(newSelection.filter(t=>ids.includes(t) && !inverseSelection.includes(t)))
                    }
                    else{
                        setSelection(newSelection.filter(t=>ids.includes(t)))
                    }
                    setLastSelectedDocId(newSelection[newSelection.length-1]?.id)
                }
                    
                    
            }
            else if(autoselectAll){
                let newSelection=texts.filter(t=>t).map(t=>t.id)
                if (inverseSelection){
                    setSelection(newSelection.filter(t=>ids.includes(t) && !inverseSelection.includes(t)))
                }
                else{
                    setSelection(selection?.filter(t=> ids.includes(t)))
                }
                setLastSelectedDocId(newSelection[newSelection.length-1]?.id)
            }
            else if (selection?.length){
                
                setSelection(selection.filter(t=> ids.includes(t)))
            }
        }
        else{
            //setCurrentIndex(null) this resets id from url
            setSelection(undefined)
            setInverseSelection(null);
            setLastSelectedDocId(undefined)
        }
        
    },[texts,autoselectSimilar, sim_threshold, autoselectAll,similarIds])

    function setTexts(newTexts){
        onTextsChanges(newTexts)
    }


    const itemsRef = useRef();
    const  [currentIndex,_setCurrentIndex] =useState(-1);
    const setCurrentIndex=(val)=>{
        _setCurrentIndex(val)
        onCurrentChange && texts && onCurrentChange(val?texts[val]:null)
    }
    

    const handleSelectionChange = (state, textDocument, withShift, withCtrl)=>{

        if (similarToDocSidebarOpen){
            onCurrentChange&&onCurrentChange(textDocument)
            onShowSimilarToDocInternal(textDocument)
            return;
        }

        let nextCurrentIndex = texts.indexOf(texts.filter(t=>t?.id===textDocument?.id)[0]); 
        
        let _textsIds = texts.map(t=>t?.id);
        let newSelection =selection?.filter(s=>_textsIds.includes(s)) ||[];
        
        if (texts.some(t=>t?.id !== lastSelectedDocId)) setLastSelectedDocId(null);
        

        if (withShift ){
            //console.log("lastSelectedDoc:"+ lastSelectedDoc);
            //console.log("textDocument.id:"+ textDocument.id);
            let startIndex =0;
            if (lastSelectedDocId!==null){
                let item = texts.filter(t=>t?.id===lastSelectedDocId)[0];
                if (item){
                    startIndex =currentIndex;
                }
                else{
                    startIndex=0;
                }
            }
            let endIndex =nextCurrentIndex;
            let direction = startIndex<endIndex?1:-1;
            
            if (lastSelectedDocId && selection?.includes( lastSelectedDocId) && selection?.includes( textDocument.id)){
                //This means that I am unselecting previous item...
                /*
                a 
                B | [shift down]
                C
                D | [shift up]
                e
                */
                let idsToExclude=[]
                for (let i=startIndex; i!==endIndex;i=i+direction)
                {
                    if (i<0 || i>endIndex){
                        console.error("i index overflow!!!:"+i)
                        break
                    }
                    texts[i] && idsToExclude.push(texts[i].id)
                }
                if (autoselectSimilar){
                    setInverseSelection(idsToExclude)
                }
                newSelection = newSelection.filter(t=>!idsToExclude.includes(t))
            }
            else{
                if (lastSelectedDocId &&!selection?.includes( lastSelectedDocId)){
                    startIndex=startIndex+direction;
                }
                for (let i=startIndex; i!==endIndex && i>=0;i=i+direction)
                {
                    if (texts[i] && !newSelection.includes(texts[i].id)){
                        newSelection.push(texts[i].id)
                    }
                }
            }


            if (window.getSelection) {
                if (window.getSelection().empty) {  // Chrome
                  window.getSelection().empty();
                } else if (window.getSelection().removeAllRanges) {  // Firefox
                  window.getSelection().removeAllRanges();
                }
              } else if (document.selection) {  // IE?
                document.selection.empty();
              }


            newSelection.push(textDocument.id)
            setSelection(newSelection)
            autoselectSimilar && setInverseSelection(null);
        }
        else
        {
            if (!withCtrl){
                if (state){
                    newSelection=[textDocument.id]
                    setSelection(newSelection)
                    autoselectSimilar &&  setInverseSelection(null);
                    if (similarToDocSidebarOpen){
                        onShowSimilarToDocInternal && onShowSimilarToDocInternal(textDocument)
                    }
                }
                else{
                    newSelection=[]
                    setSelection(newSelection)
                    autoselectSimilar && setInverseSelection(null);
                }
            }
            else{
                if (state){
                    newSelection.push(textDocument.id)
                    if (autoselectSimilar && inverseSelection ){
                        setInverseSelection(inverseSelection.filter(t=>t!=textDocument.id));
                    } 
                }
                else{
                    newSelection=newSelection.filter(t=>t!=textDocument.id)
                    if (autoselectSimilar){
                        
                        if (inverseSelection){
                            setInverseSelection([...inverseSelection, textDocument.id])
                        }
                        else{
                            setInverseSelection([textDocument.id])
                        }
                    } 
                    else {}
                }
                setSelection(newSelection)
            }
        }
        
        setLastSelectedDocId(textDocument.id)
        
        
        setCurrentIndex(nextCurrentIndex)

    }
    

    function modifyLables(arr, labelOrLabels, addOrRemove, changes){
        let allowMultiple = projectInfo.task_type=="MultiLabelTextClassification";
        changes=changes || {added:{},removed:{}};
        let wasChange=false;
        let labels=undefined;
        if (!Array.isArray(labelOrLabels))
            labels=[labelOrLabels]
        else if (labelOrLabels)
            labels=allowMultiple?labelOrLabels:[labelOrLabels[0]]
        

        if (!arr)
            throw new Error("arr must not be null");
        if (addOrRemove){
            if (allowMultiple){   
                for(let label of labels){
                    if (!arr.includes(label)){
                        arr.push(label)
                        changes.added[label]=(changes.added[label]||0)+1
                        wasChange=true;
                    }
                }
            }
            else{
                for(let label of labels){
                    if (!arr.includes(label)){
                        
                        arr.forEach(l=>{
                            changes.removed[l]=(changes.removed[l]||0)+1
                        });

                        arr.length = 0; //clear array
                        arr.push(label)
                        changes.added[label]=changes.added[label]||0+1
                        wasChange=true;
                    }
                }
            }
        }
        else{
            if (allowMultiple){
                for(let label of labels){
                    const index = arr.indexOf(label, 0);
                    if (index > -1) {
                        arr.splice(index, 1);
                        changes.removed[label]=(changes.removed[label]||0)+1
                        wasChange=true;
                    }
                }
            }
            else{
                for(let label of labels){
                    if (arr.includes(label)){

                        arr.forEach(l=>{
                            changes.removed[l]=(changes.removed[l]||0)+1
                        });
                        arr.length=0;
                        wasChange=true;
                        break
                    }
                }
            
            }
        }
        return wasChange;
    }

    const  keyPress = useCallback((event)=>{
        
        if(event.key==="ArrowDown"){
            if (currentIndex<texts?.length-1) {
                handleSelectionChange(true,texts[currentIndex+1],event.shiftKey);
            }
        }
        else if(event.key==="ArrowUp"){
            if (currentIndex>0){
                handleSelectionChange(true,texts[currentIndex-1],event.shiftKey);
            } 
        }
    })

    const [lastSimilarToDocId, setLastSimilarToDocId] = useState(undefined);
    const [lastSimilarToDocLabels, setLastSimilarToDocLabels] = useState(undefined);
    useEffect(()=>{
        if (similarToDoc && similarToDoc.id){
            if (similarToDoc.id==lastSimilarToDocId){

                if (lastSimilarToDocLabels && similarToDoc&& similarToDoc?.labels!=lastSimilarToDocLabels){
                    let addedLabels = similarToDoc.labels?.filter(l=>!lastSimilarToDocLabels.includes(l))
                    if (addedLabels?.length){
                        handleLabelsUpdated( similarToDoc.id, addedLabels,true)
                    }else{

                        let removedLabels = lastSimilarToDocLabels?.filter(l=>!similarToDoc.labels?.includes(l))
                        if (removedLabels?.length){
                            handleLabelsUpdated( similarToDoc.id, removedLabels,false)
                        }
                    }

                    if (similarToDoc?.labels?.length) //must be copy of the list, otherwise the instance is synced
                        setLastSimilarToDocLabels([...similarToDoc.labels])
                    else
                        setLastSimilarToDocLabels([])
                    
                }
               
            }
            else{
                setLastSimilarToDocId(similarToDoc.id)
                if (similarToDoc?.labels?.length)
                    setLastSimilarToDocLabels([...similarToDoc.labels])
                else
                    setLastSimilarToDocLabels([])
            }
        }
    },[similarToDoc])
    
    
    const [labelHistory, setLabelHistory] = useState([]);
    const [commonPredictedLabels, setCommonPrecictedLabels] = useState([]);
    useEffect(()=>{setCommonPrecictedLabels([])},[query])

    const finishUpdating=(ids)=>{
        const updateState = (texts)=>{
            if (!texts) return;
            let newTextsInternal= Array.from(texts)
            ids.forEach(id=>{
                let foundIdx = newTextsInternal.findIndex(t=> t && t.id==id);
                if (foundIdx>=0 && newTextsInternal[foundIdx].updating_labels){
                    let newDoc = {...newTextsInternal[foundIdx]}
                    delete newDoc.updating_labels
                    newTextsInternal[foundIdx]=newDoc
                }
            }) 
            return newTextsInternal
        } 
        setTexts(updateState)
    }

    const handleLabelsUpdated = useCallback((doc_id, labelOrLabels, state)=>{
        if (!Array.isArray(labelOrLabels)){
            setLabelHistory( [...new Set([...labelHistory||[],...labelOrLabels])]);
        }
        else if (!(labelHistory&&labelHistory.includes(labelOrLabels))){
            setLabelHistory([...labelHistory||[],labelOrLabels]);
        }
    

        //onCurrentChange && texts && onCurrentChange(texts.find(t=>t.id==doc_id))
       
        
        let totalLabeledChange=0;
        let newTextsInternal =undefined
        const changes= {added:{},removed:{}};
        
        const compareArrays = (array1,array2) => array1.length === array2.length && array1.every((value) => array2.includes(value))
        const modifyDoc = (textCollection,doc_id_to_modify, changes)=>{
            
            let foundIndex = textCollection.findIndex(t=>t && t.id===doc_id_to_modify);
            let textDoc = textCollection[foundIndex]
            if (!textDoc)
                return null;
            let newTextDoc = Object.assign({}, textDoc);
            
            textCollection[foundIndex] = newTextDoc
            if (!newTextDoc.labels) {
                newTextDoc.labels=[]
            }
            else{
                newTextDoc.labels=[...newTextDoc.labels]
            }
            let wasLabeledBefore=false;
            if (newTextDoc.labels && newTextDoc.labels.length>0 ) wasLabeledBefore=true;

            if (modifyLables(newTextDoc.labels ,labelOrLabels, state, changes)){
                if (newTextDoc.labels && newTextDoc.labels.length>0 && !wasLabeledBefore){
                    totalLabeledChange++; 
                }
                else if (!(newTextDoc.labels && newTextDoc.labels.length>0) && wasLabeledBefore){
                    totalLabeledChange--; 
                }
                return newTextDoc;
            }
            else{
                //no changes
                return null;
            }
        }

        

        if (doc_id ===null || selection?.includes(doc_id) || doc_id==similarToDoc?.id){ //update the whole selection ONLY if my document is part of it
            newTextsInternal= texts//Array.from(texts)
            let lastLabelsArr=[];
            let ids_with_same_labels=[]

            for (let i=0; i<selection?.length; i++ ){
                let sel_id=selection[i];
                
                let newTextDoc = modifyDoc(newTextsInternal,sel_id, changes)
                if (newTextDoc){
                    
                    //Set labels that are being updated
                    newTextDoc.updating_labels=Array.isArray(labelOrLabels)?labelOrLabels:[labelOrLabels]

                    if (compareArrays(lastLabelsArr, newTextDoc.labels)){ //if previous doc had the same labels 
                        ids_with_same_labels.push(newTextDoc.id);
                        if (i<selection?.length-1){
                            continue
                        }
                    }
                }
                else if (i<selection?.length-1){
                    continue;
                }
                // if (lastLabelsArr?.length==0){
                //     console.log("s")
                // }
                if (ids_with_same_labels.length>0){ //flush existing
                    getApi().updateDocLabels(project_id, ids_with_same_labels, lastLabelsArr).then(()=>finishUpdating(ids_with_same_labels));
                }
                
                
                if(i===selection?.length-1){ //if this is the last one, 
                    //and it wasnt similar to the ones already flushed
                    if(newTextDoc && !ids_with_same_labels.includes(newTextDoc.id)){
                        getApi().updateDocLabels(project_id, [newTextDoc.id], newTextDoc.labels).then(()=>finishUpdating([newTextDoc.id]));

                    }
                }
                else{
                    ids_with_same_labels =[newTextDoc.id]; //add current
                    lastLabelsArr=newTextDoc.labels;
                }
                
            }
           
        }
        else if (texts.filter(t=>t?.id==doc_id).length>0){
            newTextsInternal= texts
            let newTextDoc = modifyDoc(newTextsInternal,doc_id, changes)
            if (newTextDoc){
                newTextDoc.updating_labels=Array.isArray(labelOrLabels)?labelOrLabels:[labelOrLabels]
                getApi().updateDocLabels(project_id, [newTextDoc.id], newTextDoc.labels).then(()=>{
                    finishUpdating([newTextDoc.id])


                });
            }
        }

        if (newTextsInternal){ //this checks if any change was made
            
            
            
            applyLabelChanges(changes, totalLabeledChange)
            //setTexts(newTextsInternal)
            
            
            eventBus&& texts.filter(t=>t?.id===doc_id).length &&  eventBus.publish({
                topic:"LabelsUpdated", 
                payload:{
                    doc_id:doc_id,
                    label:labelOrLabels,
                    state:state
                    
                }
            })
        }

        if (selection?.includes(doc_id) && onSelectedLabeled){
            // To handle modification of master in in Master-Detail mode ... 
            let res = onSelectedLabeled(doc_id,  labelOrLabels, state)
        }
    })

    function applyPrediction(text_ids){
        let newTexts= [...texts]
        let groupsToUpdate={}
        let changes= {added:{},removed:{}};
        let totalLabeledChange=0;
        newTexts.forEach(text=>{
            if (text.predicted_labels?.length && text_ids.includes(text.id)){
                if (!text.labels?.length){
                    totalLabeledChange+=1;
                }else{
                    text.labels.forEach(l=>{
                        if (!text.predicted_labels.includes(l)){
                            changes.removed[l]=(changes.removed[l]||0)+1
                        }
                    })
                } 
                text.predicted_labels.forEach(l=>{
                    if (!text.labels?.includes(l)){
                        changes.added[l]=(changes.added[l]||0)+1
                    }
                })
                
                
                text.labels = [...text.predicted_labels]
                text.updating_labels=text.labels
                let key= text.predicted_labels.join(" ");
                if (groupsToUpdate[key]){
                    groupsToUpdate[key].ids.push(text.id)
                }
                else{

                    groupsToUpdate[key]={ids:[text.id], labels:text.predicted_labels}
                }
            }
        })
        applyLabelChanges(changes, totalLabeledChange)
        setTexts(newTexts)
        Object.keys(groupsToUpdate).forEach(grpKey=>{
            getApi().updateDocLabels(project_id, groupsToUpdate[grpKey].ids, groupsToUpdate[grpKey].labels).then(()=>finishUpdating(groupsToUpdate[grpKey].ids,));
        })
    }

    const  handleAnswerUpdated= useCallback((doc_id, answer, callback)=>{
        let doc_ids;
        if (!selection?.includes(doc_id) ){
            doc_ids = [...selection, doc_id]
        }
        else{
            doc_ids = selection
        }
        getApi().setTextAnswers(project_id, doc_ids.filter(t=>t), answer).then(()=>{
            let newTextsInternal = texts.map(t=>{
                if (t && doc_ids.includes(t.id)){
                    let newText={...t}
                    newText.answer=answer
                    return newText
                }
                else{
                    return t
                }
            })
            setTexts(newTextsInternal)
            callback&&callback()
        })

    })

    useEffect(()=>{
        if (eventBus){
            const subscribtions=[];
            // subscribtions.push(
            //     eventBus.subscribe("LabelsUpdated",({doc_id,label,state})=>{
            //         if (similarToDoc && doc_id==similarToDoc.id && !texts.filter(t=>t.id===doc_id).length){
            //                     TODO -> handle that label can be array???
            //                 selection?.length>0 && handleLabelsUpdated(doc_id, label, state)
                        
            //         }
            //     })
            // )

             subscribtions.push(
                eventBus.subscribe("TopicLabeled",({label,state})=>{
            
                            selection?.length>0 && handleLabelsUpdated(null, label, state)
                        
                    
                })
            )

            return ()=>subscribtions.forEach(s=>s.unsubscribe());
        }
    },[texts, selection])

    const onExcludeClick = useCallback((doc)=>{
       setDialog(<ConfirmDialog 
        text="Are you sure you want to definitely delete this record?"
        onOk={()=>{
                    getApi().deleteDoc(project_id, doc.id).then(()=>{
                        setDialog(null)
                        setTexts(texts.filter(d=>d && d.id!==doc.id))
                    })
        }}
        onCancel={()=>setDialog(null)}

    />)
    })

    const setSimilarityFeedback = useCallback((doc, label)=>{

        let like_doc = similarToDoc.id
        
        let toUpdate;
        
        if (!(autoselectSimilar && label===false ) &&  selection?.includes(doc.id)){
            let text_ids= texts.map(t=>t.id)
            toUpdate=selection.filter(t=>text_ids.includes(t))
        }
        else
        {
            toUpdate=[doc.id]
        }

        const postprocess=()=>{
            let newTextsInternal;
            // commented out becasue I dont like to filter out the data out of the texts
            // if (!label){
            //     newTextsInternal=texts.filter(d=>!toUpdate.includes(d.id))
            // }
            // else{
                newTextsInternal= texts.map(d=>{
                    if (toUpdate.includes(d.id)){
                        let res = Object.assign({}, d);
                        res["liked"]=label;
                        return res;
                    }
                    else return d;

                })
            //}
            setTexts(newTextsInternal)
        }

        if (label==null){
            getApi().deleteSimilarity(project_id, like_doc,toUpdate).then(postprocess)
        }
        else{
            getApi().setSimilarity(project_id, like_doc,toUpdate,label).then(postprocess)
        }

    })
    

    const previous = (textDocument)=>{
        setHasNext(true);
        move(textDocument,getApi().previousText);
      }
      
    const [hasPrevious,setHasPrevious] = useState(true);
    const [hasNext,setHasNext] = useState(true);
    const next = (textDocument)=>{
        setHasPrevious(true);
        move(textDocument,getApi().nextText);
      }
    
    const move = (textDocument, apiCall)=>{
        let iindex = textDocument["_i"];
        if (iindex ||iindex>=0){
          let queryFilters={};
          Object.getOwnPropertyNames(filters).filter(p=>!p.startsWith("similar")).forEach(p=>{
            queryFilters[p]=filters[p]
          })
          
          apiCall.apply(getApi(), [project_id, queryFilters,iindex,(nextDoc)=>{
            if (nextDoc && nextDoc.id){
              let query = new URLSearchParams(document.location.search);
              query.set("similar_to_doc",nextDoc.id);
              navigate("?"+query.toString())
              setHasNext(true);
            }
            else {
                if (apiCall.name==="nextText"){
                    setHasNext(false);
                }
                else if (apiCall.name==="previousText"){
                    setHasPrevious(false);
                }
            }
          }])
        }
    }

    const textsRefs = useRef([]);
    // you can access the elements with itemsRef.current[n]

    useEffect(() => {
        if (texts){
            textsRefs.current = textsRefs.current.slice(0,texts.length);
        }
    }, [texts]);

    //const isScrollingDebounced = useDebounce(inView, 500);
    const [isScrolling, setIsScrolling]=useState();
    const [lastScroll, setLastScroll]=useState();
    function handleScroll(e){
        const now=new Date();
        if (isScrolling && lastScroll &&  now-lastScroll<200){ //Limit the update on 800ms{
            return;
        }
        setLastScroll(now)
        setIsScrolling(true)
        setTimeout(() => {
            if (lastScroll &&  new Date()-lastScroll>200){
                setIsScrolling(false)
            }
        }, 300);

    }

    const [preferencesOpen, _setPreferencesOpen]=useState(false);
    const setPreferencesOpen=(val)=>{
        _setPreferencesOpen(val)
    }
    const alreadyLabaled = useMemo(()=>{
        if (filters?.by_label=="null" ){
            return texts?.filter(t=>t?.labels?.length>0).length
        }

    },[filters,texts])

    useEffect(()=>{
        if (!isLoading && autoselectSimilar && similarToDoc && similarIds && selection){
            let shouldBeSelected= similarIds.filter(id=>!inverseSelection?.includes(id));
            if (shouldBeSelected.length>selection.length || shouldBeSelected.some(s=>!selection.includes(s)) ){
                setAutoselectSimilar(false)
            }
        }
    },[selection,inverseSelection,texts])

    const [dialog,_setDialog] = useState();
    const setDialog = useCallback((val)=>{
        _setDialog(val)
    });
    const [firstNotNullText,setFirstNotNullText] = useState();
    const [showIndex,_setShowIndex] = useState();
    const setShowIndex = (val)=>{
        _setShowIndex(val)
        // setTimeout(() => {
        //     _setShowIndex(undefined) //delete show index after if was initialy set so we can set it again and also so if wouldnt just be showing up on re-render
        // }, 1500);
    }

    useEffect(()=>{
        if(texts && texts?.length){

            if (!similarToDoc){
                let query = new URLSearchParams(window.location.search)
                if (query.get("id")){
                    let found = query.get("id") && texts.findIndex(t=>t?.id ==query.get("id"))
                    if (found >=0 ){
                        if (!showIndex)
                            setShowIndex(found)
                        //setCurrentIndex()
                        if(!selection?.length){
                             setSelection([ query.get("id") ])
                        }
                    }
                }
                
                
            }
        }
    },[texts])



    function _runAction(textDocument, action){
        if (action=="generate_similar"){

            let similarTo = [textDocument]
            if (texts && selection?.includes(textDocument.id) ){
                similarTo = texts.filter(t=> selection.includes(t?.id))
            }

            setDialog(<GenerateSimilarExamplesDialog textDocuments={similarTo} onClose={()=>setDialog()}/>)
        }
        
    }
    const runAction = useCallback(_runAction)


    return (
        <>
        {/* {
            similarToDoc &&(
                <>

                <StandaloneTextCard
                label="Similar to" 
                document={similarToDoc} 
                onNext={next} 
                hasPrevious={hasPrevious} 
                hasNext={hasNext} 
                onPrevious={previous} 
                onLabelsUpdated={(doc_id,labels,state)=>handleLabelsUpdated(null,labels,state)}
                />
                </>
            )
            
        } */}
        {dialog}
            <Box direction="column" flex={false} style={{position:"sticky", top:"0px", zIndex:999}} background="light-2" pad="0px 0px 8px 18px">
            
                {header}

                
                <Box  direction="row" justify="between" align="start" flex={false}>

                    {similarToDoc ? (
                            <Box direction="row">
                                {(similarToDoc.labels?.length && selection?.length)? 
                                <BetterButton 
                                    tooltip="Copy labels from main document to selected documents"
                                    size="small" 
                                    nowrap
                                    label="Copy labels to selection "
                                    onClick={()=>{
                                        handleLabelsUpdated(similarToDoc.id, similarToDoc.labels, true)
                                    }}
                                />
                                :<></>}

                                {(filters && Object.keys(filters).length &&onDisableFilters)?(
                                    <ExpandingButton
                                    flex="shrink"
                                    pad="0px"
                                    collapsedContent={
                                        <Box pad="5px 5px" align="center" justify="center">
                                        <Text size="small" className="nowrap" style={{ maxWidth:"100%"}}>
                                            {(Object.keys(filters).length==1 && filters.by_label=="null")?"Showing only not labeled":"Showing filtered list"}
                                        </Text>
                                        </Box>
                                    }
                                    expandedContent={
                                        <Box pad="2px 20px"  background="brand" round>
                                        <Text  className="nowrap" style={{ maxWidth:"100%"}}>
                                            Show all similar
                                        </Text>
                                        </Box>
                                    }
                                    onClick={onDisableFilters}
                                    />
                                    
                                ):(<></>)}
                            
                            </Box>

                    ):(
                        <Box direction="row" gap="small">
                            <Text>{caption}</Text>
                            
                        </Box>
                    )}
                    <Box direction="row" gap="xsmall">
                    {alreadyLabaled?(
                        <BetterButton primary size="small" nowrap label={`Hide ${alreadyLabaled} already labeled`} onClick={(e)=>{
                            // if (selection?.length){
                            //     setSelection()

                            // }
                            // setInverseSelection([])
                            setSelectionBckup([])
                            onHideLabeled()
                            let findNextShowItem=0;
                            
                            for (let i = 0; i < currentIndex; i++){
                                if (texts[currentIndex]?.labels?.length ||texts[currentIndex]?.answer){
                                    continue // these will be skipped/hidden... we ignore them 
                                }
                                findNextShowItem++;
                            }
                            
                            setTimeout(()=>setShowIndex(findNextShowItem),1000)
                        }
                        }/>
                    ):(<></>)}
                    {testPermission("IMPORT_DATA") && !similarToDocSidebarOpen && !similarToDoc &&   <Badge 
                                value={<Box direction="row" align="center" gap="2px"><Add color="brand"/>Add new </Box>}
                                //background="brand"
                                onClick={()=>{
                                    let defaultData=null;
                                    if (texts && texts.length && texts[0].context_data){
                                        defaultData={}
                                        Object.keys(texts[0].context_data).forEach(k=>{
                                            defaultData[k]=""
                                        })
                                    }
                                    setDialog(<NewDocument
                                        onCancel={()=>setDialog()}
                                        onSaved={(next)=>{
                                            if (!next) {
                                                setDialog()
                                            }
                                        }}
                                        defaultContextData={defaultData}
                                    />)
                                }}
                            /> }
                    {selection?.length>1&& predictedInSelection && (
                        <BetterButton 
                        tooltip="Copy labels from main document to selected documents"
                        size="small" 
                        nowrap
                        label="Apply predicted"
                        onClick={()=>{
                            applyPrediction(selection)
                        }}
                    />
                    )}
                    {selection?.length>1? (
                        <Badge value={`${selection.length} selected`} tooltip="unselect all" onClick={()=>{
                            setSelection([])
                            setInverseSelection([])
                        }}/>
                        
                    ):(selectionBckup?(
                        <Badge value="Restore selection" icon={<Undo/>} tooltip="Restore previous selection" onClick={()=>{
                            setSelection(selectionBckup)
                            setInverseSelection(texts.filter(t=>!selectionBckup.includes(t.id)).map(t=>t.id))
                        }}/>
                    ):<></>)}
                    {toolbarOptions && toolbarOptions.includes(ToolbarOptions.select_all) && (selection?.length||0)<texts?.length && <>
                        <Badge value="Select all" onClick={()=>{
                            setSelection(texts.map(t=>t.id))
                            setInverseSelection([])
                        }}/>
                        </>
                        }
                    {/* {inverseSelection?.length} */}
                    {texts?.some(t=>t?.score>sim_threshold) && similarIds &&
                                <BetterButton 
                                nowrap 
                                size="xsmall" 
                                primary={autoselectSimilar?true:false} 
                                icon={autoselectSimilar?<CheckboxSelected/>:< Checkbox/>} 
                                onClick={()=> {
                                    if (!autoselectSimilar){
                                        setInverseSelection(undefined)
                                        SelectAllSimilar()
                                    }
                                    setAutoselectSimilar(!autoselectSimilar)
                                }}
                                label="Autoselect similar"
                                />}
                    
                    {toolbarOptions && toolbarOptions.includes(ToolbarOptions.labeling_preferences) && <>
                        <Badge icon={<Aggregate/>} tooltip="Similarity score threshold" value={`${sim_threshold*100} %`} onClick={()=>setPreferencesOpen(true)}/>
                        <Tip content="Labeling preferences">
                            <Box>
                            <BetterDropButton open={preferencesOpen}  icon={<Performance />} dropAlign={{ "top": "bottom" }} 
                            onDropClose={()=>setPreferencesOpen(false)}
                            onDropOpen={()=>setPreferencesOpen(true)}
                            getDropContentFunc={() => (
                                <LabelingPreferencesDialog onAfterApply={() => setPreferencesOpen(false)} />
                            )
                            } />
                            </Box>
                        </Tip>
                        </>
                        }
                    </Box>
                </Box>
            </Box>
        
        
        <Box  ref={itemsRef} flex overflow="auto" gap="1px" pad="2px" onScroll={handleScroll} >
        <ScrollToTopButton reference={itemsRef}/>












        {texts&&
            
                <InfiniteScroll 
                    id="theInfiniteScroll"
                    onScroll={handleScroll}

                    //temporary disabled as is had weird consequences (scrolling when changing text)
                    show={ showIndex}
                    items={texts} 
                    step={pageSize/2}
                    //show={0}
                    replace={true}
                    chromatic= {{ disable: true }}
                    renderMarker={(marker)=>{
                        
                            if (hasNextPage || isLoading){
                                return (<Box margin="small" align="stretch" flex="grow" >
                                <TextCardPlaceholder  onInViewChaged={(inView)=>{
                                        let _showIndex=undefined
                                        if (texts?.length  && inView && loadPreviousPage){
                                            isLoading=true
                                            if (currentIndex>=0){
                                                _showIndex = currentIndex
                                            }
                                            else if (texts.length>10){
                                                _showIndex =(texts.length-(pageSize/2)-5)
                                            }
                                            
                                            //setShowIndex(_showIndex)
                                            
                                            console.log(_showIndex)
                                            loadNextPage().then(()=>{
                                                setShowIndex(_showIndex)
                                            })
                                        }
                                        }
                                }/>

                                </Box>)
                            }
                            else return (<Box margin="small" round border align="center"> the end (no more data) {marker}</Box>)
                        }
                        } 
                    onMore={() => { 
                       // loadNextPage();
                    }
                    }    
                    >
                    {(text, index,ref)=> 
                    
                    
                    {
                    if    (!text){
                        return (
                            

                            <TextCardPlaceholder key={"placeholder-"+index} onInViewChaged={(inView)=>{
                                if (!isLoading  && inView && loadPreviousPage && isScrolling ){
                                    isLoading=true
                                    

                                    let firstNotNullText = texts.findIndex(item => item !== null)
                                    if (firstNotNullText>10)
                                    {
                                        if (query.get("page")>1){
                                            firstNotNullText= firstNotNullText+2
                                        }
                                        else{
                                            firstNotNullText=undefined
                                        }
                                        
                                        loadPreviousPage().then(()=>setShowIndex(firstNotNullText))
                                    }
                                }
                                else if (texts && texts[0]==null){
                                    let firstNotNullText = texts.findIndex(item => item !== null)
                                    setShowIndex(firstNotNullText)
                                }
                            }
                        }/>
                        
                        )
                    }
                    else if (!text.score ){
                            return (
                                
                                <TextCard 
                                key={text.id} 
                                textDocument={text} 
                                keyPressed={keyPress}
                                //allLabels={allLabels} 
                                //compact={similarToDocSidebarOpen || (index != currentIndex && selection?.includes(text.id))}
                    compact={similarToDocSidebarOpen }
                    showCheckbox={!similarToDocSidebarOpen}
                    additionalKeywords={additionalKeywords}
                    selected={selectedBitmap[index]}
                    eventBus={eventBus}
                    similarSearchOptions={similarSearchOptions}
                    projectPredictionInfo={projectPredictionInfo}
                    runAction={runAction}
                                //lastSelected={lastSelectedDocId==text.id}
                                onSelectionChanged={handleSelectionChange} 
                                disabledSimilaritySearch={isScrolling}
                                isScrolling={isScrolling}
                                onLabelsUpdated={handleLabelsUpdated} 
                                onAnswerUpdated={handleAnswerUpdated}
                                onExcludeClick={onExcludeClick} 
                                showSimilarToDoc={onShowSimilarToDocInternal}
                                />
                                
                            )
                    }
                    else {
                        return (
                                <div  key={text.id}    ref={el => textsRefs.current[index] = el} >
                                    {(((index>0 && Math.floor(texts[index+1]?.score*100)>= Math.floor(100*sim_threshold)) || index==0) &&  Math.floor(100*text.score)< Math.floor(100*sim_threshold) )?(<Box pad="20px 5px">
                                    <Box direction="row" justify="center" gap="medium">
                                    <Down/><Down/><Down/>
                                    </Box>
                                    <Box direction="row" justify="center"  >
                                        
                                        <Box direction="row" margin="10px 10px 0px">
                                        <Text margin="0px 10px 4px" size="small"  alignSelf="end">These documents are below your similarity threshold </Text>
                                        
                                        <Badge value={`${sim_threshold*100} %`}/>
                                        <Text  margin="0px 5px 4px" size="xsmall" style={{whiteSpace:"nowrap"}} alignSelf="end"><a href="#" onClick={()=>setPreferencesOpen(true)}>change it</a></Text>
                                        </Box>

                                    </Box>
                                    <Box alignSelf="center"  pad="5px">
                                        
                                        <Text size="xsmall"  alignSelf="end">Or simply select more documents by clicking on them using Ctrl / Shift keys  </Text>
                                    </Box>
                                    
                                </Box>):(<></>) }
                                <TextCard 
                                    
                                    key={text.id} 
                                    textDocument={text} 
                                    eventBus={eventBus}
                                    keyPressed={keyPress}
                                    //compact={similarToDocSidebarOpen || (selection?.includes(text.id))}
                                    //labelAreaTooltipMsg={similarToDoc && selection?.includes(text.id)?"To change labels of selected similar record, modify labels of the master record or unselect it":undefined}
                                    enabledSimilarityVoting={!!similarToDoc}
                                    additionalKeywords={additionalKeywords}
                                    //allLabels={allLabels} 
                                    selected={selectedBitmap[index]}
                                    lastSelected={lastSelectedDocId==text.id}
                                    onSelectionChanged={handleSelectionChange} 
                                    onLabelsUpdated={handleLabelsUpdated} 
                                    onAnswerUpdated={handleAnswerUpdated}
                                    simScore={text.score} 
                                    setSimilarityFeedback={setSimilarityFeedback} 
                                    onExcludeClick={onExcludeClick} 
                                    isScrolling={isScrolling}
                                    similarSearchOptions={null}
                                    commonPredictedLabels={commonPredictedLabels}
                                    runAction={runAction}
                                    />
                                    
                                    </div>
                            )

                    }

                }
              }
                </InfiniteScroll>
            
            }
        </Box>
        
        </>
    );
}



  
export const SimilarTexts =({project_id,stateData, onStateChanged, header,similarToDoc,queryFilters,onSelectionChange, onSelectedLabeled, onTextsChanges, eventBus})=>{
    const pageSize =100;

    //const {texts, lastQuerySnapshot, similarToDocId, page} = useMemo=(()=>stateData||{texts:[], lastQuerySnapshot:null,similarToDocId:null, _currentPage:1},[stateData]);

    const [isLoading, setIsLoading]=useState()
    const [hasNextPage, setHasNextPage]=useState()
    const [internalTextState, setInternalTextState]=useState()
    useEffect(()=>{
        stateData&&setInternalTextState(stateData.texts||[])
    },[stateData])
    //const [currentPage, setCurrentPage] = useState(similarToDoc.page);
    //const [texts, _setTexts]=useState([])
    //const [lastQuery, setLastQuery]=useState()
    //const [similarToDocId, setSimilarToDocId]=useState()
    const setTexts=(data,page)=>{
        
        onStateChanged({texts:data, similarToDocId:similarToDoc.id, page, lastQuerySnapshot:JSON.stringify(queryFilters)} )
    }

    const onHideLabeled =()=>{
        let newStateData = {...stateData}
        newStateData.texts=(texts)=>{
            return texts.filter(t=>!(t?.labels?.length))   
        }
        onStateChanged(newStateData)
    }

    const procesTextChanges = (newData)=>{
        
        
        const _processChanges = (newData)=>{
            //I've added similar to doc to the overall changed list, so it would update that record as well... 
            // the consumer (TextDocumentPage) doesnt have updated state yet, and it would trigger cascade of changes, where similarToDoc is beeing changed as well from the old data
            
            //Just sanity check... if it is among data alreday, dont add it twice
            let similarIndex = newData.findIndex(t=>t?.id==similarToDoc.id) 
            if (similarIndex>=0){
                //this should never happend... it would mean that similar docs contains the reference document it self
                newData[similarIndex] = similarToDoc 
                onTextsChanges && onTextsChanges(newData) 
            }
            else{
                onTextsChanges && onTextsChanges([...newData, similarToDoc]) 
            }


            eventBus&&eventBus.publish({
                topic:"SimilarRecordsUpdated", 
                payload:{
                        similar_to_doc_id:similarToDoc.id
                    }
                })
        }

        if (typeof newData ==="function"){
            setTexts((oldData)=>{
                let newDataRes = newData(oldData)
                _processChanges(newDataRes)
                return newDataRes
            })
        }
        else{
            setTexts(newData)
            _processChanges(newData)
        }

    }

    const loadNextPage = ()=>{
        let page = (stateData?.page||1)+1
        //setCurrentPage(page)
        loadData(project_id, page, filtersTemporaryDisabled)    
    }

    
    useEffect(()=>{
        let queryIsTheSame= (stateData?.lastQuerySnapshot==JSON.stringify(queryFilters));        
        if (stateData?.similarToDocId!=similarToDoc?.id|| !queryIsTheSame){
            setFiltersTemporaryDisabled(false)
            setIsLoading(true)
            setTexts([])
            //setCurrentPage(0)
            loadData(project_id,1, false)
        }
    },[similarToDoc,queryFilters])

    const [filtersTemporaryDisabled, setFiltersTemporaryDisabled] = useState();
    useEffect(()=>{
        if (stateData?.texts){        
            if (filtersTemporaryDisabled){
                setIsLoading(true)
                setTexts([])
                loadData(project_id,1, filtersTemporaryDisabled)
            }
        }
    },[filtersTemporaryDisabled])
    

    const loadData = (project_id,  page, disableFilters)=>{
        if (!similarToDoc) return;
        page=page||1;
        let query={
            similar_to_doc:similarToDoc.id,
            skip:pageSize*(page-1),
            take:pageSize,

        }
        if (queryFilters && !disableFilters){
            query = Object.assign(query,queryFilters)
        }
      
        setIsLoading(true);    
        // if (page==1) {
        //     setTexts([]);
        // }
        getApi().searchTexts(project_id, query, (newData)=>{
            //setIsLoading(false);
          
          if (page>1){
            if (stateData?.texts&& newData && newData.length>0){
              
              
              //newData = data.concat(newData.filter(t=>!existingIds.has(t.id))); //this breaks infinite view... since I dont get all the expected records count
              newData = stateData.texts.concat(newData);
              setTexts(newData, page);
            }
          }
          else{
            setTexts(newData,page);
            
          }
          
            if (newData.length < query["take"] || newData.length === 0) {
                setHasNextPage(false);
            }
            else {
                setHasNextPage(true);
            }
          }) .finally(()=>{
            setIsLoading(false)
          })
      }

    

    return (<Texts 
        isLoading={isLoading} 
        header={header}
        onHideLabeled={onHideLabeled}
        similarToDoc={similarToDoc}
        onSelectionChange={onSelectionChange} 
        onSelectedLabeled={onSelectedLabeled}
        filters={queryFilters}
        onTextsChanges={procesTextChanges} 
        onDisableFilters={filtersTemporaryDisabled?undefined:()=>setFiltersTemporaryDisabled(true)}
        hasNextPage={stateData?.hasNextPage} 
        texts={internalTextState} 
        pageSize={pageSize} 
        
        loadNextPage={loadNextPage}
        eventBus={stateData?.texts&&eventBus}
        toolbarOptions={[ToolbarOptions.labeling_preferences]}
        />)
}