import { Box, InfiniteScroll,Spinner } from "grommet";
import {useEffect, useState,createRef, useMemo} from "react";
import { TopicCard } from "./TopicCard";
import {
    Chart as ChartJS,
    LinearScale,
    PointElement,
    Tooltip,
    
  } from 'chart.js';
import { Bubble } from 'react-chartjs-2';
import {randomColor} from "randomcolor";
import zoomPlugin from 'chartjs-plugin-zoom';
import { getApi } from "../ApiService";
ChartJS.register(LinearScale, PointElement,zoomPlugin, Tooltip);

export const Topics = ({topics, loadNextPage, hasNextPage,  pageSize=100})=> {
    const [chartData, setChartData] = useState([]);
    const [scaleFactor, setScaleFactor] = useState(1);
    
    
    useEffect(()=>{
        
        if (topics &&chartData && topics.length== chartData.length){
            return;
        }
        setChartData(getChartData())
        

    }, [topics]);

    function median(values){
        if(values.length ===0) throw new Error("No inputs");
      
        values.sort(function(a,b){
          return a-b;
        });
      
        var half = Math.floor(values.length / 2);
        
        if (values.length % 2)
          return values[half];
        
        return (values[half - 1] + values[half]) / 2.0;
      }
      
    function getChartData(){
        let topics_filtered = topics.filter(t=>t.topic_id>0);
        const maxSize =Math.max.apply(Math, topics_filtered.map(t=>t.size))
        const avg =median( topics_filtered.map(t=>t.size))

        let scale=(num)=>{
            
            if (Math.log10((num/avg+1))>2){
                return 10*(num/maxSize)*Math.log10(num);
            }
            else{
                return num/avg;
            }
        }

        
        let chart_data = topics_filtered.slice(1).map(t=>{
            return {
              label: `${t.topic_name} (${t.topic_id}) - count: ${t.size}`,
              data: [{ 
                    x:t.centroid[0], 
                    y:t.centroid[1], 
                    r:4+scale(t.size)
                }],
                backgroundColor:getTopicColor(t.topic_id, 0.9,"light"),
                borderColor:"transparent"
                
            }; 
          });

        return chart_data;
    }
    
    function getTopicColor(topic_id, alpha, luminosity){
        
        return topic_id>=0?randomColor({seed:topic_id*10, luminosity: luminosity||'dark', format: 'rgba', alpha:alpha||1}):"dark-2"
    }
   
    const chartOptions={
        scales: {
        x: {
            display: false,
        },
        y: {
            display: false,
        }
        
        },
        onClick: function(e,i) {
            const topic_index_to_select = i[0] && i[0].datasetIndex+1;
            
            setTopicToShow(topic_index_to_select)
            if (topic_index_to_select ){
                setTimeout(()=>{
                    let topic = topics[topic_index_to_select] //+1 to index because we skiped first topic - outliers in chart
                    if (topic.ref){
                        topic.ref.current.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start',
                        });
                    }
                },800)
               
            }
        
        },
        plugins: {
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'xy',
                  },
              zoom: {
                onZoomComplete:({chart})=>{
                    // const zoomScale=chart.getZoomLevel()
                    // setScaleFactor(zoomScale)
                    // chart.zoom(zoomScale)
                    // //chart.updatr("none")
                },
                wheel: {
                  enabled: true,
                },
                pinch: {
                  enabled: true
                },
                mode: 'xy',
             
                }
            },
          }
        
    };

   
        
    const [topicToShow,setTopicToShow]=useState()
    return (
        <Box   flex overflow="auto" gap="xxsmall" pad="small">
        {
            <InfiniteScroll items={[[],...topics]} 
                step={pageSize}
                show={topicToShow}
                //chromatic= {{ disable: true }}
                renderMarker={(marker)=>{
                    if (hasNextPage){
                    return (<Box margin="small" align="center"><Spinner/> loading...{marker}</Box>)
                    }
                    else return (<Box margin="small" round border align="center"> the end (no more data) {marker}</Box>)
                }
                } 
                onMore={() => loadNextPage()}
            >
            {(item) => {

                if (Array.isArray(item)){
                    return (
                      <Box  key="-10" style={{minHeight:"350px", margin:"0px"}} pad="0px 0px" border round background="dark-1" flex={false} overflow={"hidden"}>
                            
                         <Bubble
                         style={{minHeight:"500px", width:"100%", margin:"-80px" }}
                       plugins={[zoomPlugin]}
                        data={{datasets: chartData}}
                        options={chartOptions}
                        />
                        
                    </Box>
                        )
                }
                else{
                    item.ref = createRef();
                    return (<div ref={item.ref}  key={item.topic_id} style={topics[topicToShow]?.topic_id==item.topic_id?{border:"2px solid red"}:undefined}  > <TopicCard topic={item} color={1==0?"red": getTopicColor(item.topic_id, 0.5)} /></div>)
                }
                //<Box pad="medium" round border key={item.topic_id}>{item.topic_id}{item.topic_name}</Box>
            }
            }
            
            </InfiniteScroll>
        }
        </Box>
    );
}