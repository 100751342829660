
// Import the functions you need from the SDKs you need
import { initializeApp} from "firebase/app";
import {firebaseConfig} from "./secrets/firebaseConfig"

import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
require('firebase/auth');
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries



// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
//export const analytics = getAnalytics(app);
//export const db = getFirestore(app);
const auth = getAuth(firebaseApp);
export {firebaseApp,auth};