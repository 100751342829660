import { getActiveElement } from '@testing-library/user-event/dist/utils';
import { Box, Layer, Spinner, Form, TextInput, FormField, Button, Text, Select, Stack } from 'grommet'


import { Close, Down, Schedule, Task } from "grommet-icons"
import { useState, useEffect, useMemo } from "react";
import { getApi } from '../../ApiService';
import { Badge } from '../../components/Badge';
import { ModelSettingsBox } from '../../components/ModelSettingsBox/ModelSettingsBox';
import { Switch } from '../../components/Switch';




export const ModelSettingsAnalysisDialog = ({ taskId=undefined, nodeName=undefined, modelSettings=undefined, onClose }) => {
    const [wait, setWait] = useState();




    const [state, setState] = useState()
    const [taskStatus, setTaskStatus] = useState()
    const [activeView, setActiveView] = useState("analysis")
    const originalSettings = useMemo(()=>state?.settings || modelSettings, [state] )
    useEffect(() => {
        //     let data ={"accuracy":0.08571428571428572,"handling_stats":{"manual":0.14,"model-auto":0.36,"model-review":0.5},"route_stats":{"default_handling":0.14,
        //     "route.0":{"matched":0.5,"used":0.5},
        //     "route.1":{"matched":0.62,"used":0.36}
        // }}

        // let previousRouteId;
        // Object.keys(data.route_stats).filter(t=>t!=="default_handling").forEach((route_id)=> {
        //     if (previousRouteId){
        //         data.route_stats[route_id].padRight=(data.route_stats[previousRouteId].padRight||0) + data.route_stats[previousRouteId].matched - (data.route_stats[route_id].matched -data.route_stats[route_id].used )
        //     }
        //     previousRouteId=route_id
        // })
        // setState(data)
        

        //return 
        
        if (taskId){
            readData(taskId)
        }
            

    }, [])

    function startAnalysis(){
        
        setWait(true)
        getApi().analyseModelSettings(nodeName, modelSettings).then(({ task_id }) => {
            waitUntilFinish(task_id)
        })
    }

    function waitUntilFinish(task_id) {
        setTimeout(() => {
            getApi().getTaskStatus(task_id).then(status => {
                setTaskStatus(status)
                if (status.state != "ERROR" && status.state != "FINISHED" && status.state != "TIMEOUT") {
                    waitUntilFinish(task_id)
                }
                else {

                    setWait(false)
                    if (status.state == "FINISHED") {

                        readData(task_id)
                    }
                }

            })
        }, 5000)

    }


    function readData(task_id) {

        getApi().getTaskResultData(task_id).then(data => {

            let previousRouteId;
            Object.keys(data.route_stats).filter(t => t !== "default_handling").forEach((route_id) => {
                if (previousRouteId) {
                    data.route_stats[route_id].padRight = (data.route_stats[previousRouteId].padRight || 0) + data.route_stats[previousRouteId].matched - (data.route_stats[route_id].matched - data.route_stats[route_id].used)
                }
                previousRouteId = route_id
            })
            setState(data)
        })
    }

    return (

        <Layer onEsc={onClose}>
            <Box width={"800px"} margin="small" gap="small" >
                <Box direction="row" justify='between' width="100%">
                    <Text size="large">Model routing analysis </Text>
                    <Close style={{ float: "right" }} onClick={onClose} />
                </Box>
                {
                    !state ? (
                        <Box align='center' justify='center'>
                            {wait ?( <Box>
                                <Spinner size="large" alignSelf="center" />

                                {taskStatus && ( <Box>
                                        <Text>{taskStatus.state} {taskStatus.current_subtask}: {taskStatus.progress_current}/{taskStatus.progress_total}</Text>
                                        {taskStatus?.state === "ERROR" && (<Text color="red">{taskStatus.error_msg}</Text>)}
                                    </Box>
                                )}
                                </Box>
                            ):(
                            !taskId&&(
                                <Box gap="small" align='center'>
                                    <Text size="small">Warning: Running the analysis can affect the node performance... </Text> 
                                    <Text size="small">... running it when node is used in production is not recomended</Text>
                                    <Text size="small">It can take several minutes</Text>
                                    <Button label="Start analysis" onClick={()=>startAnalysis()}/>
                                </Box>

                            ))}

                        </Box>
                    )
                        :
                        (<Box  align='center' gap="small">
                            <Switch options={[{key:"settings",label:"Settings"},{ key:"analysis",label:"Analysis"}]} active={activeView} onActiveChange={(val)=>setActiveView(val)} />

                            {activeView=="settings" ? (
                                <Box flex={false} width="100%" gap="small" border pad="10px">
                                    <ModelSettingsBox modelSettings={originalSettings} readonly/>
                                    </Box>
                                    ):(
                                
                                    <Box flex={false} width="100%" gap="small" border pad="10px" >


                                        <Box width="75%" direction='row'>

                                            <Box width="100%" flex={false} border background="orange" align='center' pad="10pt" round="small">
                                                {taskStatus?.progress_total} random examples
                                            </Box>
                                        </Box>

                                        {Object.keys(state.route_stats).filter(t => t != "default_handling").sort().map((route_id, i) => ({route_id, route:originalSettings.routing[i]})).map(({route_id, route})=>(
                                            <Box width="100%" align='start' gap="10px">
                                                <Box direction='row' width="75%" justify='between' pad="0px 10px" >
                                                    <Down color='lightgray' /><Down color='lightgray' /><Down color='lightgray' /><Down color='lightgray' /><Down color='lightgray' /><Down color='lightgray' /><Down color='lightgray' /><Down color='lightgray' /><Down color='lightgray' /><Down color='lightgray' />
                                                </Box>
                                                <Box width="75%" direction='row'>

                                                    <Box width="100%" flex={false} border round="small" justify='end' style={{ overflow: "hidden" }} direction="row" align='center'>


                                                           <Box width="300px" margin={state.route_stats[route_id].matched >0.5?"0px -250px 0px 0px":undefined} style={{zIndex:900}} align='center'>
                                                                    <Text>{route?.handling}</Text>
                                                                    <Badge background="lightgray" size="xsmall" value={`${route.rule_type } : ${route.similarity_range?.min} - ${route.similarity_range?.max} %`}/>
                                                                    <Badge background="lightgray" size="xsmall" value={`${(route.predicted_labels&& route.predicted_labels?.length)?(route.predicted_labels.join(",")):"any label"} : ${Math.round((route.prediction_score_range?.min||0.5)*100)} - ${Math.round((route.prediction_score_range?.max||1)*100)} %`}/>
                                                            </Box>
                                                        <Box
                                                            width={`${Math.round(state.route_stats[route_id].used * 100)}%`}

                                                            height="100%"
                                                            flex={false}
                                                            align="center"
                                                            justify='center'

                                                            background="orange">
                                                                 
                                                        </Box>
                                                        <Box
                                                            width={`${Math.round((state.route_stats[route_id].matched - state.route_stats[route_id].used) * 100)}%`}
                                                            margin={`0px ${(state.route_stats[route_id].padRight || 0) * 100}% 0px 0px`}
                                                            height="100%"
                                                            flex={false}
                                                            align="center"
                                                            justify='center'
                                                            background="gray">

                                                        </Box>


                                                    </Box>
                                                    <Box flex="grow" pad="5px 10px">
                                                        <Text>{route_id}</Text>
                                                        <Text size="10pt">{`Match rate: ${Math.round(state.route_stats[route_id].matched * 100)}%`}</Text>
                                                        <Text size="10pt">{`Used rate: ${Math.round(state.route_stats[route_id].used * 100)}%`}</Text>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        ))}
                                        <Box direction='row' width="75%" justify='between' pad="0px 10px">
                                            <Down color='lightgray' /><Down color='lightgray' /><Down color='lightgray' /><Down color='lightgray' /><Down color='lightgray' /><Down color='lightgray' /><Down color='lightgray' /><Down color='lightgray' /><Down color='lightgray' /><Down color='lightgray' />
                                        </Box>
                                        <Box direction='row' width="100%" >
                                            <Box width="75%" direction='row'>
                                                <Box width="100%" flex={false} border round="small" align='start' style={{ overflow: "hidden" }}>
                                                    <Box width={`${Math.round(state.route_stats.default_handling * 100)}%`} height="100%" flex={false} background="orange" align='center' justify='center'>
                                                        <Text>{state.route_stats.default_handling > 0.3 && originalSettings.default_handling}</Text>
                                                    </Box>
                                                    <Text>{state.route_stats.default_handling <= 0.3 && originalSettings.default_handling}</Text>
                                                </Box>
                                            </Box>
                                            <Box flex="grow" pad="5px 10px">
                                                <Text weight={800}>Default handling</Text>
                                                <Text size="10pt">{`Match rate: ${Math.round(state.route_stats.default_handling * 100)}%`}</Text>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Box  >
                                                <Text >By handling type</Text>
                                                <Box direction='row' gap="5px">
                                                    {
                                                        Object.keys(state.handling_stats).map(k => (
                                                            <Badge value={`${k}: ${Math.round(state.handling_stats[k] * 100)}%`} />
                                                        ))
                                                    }
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box direction='row' gap="small" >
                                            <Box>
                                            <Text>Estimated accuracy</Text>
                                            <Text size="8pt">On the records with allowed prediction</Text>

                                            </Box>
                                            <Badge background="brand" size="medium" value={`${Math.round(state.accuracy * 100)}%`} />
                                        </Box>

                                    </Box>
                                

                            )}
                        </Box>)
                }
                <Box direction="row" justify="end" gap="xsmall">

                    <Button secondary label="Close" onClick={onClose} />
                </Box>
            </Box>
        </Layer>

    )
}
