import { Box,Text } from "grommet";
import { TipIfContent } from "./TipIfContent";

const Badge = ({value, 
    background=undefined, 
    textColor=undefined, 
    size=undefined,
    border=undefined, 
    tooltip=undefined, 
    onClick=undefined, 
    textStyle=undefined, 
    icon=undefined,
    pad=undefined,
    gap=undefined,
    boxExtra=undefined
})=>{
    
    return (
    <TipIfContent content={tooltip}>
        <Box {...boxExtra} flex={false} onClick={onClick} focusIndicator={false} align='center' pad={pad||"2px 10px"} direction='row' gap={gap||"5px"} round background={background}  border={border==undefined?(background?undefined:true):border} >
            {icon}
            <Text  style={textStyle} size={size||"small"} color={textColor}>{value}</Text>
        </Box>    

    </TipIfContent>)

}

export {Badge}