import { grommet } from "grommet";
import { deepMerge } from "grommet/utils";

export const theme = deepMerge(grommet, {
  global: {
    font:{
      size:"14px",
      height:"16px"
    },
    focus:{ border: {color:"dark-2"}},
    colors: {
      active: "light-3",
      brand:"#d536b4",
      "accent-1":"#ED9B40",
      focus: '#50c050',
      'dark-1': '#2a262a',
      'dark-2': '#4c484c',
      'dark-3': '#635d63',
      'dark-4': '#6f6b6f',
      'light-1': '#ffffff',
      'light-2': '#f9f7f7',
      'light-3': '#f9f7f7',
    },
    elevation: {
      light: {
        small: "0 0 1px 0 rgba(0, 0, 0, 0.40), 0 1px 2px 0 rgba(0,0,0,0.40)",
        medium: "0 0 2px 0 rgba(0,0,0,0.40), 0 2px 4px 0 rgba(0,0,0,0.40)",
        large: "0 0 1px 0 rgba(0,0,0,0.40), 0 4px 8px 0 rgba(0,0,0,0.40)",
        xlarge: "0 0 1px 0 rgba(0,0,0,0.40), 0 8px 16px 0 rgba(0,0,0,0.40)"
      }
    },
    
    input: {
      weight: 400,
      padding:"5px",
      font:{
        size:"12pt"
      },
      

    },
    size: {
      avatar: "36px",
      sidebar: "60px"
    }
  },
  icon: {
    size: {
      medium: "18px"
    }
  },
  tip:{
    content:{
      background:"white"
    }
  },
  
  textArea:{disabled:{opacity:0.7}},
  textInput:{disabled:{opacity:0.7}},

  notification:{critical:{toast:{
    time:200000,
    background:{color:"dark-1"},


  }}},
  
  text:
  {
    xsmall: {
      size: '10px',
      height: '12px',
    },
    small: {
      size: '12px',
      height: '14px',
    },
    medium: {
      size: '14px',
      height: '16px',
    },
    large: {
      size: '18px',
      height: '22px',
    },
    xlarge: {
      size: '22px',
      height: '26px',
    },
    xxlarge: {
      size: '26px',
      height: '30px',
    },
  },
  
  button:{
    
    padding:
  {
    vertical: "5px",
    horizontal: "12px",
    
  
  }}
  
});
