import {React, useState, useEffect, useContext, useMemo} from "react";
import { Box, Text, Grid, Button,Tip,ResponsiveContext, Spinner } from "grommet";

import { Tag,Checkmark ,FormClose} from "grommet-icons";
import { useAppContext } from "../../AppContext";
import { TipIfContent } from "../TipIfContent";

import "./LabelTag.css"


export const LabelTag = ({label,active,size, predicted, suggested,  readonly, onActiveChange, invalid, labelSettings, isUpdating}) => {
    const [isActive, setActive] = useState(active);
    const [isActiveChangePending, setActiveChangePending] = useState(false);
    
    const color = useMemo(()=>{
        return (labelSettings && labelSettings.color)
    }, [labelSettings])
    useEffect(()=> {
        setActive(active)
        setActiveChangePending(isUpdating)
    }, [active])

    useEffect(()=>{
        setActiveChangePending(isUpdating)
    },[isUpdating])
    
    //if (predicted) return (<Box>predicted: {label}</Box>);

    function toggleActive(active, event) {
        event.stopPropagation();
        if (readonly) return;
        let newState=!active;
        setActive(newState);
        if (onActiveChange){
            setActiveChangePending(true)
            onActiveChange(label, newState);
        } 

    }

    function getTooltip(label,active, predicted, readonly, invalid)
    {
        if (invalid) return "This tag is invalid. It is not setup as valid tag for this project. If you click it, it will disapear and you won't be able tu put it back unless you update labels for your project.";
        let prefix=(readonly?"(readonly) ":"");
         if (predicted && active) return prefix+ "Text class was correctly predicted as '" + label+"'"  ;
         else if (active) return   prefix+ "Text is labeled as '" + label+"'" ;
         else if (predicted && !active) return  prefix+ "Text was predicted as '" + label+"' but is not labeled so" ;
         else if (suggested) return prefix+ "Label suggested based on similar texts";
         return   prefix+ "Text is not labeled as '" + label+"'";
    }
   function getClass(active, predicted, invalid )
   {
        if (invalid) return "labelTag invalidLabel disabledSelectionChange";
        if (predicted && active) return "labelTag predictedLabel activeLabel disabledSelectionChange";
        else if (active) return "labelTag activeLabel disabledSelectionChange";
        else if (suggested) return "labelTag suggestedLabel disabledSelectionChange";
        else return "labelTag inactiveLabel disabledSelectionChange";
   }
   const keyBinding = useMemo(()=>labelSettings && labelSettings.key_bindings && labelSettings.key_bindings)

    const {testPermission} = useAppContext();
    const disabledStyle={opacity:0.5}
    const errTip = useMemo(()=>testPermission("MODIFY_DOCS",true));

   let content=(
    <Box direction="row" align="start" style={errTip?disabledStyle:undefined}>
        <TipIfContent content={errTip}>
        <Button 
                color={labelSettings?.color}  
                className={(getClass(isActive, predicted, invalid)) + (size=="small"?" smallButton":"")}  
                primary={isActive} 
                icon={ isActiveChangePending?(<Spinner style={{padding:"5px", height:"10px",width:"10px"}}/>):( invalid? <FormClose/> :((labelSettings  && labelSettings.icon && <Text>{labelSettings.icon}</Text>)||<Tag size="15px"/>))} 
                label={
                <Box direction="row" align="start">
                    {label}
                    
                    {keyBinding && size!="small" && !readonly && <Box round pad="1px 3px" margin="-5px 2px 0px" background="dark-1"><Text  color="white" size="10px">{keyBinding}</Text></Box>}
                </Box>
                } size="small" 
                hoverIndicator={false}
                onClick={(event)=>errTip?undefined:toggleActive(isActive, event)}
            />
        </TipIfContent>
        
    </Box>
   );
    return(
        <Box className="labelTagBoundingBox" border={{ size:"small", color:(predicted||suggested)?"brand":"transparent", style:!predicted?"dashed":undefined}} round="large" pad="2px" >
            {content}
            {/* {size!=="small"?
            //(<Tip  content= {getTooltip(label, isActive, predicted,readonly, invalid)}  > <>{content}</></Tip>)
            : content
            } */}

        </Box>
        )
};

