export const modifyState = (modifyFunc, currentState, setState)=>{
    const newState=Object.assign({},currentState);
    modifyFunc(newState);
    setState(newState);
  }

  const humanizedNumberThresholds=[
    {limit:10**3,divide:10**3,round:1,sufix:"k"},
    {limit:10**4,divide:10**3,round:0,sufix:"k"},
    {limit:10**6,divide:10**6,round:1,sufix:"M"},
    {limit:10**7,divide:10**6,round:0,sufix:"M"},
    {limit:10**9,divide:10**9,round:1,sufix:"B"},
    {limit:10**10,divide:10**9,round:0,sufix:"B"},
]

  export function humanizeNumber(number){
    if (number){
        let matchedThreshold=null;
        for(let threshold of humanizedNumberThresholds){
            if (number/threshold.limit>=1){
                matchedThreshold=threshold
            }
            else{
                break
            }
        }
    
        if (matchedThreshold){
            return (Math.floor((10**matchedThreshold.round)*(number/matchedThreshold.divide))/(10**matchedThreshold.round)).toString() + matchedThreshold.sufix
        }
        else{
            return number.toString()
        }
    }
    else{
        return 0;
    }
    
    
  }

//   export function mergeChanges(originalArray, arrayOfChangedObj, idProp){
//     let theIdProp=idProp||"id"
//         if (originalArray){
//           const updateIndex={}
//           for (let i = 0; i < originalArray.length; i++){
//             let found=arrayOfChangedObj.filter(t=>t[theIdProp]== originalArray[i][theIdProp])
            
//             if (found.length>0){
              
//               updateIndex[i]=found[0]
//             }
//           }
    
//           if (Object.getOwnPropertyNames(updateIndex).length>0){
//             let newData = [...originalArray]
//             for (let i of Object.getOwnPropertyNames(updateIndex)){
//               let rec = Object.assign({}, updateIndex[i])
                         
//               newData[i] = rec
//             }
//             return newData
            
//           }
    
//         }
//         return originalArray
//   }

  export function parseFile(file, chunkSize, callback) {
    var fileSize   = file.size;
    var offset     = 0;
    var self       = this; // we need a reference to the current object
    var chunkReaderBlock = null;

    return new Promise((resolve, reject)=>{

      
      var readEventHandler = function(evt) {
          if (evt.target.error == null) {
              
              callback(evt.target.result, offset, offset+evt.target.result.byteLength-1 ).then(canContinue=>{
                    if (canContinue){
                        if (offset < fileSize ){
                          chunkReaderBlock(offset, chunkSize, file);
                        }
                        else{
                            resolve()
                        }                       
                    }
                    else{
                        return reject("callback error")
                    }
              }); // callback for handling read chunk
              offset += evt.target.result.byteLength;
          } else {
              console.error("Read error: " + evt.target.error);
              return reject(evt.target.error)
          }
          if (offset >= fileSize-1) {
              console.log("Done reading file");
              return resolve();
          }

          // of to the next chunk
          
      }

      chunkReaderBlock = function(_offset, length, _file) {
          var r = new FileReader();
          var blob = _file.slice(_offset, _offset+length  );
          r.onload = readEventHandler;
          r.readAsArrayBuffer(blob);
      }

      // now let's start the read with the first block
      chunkReaderBlock(offset, chunkSize, file);
    })
}