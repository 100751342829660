import { ErrorFactory } from '@firebase/util';
import axios, { Axios, AxiosInstance } from 'axios';
import {NodeInfo} from './ViewModel/NodeInfo'
import {NodeSettings} from './ViewModel/NodeSettings'
import { parseFile } from './helpers/helperFunctions';

import { getAuth, onAuthStateChanged } from "firebase/auth";
import {LabelingPreferences} from './ViewModel/LabalingPreferences';
import { ClientJS } from 'clientjs';

let api:ApiService = null;

let abortController =null

function getApi(): ApiService{
  
    
    

    if (!api){ 
        const auth = getAuth();
        onAuthStateChanged(auth, (currentUser) => {
            if (api){
                api.user=currentUser;
            }
        });
        
        //api = new ApiService(process.env.API_URL || "http://localhost:4000/api", auth.currentUser);
        api = new ApiService(process.env.REACT_APP_API_URL || "https://api.labelator.io/app", auth.currentUser);
        //api = new ApiService("http://localhost:4000/", auth.currentUser);
    }
    
    return api;
}



class ApiService{
    baseUrl: string;
    currentUser: object;
    pendingRequests: number;
    errorHandler:(message:string)=>any;
    loadingHandler:(isLoading:boolean)=>any;
    axiosInstance: AxiosInstance
    user:any

    constructor(baseUrl: string, user:Object) {
      this.baseUrl = baseUrl ;
      this.user=user;
      this.axiosInstance= axios.create();
      const clientJS = new ClientJS();
      this.clientFingerprint =  clientJS.getFingerprint();
      this.axiosInstance.interceptors.request.use(
        
        async config =>  {
            this.pendingRequests=(this.pendingRequests||0)+1;
            if (this.loadingHandler){
                this.loadingHandler(true)
            }
            if ( api.user){
                const token = await api.user.getIdToken();
                if (token && ! config.headers['Authorization']) {
                    config.headers['Authorization'] = 'Bearer ' + token;
                    config.headers['client-id'] = this.clientFingerprint;
                }
            }
            // config.headers['Content-Type'] = 'application/json';
            return config;
        },
        
        error => {
            
            Promise.reject(error)
        });
        this.axiosInstance.interceptors.response.use((success)=>{
            if (this.loadingHandler)
                this.loadingHandler(false)
            if (this.pendingRequests){
                this.pendingRequests=this.pendingRequests-1;
            }
            return success;
        },
         (error)=>{
            if (this.axiosInstance.isCancel(error)){
                return
            }
            if (this.pendingRequests){
                this.pendingRequests=this.pendingRequests-1;
            }
            if (error.response && error.response.status===403)
                (!window.location.href.endsWith("/login")) && setTimeout(()=>window.location.href="/login",500);
            else if (error.message && this.errorHandler){
                
                this.errorHandler(error.response?.data?.message || error.message);

                if (this.loadingHandler){
                    this.loadingHandler(false)
                }
            }
            return Promise.reject(error);
        })
    }
    
    getAuthStatus(getDataCallback:(status:{status:boolean, user?:any, message?:string})=>any){
        
        return this.axiosInstance.get(this.baseUrl+"/login/status")
        .then((response) => {
            getDataCallback && getDataCallback({status:true, user:response.data});
            return response.data;
        })
        .catch(error=>getDataCallback && getDataCallback({status:false,message:error.response.data.message}))
        
    }

    getPermissions(getDataCallback){
        
        return this.axiosInstance.get(this.baseUrl+"/login/permissions")
        .then((response) => {
            getDataCallback && getDataCallback(response.data);
            return response.data;

        })
        .catch(error=>getDataCallback({status:false,message:error.response.data.message}))
        
    }

    getUsers(){
        return this.axiosInstance.get(this.baseUrl+"/users")
        .then((response) => {
            return response.data;
        })
        
    }


    patchUser(id, data){
        
        return this.axiosInstance.patch(this.baseUrl+"/users/"+id, data)
        .then((response) => {
            return response.data;
        })
        
    }

    getUserSessionLatest(id){
        return this.axiosInstance.get(this.baseUrl+"/users/"+id+"/sessions/latest")
        .then((response) => {
            return response.data;
        })
        
    }

    getUserSessions(id, from_date,to_date){
        return this.axiosInstance.get(this.baseUrl+"/users/"+id+"/sessions",{params:{
            from_date:from_date,
            to_date:to_date
        }})
        .then((response) => {
            return response.data;
        })
        
    }

    getTennantInfo(){
        return this.axiosInstance.get(this.baseUrl+"/tennant")
        .then((response) => {
            return response.data;
        })
    }

    getProjectStatus(projectId:string, getDataCallback){
        
        this.axiosInstance.get(this.baseUrl+"/projects/"+projectId+"/status",{timeout:5*60*1000})
        .then((response) => {
            getDataCallback(response.data);
        });
    }

    getProjectInfo(projectId:string, getDataCallback){
        
        return this.axiosInstance.get(this.baseUrl+"/projects/"+projectId+"/info")
        .then((response) => {
            getDataCallback && getDataCallback(response.data);
            return response.data;
        });
    }

    getProjectPredictionInfo(projectId:string){
        
        return this.axiosInstance.get(this.baseUrl+"/projects/"+projectId+"/models/prediction_info")
        .then((response) => {
            return response.data;
        });
    }

    patchProjectLabelingPreferences(projectId:string, labelingPrerences:LabelingPreferences){
        
        return this.axiosInstance.patch(this.baseUrl+"/projects/"+projectId+"/labeling-preferences/", labelingPrerences)
        .then((response) => {
            return response.data;
        });
    }


    searchProjects(searchTerm:string, exactMatch:Boolean, getDataCallback){
        let  queryParams={exact_match:exactMatch}
        if (searchTerm){
            queryParams["name"]=searchTerm
        }
      
        return this.axiosInstance.get(this.baseUrl+"/projects/search", {params: queryParams})
        .then((response) => {
            getDataCallback&& getDataCallback(response.data);
            return response.data;
        });
    }

    postProject(projectData:Object, getDataCallback, processTexts:bool, merge_with_new_data:bool){
       
        return this.axiosInstance.post(this.baseUrl+"/projects",projectData, {params:{download_and_process_data:processTexts, merge_with_new_data:merge_with_new_data}})
        .then((response) => {
            getDataCallback && getDataCallback(response.data);
        });
    }

    applyLabelsFromProject(projectId:str, source_project_id:str, missing_label_behaviour:str, getDataCallback){
       
        this.axiosInstance.put(this.baseUrl+"/projects/"+projectId+"/apply_labels",null, {
            params:{
                source_project_id: source_project_id,
                missing_label_behaviour:missing_label_behaviour
            }
        })
        .then((response) => {
            getDataCallback && getDataCallback(response.data);
        });
    }

    deleteDataFromProject(projectId:str, filters, random_sample_fraction:str){
       
        return this.axiosInstance.post(this.baseUrl+"/projects/"+projectId+"/delete-docs",{
            filters,
            random_sample_fraction
        })
        .then((response) => {
            return response.data
        });
    }

    getGoogleAuthUrl(project_id, final_redirect,reprocess){
        
        return this.axiosInstance.get(this.baseUrl+`/login/google_auth`, {params: {
            project_id:project_id, 
            final_redirect:final_redirect,
            reprocess:reprocess
        }}).then(response=> response.data["authorization_url"])
        
    }

    putLabel(projectId:str, label:str, getDataCallback){
       
        return this.axiosInstance.put(this.baseUrl+"/projects/"+projectId+"/labels/",{label:label})
        .then((response) => {
            getDataCallback && getDataCallback(response.data);
            return response.data;
        });
    }

    putLabelKeyword(projectId:str, label:str,keyword:str, getDataCallback){
       
        return this.axiosInstance.put(this.baseUrl+"/projects/"+projectId+"/label-keywords/", {label:label,keyword:keyword})
        .then((response) => {
            getDataCallback && getDataCallback(response.data);
            return response.data;
        });
    }

    getApiToken(getDataCallback){
       
        return this.axiosInstance.post(this.baseUrl+"/login/getApiToken")
        .then((response) => {
            getDataCallback && getDataCallback(response.data);
            return response.data;
        });
    }

    getInviteLinkParams(invite_email,new_tennant,user_profile){
       
        return this.axiosInstance.get(this.baseUrl+"/login/generate-invite-link",{params:{
            invite_email:invite_email,
            new_tennant:new_tennant,
            user_profile:user_profile
        }})
        .then((response) => {
           return response.data
        });
    }

    generateInviteLink(invite_email,new_tennant,user_profile, root_url){
       
        return this.axiosInstance.post(this.baseUrl+"/login/generate-invite-link",{
            invite_email:invite_email,
            new_tennant:new_tennant,
            user_profile:user_profile,
            root_url:root_url
        })
        .then((response) => {
           return response.data
        });
    }

    activateInvite(invite_id,invite_check, email, organization){
       
        return this.axiosInstance.put(this.baseUrl+"/login/activate-invite",null,{params:{
            invite_id:invite_id,
            invite_check:invite_check,
            email: email,
            organization
        }})
        .then((response) => {
           return response.data
        });
    }
    validateInvite(invite_id,invite_check, email, organization){
       
        return this.axiosInstance.post(this.baseUrl+"/login/validate-invite",null,{params:{
            invite_id:invite_id,
            invite_check:invite_check,
            email: email,
            organization
        }})
        .then((response) => {
           return response.data
        });
    }

    activateAccount(activation_code, tenant_id){
       
        return this.axiosInstance.post(this.baseUrl+"/login/activate",null,{params:{
            activation_code:activation_code,
            tenant_id:tenant_id
        }})
        .then((response) => {
           return response.data
        });
    }

    updateUser(data:{
        email?:string,
        display_name?:string,
        password?:string
    }){
       
        return this.axiosInstance.patch(this.baseUrl+"/login/current-user",data)
        .then((response) => {
           return response.data
        });
    }

    googleAuthCallback(params){
       
        return this.axiosInstance.get(this.baseUrl+"/login/google_auth_callback",{params:params}).then((response)=>{
            return response.data
        });
    }
        

    getAuthUrlParameters(projectId,parameter){
        let  queryParams={project_id:projectId,parameter:parameter}
        return this.axiosInstance.get(this.baseUrl+`/login/getAuthUrlParams`, {params: queryParams})
        .then((response) => response.data);
    }

    scheduleProjectExport(projectId,getDataCallback){
        let url=this.baseUrl+`/projects/${projectId}/export`
        return this.axiosInstance.post(url)
        .then((response) => response.data);
    }

    getDownloadFileUrl(projectId,fileName,getDataCallback){
        this.getAuthUrlParameters(projectId,fileName).then(res => {
            let url = this.baseUrl+`/projects/${projectId}/files/${fileName}?${new URLSearchParams(res).toString()}`
            getDataCallback&&getDataCallback(url)
            return url;
        })
    }

    getTasks(projectId:string, getDataCallback){
       
        this.axiosInstance.get(this.baseUrl+"/projects/tasks", {projectId:projectId})
        .then((response) => {
            getDataCallback && getDataCallback(response.data);
        });
    }

    deleteTask(taskId:string,getDataCallback){
       
        return this.axiosInstance.delete(this.baseUrl+"/projects/tasks/"+taskId)
        .then((response) => {
            getDataCallback && getDataCallback(response.data);
            return response.data;
        });
    }

    stopTask(taskId:string,getDataCallback){
       
        return this.axiosInstance.put(this.baseUrl+"/projects/tasks/"+taskId+"/stop")
        .then((response) => {
            getDataCallback && getDataCallback(response.data);
            return response.data;
        });
    }

    getTaskStatus(taskId:string){
       
        return this.axiosInstance.get(this.baseUrl+"/projects/tasks/"+taskId)
        .then((response) => {
            return response.data
        });
    }

    getTaskResultData(taskId:string){
       
        return this.axiosInstance.get(this.baseUrl+"/projects/tasks/"+taskId+"/result-data")
        .then((response) => {
            return response.data
        });
    }

  

    uploadFiles(projectId:string, files:Object[], getDataCallback, processTexts){
       
       let formData = new FormData();
       
       files.forEach(f=> formData.append(f.name, f))
       
       return this.axiosInstance.post(this.baseUrl+"/projects/"+projectId+"/files", formData, { headers: { 'Content-Type': 'multipart/form-data' }, params:{download_and_process_data:processTexts||false} } 
       ).then((response) => {
            getDataCallback && getDataCallback(response.data);
         });
        // this.axiosInstance.post(this.baseUrl+"/projects",projectData, {params:{download_and_process_data:processTexts}})
        // .then((response) => {
        //     getDataCallback && getDataCallback(response.data);
        // });
    }

  

    uploadImportFiles( file:Object, getDataCallback){
        const chunk_size =10* 1024*1024 // 5Mb
 
        return this.axiosInstance.get(this.baseUrl+"/projects/import").then((response)=>{
            let upload_url = response.data["upload_url"]
            let call_when_finished = response.data["call_when_finished"]
            let uploads=[]
            let hasErrors=false;


            return parseFile(file, chunk_size, (data, start, end)=>{
                let headers = { 
                    "Content-Type":"binary/octet-stream",
                    //"Content-Length":data.byteLength, // this is considered unsafe and browser is setting is for us
                } 
                if (end<file.size){
                    headers["Content-Range"] = `bytes ${start}-${end}/${file.size}`
                }
                let resultPromise = this.axiosInstance.put(this.baseUrl+"/projects/import-upload", data, { 
                    params:{"gcp_upload_url":upload_url},
                    headers:headers })
                .then(resp=>{
                    return true
                })
                .catch(err=>{
                    if (err.response){
                        hasErrors=true
                        console.error(err)
                    }
                    return false
                })
                uploads.push(resultPromise)
                return resultPromise
                
                
            }).then(()=>{
                return Promise.all(uploads).then(()=>!hasErrors&& this.axiosInstance.post(call_when_finished, null)).then(()=>getDataCallback && getDataCallback())
            })
        })
     }

     uploadModelFile( project_id,file, model_name,task_type, getDataCallback){
        const chunk_size =10* 1024*1024 // 5Mb
 
        return this.axiosInstance.get(this.baseUrl+`/projects/${project_id}/models/upload/get_url`, {params:{
            model_name:model_name,
            task_type:task_type,

        }}).then((response)=>{
            let upload_url = response.data["upload_url"]
            let call_when_finished = response.data["call_when_finished"]
            let uploads=[]
            let hasErrors=false;


            return parseFile(file, chunk_size, (data, start, end)=>{
                let headers = { 
                    "Content-Type":"binary/octet-stream",
                    //"Content-Length":data.byteLength, // this is considered unsafe and browser is setting is for us
                } 
                if (end<file.size){
                    headers["Content-Range"] = `bytes ${start}-${end}/${file.size}`
                }
                
                let resultPromise = this.axiosInstance.put(this.baseUrl+`/projects/${project_id}/models/upload`, data, { 
                    params:{"gcp_upload_url":upload_url},
                    headers:headers })
                .then(resp=>{
                    console.log(resp)
                    return true
                })
                .catch(err=>{
                    if (err.response && err.message!="Network Error"){
                        hasErrors=true
                        console.error(err)
                    }
                    return false
                })
                uploads.push(resultPromise)
                return resultPromise
                
                
            }).then(()=>{
                return Promise.all(uploads).then(()=>!hasErrors&& this.axiosInstance.post(call_when_finished, null)).then(()=>getDataCallback && getDataCallback())
            })
        })
     }

    deleteProject(projectId:string, getDataCallback){
        return this.axiosInstance.delete(this.baseUrl+"/projects/"+projectId)
         .then((response) => {
            getDataCallback && getDataCallback(response.data);
            return response.data;
         });
    }

    getProject(projectId:string, getDataCallback){
        
         this.axiosInstance.get(this.baseUrl+"/projects/"+projectId)
         .then((response) => {
             getDataCallback(response.data);
         });
     }

    searchTopics(projectId:string, searchTerm:string, skip,take,getDataCallback){
        let  queryParams={"take":take, "skip":skip}
        if (typeof searchTerm === "string"){
            queryParams["keyword"]=searchTerm
        }
        
        this.axiosInstance.get(this.baseUrl+"/projects/"+projectId+"/topic/search",  {params: queryParams})
        .then((response) => {
            getDataCallback(response.data);
        });
    }

    patchTopic(projectId:string, topic_id:string, payload){

        return this.axiosInstance.patch(this.baseUrl+"/projects/"+projectId+"/topic/"+topic_id, payload)
        .then((response) => {
            return response.data;
        });
    }

    regenerateTopics(projectId:string,getDataCallback){
     
        
        this.axiosInstance.post(this.baseUrl+"/projects/"+projectId+"/topic/regenerate")
        .then((response) => {
            getDataCallback(response.data);
        });
    }

    getTopicStats(projectId:string,topicId, getDataCallback){
        return this.axiosInstance.get(this.baseUrl+"/projects/"+projectId+"/topic/"+topicId+"/stats")
        .then((response) => {
            getDataCallback &&getDataCallback(response.data);
            return response.data;
        });
    }

    getTextDocument(projectId:string, doc_id:string, getDataCallback){
        return this.axiosInstance.get(this.baseUrl+"/projects/"+projectId+"/doc/"+doc_id)
        .then((response) => {
            getDataCallback(response.data);
            return response.data
        });
    }

    getExportText(projectId:string, format, filter, getDataCallback){
        return this.axiosInstance.post(this.baseUrl+"/projects/"+projectId+"/doc/export",filter||{}, {params: {"format":format}} )
        .then((response) => {
            getDataCallback&&getDataCallback(response.data);
            return response.data;
        });
    }

    _getTextsFromData(data){
        
        if (data && data.length>0 && data[0].doc) {
            return data.map(t=>{ 
                t.doc["score"] = t.score; 
                t.doc["liked"] = t.liked; 
                return t.doc;
            });
        }
        else{
            return data;
        }
    }

    addDocument(projectId,documentMsg){
        return this.axiosInstance.post(this.baseUrl+"/projects/"+projectId+"/doc/", [documentMsg])
        .then((response) => {
            return response.data
        });
    }
    addDocuments(projectId,documents){
        return this.axiosInstance.post(this.baseUrl+"/projects/"+projectId+"/doc/", documents)
        .then((response) => {
            return response.data
        });
    }

    
    searchTexts(projectId:string, query:any,  getDataCallback){
        let  queryParams={}
        if (typeof query === "string"){
            queryParams["keyword"]=query
        }
        else{
            queryParams=query
        }
        this.axiosInstance.running=this.axiosInstance.running|{}
        if  (abortController){
            abortController.abort()
        }
        abortController= new AbortController();
        
        
        return this.axiosInstance.get(this.baseUrl+"/projects/"+projectId+"/doc/search", {params: queryParams, signal: abortController.signal})
        .then((response) => {
            getDataCallback && getDataCallback(this._getTextsFromData(response.data));
            return response.data
        })
        
    }

    getTextsCount(projectId:string, query:any){
        let  queryParams={}
        if (typeof query === "string"){
            queryParams["keyword"]=query
        }
        else{
            queryParams=query
        }
        return this.axiosInstance.get(this.baseUrl+"/projects/"+projectId+"/doc/count", {params: queryParams})
        .then((response) => {
            return response.data
        });
    }

    getTextPreditionScores(projectId:string, doc_id:string, getDataCallback){
        
        this.axiosInstance.get(this.baseUrl+"/projects/"+projectId+"/doc/"+doc_id+"/prediction_scores")
        .then((response) => {
            getDataCallback(response.data);
        });
    }

    getTextPreditionScoresBulk(projectId:string, ids:string[]){
        
        return this.axiosInstance.get(this.baseUrl+"/projects/"+projectId+"/doc/prediction_scores?ids="+ids.join(","))
        .then((response) => {
            return response.data;
        });
    }

    getTextLikelyLabels(projectId:string, doc_id:string, getDataCallback){
        
        this.axiosInstance.get(this.baseUrl+"/projects/"+projectId+"/doc/"+doc_id+"/predict_likely_labels")
        .then((response) => {
            getDataCallback(response.data);
        });
    }

   

    getTextLikelyAnswers(projectId:string, doc_id:string,search_text:string=undefined, limit=10){
        let queryParams = search_text?{search_text, limit}:{}
        return this.axiosInstance.get(this.baseUrl+"/projects/"+projectId+"/doc/"+doc_id+"/answer-predictions", {params:queryParams})
        .then((response) => {
            return response.data
        });
    }

    setTextAnswers(projectId:string, docIds:string[],answer:str){
        return this.axiosInstance.post(this.baseUrl+"/projects/"+projectId+"/doc/answers", {doc_ids:docIds, answer:answer})
        .then((response) => {
            return response.data
        });
    }

    analyseText(projectId:string, doc_id:string, select:("keywords"|"likely_labels"|"similar_count"|"similar_not_labeled")[]){
        
        return this.axiosInstance.get(this.baseUrl+"/projects/"+projectId+"/doc/"+doc_id+"/analyze", {params:{"select":select.join(",")}})
        .then((response) => {
            return response.data
        })
    }

    queryTexts(projectId:string, query:Or|DocumentQueryFilter, skip:numbe, take:numbe, order_by:string){
        
        return this.axiosInstance.post(this.baseUrl+"/projects/"+projectId+"/doc/query",query, {params:{skip:skip, take:take, order_by:order_by}})
        .then((response) => {
            return response.data
        })
    }

    nextText(projectId:string, query:any, currentIIndex,  getDataCallback){
        let queryParams=Object.assign({},query)
        queryParams["after"] = currentIIndex;
        queryParams["skip"]=0
        queryParams["take"]=1
        this.axiosInstance.get(this.baseUrl+"/projects/"+projectId+"/doc/search", {params: queryParams})
        .then((response) => {
            if (typeof response.data ==="string") response.data = JSON.parse(response.data);


            getDataCallback(response.data[0]);
        });
    }

    previousText(projectId:string, query:any, currentIIndex,  getDataCallback){
        let queryParams=Object.assign({},query)
        queryParams["before"] = currentIIndex;
        queryParams["skip"]=0
        queryParams["take"]=1
        this.axiosInstance.get(this.baseUrl+"/projects/"+projectId+"/doc/search", {params: queryParams})
        .then((response) => {
            if (typeof response.data ==="string") response.data = JSON.parse(response.data);
            getDataCallback(response.data[0]);
        });
    }

    updateDocLabels(projectId:string, docIds:string[],labels:string[], getDataCallback){
        return this.axiosInstance.patch(this.baseUrl+"/projects/"+projectId+"/doc/labels", {doc_ids:docIds, labels:labels})
        .then((response) => {
            getDataCallback && getDataCallback(response.data);
            return response.data
        });
    }

    reviewDocLabels(projectId:string, docIds:string[],labels:string[], getDataCallback){
        return this.axiosInstance.patch(this.baseUrl+"/projects/"+projectId+"/doc/labels/review", {doc_ids:docIds, labels:labels})
        .then((response) => {
            getDataCallback && getDataCallback(response.data);
            return response.data
        });
    }

    requestReview(projectId:string, docIds:string[], getDataCallback){
        return this.axiosInstance.post(this.baseUrl+"/projects/"+projectId+"/doc/request-review", {doc_ids:docIds})
        .then((response) => {
            getDataCallback && getDataCallback(response.data);
            return response.data
        });
    }

    // excludeDocs(projectId:string, docIds:string[], getDataCallback){
    //     this.axiosInstance.put(this.baseUrl+"/projects/"+projectId+"/doc/excluded", docIds)
    //     .then((response) => {
    //         getDataCallback && getDataCallback(response.data);
    //     });
    // }

    deleteDoc(projectId:string, docId:string){
        return this.axiosInstance.delete(this.baseUrl+"/projects/"+projectId+"/doc/"+docId)
        .then((response) => {
            return response.data
        });
    }

    setSimilarity(projectId:string, doc_id:string, similarDocIds:string[], label:boolean, getDataCallback){
        if (label==null){
            return this.deleteSimilarity(projectId,doc_id,similarDocIds,getDataCallback)
        }
        return this.axiosInstance.put(this.baseUrl+"/projects/"+projectId+"/doc/"+doc_id+"/similar/links/"+ (label?"positive":"negative"), similarDocIds)
        .then((response) => {
            getDataCallback && getDataCallback(response.data);
            return response.data
        });
    }

    deleteSimilarity(projectId:string, doc_id:string, similarDocIds:string[],getDataCallback){
        return this.axiosInstance.delete(this.baseUrl+"/projects/"+projectId+"/doc/"+doc_id+"/similar/links" , {data:similarDocIds})
        .then((response) => {
            getDataCallback && getDataCallback(response.data);
            return response.data
        });
    }

    // getSimilarDocumentsCount(projectId:string, doc_id:string,getDataCallback, limit:int, abortController:AbortController){
    //     let conf={
    //        params:{ 
    //            "min_score":0.80,
    //             "limit":limit
    //         }
    //     }
    //     if (abortController){
    //         conf["signal"] = abortController.signal
    //     }
    //     this.axiosInstance.get(this.baseUrl+"/projects/"+projectId+"/doc/"+doc_id+"/similar-count",conf)
    //     .then((response) => {
    //         getDataCallback(response.data);
    //     }).catch(e=>{
    //         console.warn(`getSimilarDocumentsCount(${doc_id}) was aborted `);
    //     });
    // }


    getModels(project_id,getDataCallback){
        let  queryParams={}
      
      
        return this.axiosInstance.get(this.baseUrl+`/projects/${project_id}/models`, {params: queryParams})
        .then((response) => {
            getDataCallback &&getDataCallback(response.data);
            return response.data;
        });
    }

    getIntegrationModules(project_id){
        let  queryParams={project_id:project_id}
      
      
        return this.axiosInstance.get(this.baseUrl+`/integration/modules`, {params: queryParams})
        .then((response) => {
            return response.data;
        });
    }

    getIntegrationModule(module_id, project_id){
        let  queryParams={project_id:project_id}
      
      
        return this.axiosInstance.get(this.baseUrl+`/integration/modules/`+module_id, {params: queryParams})
        .then((response) => {
            return response.data;
        });
    }

    getIntegrationConnectorSettings(connector, project_id){
        return this.axiosInstance.get(this.baseUrl+"/integration/"+connector+"/settings", {params:{project_id}}).then(response=>response.data)
    }

    patchIntegraionConnectorSettings(connector,project_id, payload){
        return this.axiosInstance.post(this.baseUrl+"/integration/"+connector+"/settings",payload , {params:{project_id}}).then(response=>response.data)
    }


    getModelInfo(project_id,model_id,getDataCallback){
        let  queryParams={}

        return this.axiosInstance.get(this.baseUrl+`/projects/${project_id}/models/${model_id}`, {params: queryParams})
        .then((response) => {
            getDataCallback && getDataCallback(response.data);
            return response.data;
        });
    }

    getNodes():Promise<NodeInfo[]>{
        return this.axiosInstance.get(this.baseUrl+`/serving/nodes`)
        .then((response) => {
            return response.data;
        });

        
    }

    createNode(node:NodeInfo){
        return this.axiosInstance.post(this.baseUrl+`/serving/nodes`, node)
        .then((response) => {
            return response.data;
        });
    }

    getNodeSettings(node_name):Promise<NodeSettings>{
        return this.axiosInstance.get(this.baseUrl+`/serving/nodes-settings/${node_name}`)
        .then((response) => {
            return response.data;
        });
    }

    saveNodeSettings(node_name, node:NodeSettings){
        return this.axiosInstance.put(this.baseUrl+`/serving/nodes-settings/${node_name}`, node)
        .then((response) => {
            return response.data;
        });
    }
    analyseModelSettings(node_name, modelSettings){
        return this.axiosInstance.post(this.baseUrl+`/serving/nodes-settings/${node_name}/analyze`, modelSettings)
        .then((response) => {
            return response.data;
        });
    }



    getNodeInfo(node_name):Promise<NodeInfo>{
        return this.axiosInstance.get(this.baseUrl+`/serving/nodes/${node_name}`)
        .then((response) => {
            return response.data;
        });
    }

    patchNodeInfo(node_name,data){
        return this.axiosInstance.patch(this.baseUrl+`/serving/nodes/${node_name}`, data)
        .then((response) => {
            return response.data;
        });
    }

    deleteNode(node_name):Promise<any>{
        return this.axiosInstance.delete(this.baseUrl+`/serving/nodes/${node_name}`)
        .then((response) => {
            return response.data;
        });
    }

    startNode(node_name):Promise<any>{
        return this.axiosInstance.post(this.baseUrl+`/serving/nodes/${node_name}/start`)
        .then((response) => {
            return response.data;
        });
    }

    stopNode(node_name):Promise<any>{
        return this.axiosInstance.post(this.baseUrl+`/serving/nodes/${node_name}/stop`)
        .then((response) => {
            return response.data;
        });
    }

    getModelTrainTemplate(project_id,task_type,getDataCallback){
        let  queryParams={task_type:task_type}
      
      
        this.axiosInstance.get(this.baseUrl+`/projects/${project_id}/models/train/options`, {params: queryParams})
        .then((response) => {
            getDataCallback && getDataCallback(response.data);
        });
    }

    trainNewModel(project_id,params,getDataCallback){
        let  queryParams={}
      
      
        this.axiosInstance.put(this.baseUrl+`/projects/${project_id}/models/train`, params)
        .then((response) => {
            getDataCallback && getDataCallback(response.data);
        });
    }

    deleteModel(project_id,model_id,getDataCallback){
        let  queryParams={}
      
        this.axiosInstance.delete(this.baseUrl+`/projects/${project_id}/models/${model_id}`, {params: queryParams})
        .then((response) => {
            getDataCallback &&  getDataCallback(response.data);
        });
    }

    getModelDownloadUrl(project_id,model_id,fileName, getDataCallback){
        this.getAuthUrlParameters(project_id,model_id).then(res => {
            res.file_name=fileName;
            getDataCallback(this.baseUrl+`/projects/${project_id}/models/${model_id}/download?${new URLSearchParams(res).toString()}`)
        })
    }

    applyModelPredict(project_id,model_id,getDataCallback){
        let  queryParams={}
      
      
        return  this.axiosInstance.put(this.baseUrl+`/projects/${project_id}/models/${model_id}/apply-predict`, {params: queryParams})
        .then((response) => {
            getDataCallback && getDataCallback(response.data);
        });
    }

    compareModelPredictions(project_id,model_id,getDataCallback){
        let  queryParams={}
      
      
        return  this.axiosInstance.put(this.baseUrl+`/projects/${project_id}/models/${model_id}/compare-predictions`, {params: queryParams})
        .then((response) => {
            getDataCallback && getDataCallback(response.data);
        });
    }

    reevaluateModel(project_id,model_id,getDataCallback){
        let  queryParams={}
      
      
        return  this.axiosInstance.put(this.baseUrl+`/projects/${project_id}/models/${model_id}/reevaluate`, {params: queryParams})
        .then((response) => {
            getDataCallback && getDataCallback(response.data);
        });
    }

    applyModelEmbeddings(project_id,model_id,getDataCallback){
        let  queryParams={}
      
      
        return this.axiosInstance.put(this.baseUrl+`/projects/${project_id}/models/${model_id}/apply-embeddings`, {params: queryParams})
        .then((response) => {
            getDataCallback && getDataCallback(response.data);
        });
    }

    generate_similar_examples({project_id,positive_examples,negative_examples, already_generated, instruction,type_of_data,count}){
        
      
      
        return this.axiosInstance.post(this.baseUrl+`/projects/${project_id}/doc/generate_examples`,{positive_examples,negative_examples, already_generated, instruction,type_of_data,count} )
        .then((response) => {
           return (response.data);
        });
    }




}

export { getApi, ApiService};