import React, { useRef, useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Tip, ResponsiveContext, Text, Stack } from 'grommet';
import {useLocation} from 'react-router-dom';

export const TooltipButton = ({  name ,icon, disabled,  minimized, linkTo, badge, matchPattern }) => {
  //const [over, setOver] = useState();
  
  const tooltipColor = { color: 'dark-6', opacity: 0.8 };
  const navigate = useNavigate();
  const size = useContext(ResponsiveContext);
  const location = useLocation();
  const [isActive,setIsActive] = useState()
  useEffect(()=>{
    if (matchPattern){

      let is_active=location.pathname.match(matchPattern)
      setIsActive(is_active?true:false)
    }
  
  },[location])
  
  var contetnt=(
    <Box width="100%" pad="5px" background={isActive?"dark-4":undefined}   focusIndicator={false} 
    onClick={!disabled?( (e)=>navigate(linkTo) || e.preventDefault()):undefined} hoverIndicator={!disabled?tooltipColor:undefined}>

        <a href={!disabled?linkTo:undefined} className="noUnderline">
      <Stack anchor='top-right'>

     <Box round="5px" pad="5px" gap="xsmall" align='center' width="100%" style={disabled?{opacity:"40%"}:undefined}>
        {icon}  
        {!minimized&&(<Text textAlign='center' color="white" size="small">{name}</Text>)}
    </Box>
        {badge&&(<Box flex="grow" background="brand" pad="1px 5px" round><Text size='8pt'>{badge}</Text></Box>)}
      </Stack>
        </a>
    </Box>
    );
  return minimized ? 
    (
      <Tip  content={minimized?null:name} drop={{align:{ right: 'right', bottom: 'bottom' }}}>
        {contetnt}
        </Tip>
    )
    :(contetnt);
  

};
