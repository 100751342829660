import { Box, Form, TextInput, FormField, Button, Heading, Grommet, grommet, Text, Spinner, Avatar } from 'grommet';
import "./expandingButton.css"
export const ExpandingButton = ({collapsedContent, expandedContent, ...rest})=>{

    const defaultProps={
        border:true,
        round:true,
        pad:"0px 5px"
    }
    const boxProps = Object.assign({...defaultProps},rest);
    
    return (<Box className='expandingButton' {...boxProps}  focusIndicator={false} align='center' justify='center'>
        <Box className='btnContentExpanded'  align='center' justify='center'>
            {expandedContent}
        </Box>
        <Box className='btnContentColapsed' focusIndicator={false}   align='center' justify='center'>
            {collapsedContent}
        </Box>
    </Box>)
}