import {  Box,  Layer,   Spinner, Form, TextInput, FormField, Button, Text, Select, Tip } from 'grommet'

import { parse, stringify } from 'yaml'
import {Close, Dislike, FormClose} from "grommet-icons"
import { useState, useEffect } from "react";
import { getApi } from '../../ApiService';
import { useAppContext } from '../../AppContext';
import "./generateSimilarExamplesDialog.css"
import { HiglightableText } from '../../components/HiglightableText';
import { TipIfContent } from '../../components/TipIfContent';
import { LabelTag } from '../../components/LabelTag/LabelTag';


const TextExample=({value, onChange, onNotLike, onDelete})=>{
    const {projectInfo} =  useAppContext();
    return (
    <Box margin="2px" >
    <Box direction='row' align='start' width="100%"  justify='stretch'  >
        <Text>- </Text>
        <Box width="100%">
        <TipIfContent content={
            <HiglightableText text={value?.text}/>
        }>
            <Box width="100%" >
                <TextInput className='generateExamples-textExampleInput' value={value?.text} onChange={e=>onChange(e.target.value)}/>
            </Box>
        </TipIfContent>
        {value.labels &&
        <Box direction='row' justify='end' margin="-5px 0px 0px 0px">
            {value.labels.map(label=><LabelTag 
                active
                key={label}
                label={label} 
                readonly
                labelSettings={projectInfo?.label_settings && projectInfo.label_settings[label]}
                size="small"/>)}
        </Box>
    }
        </Box>
        {onNotLike && <Box width="25px" focusIndicator={false} onClick={()=>onNotLike()}>
            <Dislike className='generateExamples-dislike' color='lightgray'/>
        </Box>}
        {onDelete && <Box width="25px" focusIndicator={false} onClick={()=>onDelete()}>
            <FormClose className='generateExamples-dislike' color='lightgray'/>
        </Box>}
    </Box>
    
    </Box>
    )
}

export const GenerateSimilarExamplesDialog= ({textDocuments, onOK, onClose })=>{

    
  const [wait, setWait] = useState(false);

  const {projectInfo, setProjectInfo} =  useAppContext(); 
  const [generatedResults, setGeneratedResults] = useState();


  function generateResults(){
    

        setWait(true)
        getApi().generate_similar_examples({
            project_id:projectInfo.id,
            count:count,
            positive_examples:similarExamples.map(t=>t.text),
            negative_examples:negativeExamples.map(t=>t.text),
            already_generated:generatedResults,
            instruction:instructions,
            type_of_data:typeOfData
        }).then((results=>{
            if (generatedResults?.length){
                setGeneratedResults([...generatedResults, ...results.filter(r=>!!r)])
            }
            else{
                setGeneratedResults(results.filter(r=>!!r))
            }
            setWait(false)
        }))
        


    }

  const [page,_setPage]=useState(1)
  function setPage(newPage){
    if (page==newPage) return
    if (newPage==2){
        setWait(true)
        if (
            projectInfo?.labeling_preferences?.gpt_generate_sim_examples_settings?.instructions!=instructions ||
            projectInfo?.labeling_preferences?.gpt_generate_sim_examples_settings?.type_of_data!=typeOfData 
        ){

                // getApi().patchProjectLabelingPreferences(projectInfo.id, {
                //     gpt_generate_sim_examples_settings:{
                //         instructions:instructions,
                //         type_of_data:typeOfData
                //     }
                // }).then(()=> {
                //     getApi().getProjectInfo(projectInfo.id).then(proj=>setProjectInfo(proj))
                // })
        }

        generateResults()
        

    }
    _setPage(newPage)
  }

  const mergeTexts=(listA, listB)=>{
            
    let newVal = [...(listA || [])]
    listB?.forEach(doc=> {
        if (! newVal.some(t=>t.text==doc.text)){
            newVal.push(doc)
        }
    })
    return newVal
}

  useEffect(()=>{

    if (textDocuments?.length){
        let refLabel=undefined
        if (textDocuments[0].labels?.length){

            refLabel = textDocuments[0].labels[0]
            setTypeOfData(refLabel)
        }
        
        setWait(true)
        let promises=[]
        if (textDocuments.length==1){
            promises.push( 
                getApi()
                .searchTexts(projectInfo.id, {similar_to_doc:textDocuments[0].id, "by_label":refLabel, "take":2})
                .then(texts=>{
                    setSimilarExamples(mergeTexts(textDocuments, texts.map(t=>t.doc)))
                    
                })
            )
        }
        else if (textDocuments.length<3){
            textDocuments.forEach(textDocument=>{
                promises.push( 
                    getApi()
                    .searchTexts(projectInfo.id, {similar_to_doc:textDocument.id, "by_label":refLabel || "!null", "take":1})
                    .then(texts=>{
                        setSimilarExamples(current=>{
                            return mergeTexts(mergeTexts(current,  texts.map(t=>t.doc)), textDocuments)
                        })
                    })
                )
            })
        }
        else{
            setSimilarExamples(textDocuments)
        }
       
        let take_negatives = 3;
        if (textDocuments.length>1 && textDocuments.length<=5){
            take_negatives=1
        }
        else if (textDocuments.length>5){
            take_negatives=0
        }

        if (take_negatives){
            textDocuments.forEach(textDocument=>{
                promises.push( 
                    getApi()
                    .searchTexts(projectInfo.id, {similar_to_doc:textDocument.id, "by_label":"!"+refLabel, "take":take_negatives, min_score:0.85})
                    .then(texts=>setNegativeExamples(currentVal=>{
                        return setNegativeExamples(mergeTexts(currentVal,  texts.map(t=>t.doc)))
                    }))
                )
            })
        }
        if (promises){
            Promise.all(promises).then(()=>setWait(false))
        }
        else{
            setWait(false)
        }
        
    }

},[textDocuments])



  const [errorMsg, setErrorMsg] = useState();

  const _onClose=()=>{
    onClose();
  }



function submit(){


      try{
        setWait(true)
        getApi().addDocuments(projectInfo.id, generatedResults.map((example_text,i)=>({
            "key":textDocuments?.length==1? textDocuments[0].key+"-"+i:undefined , 
            "text":example_text,
            "labels":textDocuments?.length? textDocuments[0].labels:undefined 
        })
        )).then(()=>onClose())
        

      }catch (error)
      {
        setErrorMsg(error.message)
      }
  
}



const [count,setCount] = useState(5)
const [typeOfData,setTypeOfData] = useState("");
const [similarExamples, setSimilarExamples]=useState()
const [negativeExamples, setNegativeExamples]=useState([])
const [instructions,setInstructions] = useState("");


// useEffect(()=>{
//     if (projectInfo?.labeling_preferences?.gpt_generate_sim_examples_settings){
//         setInstructions(projectInfo?.labeling_preferences?.gpt_generate_sim_examples_settings.instructions)
//         setTypeOfData(projectInfo?.labeling_preferences?.gpt_generate_sim_examples_settings.type_of_data)
//     }
// },[projectInfo])

    
function updateArrayItemValue(array,idx,newVal,setValueFunc){
    let newArr=[...array]
    if (idx>0){
        newArr[idx]=newVal
    }
    else if (idx==-1){
        newArr.push(newVal)
    }
    setValueFunc(newArr)

}



return (
    
<Layer onClickOutside={onClose} onEsc={onClose}>
  <Box style={{minWidth:"65vw"}} margin="small" gap='medium'> 
    <Box direction="row" justify='between' width="100%">
      <Text size="large">Generate similar examples </Text>
      <Box  onClick={onClose} focusIndicator={false}>

        <Close style={{float:"right"}}/>
      </Box>
    </Box>
    {
      wait?
      (<Spinner size="large" alignSelf="center"/>)
      :
      (page==1?(
         <Box  gap="xsmall">

       <Box direction='row' align='center' justify='start' gap="5px"> 
       
        <Text>Generate</Text>
        <input className='numberCountInput' type="number" value={count} onChange={e=>setCount(parseInt(e.target.value))}/>
        <Text>similar examples, </Text>
        <Text>of </Text>
        <input  placeholder="type of data" value={typeOfData} onChange={e=>setTypeOfData(e.target.value)}/>
       </Box>
        <Text>that are <b>similar</b> to:</Text>
       
       <Box>
       {similarExamples?.map((e,i)=>(
        <TextExample key={i} value={e} onChange={v=>{
            e.text=v
            updateArrayItemValue(similarExamples,i,e,setSimilarExamples)
        }} 
        onNotLike={()=>{
            negativeExamples.push(e)
            setNegativeExamples([...negativeExamples])
            setSimilarExamples(similarExamples.filter(t=>t!=e))
            }} 
        onDelete={()=>{
                setSimilarExamples(similarExamples.filter(t=>t!=e))
                }} 
        />
       ))}
       <Box direction='row' margin="-5px 0px 0px">
            {similarExamples && similarExamples[similarExamples.length-1] && 
                // <Button size='small' className='addExampleBtn' label="Add" onClick={()=>setSimilarExamples([...similarExamples,{text:""}])} /> 
                <Box onClick={()=>setSimilarExamples([...similarExamples,{text:""}])} ><Text color="gray">+ Add new</Text></Box>
            }
       </Box>
       </Box>
       <Box margin="10px 0px 0px">
        <Text>but <b>different</b> from:</Text>
       </Box>
       <Box>
       {negativeExamples?.length? negativeExamples.map((e,i)=>(
        <TextExample key={i} value={e} onChange={v=>{
            e.text=v
            updateArrayItemValue(similarExamples,i,e,setSimilarExamples)
        }} 
        onDelete={()=>{
            setNegativeExamples(negativeExamples.filter(t=>t!=e))
            }} 
        />
       )):
       (
        <Box pad="10px">
            <Text color="gray">No negative examples defined</Text>
        </Box>
       )
    }


       <Box direction='row' margin="-5px 0px 0px">
            {/* {negativeExamples && negativeExamples[negativeExamples.length-1] && 
                // <Button size='small' className='addExampleBtn' label="Add" onClick={()=>setSimilarExamples([...similarExamples,{text:""}])} /> 
            } */}
            
            <Box onClick={()=>setNegativeExamples([...negativeExamples ||[],{text:""}])} ><Text color="gray">+ Add new</Text></Box>
       </Box>
       
       </Box>
       <Text color="gray" size="8pt">Providing few relevant similar and different examples help the AI to understand what kind of new data should be generated. Try to keep them as realistic as posible</Text>

            <Tip content="Provide additional instructions in plain natural language, as you would describe the task to a friend or colleague">
        
                <Box>
                    <Text size="small" weight={600}>Additional instructions</Text>
                    <TextInput className='generateExamples-textExampleInput' value={instructions} onChange={(e)=>setInstructions(e.target.value)}    suggestions={[
                        "prevent harmful content",
                        "explicitly use profanities, hateful and obscene expressions",
                        "write the results in [replace-with-language-of-choice] language"
                    ]}
                    onSuggestionSelect={(e)=>{
                        setInstructions(e.suggestion)
                    }}    
                />
                </Box>
            </Tip>
         
            
       
        </Box>
      ):(
        page==2?(
            <Box>
                <Text weight={800}>Generated similar examples</Text>
                 {generatedResults?.map((e,i)=>(
                     <TextExample key={i} value={{"text":e, "labels":similarExamples?.length?similarExamples[0].labels:undefined}} 
                     onChange={val=>{
                        let newGenerateResults = [...generatedResults]
                       newGenerateResults[i]=val
                        setGeneratedResults(newGenerateResults)
                     }}
                     onNotLike={()=>{
                        setNegativeExamples(mergeTexts(negativeExamples, [{"text":e, "labels":similarExamples?.length?similarExamples[0].labels:undefined}]))
                        setGeneratedResults(generatedResults.filter(ge=>ge!=e))
                     }
                    }
                     onDelete={()=>setGeneratedResults(generatedResults.filter(ge=>ge!=e))}/>
                     ))}
                <Box align='center'>
                    <Button label={`Generate ${count} more`} onClick={generateResults}/>
                <Text color="gray" size="small">or proceed to add these examples into project</Text>
                </Box>
            </Box>
        ):(
            <></>
        )
      )
      )
    }
    {errorMsg&&<Text size="small" color="red">{errorMsg}</Text>}
    <Box direction="row" justify="between" gap="xsmall" >
        <Button secondary label="Cancel" onClick={_onClose}/>
        <Box direction="row"  gap="xsmall">
            
        {page>1&&(<Button  label="Previous" onClick={()=>{
            setPage(page-1)
            setWait(false)
        }
            }/>)}
            
        
        {page<2?(<Button primary label="Next" onClick={()=>setPage(page+1)}/>):(
            <Button primary label="Finish" onClick={submit}/>
        )}
        </Box>
    </Box>
  </Box>
</Layer>

)
}
