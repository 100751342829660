import {TextInput } from "grommet";
import * as React from "react";
import { useMemo } from "react";
import {useState} from "react";


export interface AutocompleteParams{
    searchFunc?: (searchTerm:string)=>Promise<string[] | {
        label?: React.ReactNode;
        value?: any;
    }[]>|string[] | {
        label?: React.ReactNode;
        value?: any;
    }[]
    defaultSuggestions?: (string | {
        label?: React.ReactNode;
        value?: any;
    })[]
    value?: string
    onTextChange?: React.ChangeEventHandler<HTMLInputElement> | undefined
    onValueChange?: (value:string|any, target:React.RefObject<HTMLElement> )=>any
    id?: string
    name?: string
    disabled?:boolean,
    [key:string]:any
}
export const Autocomplete = ({searchFunc, defaultSuggestions, value, onTextChange, onValueChange, labelProperty, id, name, disabled, ...rest}:AutocompleteParams)=>{

    const [textVal, setTextVal] = useState<string>("");
    React.useEffect(()=>{
        setTextVal(value&&labelProperty?value[labelProperty]:value)
    }
    ,[value,labelProperty])
    const [suggestions, setSuggestions] = useState(defaultSuggestions);
    const _searchFunc = useMemo(()=>{
        return searchFunc || ((searchTerm:string)=>defaultSuggestions.filter((t:any)=>(t.value || t).toLowerCase().includes(searchTerm.toLowerCase())))
    }
    ,[searchFunc, defaultSuggestions]);

    function suggest(currentVal:any){
        if (currentVal||!defaultSuggestions){
            let possiblePromise = _searchFunc(currentVal)
            if (possiblePromise instanceof Promise){
                possiblePromise.then((data)=>setSuggestions((data as any).map((s:any)=>labelProperty?{value:s,label:s[labelProperty]}:s ))) 
            }
            else{
                setSuggestions(possiblePromise.map((s:any)=>labelProperty?{value:s,label:s[labelProperty]}:s ))
            }
        }
        else{
            setSuggestions(defaultSuggestions)
        }

    }
    const inputEl = React.useRef(null);
    return (<TextInput
        
        ref={inputEl}
        disabled={disabled}
        id={id} 
        name={name} 
        suggestions={suggestions} 
        value={textVal}
        onSelect={(e:any)=>{
            onValueChange && onValueChange(e.suggestion?.value||e.suggestion, inputEl)
            defaultSuggestions && setSuggestions(defaultSuggestions)
            e.target.value=""
            
            setTextVal("")
            e.ignoreSelectionChange=true
        }}
        onChange={e=>{
            setTextVal(e.target.value as string)
            suggest(e.target.value as any);
            onTextChange && onTextChange(e);
        }} 
        {...rest}
        />)
}