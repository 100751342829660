import { React, useState, useEffect, useRef, memo } from "react";
import { Box, Text} from "grommet";
//import emoji from 'react-easy-emoji'


export const HiglightableText = memo(({text, highlightText, coloredKeywords})=>{
    // const emojify = (str)=>{
    //     try {
    //         return emoji(str)
    //     } catch (error) {
    //         return str;
    //     }
    // }
    const highlight = (text, highlightText,coloredKeywords)=>{
        if (!text) return "";
        const regEscape = v => v.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
        let lastMatchIndex=0;
        let matchedSubStrings =[];
        if (coloredKeywords){
            for (const kw of Object.getOwnPropertyNames(coloredKeywords)){
                
                for (const match of text.matchAll(new RegExp(regEscape(kw),"gi"))){                    
                        matchedSubStrings.push(match)
                }
            }
        }
        if (highlightText){
            let hightlightMathes=[];
            for (const subText of highlightText.split(" ")) {
                const matchRegex = new RegExp(regEscape(subText),"gi");
                for (const match of text.matchAll(matchRegex)){
                    match && hightlightMathes.push(match)
                }
            }
            
            if (matchedSubStrings && matchedSubStrings.length>0)
                matchedSubStrings =  [...hightlightMathes,...matchedSubStrings ]
            else
                matchedSubStrings =  hightlightMathes;

        }
        const splits=[{from:-1,to:text.length,colors:null}]
        for (const matchId in matchedSubStrings){
            let match = matchedSubStrings[matchId]
            if (match[0]){
                let color = (coloredKeywords && coloredKeywords[match[0].toLowerCase()])|| "yellow";
                splits.push({from:match.index, to:match.index+match[0].length, color:color })
            }
        }

        let ranges = new Set;
        
        splits.forEach(({ from, to }) => (ranges.add(from), ranges.add(to)));
        let parts = [...ranges].sort((a, b) => a - b);
        let resultSplits = parts.slice(1).map(function (a, i, aa) {
            var from = i ? aa[i - 1] : parts[0],
                to = a,
                colors = splits.filter(d => d.from <= from && to <= d.to).map(({ color }) => color).filter(c=>Boolean(c));
            return { from: from>=0?from:0, to, colors };
        });
            
        return resultSplits.map((s)=>({text:text.slice(s.from,s.to), color:s.colors[0]})).filter(t=>t.text)
        .map(({text,color},i)=>{
            const style=color? {backgroundColor:color} : undefined;
            return <Text key={i} style={style} wordBreak="break-word">{text}</Text>
        })

        // return matchedSubStrings.map((match, i)=>{
        //             let matchedText = match[0];
        //             let previousStr = text.slice(lastMatchIndex, match.index);
        //             let res = (<><Text key={i} wordBreak="break-word">{(previousStr)}</Text><Text key={-i-1} style={{backgroundColor:"yellow"}}>{matchedText}</Text></>);
        //             lastMatchIndex = match.index+matchedText.length;
        //             return res;
        //         }).concat([(<Text key={"last"} wordBreak="break-word">{(text.substr(lastMatchIndex))} </Text>)])
        
        
    }
    const [subTexts,setSubTexts]=useState()
    useEffect(()=>{

        setSubTexts(highlight(text,highlightText,coloredKeywords))
    },[text, highlightText, coloredKeywords])


    if (highlightText||coloredKeywords){

        return( 
            <div>
            {
               subTexts
            }
            
            </div>
         )
        
    }
    else 
        return (text&&text.split(/(\r?\n)|(\xa0)/).map((t,i)=>( <Text key={i} wordBreak="break-word">{t}<br/> </Text>)) )

})