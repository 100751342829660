import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from "react-router-dom"
import { Box, Heading, TagInput, Sidebar, Text, Form, Button, FormField, TextInput, Accordion, AccordionPanel, Select, CheckBox } from 'grommet';
import { Tag, Play, Close } from 'grommet-icons'

import { getApi } from '../ApiService';
import { TaskBox } from './TaskBox';
import { InputWithTags } from './InputWithTags';
import {Constants} from "../contants";
import { ModelAutocomplete } from './ModelAutocomplete';
import { modifyState } from '../helpers/helperFunctions';
import { useAppContext } from '../AppContext';

export const NewModelSidebar = ({ onClose,newModelTemplate }) => {
  //const size = useContext(ResponsiveContext);
  const {newTaskScheduler} = useAppContext();
  const { project_id } = useParams();
  const {projectInfo,stats} =useAppContext()
  //const defaultModelParams = { max_num_epochs: 5, from_model: sourceModel }
  const [modelTrainParams, setModelTrainParams] = useState(newModelTemplate);
  //const [advancedSettings, setAdvancedSettings] = useState();
  
  const [err, setErr] = useState(null);
  const trainTypes = Constants.TRAINING_TASK_TYPES;
  const sortLabelsByLabeledCound = (labelsArray)=>{
    stats.by_label_count && labelsArray.sort((a,b)=>stats.by_label_count[b]-stats.by_label_count[a] )
  }
  // const updateAdvancedSettings = (training_params) => {
  //   let newModel = Object.assign({}, modelTrainParams);
  //   //data.labels_filter && sortLabelsByLabeledCound(data.labels_filter)
  //   newModel["training_params"] = training_params;
  //   setModelTrainParams(newModel);
  //   setAdvancedSettings()
    
  // }

  // useEffect(() => {
  //   if (!newModelTemplate){
  //     getApi().getModelTrainTemplate(project_id, task_type,(data) => {
  //       //setTrainParams(data);
  //       setNewModelTemplate(data);
  //       setAdvancedSettings(data.training_params)
  //     })
  //   }
  //   else{
  //     let newTemplate = Object.assign({},newModelTemplate)
  //     newTemplate.from_model=sourceModel
  //     setNewModelTemplate(newTemplate)
  //     setAdvancedSettings(data.training_params)
  //   }
  // }, [sourceModel])

  const [modelType, setModelType] = useState(newModelTemplate && newModelTemplate.task_type);
  useEffect(()=>{
    if (modelTrainParams.task_type!==modelType){
      getApi().getModelTrainTemplate(project_id,modelTrainParams.task_type,(data) => {
        //setTrainParams(data);
        let newModelTrainParams = Object.assign({},modelTrainParams)
        newModelTrainParams.training_params=data.training_params
        setModelType(newModelTrainParams.task_type)
        setModelTrainParams(newModelTrainParams);
      })
    }
  }, [modelTrainParams])


  function validate(newVal) {
    
    if (!newVal.max_num_epochs){
      setErr({max_num_epochs:"Max epochs is required"})
      return false;
    }

    if (parseInt(newVal.max_num_epochs) != newVal.max_num_epochs.toString()) {
      setErr({max_num_epochs:"Not a valid number"})
      return false;
    }
    setErr(null);
    return true;
  }
  function trainNewModel() {

    modelTrainParams.max_num_epochs = parseInt(modelTrainParams.max_num_epochs)
    console.log(modelTrainParams);
    
    getApi().trainNewModel(project_id, modelTrainParams, () => {
      onClose()
      newTaskScheduler()
    })
  }
  const [traintingParamsTemplate,setTraintingParamsTemplate]=useState()
  useEffect(()=>{
    if (!traintingParamsTemplate) {
        setTraintingParamsTemplate(modelTrainParams.training_params)
        }
    },[modelTrainParams])

  return (
    <Sidebar background="light-4" overflow="auto" style={{ width: "650px" }}>
      {modelTrainParams && (
      <Box gap='large' flex="grow">
        
        <Box direction="row" justify='between' ><Heading>New model </Heading><Button icon={<Close />} onClick={() => onClose()} /></Box>
        <Form
          onSubmit={trainNewModel}
          value={modelTrainParams}
          onChange={newVal => { 
            setModelTrainParams(newVal);  
            validate(newVal) }}
          onReset={() => setModelTrainParams(modelTrainParams)}
        >
          <Button size="small" icon={<Play />} label="Train new model" type="submit" />
          <Box>

          
          </Box>
          <FormField label="New model name">
            <TextInput name="model_name" placeholder="(random name)" />
            
            <Text alignSelf='end' color="red" size="small">{err&& err["model_name"]}</Text>
          </FormField>
          <FormField label="Max epochs">
            <TextInput type="number" name="max_num_epochs" />
            <Text alignSelf='end' color="red" size="small">{err&& err["max_num_epochs"]}</Text>
          </FormField>
          <FormField label="Model origin (model name)" >
       
            <ModelAutocomplete 
            projectInfo={projectInfo}
            value={modelTrainParams.from_model}
            onChange={(val)=>modifyState((m)=>m.from_model=val,modelTrainParams, setModelTrainParams) }

            />
          </FormField>
          <FormField label="Task type" >
            <Select
            name="task_type" 
            options={Constants.TRAINING_TASK_TYPES}
            />
            
          </FormField>

        </Form>
        {modelTrainParams.training_params &&(
          <Accordion >

            <AccordionPanel label="Advanced">
              <Box padding="small">
                <Form value={modelTrainParams.training_params} onChange={trainingParams =>{
                    let newTrainParams = Object.assign({}, modelTrainParams);
                    newTrainParams.training_params = trainingParams
                    setModelTrainParams(newTrainParams)
                } }>
                  {
                    traintingParamsTemplate&&
                    Object.getOwnPropertyNames(traintingParamsTemplate).filter(t=> !["aditional_dataset_projects","include_context_data"].includes(t)).map(p => {
                      if ((typeof traintingParamsTemplate[p]) === "number") {
                        return (
                        <FormField key={p} pad={false} label={p} name={p} type="number">
                          <TextInput plain size="small" type="number" name={p} />
                          <Text alignSelf='end' color="red" size="small">{err&& err[p]}</Text>
                        </FormField>)
                      }
                      else if ((typeof traintingParamsTemplate[p]) === "string") {
                        return (<FormField key={p} pad={false} label={p} name={p} required>
                          <TextInput plain size="small" name={p} />
                          <Text alignSelf='end' color="red" size="small">{err&& err[p]}</Text>
                        </FormField>)
                      }
                      else if ((typeof traintingParamsTemplate[p]) === "boolean") {
                        return (<FormField key={p} pad={false} name={p} required>
                          <CheckBox label={p} name={p}/>
                          <Text alignSelf='end' color="red" size="small">{err&& err[p]}</Text>
                        </FormField>)
                      }
                      else if (traintingParamsTemplate[p] && Array.isArray(traintingParamsTemplate[p])) {
                        
                        return (
                          <FormField label={p} key={p}>
                          <InputWithTags key={p}
                            allSuggestions={modelTrainParams.training_params[p]}
                            onChange={v=>{
                              let newTrainParams = Object.assign({}, modelTrainParams);
                              newTrainParams.training_params[p] = v;
                              setModelTrainParams(newTrainParams);
                            }}
                            value={modelTrainParams.training_params[p]}
                          />
                          </FormField>
                          )
                      }
                      return (<div key={p}></div>)
                      
                    })
                   
                  }
                    <FormField label="Additional datasource projects" key="aditional_dataset_projects">
                          <InputWithTags key="aditional_dataset_projects"
                            suggest={(q)=>{
                              return getApi().searchProjects(q).then(found=>{
                                return found.map(p=>({label:p.name, value:{id:p.id, name:p.name}}))

                                  })}
                            }
                            onChange={v=>{
                              let newTrainParams = Object.assign({}, modelTrainParams);
                              newTrainParams.training_params.aditional_dataset_projects = v.map(s=>s.value.id);
                              setModelTrainParams(newTrainParams);
                            }}
                            value={modelTrainParams.aditional_dataset_projects ||[]}
                          />
                          </FormField>
                          <FormField key="include_context_data_key" pad={false} label="Include context data" name="include_context_data_key" required>
                          <TextInput plain size="small" name="include_context_data_key" />
                        </FormField>
                </Form>
              </Box>
            </AccordionPanel>
          </Accordion>
        )}
      </Box>
      )}
    </Sidebar>
  );
};

