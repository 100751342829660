
import {useEffect, useState, useMemo, useCallback, useLayoutEffect} from "react"
import {useLocation,useSearchParams} from "react-router-dom"



const useDebounce =  function useDebounce(value, delay){
    const [debouncedValue, setDebouncedValue] = useState(value)
    
    useEffect(() => {
      const timer = setTimeout(() => setDebouncedValue(value), delay || 500)
  
      return () => {
        clearTimeout(timer)
      }
    }, [value, delay])
  
    return debouncedValue
  }
  

  const debouncePromise = (fn, ms = 0) => {
    let timeoutId;
    const pending = [];
    return (...args) =>
      new Promise((res, rej) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          const currentPending = [...pending];
          pending.length = 0;
          Promise.resolve(fn.apply(this, args)).then(
            data => {
              currentPending.forEach(({ resolve }) => resolve(data));
            },
            error => {
              currentPending.forEach(({ reject }) => reject(error));
            }
          );
        }, ms);
        pending.push({ resolve: res, reject: rej });
      });
  };


// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery =  function useQuery() {
  const [urlParams, setUrlParams] = useSearchParams();
    
    return urlParams;
    //return  useSearchParams();
  }


  export const useIsOverflow = (ref, callback) => {
    const [isOverflow, setIsOverflow] = useState(undefined);
  
    useLayoutEffect(() => {
      const { current } = ref;
  
      const trigger = () => {
        const hasOverflow = (current.scrollHeight > current.clientHeight+15 ) ;
  
        setIsOverflow(hasOverflow);
  
        if (callback) callback(hasOverflow);
      };
  
      if (current) {
        trigger();
      }
    }, [callback, ref]);
  
    return isOverflow;
  };

// Hook
function useOnScreen(ref, rootMargin = "0px") {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false);
  useEffect(() => {
    
    const observer = new IntersectionObserver(
      ([entry]) => {
        
        // Update our state when observer callback fires
        
      },
      {
        rootMargin,
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      observer.unobserve(ref.current);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount
  return isIntersecting;
}


export {useDebounce, debouncePromise, useQuery, useOnScreen}