

import { useState, useEffect } from 'react';
import { Box, Form, TextInput, FormField, Button, Heading, Grommet, grommet, Text, Tip, CheckBox, Select, Spinner } from 'grommet';
import {Play,Clone,LinkPrevious, FormClock, Copy, FormClose, StatusGood, Clear, Edit } from "grommet-icons"

import { getApi } from '../../ApiService';
import { useAppContext } from '../../AppContext';
import { ExpandingButton } from '../ExpandingButton/expandingButton';
import { reload } from 'firebase/auth';
import { Badge } from '../Badge';
import Moment from 'react-moment';
import { UsersSessionsDialog } from './UsersSessions';
import { SearchBar } from '../SearchBar/searchBar';
import { Switch } from '../Switch';



const ALL_ROLES = ["READ_ONLY","ANOTATOR","POWER_USER","ADMIN"]
const validateEmail = (email) => {
    

    let res = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(String(email));

    return res;
  };


const InviteBlock = ({max_users_exceeded})=>{
    const [activated,setActivated] =useState(false);
    const [link,setLink] =useState(undefined);
    const [email,setEmail] =useState("");
    const [newTennant,setNewTennant] =useState(false);
    const [userProfile,setUserProfile] =useState("ADMIN");
    const [error,setError] = useState("");
    
    function getInviteLink(){
        setError("");
        if (validateEmail(email)){
            let currentUrl = window.location.href.split('?')[0];
            getApi().generateInviteLink(email, newTennant, newTennant?null:userProfile, currentUrl).then(invite_link=>{

                setLink(invite_link)
                setError("")
            })

        }
        else{
            setError("This email form doesn't seems to be right")
        }
    }

    

    return activated?(
        link?(  
            <Box gap="small" align='start' justify='start' width="100%">
                <Text weight={600}>Create an invite link</Text> 
                <Box direction='row' gap="small"   width="100%" style={{minWidth:"400px"}}>
                    <TextInput size="small" style={{padding:"5px"}} value={link} disabled={true}/>    
                    <Button onClick={()=>navigator.clipboard.writeText(link) }><Clone/></Button>
                </Box>
                <Text size="small">You can send this link to {email}. When he signs up, {newTennant? "he/she will have fully functional account with 1 month free trial period.":`he/she will be able so see all your data and have ${userProfile} permissions.`  }</Text>
                <Box direction='row' justify='start'>

                <Button label="Create new invite link" onClick={()=>{
                    setLink(null);
                    setEmail(null);
                    setNewTennant(null);
                }}/>
                </Box>
            </Box>      
            ):(
                <Box align='start' flex="grow">
                    <Text weight={600}>Create an invite link</Text> 
                    <Box pad="10px" gap="2px" align='start' flex="grow">
                        <Box direction='row' gap="small" flex="grow" align='center'>
                            <TextInput size="small" style={{padding:"5px", width:"290px"}} placeholder="Email of invitee" value={email} onChange={e =>setEmail(e.target.value.toLowerCase())}/>
                        
                            <CheckBox   size="small" label={<Text wordBreak="keep-all" style={{whiteSpace:"nowrap"}} size="15px">Invite into my organization (share my data)</Text>} checked={!newTennant} onChange={e=>{setNewTennant(!e.target.checked)} }/>
                            
                        </Box>
                        {!newTennant && (
                            <Box direction='row' align='center' gap="small">
                                <Text size="small">User profile</Text> 
                                    <Select options={ALL_ROLES} size="small"  style={{padding:"5px"}} value={userProfile} onChange={e=>setUserProfile(e.target.value)} />
                                {max_users_exceeded && ( <Text color="red" size="small">Your user quota has been exceeded</Text> )}
                                </Box>)}
                            {error&&<Text size="small" color="red">{error}</Text>}
                        <Box direction='row' gap="small" pad="10px 0px">
                        <Button label="Generate link" onClick={()=>getInviteLink()}/>
                        <Box focusIndicator={false} align="center" direction='row' onClick={()=>setActivated(false)}><FormClose/><Text size="12px">Cancel</Text></Box>
                        </Box>
                    </Box>
                </Box>
            )
        
        ):(
            <Box gap="small" align='start' justify='start'>
            <Tip content="Create an invlite link that you can send your friends and colleagues so they can join Labelator.io">
                <Button  label="Create invite link" onClick={()=>setActivated(true)}/>
            </Tip>
            </Box>
        )
}


const Users=({show_tennant, users_left})=>{
    let {  testPermission } = useAppContext();
    
    const [users,setUsers] = useState("");
    const [dialog, setDialog] = useState()
    
    const [error,setError] = useState("");
    const [isLoading,setIsLoading] = useState()
    const [profileEditingUser,setProfileEditingUser] = useState()

    const reloadUsers=()=>{
        setIsLoading(true)
        getApi().getUsers().then((data)=>{
            setUsers(data)
            
            setError("")
            setIsLoading(false)
        })

        
    }

    useEffect(()=>{
        if (users && users.length && users[0].session==undefined){
            Promise.all( users.map(u=>getApi().getUserSessionLatest(u.id).then(session=>{
                u.session=session
                return u
            } ))).then(data=>{
                setUsers(data)
            }) 
        }
    },[users])

    useEffect(()=>{
        reloadUsers()
    },[])

    function enableUser(user_id){
        if (users_left>0){

            getApi().patchUser(user_id, {enabled:true}).then(()=>{
                reloadUsers()
            })
        }
        else{
            setError("You've spent your daily active user quota. Disable some users or request an increase")
        }
    }

    function disableUser(user_id){
        getApi().patchUser(user_id, {enabled:false}).then(()=>{
            reloadUsers()
        })
    }
    
    const [userFilterLogin, setUserFilterLogin]=useState()
    const [userFilterOnlyActive, setUserFilterOnlyActive]=useState(true)

    function applyFilters(user){
        let isMet=(user.enabled && userFilterOnlyActive) || !userFilterOnlyActive;
        if (userFilterLogin){
            isMet=isMet&&user.login.toLowerCase().includes(userFilterLogin.toLowerCase())
        }
        return isMet
    }

    return (<Box flex={false} width="80vw" pad="5px" gap="small">
         {!error && testPermission("INVITE_USER") && (
            <InviteBlock max_users_exceeded={users_left<=0}/>
        )}
        <Box direction='row' justify='between' wrap align='center'>
            <Text weight={900}>All users in your organisation </Text>
            <Box direction='row'>

            <Box direction='row' align='center' gap="5px">
                <Text size='small'>{userFilterOnlyActive?"Only active":"All"}</Text>
                <Switch active={userFilterOnlyActive} onActiveChange={(val)=>setUserFilterOnlyActive(val)}/>
            </Box>
            <SearchBar disableSyncWithUrl onChange={(val)=>setUserFilterLogin(val)} margin="0px 15px"/>
            </Box>
        </Box>
        {dialog}
        <Box  >
            {isLoading?(
            <Box pad="20px" align='center' justify='center'>

                <Spinner/>
            </Box>
            ):(
                <Box gap="5px" pad="5px 25px">
                    {users&&users.filter(u=>applyFilters(u)).map(user=>(
                        <Box key={user.id} border round="xsmall" elevation='small' pad="5px" flex="grow" className={user.enabled?"activeUser":"disabledUser"} >
                            <Box  direction='row' justify='between' wrap>
                            
                            <Text size='small' weight={800}>
                                {user.login}
                            </Text>
                            {user.session && 
                                <Badge 
                                onClick={()=>{
                                    setDialog(<UsersSessionsDialog user={user} onClose={()=>setDialog(null)}/>)
                                }}
                                 value={<Text size="8pt">Last session <Moment utc fromNow>{user.session?.end}</Moment></Text>}/>
                            }
                            
                            {user.enabled?(
                                <ExpandingButton
                                border={false}
                                onClick={()=>disableUser(user.id)}
                                
                                    collapsedContent={
                                        
                                        <StatusGood color='green'/>
                                    }
                                    expandedContent={
                                        <Clear/>
                                    }
                                />
                                ):(
                                    <ExpandingButton
                                    onClick={()=>enableUser(user.id)}
                                    border={false}
                                    collapsedContent={
                                        <Clear/>
                                    }
                                    expandedContent={
                                        <StatusGood/>
                                    }
                                    />
                            )}
                            </Box>
                            <Box>
                                <Box direction='row' align='center' >
                                {user.id==profileEditingUser?(
                                        <Box direction='row'  round align='center' >
                                          <Text size='small'>
                                                profile: 
                                                </Text>
                                        <Select
                                            style={{padding:"2px", fontSize:"8pt"}}
                                            value={user.user_profile}
                                            open={true}
                                            onClose={()=>setProfileEditingUser(undefined)}
                                            options={ALL_ROLES}
                                            onChange={({option})=>{
                                                console.log(option)
                                                getApi().patchUser(user.id,{user_profile:option}).then(()=>
                                                    reloadUsers()
                                                )
                                                //setProfileEditingUser(undefined)
                                            }
                                            }
                                        />
                                    </Box>
                                ):(                               
                                    <ExpandingButton
                                        border={false}
                                        onClick={()=>setProfileEditingUser(user.id)}
                                        collapsedContent={
                                            <Box direction='row'>
                                                <Text size='small'>
                                                    profile: {user.user_profile}
                                                </Text>
                                                
                                            </Box>
                                        }
                                        expandedContent={
                                            <Box direction='row'  round align='center' >
                                                <Text size='small'>
                                                profile: 
                                                </Text>
                                                <Box border>
                                                <Text size='small'>
                                                {user.user_profile}
                                                </Text>
                                                </Box>
                                                <Edit size="small"/>
                                                
                                            </Box>
                                        }
                                        />
                                    )}
                            </Box>
                            {show_tennant&&(
                                <Text size='small'>
                                    tennant: {user?.tennant_id}
                                </Text>
                            )        
                            }
                            </Box>
                        </Box>
                    ))}
                </Box>
            )}
        </Box>

       
        {error&&(
            <Text color="red">{error}</Text>
        )}
    </Box>)
}

export {Users}

