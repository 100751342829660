
import { Box, Button, Heading, Header, ResponsiveContext ,Text, DropButton} from 'grommet';
import { Test, BarChart, Deploy, Menu, Performance, User, SettingsOption, UserSettings, Task, SchedulePlay } from 'grommet-icons';
import { useParams ,useNavigate, useLocation, useSearchParams} from 'react-router-dom';
import React, { useContext, useEffect, useState } from "react";
import { getApi } from '../ApiService';

import { useAppContext } from '../AppContext';
//import { useSharedProjectState } from '../ProjectState';
import RequestAccessButton from './RequestAccessButton';

export const AppHeader = ({sideBarToggle, menuToggle, ...props}) => {
    const size = useContext(ResponsiveContext);
    
    let { project_id } = useParams();
    const [ searchParams,setSearchParams ]= useSearchParams();
    //const {setProjectId} = useSharedProjectState();
    const {currentUser, logout, projectInfo, userProfile,setProjectInfo:setProjectContext} = useAppContext();
    
    const urlChange = useNavigate();//this is here to foce useEfect run on url change
    useEffect(()=>{
        if (project_id=="projects" ||project_id=="login"  ||project_id=="undefied" ||project_id=="serving"||project_id=="integration"){
            project_id=undefined
        }

        if ((! searchParams.has("project_id") && !project_id ) || (project_id && projectInfo && projectInfo.id===project_id) || !currentUser)
             return;
        
        getApi().getProjectInfo(project_id||searchParams.get("project_id"), (data)=> {
            //setProject(data)
            setProjectContext(data);
        });
        
    }, [project_id,searchParams,urlChange] );


    const [isDemoUser,setIsDemoUser]=useState(false);

    useEffect(()=>{
        if (currentUser && (userProfile=="DEMO_USER") ){
            setIsDemoUser(true)
        }
    },[userProfile]);

    return( <Header
                tag='header'
                direction='row'
                align='center'
                justify='between'
                background='brand'
                pad={{ left: 'medium', right: 'small', vertical: '0px' }}    
                elevation='medium'
                style={{ zIndex: '1' }}
                
                {...props}
            >
                
                <Box direction='row' flex="grow" align='center'  pad="0px"> {size==="small"&&( <Box margin="0px 5px"><Menu onClick={()=>menuToggle()}/>  </Box>)} 
                {(size!=="small"  || !projectInfo )&& <img src="/logo_full.png" alt="Labelator.io" height="35px"/> }
                
                </Box>
                {(size!=="small" && isDemoUser)  && (
                     <Box >
                         <RequestAccessButton/>

                     </Box>
                )}
                <Box direction="row" flex="grow" justify='end'>
                {
                projectInfo? (
                    <Box direction='row' align='center'>
                        <a style={{color:"white",textDecoration:"none",margin:"auto" }} href={`/${projectInfo.id}/settings`}> 
                        <Text  truncate >{projectInfo&&projectInfo.name}</Text>
                        </a>
                        <Button icon={<BarChart />} onClick={sideBarToggle} />
                        
                        
                    </Box>
                ):(
                    <Box direction='row' align='center'>
                       
                        
                       {currentUser &&      
                        <Button icon={<SchedulePlay />} onClick={sideBarToggle} />
                       }
                        
                        
                    </Box>
                )
                }
                {!currentUser?(
                      <Box direction='row' align='center'>
                    <a style={{color:"white",textDecoration:"none",margin:"auto" }} href={`/login`}> 
                    <Button icon={<User />}  />
                    </a>
                    </Box>
                ):(
                
                    <DropButton plain label={<User />}  
                    dropAlign={{"top":"bottom"}}
                        dropContent={
                        <Box round pad="15px" gap="small" align='end'>
                            <Box direction='row' gap="small">
                             
                                <Text color="white">{ currentUser.displayName|| currentUser.email}</Text>
                            </Box>
                                <a href='/login' >  <Button plain icon={<UserSettings/>} label="User settings"/> </a>
                              
                            <Button onClick={()=>logout().then(()=>window.location.href ="/login")} label="Logout"/>
                        </Box>
                    } />
                )}
                </Box>
            </Header>
            );
        };
