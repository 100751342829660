import { React, useState, useEffect, useRef, useMemo,memo } from "react";
import { Box, Button, Text} from "grommet";
import { LabelTag } from "./LabelTag/LabelTag";
import {AddLabelButton} from "./AddLabelButton"
import { Autocomplete } from "./Autocomplete";
import { getApi } from "../ApiService";
import { useParams } from "react-router-dom";
import { Tag } from "grommet-icons";

const MAX_LABELS=6;

class LabelAreaProps{ 
    labelsValue:string[]|undefined;
    allLabels:string[]|undefined;
    onUpdateLabels:any|undefined;
    
    
    
    
    allowMultiple:any|undefined;
    
    disabled:any|undefined;
    
    
}

export const LabelArea = memo(({ 
    labelsValue, 
    allLabels, 
    onUpdateLabels, 
    predicted_labels=undefined,  
    likelyLabels=undefined,
    history=undefined,
    onAddLabel=undefined, 
    allowMultiple=undefined, 
    labelsSettings=undefined, 
    disabled=false, 
    updatingLabels=undefined 
}:LabelAreaProps) => {
    const [mouseOver, setMouseOver] = useState(false);
    const [searchValue, setSearchValue] = useState();
    //const [likelyLabelsInternal, setLikelyLabelsInternal] = useState([]);
    // useEffect(()=>{
    //     if  (likelyLabels){

    //         setLikelyLabelsInternal([...new Set([...likelyLabels ,...likelyLabelsInternal])])
    //     }
    // },[likelyLabels])

    const { project_id } = useParams();
    function prepareState(labelsValue, allLabels) {
        
        let initialLabelState;
        if (allLabels?.length>MAX_LABELS){
            initialLabelState= labelsValue ||[];
        }
        else{
            initialLabelState= allLabels||[];
        }
        let ls =  []
        initialLabelState.forEach(l => {
            if (allLabels  && allLabels.includes(l)){

                ls.push( {
                    label: l,
                    isActive:  labelsValue?.includes(l),
                    isPredicted:  predicted_labels?.includes(l),
                    isLikely: likelyLabels?.includes(l),
                    isHistory: history?.includes(l),
                    invalid: false,
                });
            }
        })
        let invalidLabels = (labelsValue||[]).filter(l=>allLabels && !allLabels.includes(l));
        if (allLabels?.length>MAX_LABELS){

            [...(new Set([ ...(history||[]), ...(likelyLabels||[]),...(predicted_labels||[]),...invalidLabels ]))].filter(l=>!labelsValue?.includes(l)).forEach(l => {
                let labelStateItem = {
                    isActive: false,
                    isPredicted: predicted_labels?.includes(l),
                    isLikely:likelyLabels?.includes(l),
                    isHistory: history?.includes(l),

                    label: l,
                    invalid: invalidLabels.includes(l),
                }
                
       
                    ls.push(labelStateItem);
                
            })
        }else{
            invalidLabels.forEach(l => {
                    ls.push( {
                        isActive: true,
                        isPredicted: false,
                        isLikely:false,
                        isHistory:false,
    
                        label: l,
                        invalid: true,
                    });
            })
        }
       
        return ls;
    }
    const [labelsState, setLabelsState] = useState();

    useEffect(() => {
        setLabelsState(prepareState(labelsValue, allLabels));

    }, [labelsValue, allLabels, likelyLabels, history]);


    const updateLabels = (label, state)=>{
        if (!disabled){
            onUpdateLabels(label,state);
        }
    }

    

    const autocompletestyle={height:"30px", borderRadius:"20px", maxWidth:"150px",width:"250px"};   
    return (
        <Box gridArea="labels" width="100%"  align="center" direction="row" flex wrap onMouseEnter={()=>setMouseOver(true)} onMouseLeave={()=>setMouseOver(false)}>
            
            { labelsState && (
                (allLabels?.length < MAX_LABELS) ?
                (
                    <Box direction="row" wrap align="center">
                        {  labelsState.map(labelObj => (
                         <LabelTag 
                            key={labelObj.label} 
                            label={labelObj.label} 
                            active={labelObj.isActive} 
                            predicted={labelObj.isPredicted } 
                            suggested={labelObj.isLikely}
                            readonly={disabled}
                            invalid={labelObj.invalid} 
                            onActiveChange={updateLabels} 
                            isUpdating={updatingLabels?.includes(labelObj.label)}
                            labelSettings={labelsSettings && labelsSettings[labelObj.label]}
                            />
                            )
                        )}
                        {onAddLabel && ( <AddLabelButton mouseOver={mouseOver||labelsState.length==0} caption={labelsState.length==0&&"Add label"} onAddLabel={onAddLabel}/>)}
                        </Box>
                )
                :
                (
                        <Box  gridArea="labels" align="center" direction="row" flex wrap >
                        {
                            labelsState
                            .filter(t=> t.isActive|| t.isPredicted || t.invalid || t.isLikely || t.isHistory    )
                            .map(labelObj => (
                                <LabelTag 
                                    key={labelObj.label} 
                                    label={labelObj.label} 
                                    active={labelObj.isActive} 
                                    predicted={labelObj.isPredicted} 
                                    invalid={labelObj.invalid} 
                                    onActiveChange={updateLabels} 
                                    isUpdating={updatingLabels?.includes(labelObj.label)}
                                    labelSettings={labelsSettings && labelsSettings[labelObj.label]}
                                    />
                                    )
                                )
                        }
                        {/* {
                            likelyLabelsInternal&&likelyLabelsInternal
                            .filter(l=>!(textDocument.labels?.includes(l) || textDocument.predicted_labels?.includes(l)))
                            .map(label=>(
                                <LabelTag 
                                key={label} 
                                label={label} 
                                active={false} 
                                predicted={false} 
                                invalid={false} 
                                onActiveChange={onUpdateLabels} 
                                labelSettings={labelsSettings && labelsSettings[label]}
                                />
                            ))
                        } */}
                        <Box>
                            
                        <Autocomplete  
                            
                            className="disabledSelectionChange"
                            onValueChange={(label, target)=>{
                                if(!labelsValue?.includes( label)){
                                    updateLabels(label,true);
                                }
                                target.current.blur()
                                setSearchValue("");
                            }}
                            value={searchValue}
                            defaultSuggestions={allLabels?.map(l=>({
                                label:(
                                    <Box pad="5px" direction="row" gap="small"
                                        round="5px"
                                        margin="2px"
                                        border={{color:((labelsSettings && labelsSettings[l]?.color) || undefined), size:"2px"} }>
                                        {labelsSettings && labelsSettings[l]?.icon||<Tag size="15px"/>}
                                        <Text>{l}</Text>
                                    </Box>
                                ),
                                // label:(<Box fill>
                                //     <Button  flex="10" size="small" margin="2px" style={{borderRadius:"5px"}} 
                                //         color=
                                //         icon={<Text>{labelsSettings && labelsSettings[l]?.icon||<Tag size="15px"/>}</Text>}/></Box>),
                                value:l
                            }))}
                            style={autocompletestyle}
                            size="15px"
                            placeholder="search label..."
                            />
                        </Box>
                        {onAddLabel && ( <AddLabelButton mouseOver={mouseOver} onAddLabel={onAddLabel}/>)}
                        </Box>
                )
            )
            }

        </Box>)
})