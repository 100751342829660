import {React, useState, useEffect, useRef, useMemo} from "react";
import { Box, Text, Grid, Button,Tip,ThemeType, TextInput, Spinner } from "grommet";

import { Add, Checkmark} from "grommet-icons";
import { useAppContext } from "../AppContext";


export const AddLabelButton = ({mouseOver, caption, onAddLabel}) => {

    const [isActive, setActive] = useState();
    const [isSaving, setIsSaving] = useState();
    const [isValidState, setIsValidState] = useState({msg:"Enter label name first",color:"#DADADA"});
    
    //const [isMouseOver, setIsMouseOver] = useState();

    const textboxRef = useRef();

   const styles = {
    normal:{
        minWidth:"90px",
    },
    onHover:{
        borderStyle:"dashed",
        borderColor:"#DADADA",
        borderRadius:"15px",
        minWidth:"90px",
        //padding:"5px",
    },
    active:{
        borderStyle:"dashed",
        borderColor:"#999999",
        borderRadius:"15px",
        minWidth:"130px",
        padding:"5px",
        minWidth:"90px"
        
    },
    inputStyle:{border:"none", outline: "none", fontSize:"12pt"}
   }

    function onEnter(event){
        setActive(true);
        //textboxRef.current.focus();
        
        //console.log(textboxRef.current);
        //console.log("<<current>>");
        event.stopPropagation();
    }
    let timeout;
    function onLeave(e){
        //console.log(e);
        timeout = setTimeout(()=>{
            setActive(false)
            //setIsMouseOver(false)
        }, 250)
        //clearTimeout(timeout);
    }

    function validate(value){
        
        let whiteSpaceRegex = /\s/gm
        let res={color:"neutral-1", state:true};
        if (whiteSpaceRegex.test(value)){
            res= {msg:"Label can't containt white spaces", color:"#ffc9c9"};
        }
        if (value.length===0){
            res= {msg:"Label must contain at least one character", color:"#DADADA"};
        }
        
        setIsValidState(res);
        return res;
    }
    function onCommit(event){
        clearTimeout(timeout);
        textboxRef.current.preventExit=true;
        let val = textboxRef.current.value.trim()
        let validationRes = validate(val);
        console.log(validationRes);
        if (validationRes.state!==true){
            
        }
        else{
            setIsSaving(true)
            let res = onAddLabel(val)
            if(res.then){

                res.then(()=>{
                    onLeave()
                    setIsSaving(false);
                }).catch(()=>{
                    onLeave()
                    setIsSaving(false);
                })
                
            }
            else{
                onLeave()
                setIsSaving(false);
            }
        }
    }
    const {testPermission} = useAppContext();
    
    const errTip = useMemo(()=>testPermission("MODIFY_PROJECT",true));

    if (!isActive){
        return(
            <div onClick={errTip?undefined:onEnter} >
            <Tip content={errTip||"Add new label"}>
                <Box direction="row" align="center"  className={mouseOver?"addLabel":"addLabel-hidden"} >
                    <Add  />{caption&&<Text size="small">{caption}</Text>}
                </Box>
            </Tip>
            </div>
            )
    }
    else{
        return (
            isSaving?(
                <Box direction="row" align="center"  pad="3px" margin="0px 5px" >
                <Spinner size="small"/>
                </Box>
            ):(
        <Box pad="8px" style={styles.active} direction="row" background="white" >

        <input ref={textboxRef} autoFocus  style={styles.inputStyle} onBlur={onLeave} onChange={e=>validate(e.target.value.trim())} />  
        <Tip content={isValidState.msg || "Add label"}>
        <Box  onClick={onCommit} 
        align="center"
            direction="row" pad="2px 5px" round background={isValidState.color}>
                
            <Checkmark size="15px"/><Text>Save</Text></Box>
        </Tip>
        
        </Box>)
        )
    }
};

