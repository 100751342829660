

import {  Box, Heading, Spinner,Grid, Tip, Collapsible, Text,ResponsiveContext, CheckBox, DropButton } from 'grommet'

import { SearchBar } from "../components/SearchBar/searchBar"
import { Texts, SimilarTexts, ToolbarOptions } from "../components/Texts"

import { useState, useEffect, useContext, useRef, useMemo } from "react";
import {getApi} from "../ApiService"
//import {useQuery} from "../components/_customHooks"
import { useParams, useSearchParams} from "react-router-dom"
import { useAppContext } from '../AppContext';
import { Ascend, Checkbox, Close, Contract, Descend, Down, Download, Expand, FormClose, FormNext, FormPrevious, Grommet, Next, Performance, Previous, Refresh, Risk } from 'grommet-icons';
import { BetterDropButton } from '../components/BetterDropButton';



import useEventBus from '../helpers/EventBus';

import { TextCard } from '../components/TextCard/TextCard';
import { TrainingTaskTypes } from '../contants';
import { ExpandingButton } from '../components/ExpandingButton/expandingButton';
import { Badge } from '../components/Badge';
import { humanizeNumber } from '../helpers/helperFunctions';





const  TextDocumentsPage = ({backlog})=>{
  const pageSize =100; 
  const [currentPage, setCurrentPage] = useState(undefined);
  let [data, _setData] = useState(undefined);
  const setData = (data)=>{
    if (similarToDoc){
      const _postprocess = (data)=>{
        let found = data.filter(t=>t.id==similarToDoc?.id)
        if(found[0]){
          if (!similarToDoc || ( JSON.stringify(found[0])!=JSON.stringify(similarToDoc) )){
            
            setSimilarToDoc(found[0])
          }
        }
      }
      if (typeof data=="function"){
        _setData((oldData)=>{
          let newDataRes = data(oldData)
          _postprocess(newDataRes)
          return newDataRes
        })
      }
      else{
        _postprocess(data)
        _setData(data)
      }
    }
    _setData(data)
  }

  const [contextParamsKeys,setContextParmasKeys]=useState();

  const [isLoading, setIsLoading] = useState(true);
  const [lastQuery, setLastQuery] = useState();
  const [similarToDoc,_setSimilarToDoc]=useState();
  const setSimilarToDoc = (s)=>{
    _setSimilarToDoc(s)
  }


  
  const [isMoreData, setIsMoreData] = useState(true);

  const [count, setCount] = useState();
  const [filters, setFilters] = useState({});
  const [queryParams, setQueryParams]=useSearchParams()
  
  useEffect(()=>{
    if(queryParams){
      setCurrentPage( parseInt(queryParams.get("page"))||1)
      if (queryParams.get("order_by")){
        let orderBy = queryParams.get("order_by")
        if (orderBy=="_i" || orderBy=="-_i" ){
          setOrderBy(orderBy)
        }
      }
    }
  },[queryParams])
   
  //const urlQueryParams = useQuery();
  
  let { project_id } = useParams();

  // useEffect(()=>{
  //   let newFilters = Object.assign({},filters)
  //   if (backlog && newFilters.by_label!="null"){
  //     newFilters.by_label="null";
  //     search(newFilters);
  //   }
  //   else if (newFilters.by_label)
  //   {
  //     delete newFilters.by_label
  //     search(newFilters);
  //   }
  // },[backlog])

  const search = (searchTerm, specialTokens)=>{
    let page=1
    if (lastQuery==undefined){
      //if this is the first load, respect the current page from url... otherwise > the filter changed so lets start from page:1
      page=currentPage
    }
    if (!projectInfo) return;
    let query = {};
    if (searchTerm){
      query["q"]=searchTerm
    }
    
    if (backlog ){
      if(projectInfo?.task_type!=TrainingTaskTypes.QuestionAnswering){
        query.by_label="null";
      }
      else{
        query.answer="null";
      }
    }

    query = Object.assign(query,specialTokens)

    
    setFilters(Object.assign({},query));
    

    let currentQuery = (new URLSearchParams(query)).toString();
    
    if (currentQuery!=lastQuery) {
      data=[]
      setData([]); //reset data
     
    }
    setIsLoading(true);
    
    setLastQuery(currentQuery);

    loadData(project_id, query,page);
    
  };

  

  const searchSimilar = (searchTerm, specialTokens)=>{

    let query = {keyword:searchTerm};
    if (backlog){
      query.by_label="null";
    }

    //do not pass other special filters into query, it is weird
    //query = Object.assign(query,specialTokens)

    setSimilarToQueryFilter(query)
    
  };

  const loadData = (project_id, query, page)=>{
    page=page||1;
    let take= pageSize; 
    let skip =  pageSize*(page-1); 
    query["take"] = take;
    query["skip"] = skip;
    if (query["q"])
    {
      query.keyword = query.q
      delete query.q
    }
    
    query["order_by"] = orderBy;
    // if (orderBy=="-_i"){
    // }
    // else if (query["order_by"]){
    //   delete query.order_by
    // }
    
    
    setIsLoading(true);    
    return getApi().searchTexts(project_id, query, (newData)=>{
      setIsLoading(false);
      setCurrentPage(page);

      if (newData && contextParamsKeys==undefined){
        let _contextParamsKeys=new Set()
        newData.forEach(d=>d?.context_data && Object.keys(d.context_data).forEach(k=>!_contextParamsKeys.has(k)&&_contextParamsKeys.add(k)))
        setContextParmasKeys([..._contextParamsKeys])
        // setTimeout(()=>{
        // },10)
        
      }
      //newData?.forEach(d=>d.key="page-"+page+"-"+d.key)
      //if (data?.length<=skip && page>1){

        let newQueryParams = new URLSearchParams(window.location.search)
        if (page>1 || newQueryParams.get("page")>1 ){
          newQueryParams.set("page", page)
        }
        if (query["order_by"]){
          newQueryParams.set("order_by", query["order_by"])
        }
        setQueryParams(newQueryParams) //<<this!!!
      //}
      
      if (page>1){

        if (!data || data?.length<skip){
          if (!data?.length && newData?.length==0){
            //page>1 but not more data... and no previous data loaded yet
            loadData(project_id,query,1)
            return
          }
         
          if (newData?.length){
            newData = (new Array(skip)).fill(null).concat(newData);
            setData(newData);
            
          }

        }
        else if (newData && newData.length>0){
          if (data.length>skip){
            newData =[...data.splice(0,skip), ...newData]
            // dataCopy.splice(skip,newData.length,...newData)
            // newData = dataCopy
          }
          else{
            newData =[... data.concat(newData)];
          }
          setData(newData);
        }
        else{
          setData(data || []);
        }
       
      }
      else{

        setData(newData);
      }
      
      if (page==1&&  newData.length<take ){
        setCount( newData.length)
      }else if (page==1 || (count===undefined)){
        //warning: this condition 
        let countQuery={...query}
        delete countQuery.take
        delete countQuery.skip
        Object.keys(countQuery).filter(f=>f.startsWith("similar_to")).forEach(f=>{
          delete countQuery[f]
        })
        // if (Object.keys(countQuery).length){
        //   getApi().getTextsCount(project_id,countQuery).then(newCount=>{
        //     setCount(newCount)
        //   })
        // }
        // else{
        //   setCount(backlog?projectInfo.total_count-projectInfo.labeled_count: projectInfo.total_count)
        // }
      }
      

      if (similarToDoc && newData.filter(t=>t.id==similarToDoc.id).length==0){
        setSimilarToDoc(null)
      }
      
      if (newData.length<query["take"] || newData.length===0) {
        setIsMoreData(false);
      }
      else{
        setIsMoreData(true);
      }
      })
      .catch((ex)=>{
        setIsLoading(false)
      })
  }

  const loadNextPage = ()=>{
    console.log("loadNextPage");
    if(currentPage){

      let page = currentPage+1
      return loadData(project_id, filters,page);
    }
  }
  const loadPreviousPage=()=>{
    console.log("loadPreviousPage");
    if(currentPage){

      let page = currentPage-1
      return loadData(project_id, filters,page);
    }
  }

  const previousSimilar =()=>{
    if (similarToDoc){
      let cur_idx= data.indexOf(similarToDoc)
      if (data[cur_idx-1]){
        setSimilarToDoc(data[cur_idx-1])
      }
    
    }
  }
  const nextSimilar =(idx)=>{
    if  (idx && data[idx]){
      setSimilarToDoc(data[idx])
    }
    else if (similarToDoc){
      
      let cur_idx= data.indexOf(similarToDoc)
      if (data[cur_idx+1]){
        setSimilarToDoc(data[cur_idx+1])
      }
      else{
        loadNextPage().then(()=>{
          nextSimilar(cur_idx+1)
        })
      }
    }
  }

  const onHideLabeled=()=>{
    setData(data.filter(t=>!(t?.labels?.length)))
  }


  const updateTexts = (changedTexts)=>{

    const updateFunc = (data)=>{
        if (data){
          
          const updateIndex={}
          for (let i = 0; i < data.length; i++){
            let found=changedTexts.find(t=>data[i] && t.id==data[i].id)
            
            if (found){
             
              updateIndex[i]=found
            }
          }
    
          if (Object.getOwnPropertyNames(updateIndex).length>0){
            let newData = [...data]
            for (let i of Object.getOwnPropertyNames(updateIndex)){
            
              let rec = Object.assign({}, updateIndex[i])
              
              if (rec.score) delete rec.score
              newData[i] = rec
              
            }
            return newData
            
            
          }
          else
            return data
    
        }
      }
      _setData(updateFunc)
        
  
  }

  function exportData(format){
    let exportFilters = Object.assign({},filters)
    if (exportFilters.skip) delete exportFilters.skip
    if (exportFilters.take) delete exportFilters.take
    
    getApi().getExportText(projectInfo.id, format, {search:exportFilters}).then(()=>newTaskScheduler())
  }
  
  
  const {projectInfo,newTaskScheduler,applyLabelChanges}=useAppContext();
  const [projectPredictionInfo, setProjectPredictionInfo] = useState()

  useEffect(()=>{
    if (projectInfo && projectInfo?.id!=projectPredictionInfo?.project_id){
      getApi().getProjectPredictionInfo(projectInfo.id)
      .then(newProjectPredictionInfo=>setProjectPredictionInfo(newProjectPredictionInfo))
    }
  },[projectInfo])

  function suggestTokenValue(tokenType, currentValue){
    if (["by_label","predicted_label","false_positives","false_negatives"].includes(tokenType)){
      if (!currentValue || projectInfo?.labels?.includes(currentValue) ){
        return ["null","!null",...projectInfo?.labels||[]]
      }
      else{
        return ["null","!null",...projectInfo?.labels||[]]?.filter(t=>t.includes(currentValue))
      }
    }
    else if (tokenType=="review_state"){
      let options=["review_requested","reviewed"]
      if (!currentValue || options.includes(currentValue) ){
        return options
      }
      else{
        return options.filter(t=>t.includes(currentValue))
      }
    }
    else if (["prediction_comparsion"].includes(tokenType)){
      let labels_with_prefix = [...projectInfo?.labels?.map(l=>"+"+l)||[], ...projectInfo?.labels?.map(l=>"-"+l)||[]]
      if (!currentValue){
        return ["null","!null",...labels_with_prefix]
      }
      else{
        return ["null","!null",...labels_with_prefix]
      }
    }

  }
  
  function handleSimilarToDocLabelsUpdated(doc_id,label,state){
    let newSimilarToDoc = Object.assign({},similarToDoc);
    let isChanged=false;
    let changes={added:{},removed:{}};
    if (state){
      if (!newSimilarToDoc.labels?.includes(label)){

        if (projectInfo.task_type==TrainingTaskTypes.MultiLabelTextClassification){
          newSimilarToDoc.labels = [...newSimilarToDoc.labels||[], label]
          isChanged=true
          changes.added[label]=1
        }
        else{
          newSimilarToDoc.labels = [label]
          changes.added[label]=1
          isChanged=true
        }
      }
    }
    else{
      newSimilarToDoc.labels =newSimilarToDoc.labels?.filter(l=>l!=label)
      isChanged=similarToDoc.labels?.length!=newSimilarToDoc.labels.length
      if (isChanged){
        changes.removed[label]=1
      }
    }
    if (isChanged){
      let totalLabeledChanged= !similarToDoc.labels?.length && state?1:-1
      
      setSimilarToDoc(newSimilarToDoc)
      getApi().updateDocLabels(project_id, [newSimilarToDoc.id], newSimilarToDoc.labels).then(()=>{
        updateTexts([newSimilarToDoc])
      })
      applyLabelChanges(changes, totalLabeledChanged)
    }

    
    
  } 

  
 
  const showSimilarToDoc=(doc)=>{
    setSimilarToDoc(doc)
    setPane("both")
  }
  const responsiveSize = useContext(ResponsiveContext);
  useEffect(()=>{
    if (!similarToDoc){
      setPane("left")
    }
    else{
      if (pane=="left"){
        if (responsiveSize=="large" ||responsiveSize=="xlarge"   ){

          setPane("both")
        }
        else{
          setPane("right")
          
        }
      }
    }
  },[similarToDoc])

  const eventBus = useEventBus();
  const [pane,setPane] = useState("left")
  const [isRightPaneOpen,setIsRightPaneOpen] = useState(false) // this is a proxy property that shoul fix the complete re-render of right pande on swithing between right/both 
  useEffect(()=>{
    if ((pane=="right"||pane=="both")&& !isRightPaneOpen){
      setIsRightPaneOpen(true)
    }
    else if (pane=="left"&& isRightPaneOpen){
      setIsRightPaneOpen(false)
      setSimilarTextsControlState(undefined)
      setSimilarToDoc(undefined)
    }
  },[pane])

  const [similarToQueryFilter,setSimilarToQueryFilter] = useState( backlog?{by_label:"null"}:{})
  const [similarTextsControlState,setSimilarTextsControlState] = useState()

  const backlogSimilar = useMemo(()=>projectInfo?.task_type==TrainingTaskTypes.QuestionAnswering?"similar_not_answered":"similar_not_labeled", [projectInfo])
  
  const setCurrent = (item)=>{
    if (item){
      let newParams = new URLSearchParams(queryParams)
      newParams.set("id", item.id)
      //setQueryParams(newParams)
    }
    else if (queryParams.get("id")){
      let newParams = new URLSearchParams(queryParams)
      newParams.delete("id")
      //setQueryParams(newParams)
    }
  }

  let [orderBy, setOrderBy]=useState("-_i")
  const toolbarPadding = responsiveSize=="small"?"5px":"10px"

  const specialTokenParsers = {
  //topic_id:/^topic_id:(-?\d*)/,
  //similar_to_doc:/^similar_to_doc:([^\s]*)/,
  //similar_to_phrase:/^similar_to_phrase:(.*)/,
  key:/^key:(.*)/,
  by_label:/^by_label:(!?[\w|\d|_|-]*)/,
  predicted_label:/^predicted_label:(!?[\w|\d|_|-]*)/,
  prediction_certainty:/^prediction_certainty:((!?[>|<]0\.[\d]*)|$)/,
  false_positives:/^false_positives:(!?[\w|\d|_|-]*)/,
  false_negatives:/^false_negatives:(!?[\w|\d|_|-]*)/,
  prediction_comparsion:/^prediction_comparsion:(!?[\w|\d|_|-]*)/,
  review_state:/^review_state:(!?[\w|\d|_|-]*)/,
  
  //context_data:/^context_data:(!?[\w|\d|_|-]*)/,
  }

  return (
    
    <Box fill background="light-3" >
    
      <Box direction='row' align='center' justify='stretch'  flex={false}>
        <Box width="100%"  >
        { projectInfo && ( pane=="left"?(
        <SearchBar
          onChange={search} 
          semanticSearchFilterKey="similar_to_phrase"
          specialTokensRegexParsers={ contextParamsKeys? Object.assign(specialTokenParsers, ...contextParamsKeys?.map(key=> ({ ["@"+key]: new RegExp("^#"+key+":(!?[\w|\d|_|-]*)") }))||{}): specialTokenParsers }
          preddefinedFilters={{
            "not labeled":{"by_label":"null"},
            "labeled":{"by_label":"!null"},
            "removed":{"excluded":false},
            "wrong prediction":{"false_positives":"!null","false_negatives":"!null"}
          }}
          suggestTokenValue={suggestTokenValue}
      />
          ):(
            <SearchBar
            placeholderText='Seach similar'
            disableSyncWithUrl
          onChange={searchSimilar} 
          
      />
          ))}
      </Box>

      <Box direction='row'  flex="grow" gap="0px"  margin="0px 0px 0px -15px" align='center'>

          <Tip content="Reload">
            {isLoading?(<Box pad={toolbarPadding} ><Spinner size="4px"/></Box> ):(
              <Box focusIndicator={false}  flex="grow" pad={toolbarPadding} onClick={()=> loadData(project_id, filters,1)} >
                <Refresh size="22px"/></Box>
            )}
          </Tip>
        <Tip content="Change order">
          <Box>
            <ExpandingButton
            pad={toolbarPadding}
            onClick={()=>{
              let newOrder = orderBy?.startsWith("-")?orderBy.substring(1):"-"+orderBy||"_i"
              setOrderBy(newOrder)
              orderBy=newOrder
              loadData(project_id, filters,1)
            }}
            border={false}
              collapsedContent={
                !orderBy?.startsWith("-")? (<Ascend/>):(<Descend/>)
              }
              expandedContent={
                <Box direction='row' align='center'>
                  {!orderBy?.startsWith("-")? (<Ascend/>):(<Descend/>)}
                  <Text size="10pt">#order</Text>
                </Box>
              }
            />
          </Box>
        </Tip>
        {projectInfo &&
        <Tip content="Export view">
        <Box   pad="0px 0px" flex="grow" margin="0px -5px 0px -2px" >
          <BetterDropButton pad={toolbarPadding} icon={<Download/>} 

          content={[
            {
              label:"Export to csv", 
              onClick:()=>exportData( "csv"),
              permissions:"EXPORT_DATA"
            },
            {
              label:"Export to xlsx", 
              onClick:()=>exportData( "xlsx"),
              permissions:"EXPORT_DATA"
            },
            {
              label:"Export to json", 
              onClick:()=>exportData( "json"),
              permissions:"EXPORT_DATA"
            }
          ]}  />
        </Box>
        </Tip>}
       
      </Box>
     </Box>
       
     
      <Box direction='row'>
      {pane=="right" && 
      <Box   pad="0px 5px 5px"  gap="small" >
                 <Box  pad="6px" border round elevation='medium'  focusIndicator={false} onClick={()=>setPane("both")}>
                 <Contract style={{transform: "rotate(90deg)"}} size="15px"/>
                </Box>
                <Box  pad="5px" border round elevation='medium'  focusIndicator={false} onClick={()=>setPane("left")}>
                 <FormClose/>
                </Box>
              </Box>}
         
      <Box width={pane=="left"?"100%":(pane=="right"?"0px":"30%")}>
             
                <Texts 
                caption={ <Box direction='row'>{backlog?"Backlog":"Documents"}
                {count?<Badge pad="1px 4px" boxExtra={{margin:"-5px 2px 5px"}} background="brand" tooltip={count} value={<Text size="small">{humanizeNumber(count)}</Text>}/>:<></>}</Box>}
                texts={data} 
                onHideLabeled={onHideLabeled}
                projectPredictionInfo={projectPredictionInfo}
                
            
                //similarToDoc={(new URLSearchParams(document.location.search)).get("similar_to_doc") }
                onTextsChanges={(newData) => setData(newData)}

                  hasNextPage={isMoreData} isLoading={isLoading} pageSize={pageSize} filters={filters} 
                  similarSearchOptions={backlog ? [backlogSimilar]: ["similar_count",backlogSimilar]}
                  onShowSimilarToDoc={showSimilarToDoc}
                  similarToDocSidebarOpen={pane!="left"}
                  loadPreviousPage={loadPreviousPage}
                  loadNextPage={loadNextPage}
                  eventBus={eventBus}
                  toolbarOptions={pane=="left"&&[ToolbarOptions.labeling_preferences]}
                  onCurrentChange={setCurrent}
                />
                
          </Box>
              

        {(isRightPaneOpen)&&( 
          <Box width={pane=="both"?"70%":"100%"}>

             <SimilarTexts project_id={project_id} 
             stateData={similarTextsControlState}
             onStateChanged={setSimilarTextsControlState}
             onSelectedLabeled={handleSimilarToDocLabelsUpdated}
           
            header={
              <>
              {  pane=="both" ? 
              <Box  direction='row'  pad="0px 0px 5px"  margin="-5px 0px 0px -10px" gap="small" >

                <Box  pad="5px"  round border focusIndicator={false} onClick={()=>setPane("right")} elevation="small">
                 <Expand style={{transform: "rotate(90deg)"}} size="14px" />
                </Box>
                <Box pad="5px 8px" align='center' justify='center' round  border focusIndicator={false} onClick={()=>setPane("left")} elevation="small">
                  <Close size="14px"  />
                </Box>
              </Box>:(
               <></>
              
              )}
              { pane=="right" &&(<Box  direction="row" justify='between'  pad="0px 5px 5px"  gap="small" >
                  <Box>
                    {data.indexOf(similarToDoc)>0 && (
                    <Box pad="5px" width="100px"  align='center' direction='row' round border  focusIndicator={false} onClick={()=>previousSimilar()}>
                    <Previous /><Box width="100%" align="center"><Text size='small'>Previous</Text></Box>
                    </Box>
                    )}
                  </Box>
                <Box>
                {isMoreData && ( 
                  <Box  pad="5px" width="100px" align='center' justify='end' direction='row' round border focusIndicator={false} onClick={()=>nextSimilar()}>
                  <Box width="100%" align="center"><Text size='small'>Next</Text></Box> <Next  />
                </Box>
                )}
                </Box>
                </Box>)
                
              }
              <Box pad="0px 0px 0px 0px" margin="-5px -10px 0px -20px" >
                <TextCard 
                  showCheckbox={false}
                  textDocument={similarToDoc} 
                  selection={[similarToDoc.id]}
                  onLabelsUpdated={handleSimilarToDocLabelsUpdated} 
                />
              </Box>
              </>
            }
            queryFilters={similarToQueryFilter}
            //similarScoreAutoselectRange={autoselect? similarAutoselectRange:undefined} 
            onTextsChanges={(changedTexts)=>{
              updateTexts(changedTexts)
              //setTimeout(()=>updateTexts(changedTexts),1500) // some timeout because here it works with old data for some reason
            }} 
            similarToDoc={similarToDoc}
            eventBus={eventBus}
            />
            
         
          </Box>
        )}

       
      </Box>
    </Box>
    
   

  );
}

const BacklocTextDocumentsPage = ()=>{
  return (<TextDocumentsPage backlog/>)
}

export {TextDocumentsPage, BacklocTextDocumentsPage};
