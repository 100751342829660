import { Layer,Spinner,Text,Box, Button } from "grommet";
import { useState } from "react";

const ConfirmDialog=({text, onOk, onCancel})=>{
    const [isWaiting,setIsWaiting] = useState(false)
    return (

        <Layer>
            <Box pad="large" align='center' gap='medium'>
            
                <Text>{text}</Text>
                {isWaiting?(
<Box direction="row" gap="small" align="center">
                    <Spinner alignSelf="center"/>
                    <Text>Processing</Text>
                    </Box>
                ):(

                <Box direction="row" gap="small">
                    <Button primary label="OK" onClick={()=>{
                        setIsWaiting(true)
                        onOk&&onOk()}}/>
                    <Button secondary label="Cancel" onClick={()=>onCancel&&onCancel()}/>
                </Box>
                )}
            </Box>
        </Layer>
    )
    }

export {ConfirmDialog};