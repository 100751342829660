import { Box, RangeSelector as GrommetRangeSelector, RangeInput, Stack,Text, ThemeType, Grommet, TextInput } from "grommet";
import { useState } from "react";


const customThemeRangeInput: ThemeType = {
    global: {
      spacing: '12px',
    },
    rangeInput: {
      track: {
        color: 'accent-1',
        height: '10px',
        extend: () => `border-radius: 20px`,
        lower: {
          color: 'dark-4',
          opacity: 0.5,
        },
        upper: {
          color: 'rgb(255,120,50)',
          opacity: 0.8,
        },
      },
      thumb: {
        extend: () => `padding: 10px; margin:-5px`,
        color: 'rgb(255,140,80)',
      },
    },
  };


  function RangeSelector({ minMaxRange = [0, 100], label, rangeStart, rangeEnd, fixedEnd, onChange,disabled=false }:{
    minMaxRange?:number[],
    label:string,
    rangeStart:number, rangeEnd:number, 
    fixedEnd?:boolean
    onChange:(start:number,end:number)=>any
    disabled?:boolean
  }) {
  const [range, setRange] = useState([rangeStart,rangeEnd]);

  const [editMode,setEditMode]= useState<string|undefined>();
  const validateNumber=(num:any, defaultValue:number)=>{
    if (num=="" ){
      return "";
    }
    else {
      let parsed = parseFloat(num);
      if  (minMaxRange&& parsed && parsed>=minMaxRange[0] && parsed<=minMaxRange[1]){
        return parsed;
      }
      else
        return defaultValue;
    }
  }

  return (
    <>
          {fixedEnd?(
          
                <Box gap="4px" pad="0px 5px" >
                  {label ? <Text size="small">{label}</Text> : null}
                  
                    
                    <Grommet theme={customThemeRangeInput}>
                    <RangeInput
                      //direction="horizontal"
                      disabled={disabled}
                      min={minMaxRange[0]||0}
                      max={minMaxRange[1]||100}
                      value={range[0]||rangeStart}
                      step={1}
                      
                      //round="5px"
                      
                      onChange={(e) => {
                        if (!disabled){

                          let nextRange=[parseFloat(e.target.value),rangeEnd]
                          setRange(nextRange);
                          onChange && onChange(nextRange[0],nextRange[1])
                        }
                      }}
                    />
                  </Grommet>
              
                </Box>
              ):(
                <Box gap="4px" pad="0px 5px" >
                  {label ? <Text size="small">{label}</Text> : null}
                  <Stack>
                    <Box background="light-4" height="8px" direction="row" />
                    <GrommetRangeSelector
                      direction="horizontal"
                      
                      min={minMaxRange[0]}
                      max={minMaxRange[1]}
                      step={1}
                      size="18px"
                      round="5px"
                      values={[rangeStart||range[0], rangeEnd||range[1] ]}
                      onChange={(nextRange) => {
                        if (!disabled){

                          setRange(nextRange);
                          onChange(nextRange[0],nextRange[1])
                        }
                      }}
                    />
                  </Stack>
              </Box>
              )}
  
          <Box align="center" margin="10px 0px">
            <Box direction="row" gap="small">
            <Box onClick={()=>true} focusIndicator={false}>
              {editMode=="start"?(
                <Box direction="row">
                <TextInput size="small" style={{padding:"2px", width:"40px"}} autoFocus value={range[0]} 
                  onChange={(e)=>setRange( [validateNumber(e.target.value,range[0]) as any, range[1]])} 
                  onBlur={()=>setEditMode(undefined)}/>
                %
                </Box>
              ):(
                <Text  onClick={(!disabled )?()=> setEditMode("start"):undefined} size="small">{`${range[0]}%`}</Text>

              )}
            </Box>
            <Text size="small">-</Text>
            <Box onClick={!fixedEnd ? ()=>true:undefined}  focusIndicator={false}>
              {editMode=="end"?(
                <Box direction="row">
                <TextInput size="small" style={{padding:"2px", width:"40px"}} autoFocus value={range[1]} 
                  onChange={(e)=>setRange( [validateNumber(e.target.value,range[1]) as any, range[1]])} 
                  onBlur={()=> setEditMode(undefined)}/>
                %
                </Box>
              ):(
                <Text  onClick={(!fixedEnd && !disabled )?()=> setEditMode("end"):undefined} size="small">{`${range[1]}%`}</Text>

              )}
            </Box>
            </Box>
          </Box>
        </>
  )
  
}

export {RangeSelector};