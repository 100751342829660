import {useRef,  useState, useEffect, useContext, useMemo, useCallback, memo } from "react";
import { Box, Text, Grid, Button, ResponsiveContext, Spinner, DropButton,Tip, NameValueList, NameValuePair, Drop, CheckBox } from "grommet";
import { Filter, MoreVertical, Close, Multiple, CircleInformation, Overview, UserExpert, CircleQuestion, StatusGood, Checkmark, FormCheckmark } from 'grommet-icons'

import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { getApi } from "../../ApiService";
import { useInView } from 'react-intersection-observer';
import { useParams } from "react-router-dom"
import { LabelArea } from "../LabelArea";
//import Emoji from "react-emoji-render";
import { useDebounce, useQuery, useIsOverflow } from "../_customHooks";

import debounce from 'lodash.debounce';
import {HiglightableText} from  "../HiglightableText";
//import { useSharedProjectState } from "../ProjectState";
import {randomColor} from "randomcolor";
import { TipIfContent } from "../TipIfContent";
import { BetterButton } from "../BetterButton";
import { useAppContext } from "../../AppContext";
import { SimilarCountBadge } from "../SimilarCountBadge/similarCountBadge";
import { useLayoutEffect } from "react";
import "./textCardStyles.css"
import { LabelTag } from "../LabelTag/LabelTag";
import { TrainingTaskTypes } from "../../contants";
import { AnswerArea } from "../AnswerArea/AnswerArea";
import { Badge } from "../Badge";
import { SimilarityScoreBadge } from "../SimilarityScoreBadge/SimilarityScoreBadge";
import { GenerateSimilarExamplesDialog } from "../../dialogs/GenerateSimilarExamplesDialog/GenerateSimilarExamplesDialog";
import { ExpandingButton } from "../ExpandingButton/expandingButton";
import { ReviewButton } from "../ReviewButton/ReviewButton";




export const TextCard = memo(({ current, textDocument, keyPressed, additionalKeywords, onLabelsUpdated, selected, lastSelected, onSelectionChanged, simScore, 
    similarSearchOptions,onAnswerUpdated,
    isScrolling, setSimilarityFeedback,  onExcludeClick, history,  commonPredictedLabels,showSimilarToDoc, eventBus
    ,compact,onInViewChaged,
    labelAreaTooltipMsg,
    onTextDocumentModified,
    projectPredictionInfo,
    enabledSimilarityVoting,
    runAction,
    showCheckbox=true
}) => {
    let { project_id } = useParams();
    //let selected=selection?.includes(textDocument.id)
    //const size = useContext(ResponsiveContext);
    
    
   

    //const { refresh} =  useSharedProjectState();
    const {projectInfo, setProjectInfo, testPermission} =  useAppContext();
    //let allLabels = projectInfo ? projectInfo["all_labels"]:null;
    
    
    
    const [allLabels, setAllLabels] = useState(null);
    const [expandedText, setExpandedText] = useState(false)

    const [lastProjectVersion,setLastProjectVersion]=useState();



    useEffect(()=> {    
        projectInfo && setAllLabels(projectInfo["labels"])
        if (projectInfo && (projectInfo.label_settings || additionalKeywords) && textDocument && (coloredKeywords==undefined || projectInfo.version!=lastProjectVersion)){
            setLastProjectVersion(projectInfo.version) //usualy undefined unless set somewhere internaly in client to enforce refresh
            let matchedKeywords ={}
            if (projectInfo.label_settings){
                for(let label of Object.getOwnPropertyNames(projectInfo.label_settings)){
                    if (projectInfo.label_settings[label].keywords){
                        matchedKeywords[label]={}
                        for(let kw of projectInfo.label_settings[label].keywords){
                            
                            if (textDocument.text?.toLowerCase().includes(kw.toLowerCase()) ){
                                matchedKeywords[label][kw]=1
                            }

                        }
                    }
                }
            }
            
            if(additionalKeywords){
                matchedKeywords["__additionalKeywords"]={}
                for(let kw of additionalKeywords){
                    
                    if (textDocument.text?.toLowerCase().includes(kw.toLowerCase()) ){
                        matchedKeywords["__additionalKeywords"][kw]=1
                    }
    
                }
            }
            handleMatchedKeyWords(matchedKeywords)
        }

        }
    
        
    , [projectInfo] )


    function addLabel(label){
        if (!allLabels.includes(label )){

            
            //setAllLabels(newLabels);
            return getApi().putLabel(project_id, label,()=>{
                //refresh(); why is this here?
                const newProjInfo = Object.assign({},projectInfo);
                newProjInfo.labels = [...allLabels, label]
                setProjectInfo(newProjInfo)
                onUpdateLabels(label,true);
            });
        }
        else{
            onUpdateLabels(label,true);
        }
    }
    useEffect(()=>{
        
        if (lastSelected && entry && entry.target && !inView) {
            entry.target.scrollIntoView()
        }
    },[lastSelected])

    const { ref, inView, entry } = useInView({
        /* Optional options */
        threshold: 0,
    });


    //const isVisible = useOnScreen(nodeRef)
    const [similarTextsCount, setSimilarTextsCount] = useState(undefined);
    const [similarNotLabeledTextsCount, setSimilarNotLabeledTextsCount] = useState(undefined);


    const onClickHandleSelection = (event) => {
        
        if (
             (event.target.nodeType=="INPUT" && event.target.classList.contains("dropButtonIcon")) 
             || event.target.classList.contains("disabledSelectionChange")
             || event.target.closest('.disabledSelectionChange') !=null  
             ) return;
        
        //_setSelected(!_selected);
        if (onSelectionChanged) {
            
            onSelectionChanged(!selected, textDocument, event.shiftKey, event.ctrlKey || event.metaKey)
        }
        //_setSelected(!_selected)
    }


    
    const [infoTooltip, setLabelAreaTooltip] = useState(undefined);
    const loadTooltip = () => {
        if (infoTooltip===undefined){
            if (textDocument.predicted_label_scores){
                
                let tooltip = Object.getOwnPropertyNames(textDocument.predicted_label_scores )
                .filter(p=>!p.startsWith("__") &&!p.endsWith("__" ))
                .sort((a,b)=>textDocument.predicted_label_scores[b]-textDocument.predicted_label_scores[a])
                .slice(0,10)
                .map((p,i)=>(
                    <Badge key={i} value={p + ": " + Math.round(textDocument.predicted_label_scores[p]*100*100)/100 +" %"}/>
                    ));
                setLabelAreaTooltip(null) //FOrc 
                setLabelAreaTooltip(<Box>{tooltip}
                <Badge value={<Text size="small">
                    {textDocument.prediction_comparsion&&projectPredictionInfo.comparsion_model_name + " vs "} 
                    { textDocument.prediction_comparsion || projectPredictionInfo?.current_model_id==textDocument.predicted_label_scores?.__model_id__ ?   projectPredictionInfo?.current_model_name:"unknown model"} </Text>}/>
                    {textDocument.labeled_by && <Badge value={`labeled by: ${textDocument.labeled_by}`} />}
                </Box>)
            }
            else if(textDocument.labeled_by){
                setLabelAreaTooltip(null) //FOrc 
                setLabelAreaTooltip(<Box>
                
                    {textDocument.labeled_by && <Badge value={`labeled by: ${textDocument.labeled_by}`} />}
                </Box>)   
            }
            else{
                setLabelAreaTooltip(<Box>No predictions yet</Box>)
            }
        } 
    }
    

    const onUpdateLabels = (label, state) => {
        if (state){
            setLikelyLabels([...new Set([...(likelyLabels ||[]) ,label])])
            
        }
        return onLabelsUpdated(textDocument.id, label, state)
    };



    const query=useQuery();

    


    const [coloredKeywords, setColoredKeywords] = useState();
    const [likelyLabels, setLikelyLabels] = useState();
    
    function handleMatchedKeyWords(matched_keywords){
        let newColoredKeywords={}
        Object.getOwnPropertyNames(matched_keywords)
                        .forEach(label=>Object.getOwnPropertyNames(matched_keywords[label])
                            .forEach(kw=> 
                                        newColoredKeywords[kw.toLowerCase()] = ((projectInfo.label_settings && projectInfo.label_settings[label]?.color) || "#b336b4") + "50"
                                    )
                        );
        setColoredKeywords(newColoredKeywords)
    }

    const [lastTextDocumentId, setLastTextDocumentId] = useState();
    useEffect(()=>{
        if (lastTextDocumentId && lastTextDocumentId!=textDocument.id){
            setLikelyLabels()
            analyzeDoc()
            setLastTextDocumentId(textDocument.id)
        }
        if (!lastTextDocumentId){
            setLastTextDocumentId(textDocument.id)
        }
    },[textDocument])


    function analyzeDoc(){
        let select =null;
        if (textDocument.labels?.length || textDocument.predicted_labels?.length){
            select =["keywords"]
        }
        else{
            select =["keywords","likely_labels"];
        }
        if (similarSearchOptions && similarSearchOptions.toString().includes("similar_count")){
            select.push("similar_count")
        }
        if (similarSearchOptions && similarSearchOptions.toString().includes("similar_not_labeled")){
            select.push( "similar_not_labeled")
        }
        if (similarSearchOptions && similarSearchOptions.toString().includes("similar_not_answered")){
            select.push( "similar_not_answered")
        }
        setSimilarTextsCount(null)
        //console.log(textDocument.key + " - request")
        getApi().analyseText(project_id,textDocument.id, select).then(reponseData=>
        {   
            //console.log(textDocument.key + " - response")
            //console.log(reponseData)
            
            //if (inView){
                if (reponseData && reponseData["keywords"]!=undefined){
                    let matchedKeywords = reponseData["keywords"]
                    
                    if(additionalKeywords){ //this is duplicate from useEfect that is capturing new keywoards... todo clean up
                        matchedKeywords["__additionalKeywords"]={}
                        for(let kw of additionalKeywords){
                            
                            if (textDocument.text?.toLowerCase().includes(kw.toLowerCase()) ){
                                matchedKeywords["__additionalKeywords"][kw]=1
                            }
            
                        }
                    }
                    handleMatchedKeyWords( matchedKeywords)
                }
                if (reponseData && reponseData["likely_labels"]!=undefined){
                    let likely_labels=[...new Set([...reponseData["likely_labels"] ,...commonPredictedLabels||[]])]
                    setLikelyLabels(likely_labels)
                    textDocument.likely_labels=likely_labels //save this here, so similarToDoc in TextDocumetnPage can pick it up
                }
                if (reponseData && reponseData["similar_count"]!=undefined){
                    setSimilarTextsCount(reponseData["similar_count"])
                }
                else{
                    setSimilarTextsCount(-1);
                }
                if (reponseData && reponseData["similar_not_labeled"]!=undefined){
                    setSimilarNotLabeledTextsCount(reponseData["similar_not_labeled"])
                }

            //}
        
        })
    }

    const analyzeDocDebounced = useCallback(debounce(analyzeDoc,2000), []);
    const inViewDebounced = useDebounce(inView, 600);

    const [sim_threshold, setSimThreshold]=useState()
    useEffect(() => {
        let isSubscribed = true;
      
        if (inView&&projectInfo && (similarTextsCount==undefined || sim_threshold!=projectInfo?.labeling_preferences?.similarity_threshold )&&(!isScrolling )){
            setSimThreshold(projectInfo?.labeling_preferences?.similarity_threshold)
            
            //setTimeout(()=>{
                if (inView && !textDocument._analyzing_in_progress){
                    textDocument._analyzing_in_progress=true
                    analyzeDoc()
                }
        }
        
       
        return ()=>isSubscribed = false;
    }, [inViewDebounced, projectInfo,project_id,isScrolling, similarSearchOptions]);

    useEffect(() => {
        if(onInViewChaged){
            onInViewChaged(inView)
        }
    },[inView])

    
    useEffect(()=>{
        if (eventBus){
            const subscribtions=[];
            
            subscribtions.push(
                //Subscribe to update to some of similar records to update to stats of this doc
                eventBus.subscribe("SimilarRecordsUpdated",({similar_to_doc_id})=>{
                    if (textDocument.id==similar_to_doc_id){
                        
                        //setTimeout(()=>analyzeDoc(),8000) //wait 8s until all records were submitted and index was refreshed
                        analyzeDocDebounced()
                        
                    }
                })
            )

            return ()=>subscribtions.forEach(s=>s.unsubscribe());
        
        }
    },[eventBus])

    useEffect(()=>{
        if (commonPredictedLabels && likelyLabels)
            setLikelyLabels([...new Set([...likelyLabels ,...commonPredictedLabels])])
    },[commonPredictedLabels])


    const processKeyBindings =(event)=>{
        
        if (event.target.tagName!="INPUT"){
            if (projectInfo?.label_settings){
                let match=null;
                for (const label of Object.getOwnPropertyNames(projectInfo?.label_settings)){
                    if (projectInfo.label_settings[label].key_bindings?.toLowerCase()==event.key.toLowerCase()){
                        match=label;
                        break;
                    }
                }
                if (match){
                    onUpdateLabels(match,!(textDocument.labels &&textDocument.labels.includes( match)))
                }
            }
        }
        return event
    }

    const wrapToLink = (content, link)=>{
        if (link){
            return (<Link to={link}>
                {content}
            </Link>)
        }
        else{
            return content
        }

    }

    let labelArea =null;
    
        if (projectInfo && (projectInfo.task_type === TrainingTaskTypes.TextClassification || projectInfo.task_type ===TrainingTaskTypes.MultiLabelTextClassification)){

            labelArea=(!compact)?(
                <LabelArea 
                labelsValue={textDocument?.labels} 
                predicted_labels={textDocument?.predicted_labels}
                allLabels={allLabels} 
                likelyLabels={likelyLabels} 
                onUpdateLabels={onUpdateLabels} 
                onAddLabel={testPermission("ADD_LABELS")? (label)=>addLabel(label):undefined}
                allowMultiple={projectInfo?.task_type=="MultiLabelTextClassification"}
                labelsSettings={projectInfo?.label_settings}
                updatingLabels={textDocument.updating_labels}
                />
            ):(
                <Box direction="row">{
                textDocument.labels?.map(label=>( 
                    <LabelTag 
                        key={label} 
                        readonly
                        size="small"
                        label={label } 
                        active={true} 
                        labelSettings={projectInfo?.label_settings && projectInfo.label_settings[label]}
                    />)
                )
                }</Box>
                
            )
        }
        else if(projectInfo && projectInfo.task_type === TrainingTaskTypes.QuestionAnswering ){
            labelArea=(
               <AnswerArea 
               className="disabledSelectionChange"
                project_id={project_id}
                doc_id={textDocument.id}
                 answer={textDocument.answer}
                 onAnswerUpdated={(answer,callback)=> onAnswerUpdated(textDocument.id, answer, callback)}
               />
            )
        }

    const [contextPopupTarget, setContextPopupTarget] = useState();
    const [popupContent,setPopupContent]=useState();

    const textSectionRef = useRef();
    const isOverflow = useIsOverflow(textSectionRef);

    const createContextDataEleement = (key, value)=>{
        const urlRegex = /^((http(s?):\/\/)|(www.))(\w+[\.|-|_]?)\.\w+/gm
        if ( urlRegex.test(value)){
            return (<Box key={key} direction="row" gap="small">
                        <Text size="xsmall" weight={700}>{key}:</Text>
                        <Text size="xsmall" className="contextData"> <a href={value} target="_blank">{value}</a></Text>
                    </Box> 
                )
        }
        else{
            return (<Box  key={key} direction="row"  gap="small">
                        <Text size="xsmall" weight={700}>{key}:</Text>
                        <Text size="xsmall" className="contextData">{value}</Text>
                    </Box> 
                )
        }
    }

    function handleTextSelection(event){
        const selectedText = window.getSelection()?.toString().trim()
        
        if (testPermission("ADD_KEYWORDS") && selectedText  && selectedText.match(/\s/) === null){
            if (allLabels?.length>0){
                setContextPopupTarget(window.getSelection()?.focusNode.parentElement);
                setPopupContent(
                    <Box gap="small">
                        <Text size="10pt">Add <Text weight={800}>"{selectedText}"</Text> as a keyword to</Text>
                        <LabelArea labelsValue={[]} allLabels={allLabels} likelyLabels={[...new Set([...likelyLabels||[],...textDocument.labels||[]])]} allowMultiple={false} onUpdateLabels={(l)=>{
                            //console.log("add" +selectedText.trim()+" to " + l + "" )
                            getApi().putLabelKeyword(projectInfo.id,l,selectedText,(newProjInfo)=>{
                                newProjInfo["version"]=(new Date()).toString()
                                setProjectInfo(newProjInfo)
                                //getApi().getTextLikelyLabels(project_id,textDocument.id,handleLikelyLabels)
                            })
                           
                            setContextPopupTarget(undefined)
                        }}/>
                    </Box>
                )
            }
        }
        else{
            setContextPopupTarget(undefined);
        }
    }
    
    const renderComponent = ()=>{
        
    return (
        
        <div ref={ref} onKeyDown={(e) => {
            keyPressed && keyPressed(e)
        }} >

             
          {/* {  !inView?(
                <Box height="500px">..</Box>
            ):
            ( */}
            <Box
                className={selected ? "textDocumentCard Selected" : "textDocumentCard"}
                
                
                
                background={"white"}
                round="8px"
                //direction={size == "small" ? "column-reverse" : "row"} 
                onKeyUp={e => processKeyBindings(e)}
                margin="small" pad="xsmall" flex={false}
                onClick={(self) => {
                    if (!self.ignoreSelectionChange) {
                        onClickHandleSelection(self)
                    }
                    //setIsClicked(!isClicked)
                }
                } /*onMouseEnter={(self)=>self.buttons===1 && dragSelectStart()}*/
            >


                <Box className="textCardInfoSection" direction="row" flex="grow"  justify="between"  gap="10px"  align="start">

                    <Box direction="row" gap="xsmall" align="center" >
                        {showCheckbox &&

                            <CheckBox 
                            className="disabledSelectionChange"
                            checked={selected} onChange={(e) => {
                                onSelectionChanged(!selected, textDocument, false, true)
                                e.preventDefault()
                            }} />

                        }
                        
                        {
                            simScore && (

                                <SimilarityScoreBadge
                                    score={simScore}
                                    liked={textDocument.liked}
                                    unliked={textDocument.liked===false}
                                    onLike={enabledSimilarityVoting ? (val) => setSimilarityFeedback(textDocument, val?true:null) : undefined}
                                    onDislike={enabledSimilarityVoting ? (val) => setSimilarityFeedback(textDocument,  val?false:null) : undefined}
                                />
                            )
                        }
                        {(!textDocument.score) ?
                            <SimilarCountBadge
                                docId={textDocument.id}
                                similarTextsCount={similarTextsCount}
                                similarNotLabeledTextsCount={similarNotLabeledTextsCount}
                                isLoading={similarTextsCount === null}
                                onClick={() => {
                                    showSimilarToDoc(textDocument)
                                }}
                            /> : (<></>)}
                    </Box>
                    {/* {textDocument && textDocument.topic_id > 0 && !compact &&  (

                        
                            <Button gap="small" plain justify="end" alignSelf="end" margin="none" size="small"  >
                                <Box pad="0px 2px" direction="row" align="center" gap="xsmall" round>
                                    <Text size="10pt">{`topic #${textDocument.topic_id}`}</Text><Filter />
                                </Box>
                            </Button>
                        
                    )} */}
                    <Box justify="end" direction="row" >

                        <Text style={{ maxWidth: "100%" }} truncate="tip" margin={{ top: "3px" }} size="10px" textAlign="end">   {textDocument.key || "#" + textDocument._i}  </Text>
                        
                        <DropButton
                        className="disabledSelectionChange"
                            dropProps={{ align: { top: 'bottom', right: 'right' } }}
                            dropContent={
                                <Box pad="small" gap="medium" align="start">
                                    <BetterButton plain className="disabledSelectionChange" onClick={() =>  onExcludeClick(textDocument)} icon={<Close />} label="Delete" permission="REMOVE_DOCS" />
                                    <BetterButton plain className="disabledSelectionChange" onClick={() => { 
                                        runAction(textDocument, "generate_similar")
                                    }} icon={<Multiple />} label="Generate similar examples (chatGPT)" permission="MODIFY_DOCS" />
                                </Box>
                            }>
                            <MoreVertical className="dropButtonIcon" />
                        </DropButton>


                    </Box>
                </Box>

                <Box width="100%" justify="between" >


                    <Box gridArea="text" pad="5px 10px" >
                        <Box >
                                <Box direction="row">
                            <div ref={textSectionRef} className={(isOverflow && expandedText ? "textSection expandedText" : "textSection colapsedText") + (compact ? " compact" : "")} onMouseUp={handleTextSelection} onDoubleClick={handleTextSelection} >
                                {(!compact && textDocument.text) ? (
                                    <HiglightableText text={textDocument?.text}
                                        highlightText={query.get("q")} coloredKeywords={coloredKeywords}
                                    />

                                    ) : (
                                    <Text style={{ padding: "0px 0px 0px 0px" }}>{textDocument?.text}</Text>
                                    
                                )}
                                {compact && textDocument.context_data && (
                                    Object.getOwnPropertyNames(textDocument.context_data).map(p => (
                                        createContextDataEleement(p, textDocument.context_data[p])
                                    )
                                    )
                                )}
                            </div>
                                    {isOverflow&&!expandedText && <Text alignSelf="end" margin="0px 10px 0px 0px">...</Text>}
                                    </Box>

                            {isOverflow &&
                                <Box style={{ height: "25px" }} align="start" alignSelf="start"  direction="row">
                                    <a className="colapseLink disabledSelectionChange" onClick={() => setExpandedText(!expandedText)}>{expandedText ? (
                                        "Show less"
                                    ) : (
                                        "Show more"
                                    )}</a>
                                </Box>
                            }

                            {contextPopupTarget && (
                                <Drop
                                    target={contextPopupTarget}
                                    align={{ top: "bottom" }}
                                    elevation="large"
                                    round="small"
                                    style={{ backgroundImage: "linear-gradient(200deg, #f3882820 00%, #b336b350 80%)" }}
                                    pad="xsmall"
                                    onClickOutside={() => setContextPopupTarget(undefined)}
                                >
                                    {popupContent}
                                </Drop>
                            )}
                            {
                                //console.log(Object.getOwnPropertyNames( textDocument.context_data))&&
                                !compact && textDocument.context_data && (
                                    Object.getOwnPropertyNames(textDocument.context_data).map(p => (
                                        createContextDataEleement(p, textDocument.context_data[p])
                                    )
                                    )
                                )
                            }
                        </Box>
                    </Box>
                    
                    

                    <TipIfContent content={labelAreaTooltipMsg}>
                        <Box gridArea="labels" direction="row" justify="end" align="end" wrap>
                                {labelArea  && (
                                    <Box flex="grow">
                                        {labelArea} 
                                        {textDocument?.prediction_comparsion&&(
                                            <Box direction="row" gap="small">
                                                {textDocument?.prediction_comparsion.map(p=><Text key={p} size="10pt" color={p.startsWith("+")?"green":"red"}>{p}</Text>)}
                                            </Box>
                                        )}
                                    </Box>
                                
                                )}
                                <Box direction="row" gap="5px" flex={false} align="center">
                                    
                                    {
                                        (textDocument.predicted_label_scores || textDocument.labeled_by) && (
                                            <Box width="15px" margin="0px 3px 0px -3px" onMouseEnter={() => loadTooltip()}>
                                                <Tip content={infoTooltip === undefined ? <Box pad="0px 200px" >Loading...</Box> : infoTooltip}>
                                                    <CircleInformation />
                                                </Tip>
                                            </Box>
                                        )
                                    }
                                    {!compact && /*projectInfo && projectInfo.task_type !== TrainingTaskTypes.QuestionAnswering &&*/(
                                        <Box alignSelf="end" >
                                        <ReviewButton project_id={project_id} textDocument={textDocument} onTextDocumentModified={onTextDocumentModified}/>
                                        </Box>
                                    )}
                                    
                                    
                                </Box>
                        </Box>
                    </TipIfContent>
                    {/* {!compact && projectInfo && projectInfo.task_type === TrainingTaskTypes.QuestionAnswering &&(
                        <Box alignSelf="end">
                                <ReviewButton project_id={project_id} textDocument={textDocument} onTextDocumentModified={onTextDocumentModified}/>
                        </Box>
                    )} */}
                </Box>

              

                {/* </Grid> */}

            </Box>
            {/* )} */}

        </div>
        
        )
    }

    const memorizedComponent=useMemo(renderComponent,[lastSelected, textDocument,selected,   similarTextsCount,similarNotLabeledTextsCount ])
    return !inView? memorizedComponent: renderComponent()
});

export const TextCardPlaceholder =({onInViewChaged})=>{
    

    const { ref, inView, entry } = useInView({
        /* Optional options */
        threshold: 0,
    });

    useEffect(() => {
        if(onInViewChaged){
            onInViewChaged(inView)
        }
    },[inView])

    return (
     <Box ref={ref}  flex="grow" height="200px"  round="small" /*background="white"*/  margin="10px 2px" align="center" justify="center" gap="small">
        <Spinner/>
        <Text size="small">loading ...</Text>
    </Box>
    )
}