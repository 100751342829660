import axios from "axios";
import { PredictionResponse } from "./model";

export class NodeClient{
    url:string
    headers:any

    constructor(url:string, authorizationHeaders:{[key:string]:string}){

        this.url=url.endsWith("/")?url:(url+"/")
        this.headers=authorizationHeaders;
    }

    predict(queries:string[]|object[], model_name:string|undefined=undefined, explain:boolean=false, test:boolean=false): Promise<PredictionResponse>{
        let params:any={explain:explain, test:test}
        if (model_name){
            params[model_name]=model_name
        }

        return axios.post(this.url+"predict", {"texts":[...queries]}, {params:params, headers:this.headers} ).then(response=>response.data);
    }

    getAnswer(queries:string[]|object[], top_k:number=1, explain:boolean=false, test:boolean=false): Promise<PredictionResponse>{
        return axios.post(this.url+"get-answer", {"texts":[...queries]}, {params:{explain:explain, test:test,top_k:top_k}, headers:this.headers} ).then(response=>response.data);
    }

    forceRefreshSettings(): Promise<boolean>{
        return axios.post(this.url+"refresh",{} ).then(()=>true).catch(()=>false)
    }
}