import {Box, Spinner, Text} from "grommet"
import { useEffect, useState } from "react"
import { getApi } from "../ApiService"
import { useAppContext } from "../AppContext"
import { modifyState } from "../helpers/helperFunctions"
import { LabelingPreferences } from "../ViewModel/LabalingPreferences"
import { BetterButton } from "./BetterButton"
import { RangeSelector } from "./RangeSelector"


const LabelingPreferencesDialog=({onAfterApply}:{
  onAfterApply?:()=>any
})=>{

    const {projectInfo, setProjectInfo} = useAppContext()
    const [isWaiting,setIsWaiting] =useState(false)
    const [state, setState] = useState<LabelingPreferences>(projectInfo.labeling_preferences||{similarity_threshold:0.8})

    
    
    const afterApplyFinished=()=>{
      onAfterApply&&onAfterApply()
      setIsWaiting(false)
    }

    return (<Box background="dark-2" pad="15px"  gap="small" width="400px">
    <Text>Labeling preferences</Text>
      {isWaiting ?(
        <Box width="100%" align="center">
        <Spinner size="large"/>
        </Box>
      ):(
        <Box width="100%">  
          <RangeSelector minMaxRange={[0,100]} label="Similarity threshold" rangeStart={(state.similarity_threshold||0.8)*100} rangeEnd={100}  fixedEnd 
          
          onChange={((start,end)=>modifyState((p:LabelingPreferences)=>p.similarity_threshold=start/100, state,setState))} />
        </Box>)}
    <Box width="100%" align="center">
      <BetterButton label='Apply' disabled={isWaiting} 
      permission={"MODIFY_PROJECT"}
      onClick={()=>{
        setIsWaiting(true)
        getApi().patchProjectLabelingPreferences(projectInfo.id, state).then(()=> {
          getApi().getProjectInfo(projectInfo.id).then(proj=>setProjectInfo(proj)).finally(()=>afterApplyFinished())
        }).catch(()=>afterApplyFinished())
      }} />
    </Box>
  </Box>)
}
export {LabelingPreferencesDialog}