import {  Box,  Layer,   Spinner, Form, TextInput, FormField, Button, Text, Select, CheckBox, RangeInput } from 'grommet'


import {Close} from "grommet-icons"
import { useState, useEffect } from "react";
import { getApi } from '../../ApiService';
import { useAppContext } from '../../AppContext';
import { ConfirmDialog } from '../../components/ConfirmDialog';
import { DateRangePicker } from '../../components/DateRangePicker/dateRangePicker';
import { modifyState } from '../../helpers/helperFunctions';




export const BulkDeleteDocsDialog= ({caption, onOK, onClose })=>{
  const [wait, setWait] = useState(false);
  const {projectInfo} =  useAppContext();

function submit(){
    if (projectInfo){   
        let finalFilters={}
        Object.keys(filters).forEach(filterKey=>{

            if (filtersEnabled[filterKey] || (filterKey=="labels" && filters["labels"])){
                finalFilters[filterKey]=filters[filterKey]
            }
        })
        let describe_selection=[]

        if (finalFilters.creationTime) {
            describe_selection.push(`- created within range ${finalFilters.creationTime[">"].toLocaleDateString()} and ${finalFilters.creationTime["<"].toLocaleDateString()}`)
        }
        if (finalFilters.labels){
            if (finalFilters.labels=="null"){
                describe_selection.push("- that are not labeled")
            }
            else if (finalFilters.labels=="!null"){
                describe_selection.push("- that are labeled with any label")
            }
            else{
                describe_selection.push(`- labeled as ${finalFilters.labels}`)
            }
        } 
        describe_selection[describe_selection.length-1]=describe_selection[describe_selection.length-1]+" ?"
        setDialog(<ConfirmDialog text={<Box>
            <Text>Are you really sure you want to delete {random_sample_fraction<1?` ${Math.round(random_sample_fraction*100)} % of documents`:"all documents"} {describe_selection.length?"":"?"}</Text>
            {describe_selection.map(s=>(<Text>{s}</Text>))}
            <Text weight={700}>This action can not be undone!</Text></Box>} onOk={()=>{
            setWait(true)
            getApi().deleteDataFromProject(projectInfo.id, finalFilters, filtersEnabled.random_sample_fraction?random_sample_fraction:1).then(()=>{
                setDialog()
                onOK()
            })
        }} onCancel={()=>setDialog()}/>)
        
        }
    }
    
 



    const [dialog, setDialog] = useState(); 
    const [filtersEnabled, setFiltersEnabled] = useState({});
    const [filters, setFilters] = useState({});
    const [random_sample_fraction,setRandomSampleFraction]=useState(1)

return (
    
<Layer onClickOutside={onClose} onEsc={onClose}>
  <Box width="500px"  margin="small" gap="small">
    {dialog}
    <Box direction="row" justify='between' width="100%">
      <Text size="large">{caption} </Text>
      <Close style={{float:"right"}} onClick={onClose}/>
    </Box>
    {
      wait?
      (<Spinner size="large" alignSelf="center"/>)
      :
      ( <Box gap="small">
        <Box margin="10px 0px">
            <Text>Filter by label</Text>
            <Box width="250px">
            <TextInput  suggestions={["!null","null", ...projectInfo?.labels]} onSuggestionSelect={(e)=>modifyState(val=>val["labels"]=e.suggestion,filters,setFilters)}/>
            </Box>
        </Box>
       <CheckBox label="Created within range" value={filtersEnabled["creationTime"]} onChange={(e)=>modifyState(val=>val["creationTime"]=e.target.checked,filtersEnabled,setFiltersEnabled)}/>
       {filtersEnabled["creationTime"] ? <DateRangePicker 
                onRangeChanged={({since,until})=>modifyState((filter)=>{
                    filter["creationTime"]={">":since,"<":until}
                }, filters,setFilters)}
            />:(
                <Box pad="2px 15px">
                <Text weight={300} color="gray">Anytime</Text>
                </Box>
            )}
        <CheckBox label={"Delete only random sample " +( filtersEnabled["random_sample_fraction"]?`${ Math.round(random_sample_fraction*100)} [%]`:"")} value={filtersEnabled["random_sample_fraction"]} onChange={(e)=>modifyState(val=>val["random_sample_fraction"]=e.target.checked,filtersEnabled,setFiltersEnabled)}/>
        <RangeInput disabled={!filtersEnabled.random_sample_fraction} value={ !filtersEnabled["random_sample_fraction"]?100:random_sample_fraction*100} onChange={e=>setRandomSampleFraction( Math.round(e.target.value)/100)}/>
           
        
    </Box>)
    }
    <Box direction="row" justify="end" gap="xsmall">
        <Button primary label="OK" onClick={submit}/>
        <Button secondary label="Cancel" onClick={onClose}/>
    </Box>
  </Box>
</Layer>
)
}
