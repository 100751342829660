import {useContext, useState} from "react";
import { Box, Text, Grid, Button, DropButton, Tag, NameValueList, NameValuePair,Accordion,AccordionPanel, Spinner, Tip } from "grommet";
import { Link } from "react-router-dom";
import { Trash, MoreVertical, Play, CirclePlay, Alert,Sync, Resume, Download } from "grommet-icons"

import { getApi } from "../ApiService";
import { MetricCard } from "./MetricCard";
import { BetterButton } from "./BetterButton";
import moment from 'moment';
import { useAppContext } from "../AppContext";
import { ProjectSelectionDialog } from "../dialogs/ProjectSelectionDialog";
import { Badge } from "./Badge";


export const ModelCard = ({  modelInfo, onTrainingRequested}) => {

  const [isDeleted, setIsDeleted] = useState(false);
  const [dropOpen,setDropOpen] = useState(false);
  const [dialog,setDialog] = useState(false);

  const {projectInfo} = useAppContext()

  function isIsoDate(str) {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(str)) return false;
    return true
  }

  function formatValue(val){
    if ( isIsoDate(val)){
      let res= (moment(val).fromNow());
      return res;
    }
    else{
      return val?.toLocaleString(navigator.language, { maximumFractionDigits: 6 } )
    }
  }
  const {newTaskScheduler} = useAppContext();
  const onScheduledActionRequested=()=>{
    newTaskScheduler()
  }
  function dropWrapper(innerFunc){
    setDropOpen(false)
    innerFunc()
  }


  function downloadURI(uri, name) {
    let link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const downloadModel=()=>{
    getApi().getModelDownloadUrl(modelInfo.project_id, modelInfo.id, modelInfo.model_name+".zip",(url)=>downloadURI(url, modelInfo.model_name+".zip"))
  }

  const deleteModel=()=>{
    setIsDeleted(undefined);
    getApi().deleteModel(modelInfo.project_id, modelInfo.id, ()=>setIsDeleted(true));
  };
    return (
    <Box border    margin="small" round="xsmall" elevation="small" pad="medium" direction="row" background={modelInfo.is_ready?"light-1":"light-4"} flex={"grow"}   >
    
    {
    (isDeleted===undefined)? (<Spinner/>):
    (isDeleted===true)?("Deleted"):
    (
      <Grid width="100%"

        rows={['auto', 'auto']}
        columns={['auto', 'xxsmall']}
        gap="xsmall"
        areas={[
          { name: 'name', start: [0, 0], end: [0, 0] },
          { name: 'actions', start: [1, 0], end: [1, 1] },
          { name: 'id', start: [0, 1], end: [0, 1] },

        ]}
      >
        <Box gridArea="name" gap="small" >
          <Box direction="row" align="center" gap="small">

        <Link to={`/${modelInfo.project_id}/models/${modelInfo.id}`}>
            <Text size="25px" weight="bold" margin={{bottom:"20px"}}>   {modelInfo.model_name}    </Text>
        </Link>
        {!modelInfo.is_ready && <Badge background="black" value="Model is not ready" icon={<Alert/>}/> }
          </Box>
        <Box direction="row" wrap>
        <Tag size="xsmall" name="model origin" value={modelInfo.model_origin}/>
        <Tag size="xsmall" name="type" value={modelInfo.task_type}/>
        <Tip content={modelInfo.created_at}>
          <Tag size="xsmall" name="created" value={formatValue(modelInfo.created_at)}/>
        </Tip>
        {modelInfo.train_params && Object.getOwnPropertyNames(modelInfo.train_params).map(p=>  <Tag key={p} size="xsmall" name={p} value={<Text size="small" truncate="tip">{formatValue(modelInfo.train_params[p])} </Text> }/>)}
        </Box>
        </Box>

        <Box gridArea="id" direction="row" gap="xsmall" wrap>
        <Accordion width="100%">
          {modelInfo.is_ready && (
          <AccordionPanel  label={ 
            <div>
            <Text size="medium" weight="bold">Metrics:</Text>
            <Box direction="row" wrap gap="small"> 
            {
              modelInfo.metrics && Object.getOwnPropertyNames(modelInfo.metrics).filter(m=>typeof(modelInfo.metrics[m])!=="object").map(metric=>(
                <MetricCard key={metric} metricName={metric} metricData={modelInfo.metrics[metric]}/>
              ))
            }</Box>
            </div>
          }>
          
            <Box pad="medium" gap="none" direction="row" wrap> 
              {
            modelInfo.metrics && Object.getOwnPropertyNames(modelInfo.metrics).filter(m=>typeof(modelInfo.metrics[m])==="object").map(metric=>(
              <MetricCard key={metric} metricName={metric} metricData={modelInfo.metrics[metric]}/>
            ))
          }
            </Box>
          </AccordionPanel>
          )}
        
        </Accordion>
         
          {dialog}
        </Box>
        <Box gridArea="actions" align="end" >
          <DropButton
            open={dropOpen}
            onOpen={()=>setDropOpen(true)}
            onClose={()=>setDropOpen(false)}
            dropProps={{ align: { top: 'bottom' } }}
            dropContent={
              <Box pad="small" gap="medium" align="start">
                <BetterButton plain onClick={()=>dropWrapper(()=>deleteModel())} icon={<Trash />} label="Delete" permission="DELETE_PROJECT"/>
                {modelInfo.is_ready&& (
                  <BetterButton plain onClick={()=>
                    dropWrapper(()=>setDialog(
                    <ProjectSelectionDialog
                      defaultProject={projectInfo}
                      onClose={()=> setDialog()}
                      onOK={(proj)=>{
                        if (proj?.id){
                          getApi().applyModelPredict(proj.id, modelInfo.id).then(()=>onScheduledActionRequested && onScheduledActionRequested())
                          setDialog(null);
                        }
                      }}
                      />
                    ) )  
                  } icon={<Play />} label="Use to predict labels" permission="RUN_PREDICTIONS"  />
                  )}
                {modelInfo.is_ready&& (
                  <BetterButton plain onClick={()=>
                    dropWrapper(()=>setDialog(
                    <ProjectSelectionDialog
                      defaultProject={projectInfo}
                      onClose={()=> setDialog()}
                      onOK={(proj)=>{
                        if (proj?.id){
                          getApi().compareModelPredictions(proj.id, modelInfo.id).then(()=>onScheduledActionRequested && onScheduledActionRequested())
                          setDialog();
                        }
                      }}
                      />
                    ) )  
                  } icon={<Play />} label="Compare with current predictions" permission="RUN_PREDICTIONS"  />
                  )}

                {modelInfo.is_ready&& (
                  <BetterButton plain onClick={()=>dropWrapper(()=>getApi().applyModelEmbeddings(modelInfo.project_id, modelInfo.id).then(()=>onScheduledActionRequested && onScheduledActionRequested() ) )} icon={<CirclePlay />} label="Use to regenerate embeddings" permission="RUN_PREDICTIONS"  />
                  )}
                <BetterButton plain onClick={()=>dropWrapper(()=>onTrainingRequested("retrain",modelInfo))} icon={<Sync />} label="Retrain"  permission="RUN_TRAINING"/>
                <BetterButton plain  onClick={()=>dropWrapper(()=>getApi().reevaluateModel(modelInfo.project_id,modelInfo.id).then(()=>onScheduledActionRequested()))}  icon={<Sync />} label="Reevaluete on new data"  permission="RUN_PREDICTIONS"/>
                <BetterButton plain onClick={()=>dropWrapper(()=>onTrainingRequested("continue",modelInfo))} icon={<Resume />} label="Continue training"  permission="RUN_TRAINING"/>
                <BetterButton plain onClick={()=>dropWrapper(()=>downloadModel())} icon={<Download />} label="Download model"  permission="EXPORT_DATA"/>
                
              </Box>
            }>
            <MoreVertical />
          </DropButton>
        </Box>
      </Grid>
    )
    }
    </Box>
  );
};
