const TrainingTaskTypes = {
    //NER:"NER",
    TextClassification:"TextClassification",
    MultiLabelTextClassification:"MultiLabelTextClassification",
    TextSimilarity:"TextSimilarity",
    QuestionAnswering:"QuestionAnswering",
    //TextScoring:"TextScoring"
}

const PermissionTypes = {
    CREATE_PROJECT:"CREATE_PROJECT",
    MODIFY_PROJECT:"MODIFY_PROJECT",
    DELETE_PROJECT:"DELETE_PROJECT",
    EXPORT_DATA:"EXPORT_DATA",
    IMPORT_DATA:"IMPORT_DATA",
    MODIFY_DOCS:"MODIFY_DOCS",
    REMOVE_DOCS:"REMOVE_DOCS",
    RUN_TRAINING:"RUN_TRAINING",
    RUN_PREDICTIONS:"RUN_PREDICTIONS",
    DELETE_MODEL:"DELETE_MODEL"
}

class Constants{
    static readonly TRAINING_TASK_TYPES=Object.getOwnPropertyNames(TrainingTaskTypes)
        //.filter(t=>t!=TrainingTaskTypes.QuestionAnswering) // temporary disabled QuestionAnswering

}

export {Constants, TrainingTaskTypes}

