import {Box, Text, Tip} from "grommet"
import { CaretDownFill, CaretUpFill } from "grommet-icons";
import { useMemo } from "react";
import { getApi } from "../../ApiService";
import { useAppContext } from "../../AppContext";
import { Badge } from "../Badge";
import { TipIfContent } from "../TipIfContent";
import "./styles.css"
const ConfusionMatrix =({ data,labels, comapareTo
}:{
    data:number[][]|{[label:string]:number[][]},
    labels:string[],
    comapareTo?:[][]|{[label:string]:number[][]}
})=>{

    const {projectInfo}=useAppContext()
    // if (!_data){
    //     var len = {length: 500}
    //     _data=Array.from(len,v=>Array.from(len, (v, i) => 10+i))
    //     labels=Array.from(len, (v, i) => "looooonk_label_that_is_longer_than_expected")
    // }

    function createNDimArray(dimensions:number[]) {
        if (dimensions.length > 0) {
            var dim = dimensions[0];
            var rest = dimensions.slice(1);
            var newArray = new Array();
            for (var i = 0; i < dim; i++) {
                newArray[i] = createNDimArray(rest);
            }
            return newArray;
         } else {
            return undefined;
         }
     }

    const _data = useMemo<any>(()=>{
        if (data && labels?.length){
            //let nn_data =createNDimArray([labels.length,labels.length])
            let n_data:any={}
            if (Array.isArray(data)){
                labels.forEach((label:string,i:number)=>{
                    n_data[label] ={}
                    labels.forEach((label_prediction:string,ii:number)=>
                        n_data[label][label_prediction]=data[i]?data[i][ii]||0:0
                    )
                })      
                return n_data
            }
            else if(typeof(data)=="object"){
               return data
            }
            
        }

    },[data])

    const _data_compare = useMemo<any>(()=>{
        if (comapareTo && labels?.length){
            
            let n_data:any={}
            if (Array.isArray(comapareTo)){
                labels.forEach((label:string,i:number)=>{
                    n_data[label] ={}
                    labels.forEach((label_prediction:string,ii:number)=>
                        n_data[label][label_prediction]=comapareTo[i]?comapareTo[i][ii]||0:0
                    )
                })      
                return n_data
            }
            else if(typeof(comapareTo)=="object"){
               return comapareTo
            }
            
        }

    },[comapareTo])
    
    function comparsionBadge(label_labeled:string, label_predicted:string, total:number, data_compare_total:number){
        if (_data_compare){

            let comapre_value=_data_compare[label_labeled][label_predicted];
            let value=_data[label_labeled][label_predicted];
            let ratio= value/total;
            let diff_perc = Math.round(ratio*100)-Math.round(100*comapre_value/data_compare_total)
            let color=undefined;
            
            if (label_labeled==label_predicted){ //good
                color=diff_perc>0?"green":"red";
            }
            else{
                color=diff_perc>0?"red":"green";
            }
            
            return (
                (_data_compare[label_labeled] 
                && _data_compare[label_labeled][label_predicted] 
                && diff_perc!=-0 )? (
                    <Badge textColor="white" size="xsmall" 
                    icon={diff_perc<0?<CaretDownFill color="white"/>:<CaretUpFill color="white"/>}
                    value={ <Text size="xsmall" className="nowrap">{diff_perc + " %"}</Text>} 
                    pad="0px 3px 0px 0px"
                    gap="0px"
                    background={color}
                    tooltip={`${comapre_value} vs ${_data[label_labeled][label_predicted]}`}
                    />
                    ):(<></>)
            )
        }
    }

    if (_data){
        const total =  labels.map(l=>labels.map(ll=>_data[l][ll])).flat(1).reduce((a:number,b:number)=>a+b,0);
        const _data_compare_total = _data_compare && labels.map(l=>labels.map(ll=>_data_compare[l][ll])).flat(1).reduce((a:number,b:number)=>a+b,0);
        return (<div className="confusionMatrix" style={{
            gridTemplateColumns: `auto repeat(${labels.length},minmax(5vw,${5+(10/labels.length)}vw))`,
            gridTemplateRows: `auto repeat(${labels.length},minmax(5vw,${5+(10/labels.length)}vw))`
        }
        }>
            <div></div>

            { /*Header*/
            labels?.map((label,col_id)=>{return ( 
                  <div key={col_id} className="confusionMatrixLabel confusionMatrixLabelTop">{label}<br/>predicted</div>
            )})}

            { /*data rows*/
            labels?.map((label_labeled:string,i:number)=>{
               
              
                    let row_id=i;
                    let row_data=_data[label_labeled]
                    
                    const total_labeled =  labels.map(l=>row_data[l]).reduce((a:number,b:number)=>a+b,0);
                    
                    return [null,...labels].map((label_predicted,ii)=>{
                        let col_id=ii-1;
                        if (ii==0){

                            return ( 
                                <div className="confusionMatrixLabel" key={`${label_labeled}_label`}>
                                    {label_labeled}
                                    <br/>
                                    labeled
                                </div>)
                        }
                        else{    
                            
                            const ratio_2_labeled = row_data[label_predicted] ?(row_data[label_predicted]/total_labeled):0;
                            const ratio = row_data[label_predicted] ?(row_data[label_predicted]/total):0;
                            
                            return (
                                <div key={`${row_id}_${label_predicted}`} className="confusionMatrixItem" style={{
                                    backgroundColor:row_id==col_id?`rgba(0,220,0,${ratio_2_labeled})`:`rgba(250,50,50,${ratio_2_labeled})`
                                }}>
                                    <TipIfContent 
                                        key={`${row_id}-${col_id}`} 
                                        content={<Text> { row_data[label_predicted] } out of {total_labeled} labeled as {label_labeled} ({total} records in total) was {row_id==col_id?"correctly predicted as ["+label_predicted+"]": " predicted as [" + label_predicted +"] but labeled as [" + label_labeled + "]"}<br/>That means {Math.round(100*ratio_2_labeled)}% of records labeled as {label_labeled} were predicted as {label_predicted}  </Text>}
                                    >
                                        <Box align="center" justify="center" fill onClick={()=>{
                                            window.open(`${window.location.protocol}//${window.location.host}/${projectInfo?.id}/texts?by_label=${encodeURIComponent(label_labeled)}&predicted_label=${encodeURIComponent(label_predicted)}`, '_blank');
                                        }}>
                                        { Math.round(ratio*100)}%
                                        {_data_compare&&(
                                            (_data_compare[label_labeled] && _data_compare[label_labeled][label_predicted] !==undefined)
                                            && comparsionBadge(label_labeled,label_predicted, total,_data_compare_total)
                                            )
                                        }
                                        </Box>
                                    </TipIfContent>
                                </div>
                            )
                        }
                    }
                    )
                
            })}

        </div>)

        return (<div className="confusionMatrix" style={{
            gridTemplateColumns: `auto repeat(${_data.length},minmax(5vw,${5+(10/_data.length)}vw))`,
            gridTemplateRows: `auto repeat(${_data.length},minmax(5vw,${5+(10/_data.length)}vw))`
        }
        }>
            {labels && [null,...labels].map((label,i)=>( label==null?
            (<div key={i}></div>):
            (<>
            <div key={i} className="confusionMatrixLabel confusionMatrixLabelTop">{label}<br/>predicted</div>
            </>
            )
            ))}
            {
                _data.map((row:number[], row_id:number)=>
                [null,...row].map((item,col_id)=>
                {
                    if (item==null){

                        return (labels && labels[row_id] ? 
                        <div className="confusionMatrixLabel" key={`${row_id}_${col_id}`}>
                            {labels[row_id]}
                            <br/>
                            labeled
                        </div>
                        : <div></div>)
                    }
                    const total_labeled =  _data[row_id].reduce((a:number,b:number)=>a+b,0);
                    const ratio_2_labeled = item ?(item/total_labeled):0;
                    const ratio = item ?(item/total):0;
                    
                    return (<div key={`${row_id}_${col_id}`} className="confusionMatrixItem" style={{
                        backgroundColor:row_id+1==col_id?`rgba(0,220,0,${ratio_2_labeled})`:`rgba(250,50,50,${ratio_2_labeled})`
                        
                    }}>
                        <TipIfContent key={`${row_id}-${col_id}`} content={<Text> {item} out of {total} records was {row_id+1==col_id?"correctly predicted as ["+labels[row_id]+"]": " predicted as [" + labels[col_id-1] +"] but labeled as [" +  labels[row_id] + "]"}</Text>}>

                        <Box align="center" justify="center" fill>
                        { Math.round(ratio*100)}%
                        <Badge textColor="white" size="xsmall" value={"Text"} tooltip="aa" />
                        </Box>
                        </TipIfContent>
                    </div>)
                })
                )
                // _data.map((row__data:number[])=>(
                //     <div  className="confusionMatrixRow">
                //     {row__data.map((col__data:number)=>(
                //         <div  className="confusionMatrixItem" > {col__data} </div>
                //     ))}
                //     </div>
                // ))
            }
        </div>)
    } 
    else{
        return (<Box direction="row" wrap>
            {_data && Object.keys(_data).map(label=>(
                <Box>
                    <ConfusionMatrix data={_data[label]} labels={["True","False"]} comapareTo={comapareTo&&(comapareTo as any)[label]}/>
                </Box>

            ))
            }
        </Box>)
    }

}

export  {ConfusionMatrix}