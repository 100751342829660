import { Box, Drop, Spinner, Text, TextArea } from "grommet";
import { useEffect, useState } from "react";
import { getApi } from "../../ApiService";
import { Badge } from "../Badge";
import { ExpandingButton } from "../ExpandingButton/expandingButton";
import TextareaAutosize from 'react-textarea-autosize';
import { Aggregate, Checkmark, FormClose, Validate } from "grommet-icons";
import { useAppContext } from "../../AppContext";
import { SimilarityScoreBadge } from "../SimilarityScoreBadge/SimilarityScoreBadge";
import { SearchBar } from "../SearchBar/searchBar";
import "./AnswerArea.css"

const SearchAnswer = ({project_id,doc_id, onAdd})=>{
    const [query, setQuery]=useState()
    const [wait, setWait] = useState(false)
    const [searchResults, setSearchResults] = useState()
    function predictAnswers(){
        setWait(true)
        getApi().getTextLikelyAnswers(project_id,doc_id, query).then(t=>{
            setSearchResults(t)
            setWait(false)
            
        })
    }

    useEffect(()=>{
        if (query ){
            predictAnswers()
        }
        else{
            setSearchResults(null)
        }
    },[query])

    return (
        <Box pad="10px 100px 0px 50px">
            <SearchBar placeholderText="Search for more answers" disableSyncWithUrl onChange={(val)=>setQuery(val)} margin="0px 15px" size="small"/>
            {wait&& <Box alignSelf="center"> <Spinner size="large"/> </Box>}
            {!wait && searchResults?.map((res,i)=>(
                <Box key={i} 
                onClick={()=>{
                    onAdd(res)
                    setSearchResults(searchResults.filter(t=>t!=res))

                }}
                
                className="search-result"
                margin="10px 0px 0px"
                            background="rgb(242,240,242)"
                            pad="5px 10px"
                            round="xsmall"
                >
                    {res.text && <Text>Q: {res.text}</Text>}
                    <Text>A: {res.answer}</Text>
                </Box>
            ))}
            {searchResults && <Drop align={{bottom:"top"}} >

            </Drop>}
        </Box>

    )
}

export const AnswerArea =({project_id, doc_id,  answer, onAnswerUpdated })=>{

    const [wait, setWait] = useState(true)
    const [isEditMode, setIsEditMode] = useState()
    const [unsavedAnswer, setUnsavedAnswer] = useState()
    const [selectedAnswerExample, _setSelectedAnswerExample] = useState()

    function setSelectedAnswerExample(example){
        if(example)
            setUnsavedAnswer(example.answer)
        _setSelectedAnswerExample(example)
    }
    const [likelyAnswers, setLikelyAnswers] = useState()
    
    const {projectInfo} =useAppContext();
    const sim_threshold = projectInfo?.labeling_preferences?.similarity_threshold || 0.8;
    useEffect(()=>{
        if (isEditMode){
            setUnsavedAnswer(answer||"")
            if (project_id && doc_id){
                predictAnswers()
            }
        }
    },[answer,isEditMode])
    

    function predictAnswers(){
        setWait(true)
        getApi().getTextLikelyAnswers(project_id,doc_id).then(t=>{
            setLikelyAnswers(t)
            setWait(false)
        })
    }

    
    function save(){
        
        onAnswerUpdated(unsavedAnswer, ()=>{
            if (selectedAnswerExample){
                getApi().setSimilarity(project_id, doc_id,  [selectedAnswerExample.id],true)
            }
            setIsEditMode(false)
        })
    }

    function updateLike(example,val){
        if (likelyAnswers){
            let i = likelyAnswers.indexOf(example)
            let newLikelyAnswers=[...likelyAnswers]
            example.liked=val
            newLikelyAnswers[i]={...example}
            setLikelyAnswers(newLikelyAnswers)
        }
    }
    

    return (
    <Box  className="disabledSelectionChange" >
        {!isEditMode?(
            !answer?(
                <Badge background="brand" onClick={()=>setIsEditMode(true)} value="Set answer"/>
            ):(
                <Box pad="10px" align="start" gap="5px">
                    <Text weight={700}>Answer</Text>
                <Text size="small">
                    {answer.split("\n").map((item, idx)=>{
                            return (
                                <span key={idx}>
                                    {item}
                                    <br/>
                                </span>
                             )
                        })}
                </Text>
                <Badge background="brand" onClick={()=>setIsEditMode(true)} value="Edit answer"/>
                </Box>
            )
        ):(
            <Box border margin="5px" round="10px" pad="10px">
                <Box direction="row">
                <TextareaAutosize
                style={{width:"80vw", padding:"10px", fontFamily:"unset", fontSize:"11pt"}}
                        cacheMeasurements
                        autoFocus
                        value={unsavedAnswer||""}
                        onChange={event => setUnsavedAnswer(event.target.value)}
                        />

                    {/* <TextArea
                    style={{width:"80vw"}}
                    
                    resize="vertical"
                    placeholder="type your answer"
                    value={unsavedAwswer}
                    onChange={event => setUnsavedAnswer(event.target.value)}
                    /> */}
                    <Box pad="0px 10px" gap="5px" flex="grow">
                        <Badge background="brand" value="Save" icon={<Checkmark/>} onClick={save}/>
                        <Badge icon={<FormClose/>} value="Cancel" onClick={()=>{
                                setLikelyAnswers()
                                setSelectedAnswerExample()
                                setIsEditMode(false)
                            }}/>
                    </Box>
                </Box>
                <Box width="95vw" margin="5px" gap="5px">
                    {wait&& <Box alignSelf="center"> <Spinner size="large"/> </Box>}
                    {likelyAnswers?.length?(
                        <Box>
                        <Text size="small" weight={800}>Suggested answers</Text>
                        {likelyAnswers?.map((a,i)=> (
                            <Box direction="row" justify="stretch" align="start">
                            <ExpandingButton
                            margin="10px 0px 0px"
                            background="rgb(242,240,242)"
                            pad="5px 10px"
                            round="xsmall"
                            key={i}
                            onClick={()=>setSelectedAnswerExample(a)}
                            collapsedContent={
                                <Box width="80vw"   direction="row">
                                  
                                    <Text size="10pt" className="nowrap" style={{height:"1em", textOverflow:"ellipsis"}}>{a.answer}</Text>
                                </Box>
                            
                            }
                            expandedContent={
                                <Box width="80vw"  >
                                      { a.text && <Text size="10pt" >{("Q: "+a.text).split("\n").map((item, idx)=>{
                                    return (
                                        <span key={idx}>
                                            {item}
                                            <br/>
                                        </span>
                                    )
                                })}</Text>}
                                <Box 
                                alignSelf="start" 
                                // style={{maxHeight:"550px", overflow:"auto", }} 
                                direction="row">
                               

                                <Text size="10pt" >{("A: "+a.answer).split("\n").map((item, idx)=>{
                                    return (
                                        <span key={idx}>
                                            {item}
                                            <br/>
                                        </span>
                                    )
                                })}</Text>
                                </Box>
                                
                                </Box>
                            }
                            />
                            <Box margin="-15px 0px 0px -50px">
                            <SimilarityScoreBadge 
                                score={a.score} 
                                 liked={(a==selectedAnswerExample  )|| a.liked}
                                     unliked={a!=selectedAnswerExample && a.liked===false}
                                     onLike={a==selectedAnswerExample ? ()=>selectedAnswerExample():(val)=>getApi().setSimilarity(project_id, doc_id,  [a.id],val?true:null).then(()=>updateLike(a,val?true:null))}
                                     onDislike={(val)=>getApi().setSimilarity(project_id, doc_id,[a.id],val?false:null).then(()=>updateLike(a,val?true:null))}
                                />
                            </Box>
                            </Box>
                        ))}
                        </Box>
                    ):(
                        !wait &&(

                        <Box justify="center" direction="row" gap="5px">
                            <Text size="small">No likely answers have been found within defined </Text> <Box gap="5px" direction="row" align="center"><Aggregate/>{Math.floor(sim_threshold*100)}%</Box> <Text size="small"> threshold </Text> 
                        </Box>
                        )
                        
                    )}
                    <SearchAnswer project_id={project_id} doc_id={doc_id} onAdd={(ans)=>setLikelyAnswers([...likelyAnswers,ans]) }/>
               
                        
                </Box>
            </Box>
        )}
    </Box>)
}