import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom"
import { Box, Heading, Tabs, Tab, Sidebar, Text, Meter, Button, Tag as TagBox, Spinner } from 'grommet';
import { Tag, Refresh, Share } from 'grommet-icons'

import { getApi } from '../ApiService';
import { TaskBox } from './TaskBox';
import { useAppContext } from '../AppContext';
import { duration } from 'moment';


export const StatusNavBar = ({ activeTab, onActiveTabChanged }) => {
  //const size = useContext(ResponsiveContext);
  let { project_id } = useParams();
  const [projectId, setProjectId] = useState();
  //const { stats, refresh, projectId } = useSharedProjectState();
  const { projectInfo, projectStatistics, refreshStatistics, setIsRunningTask } = useAppContext();
  const navigate = useNavigate();
  const [isReloading, setIsReloading] = useState();
  const [tasks, _setTasks] = useState(null);

  const setTasks = (data: object[]) => {
    if (data) {
      if (data.some(t => t.state === "RUNNING" || t.state === "STARTING")) {
        setIsRunningTask(true)
      }
      else {
        setIsRunningTask(false)
      }

    }
    _setTasks(data)
  }


  function reloadProjectInfo() {
    if (projectInfo.id) {

      setIsReloading(true)

      refreshStatistics((data) => {
        setProjectId(projectInfo?.id)
        setIsReloading(false)
      })


    }
  }

  function updateTask(task){
    let idx = tasks.findIndex(task);
    if (idx>=0){
      getApi().getTaskStatus(task.task_id).then((newTaskStatus)=>{
        let newTasks = [...tasks]
        newTasks[idx]=newTaskStatus;
        setTasks(newTasks)
      })
    }
    else{
      getApi().getTasks(projectInfo.id, (data) => setTasks(data));
    }
  }

  useEffect(() => {
    
      getApi().getTasks(projectInfo?.id, (data) => setTasks(data));
      if (projectInfo && (projectStatistics === undefined || projectId != projectInfo?.id)) {
        reloadProjectInfo()
      }
      const timer = setInterval(function () {
        if (projectInfo && projectInfo.id) {
          // method to be executed;

          getApi().getTasks(projectInfo.id, (data) => setTasks(data));
        }
      }, 5000);

      return () => clearInterval(timer);
    
  }, [projectInfo])

  // projectInfo = {
  //   name: "my project very very long",
  //   id: "810e1daf-cbf4-4c4a-b070-64548d56c185",
  //   stats: {
  //     total_count: 110,
  //     labeled_count: 50,
  //     by_label: {
  //       positive: 40,
  //       negative: 10
  //     }
  //   }
  // }

  function getStateColor(tasks) {
    if (tasks && tasks.length > 0) {

      let state = tasks[tasks.length - 1].state
      if (state === "PROCESSING") return "accent-3"
      if (state === "SUCCESS") return "status-ok"
      if (state === "FINISHED") return "status-ok"
      if (state === "ERROR" || state === "FAILURE") return "status-error"
    }

    return "status-unknown"
  }

  const TAB_IDS_2_IDX = { "stats": 0, "tasks": 1 }
  const TAB_IDX_2_IDS = { 0: "stats", 1: "tasks" }
  return (
    <Sidebar background="dark-1" overflow="auto" style={{ width: "450px" }}>
      <Box gap='small'>

        {projectInfo &&
          (
            <Box gap="small" flex={false}>
              <Heading level={2} >{projectInfo.name} </Heading>

              <Text size="8pt" >{projectInfo.id}</Text>
              <Button alignSelf="center" size="small" icon={isReloading ? <Spinner /> : <Refresh />} label="Reload" onClick={reloadProjectInfo} />

            </Box>
          )
        }
        <Tabs justify="start" multiple gap="medium"
          activeIndex={projectInfo&& activeTab ? TAB_IDS_2_IDX[activeTab] : 1}
          onActive={onActiveTabChanged ? (tab) => onActiveTabChanged(TAB_IDX_2_IDS[tab]) : undefined}
        >
          {projectInfo && (
                <Tab title={
                  <Box >
                    Statistics
                  </Box>
                }>
                  {
                    projectStatistics && (
                      <Box gap='medium'>
                        {/* <Heading level={3} >Statistics </Heading> */}
                        <Text >Labeled {projectStatistics.labeled_count}/{projectStatistics.total_count}</Text>
                        <Meter thickness='40px' values={[{
                          value: 100 * projectStatistics.labeled_count / projectStatistics.total_count,
                        }]}> </Meter>



                      </Box>
                    )
                  }
                  <Box gap="small" style={{ marginTop: "20px" }}>

                    {!projectStatistics && (<Spinner />)}
                    {projectStatistics && projectStatistics.by_label_count && (<Text>By labels</Text>)}
                    {
                      projectStatistics && projectStatistics.by_label_count && (

                        Object.getOwnPropertyNames(projectStatistics.by_label_count)
                          .sort((a, b) => projectStatistics.by_label_count[b] - projectStatistics.by_label_count[a])
                          .map(label => (
                            <Box gap="small" key={label}>
                              <Box direction="row" gap="small" onClick={() => navigate(`/${project_id}/texts?label=${label}`)}>
                                <Tag size="small" />
                                <Text size='15px' >  {label}: {projectStatistics.by_label_count[label]}/{projectStatistics.labeled_count}</Text>

                                <Share size="small" />
                              </Box>
                              <Meter thickness='20px' background="dark-2" values={[{
                                value: 100 * projectStatistics.by_label_count[label] / projectStatistics.labeled_count,
                              }]}> </Meter>


                            </Box>
                          )
                          )

                      )
                    }
                  </Box>
                </Tab>
          )}
          <Tab
            overflow="auto"
            title={
              <Box direction="row" gap='xsmall' pad="10px 0px 0px">
                Tasks
                {/* <TagBox  background="light-6" name={tasks && tasks.length}></TagBox>  */}
                <Box round="small" pad={{ left: "5pt", right: "5pt" }} style={{ fontSize: "8pt", height: "18pt", marginTop: "-10px" }} background={getStateColor(tasks)}>{tasks && tasks.length}</Box>
              </Box>
            }>
            <Box>
              {tasks === null ? <Spinner alignSelf="center" size="medium" color="white" margin="small" /> : (
                tasks.length > 0 ?
                  tasks.map((tsk, i) => (<TaskBox key={i} task={tsk} 
                    onDeleted={() => setTasks(tasks.filter(t => t.task_id !== tsk.task_id))} 
                    onRefreshRequested={()=>updateTask(tsk)}
                    />)
                    )
                  :
                  (<TagBox round alignSelf='center' pad="xsmall" margin="small" name="no recent tasks">  </TagBox>)
              )
              }
            </Box>
          </Tab>
        </Tabs>
      </Box>
    </Sidebar>
  );
};
