import React, { useState } from 'react';
import {useEffect} from 'react';
import { FormClose } from 'grommet-icons';
import { Box, Button, Keyboard, Text, TextInput } from 'grommet';




export const TextInputWithSuggestions = ({allSuggestions, onChange, value, onSuggestionFilter, ...rest}) => {
const [constantAllSuggestions,_] = React.useState(allSuggestions);
 const [text, setText]=useState(value?.label||value)
const [suggestions, setSuggestions] = React.useState([]);
 const setValue=(val)=>{
   if (value!=val)
    onChange && onChange(val);
  }

  const suggest = (val) =>{
      if (onSuggestionFilter){
        onSuggestionFilter(val, setSuggestions);
        return suggestions;
      }
      else{
        return constantAllSuggestions.filter(suggestion=>!val || !value.includes(suggestion));
      }
  };
  
useEffect(()=>{
    suggest(value)
},[])


  const onFilterSuggestion = (val) =>{
    setSuggestions(suggest(val));
    // setSuggestions(
    //   constantAllSuggestions.filter(
    //     (suggestion) => suggestion.toLowerCase().indexOf(val.toLowerCase()) >= 0)
    //     );
  }
    

  return (
      <TextInput

        suggestions={suggestions}
        value={text}
        onSuggestionSelect={({target,suggestion})=>{
            console.log(target.innerText)
            setText(suggestion?.label||suggestion)
            setValue(suggestion?.value)
        }}
        onChange={({ target}) => {
          setText(target.value)
          onFilterSuggestion(target.value)
        }}
        {...rest}
      />
  );
};
