import React from 'react';
import {useEffect} from 'react';
import { FormClose } from 'grommet-icons';
import { Box, Button, Keyboard, Text, TextInput } from 'grommet';

const Tag = ({ children, onRemove, ...rest }) => {
 const tag = (
    <Box
      direction="row"
      align="center"
      background="brand"
      pad={{ horizontal: 'xsmall', vertical: 'xxsmall' }}
      margin={{ vertical: 'xxsmall' }}
      round="medium"
      {...rest}
    >
      <Keyboard >
      <Text size="xsmall" margin={{ right: 'xxsmall' }}>
        {children}
      </Text>
      </Keyboard>
      {onRemove && <FormClose size="small" color="white" />}
    </Box>
  );

  if (onRemove) {
    return <Button onClick={onRemove}>{tag}</Button>;
  }
  return tag;
};

const TagInput = ({ value = [], onAdd, onChange, onRemove, ...rest }) => {
  const [currentTag, setCurrentTag] = React.useState('');
  const boxRef = React.useRef();

  const updateCurrentTag = (event) => {
    setCurrentTag(event.target.value);
    if (onChange) {
      onChange(event);
    }
  };

  const onAddTag = (tag) => {
    if (onAdd) {
      onAdd(tag);
      setCurrentTag("")
    }
  };

  const onEnter = () => {
    if (currentTag.length) {
      onAddTag(currentTag);
      setCurrentTag('');
    }
  };

  const renderValues = () =>
    value.map((v, index) => (

      
      <Tag
        tabIndex={index}
        margin="xxsmall"
        key={`${v}${index + 0}`}
        onRemove={() => onRemove(v)}
      >
        {v}
      </Tag>
    ));

  const renderValues2 =()=>(
    value.map((value, index) => 
      <Box  
        border 
        round 
        pad="2px 8px" 
        margin="2px" 
        key={value} 
        background="brand"  
        direction="row" 
        align='center'  
        tabIndex={index} 
        //onMouseEnter={(e)=>e.target.focus()}
        >
      <Box direction='row' gap="5px" align='center'  >   
             <Text   size="10pt" > {value.label||value}</Text>
      </Box>
      <Box focusIndicator={false} onClick={() => onRemove(value)}><FormClose/></Box>
    </Box>
    )
  );


  return (
    <Keyboard onEnter={onEnter}>
      <Box
        direction="row"
        align="center"
        pad={{ horizontal: 'xsmall' }}
        border="all"
        ref={boxRef}
        wrap
      >
        {value.length > 0 && renderValues2()}
        <Box flex style={{ minWidth: '120px' }}>
          <TextInput
            type="search"
            style={{padding:"5px 10px"}}
            focusIndicator={false}
            plain
            size="small"
            dropTarget={boxRef.current}
            {...rest}
            onChange={updateCurrentTag}
            value={currentTag}
            onSuggestionSelect={(event) =>{
               onAddTag(event.suggestion)
               event.target.value=""
               
              }}
          />
        </Box>
      </Box>
    </Keyboard>
  );
};


export const InputWithTags = ({allSuggestions, suggest, onChange, value}) => {
const [constantAllSuggestions,_] = React.useState(allSuggestions);
 const [selectedTags, _setSelectedTags] = React.useState(value || []);
 const setSelectedTags=(val)=>{
   _setSelectedTags(val);
   onFilterSuggestion("");
   onChange(val);
  }
  //allSuggestions.filter(s=>!value.includes(s)

//   const [availibleSuggesitons, setAvailibleSuggesitons] = React.useState();
//  useEffect(()=>{
//   setAvailibleSuggesitons(allSuggestions.filter(s=>!value.includes(s)));
//  }, [value]);
 

 const [suggestions, setSuggestions] = React.useState();
 useEffect(()=>{
  if (constantAllSuggestions && suggestions!==constantAllSuggestions){
     setSuggestions(constantAllSuggestions.filter(suggestion=>!value.includes(suggestion)))
  } 
},[constantAllSuggestions])
 const onRemoveTag = (tag) => {
   const removeIndex = selectedTags.indexOf(tag);
    const newTags = [...selectedTags];
    if (removeIndex >= 0) {
      newTags.splice(removeIndex, 1);
    }
    setSelectedTags(newTags);
  };

  const onAddTag = (tag) => selectedTags.includes(tag) || setSelectedTags([...selectedTags, tag]);

  const onFilterSuggestion = (val) =>{
    if (suggest){
      if(val){

        suggest(val).then(newData=>{
          setSuggestions(newData)
        })
      }
        
    }
    else{

      setSuggestions(
        constantAllSuggestions?.filter(
          (suggestion) =>  !value.includes(suggestion) && ((!val) || suggestion.toLowerCase().indexOf(val.toLowerCase()) >= 0))
          );
        }
  }
    

  return (

    <Box pad="small"
      onKeyUp={event=>{
        if ( event.code=="Backspace" || event.code=="Delete" ){
            let selectedTexts = window.getSelection().toString().split(/\s/);
            let newTags= value.filter(l=>!selectedTexts.includes(l));
            setSelectedTags(newTags);

        }
    }}
    >
      <TagInput
        placeholder="add ..."
        suggestions={suggestions||[]}
        value={selectedTags}
        onRemove={onRemoveTag}
        onAdd={onAddTag}
        onChange={({ target: { value } }) => onFilterSuggestion(value)}
      />
    </Box>
  );
};
