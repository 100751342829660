import {Box, Text, TextInput, Tip} from "grommet"
import { CircleInformation, Info, StatusInfo } from "grommet-icons"
import { useEffect, useMemo, useState } from "react"
import { getApi } from "../ApiService"
import { TipIfContent } from "./TipIfContent"


const ModelAutocomplete = ({projectInfo, value, onChange, suggestPublicModels = true, lockModelsOnProejct=false, width, modelValue, onModelChange,...rest}:
    {
        projectInfo?:{id:string,name:string}|undefined,
        suggestPublicModels?:boolean,
        lockModelsOnProejct?:boolean
        value:string,
        onChange:(newValue:string)=>any,
        modelValue?:{id:string, model_name:string},
        onModelChange?:(proj:{id:string, model_name:string})=>any, 
        [x:string]: any;
    }
    )=>{

    const huggingFaceModels:any = {
        "sentence-transformers/all-mpnet-base-v2":{
            "info":"All-round model tuned for many use-cases. Trained on a large and diverse dataset of over 1 billion training pairs. 768 dimensions, max 512 token document length, Performance: 69.57",
            "language":"en"
        },
        "sentence-transformers/all-distilroberta-v1":{
            "info":"All-round model tuned for many use-cases. Trained on a large and diverse dataset of over 1 billion training pairs. 768 dimensions, max 512 token document length, Performance: 68.73",
            "language":"en"
        },
        
        "sentence-transformers/paraphrase-MiniLM-L3-v2":{
            "info":"General model can be used for tasks like clustering or semantic search. 384 dimensions, max 128 token document length, Performance: 62.29",
            "language":"en"
        },
        "sentence-transformers/gtr-t5-large":{
            "info":"This model was tuned for semantic search: Given a query/question, if can find relevant passages. 768 dimensions, max 512 token document length, Performance: 69.90",
            "language":"en"
        },
        "sentence-transformers/paraphrase-multilingual-mpnet-base-v2":{
            "info":"Multi-lingual model extended to 50+ languages.. 384 dimensions, max 128 token document length, Performance: 65.83",
            "language":"multilingual"
        },
    };
    const [suggestions,setSuggestions] = useState<any>()
    const [valueInternal,setValueInternal] = useState<string>("")
    useEffect(()=>{
        if (value!=valueInternal){
            setValueInternal(value)
        }
        else if(modelValue && projectInfo){
            if (modelValue.id.startsWith(projectInfo.id))
                setValueInternal(projectInfo.name+"/"+modelValue.model_name)
        }
    },[value,modelValue,projectInfo])
    const [selectedProject, setSelectedProject] = useState<any>();
    useEffect(()=>{
        if (projectInfo?.id!==selectedProject?.id){
            if (lockModelsOnProejct){
                if (!value){
                    setValueInternal(projectInfo.name+"/")
                }
            }
            if (!lockModelsOnProejct){
                setProjectNameSuggetions([projectInfo.name+"/"])
            }
        }
        setSelectedProject(projectInfo)
    },[projectInfo])
    const [selectedProjectModels, setSelectedProjectModels] = useState<{projectId:string, models:{id:string, model_name:string}[]}>();

    useEffect(()=>{       
         if (selectedProject && selectedProjectModels?.projectId!=selectedProject.id){
            
            getApi().getModels(selectedProject.id)
            .then((models:{id:string,name:string,is_ready:boolean}[])=> setSelectedProjectModels({
                projectId:selectedProject.id,
                models:models.filter(t=>t.is_ready).map((m:any)=>({id:m.id as string, model_name:m.model_name as string}))
            }) )
        }
        // else if (selectedProject || selectedProjectModels){
        //     setSelectedProject(null);
        //     setSelectedProjectModels(null);
        // }
    }
    ,[selectedProject])

    const MODEL_TYPES={
        PROJECT:"PROJECT",
        PROJECT_MODEL:"PROJECT_MODEL",
        COMUNITY:"COMUNITY",
    }

    const tags={

        "PROJECT":(
            <Box pad="0px 10px">
                <Text size="xsmall">📂 Project</Text>
            </Box>
        ),
        "PROJECT_MODEL":(
            <Box border round flex={false} pad="0px 10px">
                <Text size="xsmall">🧠 Private</Text>
            </Box>
        ),
        "COMUNITY":(
            <Box border round flex={false} pad="0px 10px">
                <Text size="xsmall">🤗 Comunity</Text>
            </Box>
        ),
    }

    const languages_icons:any={
        "en":" en",
        "multilingual":"🌍"
    }

    const getModelIcons =(modelName:string)=>{
    return   <Box direction="row-responsive" pad="3px" gap="5px" align="center">
        { huggingFaceModels[modelName]?.info && (
            <Box >
        <TipIfContent content={huggingFaceModels[modelName]?.info as string}>
            <Box><CircleInformation/></Box>
        </TipIfContent>
        </Box>
        )}
        { huggingFaceModels[modelName]?.language && (
         <TipIfContent content={huggingFaceModels[modelName]?.language}>
                <Text>{languages_icons[huggingFaceModels[modelName]?.language ]}</Text>
         </TipIfContent>
            
        )}

    </Box>
    }
    const prepareSuggestions= (modelNames:string[], modelType:string)=>{
        
        return modelNames.map((m:string)=>({
            label:(
                <Box direction="row" justify="between" pad="3px" align="start"> 
                    {lockModelsOnProejct&&projectInfo?(
                        <Box direction="row">
                        <Text size="small" color="gray">{projectInfo.name}/</Text> 
                        <Text size="small">{m.replace(projectInfo.name+"/","")}</Text> 
                        </Box>
                    ):( 
                    <Text size="small">{m}</Text> 
                    )}

                    <Box  direction="row" justify="end" gap="2px" align="center">
                    {(tags as any)[modelType]}
                    {
                        (modelType===MODEL_TYPES.COMUNITY ) &&  getModelIcons(m)
                              
                             
                    }
                    </Box>
                </Box>
            ),
            value:m
        }));
    }
    const [projectNameSuggetions,setProjectNameSuggetions] = useState<string[]>([])    
    const [lastValue,setLastValue] = useState<any>()    
    useEffect(()=>{

            const hfModels = suggestPublicModels &&  (value ? Object.getOwnPropertyNames(huggingFaceModels).filter(t=>t.toLowerCase().startsWith(value.toLowerCase())):Object.getOwnPropertyNames(huggingFaceModels) ) || [];
            if (!suggestions ||!suggestions?.map((s:any)=>s.value).includes(value) || value?.endsWith("/")){
                    
                    let privateModelsuggetions=undefined;
                    if (value && typeof(value)==="string" && value.includes("/")){
                        const split = value.split("/")
                        if (split.length==2 || value.endsWith("/")){
                            const projectName=split[0];
                            const modelBeginning=split[1];
                            if (!selectedProject || (selectedProject && projectName!=selectedProject.name)){
                                if (!lockModelsOnProejct && lastValue !==value || (!selectedProject&&value.endsWith("/"))){
                                    getApi().searchProjects(projectName, true ).then(projects=> {
                                           setSelectedProject(projects[0])
                                           setProjectNameSuggetions(projects.map((t:any)=>t.name+"/"))
                                        } )
                                    setLastValue(value);
                                }
                                
                            }
                            else if (selectedProjectModels){
                                if (modelBeginning){
                                    privateModelsuggetions = selectedProjectModels.models.filter((m)=>m.model_name.toLowerCase().startsWith( modelBeginning.toLowerCase())).map(m=>selectedProject.name+"/"+m.model_name)
                                }
                                else{
                                    privateModelsuggetions=selectedProjectModels.models.map(m=>selectedProject.name+"/"+m.model_name)
                                }
                            }
                        }
                    }
                    else{
                        if (value){
                            if (!lockModelsOnProejct && lastValue !==value){
                                getApi().searchProjects(value, false ).then(projects=> {
                                    setProjectNameSuggetions(projects.map((t:any)=>t.name+"/"))
                                } )
                                setLastValue(value);
                            }
                        }

                        if (selectedProject?.id!==projectInfo?.id){
                            setSelectedProject(projectInfo||null)
                            
                        }
                        else if (selectedProject &&selectedProjectModels){
                            privateModelsuggetions = selectedProjectModels.models
                                .filter((t)=>!value || (value&&  t.model_name.toLowerCase().startsWith( value.toLowerCase())))
                                .map(m=>selectedProject.name+"/"+m.model_name)
                        }
                    }

                    if (privateModelsuggetions){
                        // merge privateModelsuggetions with huggingFaceModels
                        setSuggestions([
                            ...prepareSuggestions(projectNameSuggetions, MODEL_TYPES.PROJECT),
                            ...prepareSuggestions(privateModelsuggetions, MODEL_TYPES.PROJECT_MODEL),
                            ...prepareSuggestions(hfModels,MODEL_TYPES.COMUNITY)
                        ])
                        
                        return;
                    }
                    
                
                
                //Falback
                setSuggestions([ 
                    ...prepareSuggestions(projectNameSuggetions, MODEL_TYPES.PROJECT), 
                    ...prepareSuggestions(hfModels,MODEL_TYPES.COMUNITY)
                ])
            }
            
        

    },[value,selectedProject,selectedProjectModels,projectNameSuggetions])


    return (
    <Box  width={width} > 
        
    <Box direction="row" width={width} >
    <TextInput
        suggestions={suggestions}
        onSuggestionSelect={({target,suggestion}:any)=>{
            target.value=suggestion.value

           onChange && onChange(suggestion.value)
           
           if (onModelChange){
                onModelChange(selectedProjectModels?.models.find(m=>m.model_name==suggestion.value?.split("/")[1]))
           }
        }}
        value={valueInternal}
        onChange={(e)=>{
            setValueInternal(e.target.value)
            onChange && onChange(e.target.value)
            
        
        }}
        width={width}
        
        {...rest}
    />
    {value && getModelIcons(value)}
    </Box>
 
        {lockModelsOnProejct && selectedProject&&selectedProjectModels?.models&&(selectedProjectModels?.models?.length>0?<></>:
           <Text size="10pt" color="red">{`Project ${selectedProject.name} doesn't seems to have any models`}</Text>)}

    
    </Box>
    )





}


export {ModelAutocomplete};