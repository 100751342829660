import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Grommet } from 'grommet'
import { theme } from "./theme";
import { AppContextProvider} from './AppContext';

ReactDOM.render(

  <React.StrictMode>
    <AppContextProvider>
    <Grommet theme={theme} full>
      <BrowserRouter>
    <App />
    </BrowserRouter>
    </Grommet>
    </AppContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
