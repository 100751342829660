import {  Box,  Layer,   Spinner, Form, TextInput, FormField, Button, Text, Select } from 'grommet'
import { TextInputWithSuggestions } from '../components/TextInputWithSuggestions';

import {Close} from "grommet-icons"
import { useState, useEffect } from "react";
import { getApi } from '../ApiService';



export const ProjectSelectionDialog= ({caption, defaultProject,  onOK, onClose })=>{
  const [wait, setWait] = useState(false);


function submit(){
  onOK&&onOK(selectedProject);
    
 
}



    
    const [selectedProject, setSelectedProject] = useState(defaultProject);
    

return (
    
<Layer onClickOutside={onClose} onEsc={onClose}>
  <Box width="500px"  margin="small" gap="small">
    <Box direction="row" justify='between' width="100%">
      <Text size="large">{caption} </Text>
      <Close style={{float:"right"}} onClick={onClose}/>
    </Box>
    {
      wait?
      (<Spinner size="large" alignSelf="center"/>)
      :
      ( <Box>
       
            <FormField label="Select project">
                <TextInputWithSuggestions 
                value={selectedProject&&{label:selectedProject.name, value:selectedProject}}
                onSuggestionFilter={(val, callback)=>
                getApi()
                .searchProjects(val?.value?.name||val,false).then(found=>callback(found.map(p=>({label:p.name, value:p}))))
                }
                onChange={(value)=>{
                  setSelectedProject(value)
                
                }}
                />


            </FormField>
           
        
    </Box>)
    }
    <Box direction="row" justify="end" gap="xsmall">
        <Button primary label="OK" onClick={submit}/>
        <Button secondary label="Cancel" onClick={onClose}/>
    </Box>
  </Box>
</Layer>
)
}
