import {Box,DropButton, Text} from "grommet"
import { useEffect, useState } from "react"
import { BetterButton } from "./BetterButton"


const BetterDropButton=({icon, open, label,  primary, secondary, content, getDropContentFunc: getDropContent,onDropOpen,onDropClose, rest}:{
  icon:any, 
  open?:boolean
  primary?:boolean
  secondary?:boolean
  label?:string|undefined, 
  onDropOpen?:()=>any
  onDropClose?:()=>any
  content?:{
    icon?:any,
    label:string,
    permissions?:string|undefined|null
    onClick?:()=>any
    disabled?:boolean
  }[]
  getDropContentFunc?:any
  [rest:string]: any;
  //getDropContent?:(setDropOpen:(openState:boolean)=>any)=>any
}

)=>{
    const [dropOpen,setDropOpen] = useState<boolean>(false);
    function dropWrapper(innerFunc:()=>any){
      setDropOpen(false)
      innerFunc&&innerFunc()
      
    }
    const defaultProps={
      round:true,
      border:secondary,
      background:(primary?"brand":undefined),
      gap:"5px", justify:"center", align:"center", pad:"2px 10px"
  }
  const boxProps = Object.assign({...defaultProps},rest);


    useEffect(()=>{
      if (open===true){
        onOpen()
      } else if(open===false ){
        setDropOpen(open)
      }
    },[open])

    const onOpen=()=>{
      onDropOpen&&onDropOpen();
      if (getDropContent){
        setDropContent(getDropContent(setDropOpen))
      }
      setDropOpen(true)
    }

    const [dropContent, setDropContent] = useState()
    return ( <DropButton
        open={dropOpen}
        onOpen={()=>{
          onOpen()
        }}
        onClose={()=>{
          onDropClose&&onDropClose();
          setDropOpen(false)
        }}
        
        dropProps={{ align: { top: 'bottom' } }}
        dropContent={
          dropContent ?dropContent: (
          <Box pad="small" gap="medium"  align="start" >
            {content&&content.map((c,i)=>(
              <BetterButton 
              key={i}
              plain 
              onClick={()=>dropWrapper(c.onClick)} 
              icon={c.icon} 
              label={c.label} 
              disabled={c.disabled}
              permission={c.permissions}/>   
            ))}
          </Box>
            )
        }>
       <Box direction="row"
        
        {...boxProps}
        
        
       
       > 
         {icon}
         <Text >
         {label}
         </Text>
       </Box>
      </DropButton>)
}


export {BetterDropButton}