import { ReactComponent as AiIcon }  from './ai.svg';
import { ReactComponent as KeywordIcon } from './keyword.svg';
import { Box } from 'grommet';


const AiSearch = ()=>{

    return (
        <Box style={{height:"15px",width:"15px", color:"#d536b499"}}>
            <AiIcon/>
        </Box>
    )
}

const Keyword = ()=>{

    return (
        <Box style={{height:"15px",width:"15px", color:"#d536b499"}}>
            <KeywordIcon/>
        </Box>
    )
}

export {AiSearch,Keyword}