import { Box, InfiniteScroll,Spinner, Text } from "grommet";
import {useEffect, useState,createRef, useMemo, useRef} from "react";

import { TopicCard } from "../TopicCard";
import {
    Chart as ChartJS,
    LinearScale,
    PointElement,
    Tooltip,
    
  } from 'chart.js';
  import {randomColor} from "randomcolor";
  import zoomPlugin from 'chartjs-plugin-zoom';
  import "./TopicsExplore.css"
  import { Texts, ToolbarOptions } from "../Texts";
  import { useParams, useSearchParams } from "react-router-dom";
  import { getApi } from "../../ApiService";
  import { TagCloud } from "react-tagcloud";
  import { LabelArea } from "../LabelArea";
  import { useAppContext } from "../../AppContext";
  import useEventBus from "../../helpers/EventBus";
  import { Contract, Expand, FormClose, FormPrevious } from "grommet-icons";
  import { Bubble } from 'react-chartjs-2';
  import {Chart} from 'chart.js'
import userEvent from "@testing-library/user-event";
import { TopicStatsControl } from "../TopicStats/topicStatsControl";
import {ErrorBoundary} from 'react-error-boundary'
ChartJS.register(LinearScale, PointElement,zoomPlugin, Tooltip);

const ErrorFallback = ({error, resetErrorBoundary})=>(
    <Box align="center" justify="center" pad="10px" >
        <Text >
            Uups... something went wrong 🫣
        </Text>
        <Text color="red" size="small">{error.message}</Text>
      <button onClick={resetErrorBoundary}>Try again</button>
    </Box>
)



const TopicHeader = ({topic, searchKeywords, onUpdateLabels, stats})=>{
    const {projectInfo,testPermission, setProjectInfo} =  useAppContext();

    function addLabel(label){
        if (!projectInfo?.labels.includes(label )){


            return getApi().putLabel(projectInfo.id, label,()=>{
                const newProjInfo = Object.assign({},projectInfo);
                newProjInfo.labels = [...projectInfo.labels, label]
                setProjectInfo(newProjInfo)
                onUpdateLabels(label,true);
            });
        }
        else{
            onUpdateLabels(label,true);
        }
    }


    return (
        <Box pad="20px" gap="5px">
            <Box direction="row" justify="between" gap="medium">
                <Box  >
                    <Text size="xsmall">Name</Text>
                    <Text className="nowrap" weight={900} size="large">{topic.topic_name}</Text>
                </Box>
                <Box  >
                    <Text size="xsmall">Id</Text>
                    <Text >{topic.topic_id}</Text>
                </Box>
            </Box>
            <Box direction="row">
                
                    <Text size="xsmall">Keywords</Text>
                    <Box pad="5px 20px 20px">
                    <TagCloud
                    className="tagCloud"
                    disableRandomColor={true}
                    colorOptions={{luminosity: 'dark'}}
                    randomSeed={42}
                    minSize={8}
                    maxSize={45}
                    tags={(topic.topic_keywords
                        && topic.topic_keywords?.map((kw) => {
                            let res = { value: kw.word, count: (100 * kw.score) }
                            if (searchKeywords?.includes(kw.word)){
                                res["props"]={style: {backgroundColor: "yellow"}};
                            }
                            
                            return res;
                        })
                    ) || []} />
                </Box>
                

                <Box>
                    {stats &&<TopicStatsControl stats={stats} totalCount={topic.size}/>}
                </Box>
            </Box>
            <Box >
            <LabelArea 
            
                labelsValue={topic?.labels} 
            
                allLabels={projectInfo?.labels} 
                
                onUpdateLabels={onUpdateLabels} 
                onAddLabel={testPermission("ADD_LABELS")? (label)=>addLabel(label):undefined}
                allowMultiple={true}
                labelsSettings={projectInfo?.label_settings}
            />
            </Box>
        </Box>
    )
}

export const TopicsExplore = ({topics, searchQuery, selectedTopic, showDetail, onTopicSelected, onEnterDetail, onTopicModified})=> {
    const [chartData, setChartData] = useState([]);
    const pageSize=100
    const [data, setData] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [isMoreData, setIsMoreData] = useState(true);
    
    const { project_id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    


    const [filters, setFilters] = useState(undefined);
    const {projectInfo} =  useAppContext();
    const [topicToHighlight, _setTopicToHighlight] = useState();
    let topicToHighlightId=null;
    const setTopicToHighlight=(topic_id)=>{
        topicToHighlightId=topic_id
        _setTopicToHighlight(topic_id)
    }

    //const [topicToShow,setTopicToShow]=useState()

    function setTopicToShow(topic){
        onTopicSelected&&onTopicSelected(topic)
        setTopicToHighlight(topic)
    }
   

    useEffect(()=>{
        
        if (!topics_filtered || !topics_filtered.length ){
            return;
        }
        try{

            setChartData(getChartData())
        }
        catch(error){
            console.log(error)

        }
        

    }, [topics, selectedTopic]);


    

    const  topics_filtered = useMemo(()=>{

        return (topics?.filter(t=>t.topic_id>=0))
    },[topics])

    function median(values){
        if(values.length ===0) throw new Error("No inputs");
      
        values.sort(function(a,b){
          return a-b;
        });
      
        var half = Math.floor(values.length / 2);
        
        if (values.length % 2)
          return values[half];
        
        return (values[half - 1] + values[half]) / 2.0;
      }
      
    function getChartData(){
        if (!topics_filtered){
            return;
        }
        const maxSize =Math.max.apply(Math, topics_filtered.map(t=>t.size))
        const avg =median( topics_filtered.map(t=>t.size))

        let scale=(num)=>{
            
            if (Math.log10((num/avg+1))>2){
                return 4*(num/maxSize)*Math.log10(num);
            }
            else{
                return 2*num/avg;
            }
        }

        
        let chart_data = topics_filtered.map(t=>{
            let borderColor = "transparent"
            if (t.labels?.length){
                if (projectInfo?.label_settings && projectInfo?.label_settings[t.labels[0]])
                    borderColor = projectInfo.label_settings[t.labels[0]].color
                else
                    borderColor ="#ef4fbf"
            }
            let topLabel=""
            let backgroundColor="rgba(255,255,255,0.5)"
            if (t.stats && t.stats.labeled_count && projectInfo){
                let ratio = t.stats.labeled_count/(t.stats.labeled_count<t.size?t.size:t.stats.labeled_count);
                let labels=null
                if (t.stats.by_label_stats){
                    labels=Object.getOwnPropertyNames(t.stats.by_label_stats)
                }
                if (labels && labels.length){
                    topLabel=labels.reduce((a, b) => t.stats.by_label_stats[a] > t.stats.by_label_stats[b] ? a : b);
                    //let topLabel=Object.getOwnPropertyNames(t.stats)[0]
                    if( projectInfo?.label_settings&& t.stats.by_label_stats[topLabel]>0.8){
                        backgroundColor = projectInfo?.label_settings[topLabel]?.color || "#ffffff"
                    }
                    else{
                        backgroundColor="#ffffff"
                    }
                    backgroundColor=backgroundColor+Math.round(ratio*255).toString(16)
                }
            }
            else if (t.labels?.length==1){
                backgroundColor = getLabelColor(t.labels[0])
            }

            return {
              label: `(${t.topic_id}) [${topLabel}] ${t.topic_name.substring(0,100)} - count: ${t.size}`,
              data: [{ 
                    x:t.centroid[0], 
                    y:t.centroid[1], 
                    r: 4+ (scale(t.stats?.labeled_count||t.size))
                    
                }],
                backgroundColor: (selectedTopic?.topic_id==t.topic_id)?"#d536b4":backgroundColor,// getTopicColor(t.topic_id, 0.9,"light"),
                borderColor:(selectedTopic?.topic_id==t.topic_id)?"#d536b4":"white",
                borderWidth:3// (scale(t.size - (t.stats?.labeled_count||0))/2 )||1
                
            }; 
          });

        return chart_data;
    }
    
    function getTopicColor(topic_id, alpha, luminosity){
        
        return topic_id>=0?randomColor({seed:topic_id*10, luminosity: luminosity||'dark', format: 'rgba', alpha:alpha||1}):"dark-2"
    }

    function getHash(input){
        var hash = 0, len = input.length;
        for (var i = 0; i < len; i++) {
          hash  = ((hash << 5) - hash) + input.charCodeAt(i);
          hash |= 0; // to 32bit integer
        }
        return hash;
      }
    function getLabelColor(label){
        
        return projectInfo?.label_settings && projectInfo?.label_settings[label]?.color  || randomColor({seed:getHash(label), luminosity: 'dark', format: 'rgba', alpha:1})
    }
   
    const chartOptions=useMemo(()=>{
        return (
        {
        //animation :false,
        scales: {
        x: {
            display: false,
        },
        y: {
           display: false,
        }
        
        },
        hoverBackgroundColor:"#d536b4",
        hoverBorderWidth:5,
        // onClick: function(e,pointElement) {
        //     const topic_index_to_select = pointElement[0] && pointElement[0].datasetIndex;
            
        //     if (topic_index_to_select>=0 ){
        //         let theTopic = topics_filtered[topic_index_to_select]
              
        //         setTopicToShow(theTopic)
        //         setFilters({"topic_id":theTopic.topic_id})
       
               
        //     }
        
        // },
        onClick: function(e,pointElement) {
            const topic_index_to_select = pointElement[0] && pointElement[0].datasetIndex;
            
            if (topic_index_to_select>=0 ){
                let theTopic = topics_filtered[topic_index_to_select]
                if (selectedTopic){
                    setTopicToShow(theTopic)
                    setFilters({"topic_id":theTopic.topic_id})
                }
                else{
                    setTopicToShow(theTopic)
                }

            }
        
        },
      
        interaction: {
            mode: 'dataset'
        },
            responsive: true,
            maintainAspectRatio: false,
          
        plugins: {

            tooltip: {
                callbacks: {
                    label: function(context) {
                        
                        return topics_filtered[context.datasetIndex].topic_name;
                    }
                }
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'xy',
                  },
              zoom: {
              
                wheel: {
                  enabled: true,
                  modifierKey:"alt"
                },
                pinch: {
                  enabled: true
                },
                mode: 'xy',
             
                }
            },
          }
        }
    )},[topics_filtered, selectedTopic]);
    
    const refToScroll = useRef([]);
    const scrollParrent = useRef()
    
    useEffect(()=>{
        
        if (selectedTopic && scrollParrent?.current){    
                let topic_id=selectedTopic.topic_id;
                let element= document.getElementById("topic-"+topic_id)
                //scrollRef.current?.scrollTo(refToScroll.current?.offsetTop,0,true)
                //scrollRef.current.scrollIntoViewIfNeeded(refToScroll.current)
                if (element){
                    element.scrollIntoView({ behavior: "smooth"})
                }
            
        }
    },
    [scrollParrent, refToScroll, selectedTopic])

    useEffect(()=>{
        if(filters?.topic_id ){
            setData(null);
            loadTexts()
        }
    },[filters])

    useEffect(()=>{
        setFilters({"topic_id":(new URL(document.location)).searchParams.get("topic_id")})
    },[searchParams])


    const loadTexts = (page)=>{

        page=page||1;

        if (!filters ){
            console.error(" filters is null")
            return;
        }

        let query = Object.assign({},filters)
        query["take"] = pageSize; 
        query["skip"] = pageSize*(page-1); 
    
        
    
        setIsLoading(true);    
        return getApi().searchTexts(project_id, query, (newData)=>{
          setIsLoading(false);
          setCurrentPage(page);
          if (page>1){
            if (newData && newData.length>0){
              
              newData = data.concat(newData);
              setData(newData);
            }
          }
          else{
            setData(newData);
          }
          
          
          if (newData.length<query["take"] || newData.length===0) {
            setIsMoreData(false);
          }
          else{
            setIsMoreData(true);
          }
          })
          .catch((ex)=>{
            setIsLoading(false)
          })
      }
    
   

    

    const eventBus = useEventBus();

    function handleUpdateTopicLabels(topic, label, state){
        if (state && topic.labels?.includes(label)){
            return;
        }
        if (state){
            topic.labels = [...(topic.labels||[]),label]
        }
        else{
            topic.labels=topic.labels.filter(t=>t!=label)
        }
        getApi().patchTopic(project_id, topic.topic_id, {"labels":topic.labels}).then(()=>{
            getApi().getTopicStats(project_id,topic.topic_id).then(stats=>{
                topic.stats=stats
                let newTopic = onTopicModified(topic)
                setTopicToShow(newTopic)
            })
            
            
        })
        eventBus.publish({
            topic:"TopicLabeled", 
            payload:{
                    label, 
                    state
                }
            })
    }

    

    const bubbleChartRef = useRef()
    
  

    function getKeyKeywords(kwScores){
        let higherScores = kwScores.slice(0,Math.floor(kwScores.length/2)).map(t =>t.score);
        if(higherScores.length){

            let sum =higherScores.reduce((prev, next) => prev + next);
            let mean = sum/higherScores.length;
            return kwScores.filter(t=>t.score>mean).map(t=>t.word)
        }
        else{
            return []
        }
    }

    
    const [chartExpanded,setChartExpanded] = useState();

    return  topics_filtered?(
        topics_filtered.length?(
            <Box direction="row"  fill>

                <Box
                 //width={topicToShow?"35%":"99%"} 
                 width={chartExpanded&&!selectedTopic?"99%":"35%"}
                margin="10px 5px" className="topicsChart" key="-10" pad="0px 0px" border round background="dark-1" flex={false} overflow={"hidden"}>
                    <Box focusIndicator={false} alignSelf="end" style={{zIndex:999}} margin="15px" onClick={()=>setChartExpanded(!chartExpanded)}>
                        {!chartExpanded?<Expand/>:<Contract/>}
                    </Box>
                    <Box  className="topicsChart"  width="100%" height="100%">
                    {chartData&& 

                    <ErrorBoundary FallbackComponent={ErrorFallback} onReset={()=>{}}>
                        <Bubble
                            ref={bubbleChartRef}
                            style={{  margin: "-100px"}}
                            plugins={[zoomPlugin]}
                            data={{ datasets: chartData }}
                            options={chartOptions }
                            />
                        
                    </ErrorBoundary>
                     }
                     </Box>

                </Box>
            { selectedTopic&&showDetail ? (
                <Box width="65%">
                    <Box direction="row" flex={false} margin="0px 0px -0px 10px">
                        <Box onClick={()=>{
                                onEnterDetail(null)
                            }} border round direction="row" flex={false} align="center" justify="center" pad="0px 5px 0px 0px">
                            <FormPrevious/>
                            <Text size="8pt">Back to list</Text>
                        </Box>
                    </Box>
                    <Box >
                        <TopicHeader topic={selectedTopic} onUpdateLabels={(label,state)=>handleUpdateTopicLabels(selectedTopic ,label,state)}/>
                        {!data?(
                            <Box align="center">
                            <Spinner size="large"/>
                            </Box>
                        ):
                        <Texts 
                            //caption={backlog? "Backlog":"Documents"}
                            autoselectAll
                            texts={data||[]} 
                            //onHideLabeled={onHideLabeled}
                            isLoading={isLoading}
                            additionalKeywords={getKeyKeywords(selectedTopic.topic_keywords)}
                            onTextsChanges={(newData) =>{
                                if (selectedTopic){
                                
                                    getApi().getTopicStats(project_id,selectedTopic.topic_id).then(stats=>{
                                        selectedTopic.stats=stats
                                        onTopicModified(selectedTopic)
                                    })
                                }
                                setData(newData)
                                }
                                }
                            hasNextPage={isMoreData}
                            pageSize={pageSize} filters={filters} 
                            //similarSearchOptions={backlog? ["similar_not_labeled"]: ["similar_count", "similar_not_labeled"]}
                            //onShowSimilarToDoc={showSimilarToDoc}
                            //similarToDocSidebarOpen={pane!="left"}
                            loadNextPage={loadTexts}
                            eventBus={eventBus}
                            toolbarOptions={[ToolbarOptions.select_all, ToolbarOptions.autoselect]}
                            />}
                            </Box>
                </Box>
            ):(
                chartExpanded ?(<></>):<Box width="65%" overflow="auto" ref={scrollParrent}>
                     
                    {topics_filtered.map((t,i)=>{
                        
                        return (<Box key={i} id={`topic-${t.topic_id}`} ref={refToScroll} flex="grow"  elevation="small" round="xsmall" margin="xsmall" 
                        border={selectedTopic!==t?true:{color:"brand", size:"medium"}}
                        background="white"
                        
                        onMouseEnter={()=>{
                            if (chartData && bubbleChartRef.current){
                                try{

                                    let chart = bubbleChartRef.current
                                    let scales = chart.getInitialScaleBounds()
                                    if (scales.x.max===undefined||  scales.x.max===null){
                                        scales =chart.scales
                                    }
                                    
                                    let xRange= (scales.x.max - scales.x.min)
                                    let yRange= (scales.y.max - scales.y.min)
                                    let zoomRatio = 2*chart.getZoomLevel()
                                    chart.zoomScale('x', {min: t.centroid[0]-xRange/zoomRatio, max: t.centroid[0]+xRange/zoomRatio}, 'active');
                                    chart.zoomScale('y', {min: t.centroid[1]-yRange/zoomRatio, max: t.centroid[1]+yRange/zoomRatio}, 'active');
                                    // chart.zoomScale(chart,{
                                        //     x:t.centroid[0]-((chart.scales.x.max-chart.scales.x.min)-2),
                                        //     y:t.centroid[1]-((chart.scales.y.max-chart.scales.y.min)-2)
                                        // })
                                        chart.setActiveElements([
                                            {datasetIndex: i, index: 0},
                                        ])
                                        chart.update();
                                    }
                                    catch{

                                    }
                            
                                                                    
                            }
                        }}
                        onClick={()=>onEnterDetail(t)}
                        >
                        {/* <TopicHeader topic={t} onUpdateLabels={(label,state)=>handleUpdateTopicLabels(topicToShow ,label,state)}/> */}
                        <TopicCard topic={t}/>
                        </Box>)
                    })
                    }
                    
                </Box>
            )
                            }

            </Box>
        ):(
            searchQuery ?(
                <Box fill align="center" justify="center">
                <Text size="large">No topics match the criteria</Text>
                
                </Box>
            ):(

                <Box fill align="center" justify="center">
                    <Text size="large">There are no topics generated for this project</Text>
                    <Text>Try regenerate them</Text>
                </Box>
            )
        )
    ):(
        <Box fill align="center" justify="center">
            <Spinner size="large"/>
        </Box>
    )
    
}