import { Spinner,Box,Text, Button } from "grommet"
import { useMemo } from "react";
import { Link } from 'react-router-dom';
import "./similarCountBadge.css";
const wrapToLink = (content, link)=>{
    if (!link){
        return (<Link to={link}>
            {content}
        </Link>)
    }
    else{
        return content
    }

}

const SimilarCountBadge= ({docId, similarTextsCount, similarNotLabeledTextsCount, isLoading, onClick})=>{

    const labeledPercentage=useMemo(()=>{
            if(similarTextsCount!==undefined && similarNotLabeledTextsCount!==undefined ){
                return Math.round(((similarTextsCount-similarNotLabeledTextsCount)/similarTextsCount)*100)
            }
        },[similarTextsCount,similarNotLabeledTextsCount])

    const memorizedState = useMemo(()=>{
    return (similarTextsCount!==undefined &&( similarTextsCount!=-1 || similarNotLabeledTextsCount!==undefined))? 
                                                (

                                                        (isLoading) ? 
                                                            (<Spinner style={{ padding: "5px" }} margin="xsmall" size="3px" />)
                                                            : 
                                                            (
                                                                (similarTextsCount > 0) ?
                                                                (
                                                                    wrapToLink(
                                                                        <Button 
                                                                            style={{ 
                                                                                background:`linear-gradient(90deg, #ffbd5590 0%, #ffbd5590 ${labeledPercentage}%, #ffffff00 ${labeledPercentage}%,#ffffff00 100%)`
                                                                            }} 
                                                                            className="similarCountBadgeButton"
                                                                            round 
                                                                            onClick={onClick}
                                                                            label={ (similarTextsCount>99?`${99}+`:similarTextsCount) + " more alike"}
                                                                        />
                                                                    ,"?similar_to_doc=" +docId)
                                                                )
                                                                : 
                                                                (  (similarNotLabeledTextsCount > 0 ) ?
                                                                        (
                                                                            wrapToLink(
                                                                            
                                                                    
                                                                                <Button 
                                                                                className="similarCountBadgeButton"
                                                                                round 
                                                                                onClick={onClick}
                                                                                label={ similarNotLabeledTextsCount +" alike not labeled"}
                                                                            />
                                                                                ,"?similar_to_doc=" +docId)

                                                                        ):(
                                                                        (<>
                                                                            
                                                                        {

                                                                                wrapToLink(
                                                                                    <Button 
                                                                                    // style={{ 
                                                                                    //     padding: "0px 10px", lineHeight: "20px", fontSize: "small", opacity:0.5, marginTop: 5, fontWeight: 400 ,
                                                                                    // }} 
                                                                                    className="similarCountBadgeButton"
                                                                                    round 
                                                                                    onClick={onClick}
                                                                                    label={ "More similar..."}
                                                                                    />
                                                                            
                                                                                ,"?similar_to_doc=" +docId)
                                                                                    }</>
                                                                        )
                                                                        )
                                                                )
                                                            )
                                                        
                                                )
                                                : (<></>)
    },[similarTextsCount, similarNotLabeledTextsCount, isLoading])

    return memorizedState;
}

export {SimilarCountBadge}