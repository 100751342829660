
import { Box, Button, Spinner, Text, TextInput } from "grommet";
import { Close, Edit } from "grommet-icons";
import { useEffect, useMemo, useState } from "react";
import { TipIfContent } from "../TipIfContent";
import "./AutoUI.css"


const FieldValue = ({ fieldValue, size, field_ui_hints, isEdited,  onEditStart, onEditFinish, working }) => {
    const isReadOnly = field_ui_hints && field_ui_hints["readonly"]
    const defaultNullPlaceholder = "undefined"
    field_ui_hints=field_ui_hints||{}
    const [tempValue, setTempValue]=useState("");
    useEffect(()=>{
        setTempValue(isEdited?fieldValue:"")
    },[isEdited])
    const _onEditFinish=(e)=>{
        onEditFinish&& onEditFinish(tempValue)
    }

    function getEditElement(type){
        if (type?.startsWith("Dict<string,")){
            return(<>
                <AutoUI value={fieldValue} ui_hints={field_ui_hints?.subfields}/>
                <Button size="small" label="Add"/> 
            </>)
        }
        else if (typeof(tempValue)==="object" ){
            return "Object"
        }
        else if (type=="int" ){
            return (
                <TextInput style={{width:`${ ((fieldValue||field_ui_hints?.new_val_placeholder||defaultNullPlaceholder).toString().length)*0.5}em`}} 
                value={tempValue} 
                type="number"
                onChange={(e)=>setTempValue(parseInt(e.target.value))}
                focusIndicator={false} 
                autoFocus 
                className={"textInput " +(size || "small")  } 
                onBlur={(e)=>_onEditFinish(e)}
                />
                )
        }
        else if (type=="number"  || typeof(fieldValue)==="number"){
            return (
                <TextInput style={{width:`${ ((fieldValue||field_ui_hints?.new_val_placeholder||defaultNullPlaceholder).toString().length)*0.5}em`}} 
                value={tempValue} 
                type="number"
                onChange={(e)=>setTempValue(parseFloat(e.target.value))}
                focusIndicator={false} 
                autoFocus 
                className={"textInput " +(size || "small")  } 
                onBlur={(e)=>_onEditFinish(e)}
                />
                )
        }
        else if (type=="string" || !fieldValue || typeof(fieldValue)==="string"){
            return (
                <TextInput style={{width:`${ ((fieldValue||field_ui_hints?.new_val_placeholder||defaultNullPlaceholder).toString().length)*0.5}em`}} 
                value={tempValue} 
                onChange={(e)=>setTempValue(e.target.value)}
                focusIndicator={false} 
                autoFocus 
                className={"textInput " +(size || "small")  } 
                onBlur={(e)=>_onEditFinish(e)}
                />
                )
            }
            else {
                return (
                    <>edit not supported</>
                )
            }
            
    }

    return (
        <Box className="fieldValue" focusIndicator={false} direction="row" align="center" onClick={isReadOnly?undefined:()=>!isEdited&&onEditStart()}>
            
            {
            
            isEdited ? (
                getEditElement(field_ui_hints?.type)
            ) :
                (

                    fieldValue ? (
                        (typeof(fieldValue)=="object" || field_ui_hints.type?.startsWith("Dict<string)"))?(
                            <>
                                <AutoUI value={fieldValue} ui_hints={field_ui_hints?.subfields}/>
                            </>
                        ):(
                            <Text size={size || "small"} truncate>
                            {fieldValue?.toString()}
                        </Text>
                                )
                                
                                ) : (
                                    <Box  pad="1px 5px">
                                        <Text weight={400} size={size || "small"} truncate color="gray">{field_ui_hints?.new_val_placeholder ||defaultNullPlaceholder}</Text>
                                       
                                    </Box>
                    )
                )
            }
            {working&&(
                <Box margin="0px 10px">
                <Spinner className="miniSpinner" pad="1px" size="small"/>
                </Box>
            )}
            {!isReadOnly&&!isEdited ?(
                <Box margin="5px" className="editIcon">
                    <Edit color="gray" size="15px" />
                </Box>
            ):(
                <></>
            )
            }

        </Box>
    )
}


export const AutoUI = ({ value, ui_hints, size, allowRemoveKey=false, onValueChange }) => {

    const [valueState, setValueState] = useState({});
    
    useEffect(()=>{
        setValueState(value||{})
    },[value])

    const fields = useMemo(() => {
        let newFields = [];
        if (ui_hints) {
            Object.getOwnPropertyNames(ui_hints).forEach(f => newFields.push(f))
        }
        if (value) {
            Object.getOwnPropertyNames(value).forEach(f => (!newFields.includes(f)) && newFields.push(f))
        }
        return newFields;

    }, [value, ui_hints])

    const [editedField, setEditedField] = useState()

    function modifyValue(modifyFucn){
        let newValueState = Object.assign({},valueState);
        modifyFucn(newValueState)
        setValueState(newValueState);
        onValueChange && onValueChange(newValueState)
    }
    function setValue(path, val){
        let newValueState = Object.assign({},valueState);
        let current = newValueState;
        let pathArr= path.split(".")
        pathArr.forEach((prop,i)=>{
            if( i!=pathArr.length-1){
                let newCurrent= current[prop]
                if (!newCurrent){
                    newCurrent= {}
                    current[prop]=newCurrent
                }else{
                    newCurrent = Object.assign({}, newCurrent)
                    current[prop]=newCurrent
                }
                current=newCurrent
            }
        })
        current[pathArr[pathArr.length-1]] =val
        setValueState(newValueState);
        onValueChange && onValueChange(newValueState)
    }
    
    
    function isFieldDict(field){
        return ((ui_hints && ui_hints[field]))?.type?.startsWith("Dict<string")
    }

    return (
        <>
            <table width="10%" className={"autoUIGrid " + size}>
                <tbody>
                {fields && fields.map((field,i )=> (
                    (typeof( valueState[field])=="object" || isFieldDict(field) || ( ui_hints && ui_hints[field]?.subfields) )?
                        (<tr>
                             <td colSpan={2}>
                                <Text weight={800} >{(ui_hints && ui_hints[field] && ui_hints[field]["label"]) || field}</Text>
                                <Box pad="0px 0px 10px 20px" >
                                    <AutoUI 
                                    value={valueState[field]} 
                                    ui_hints={(ui_hints && ui_hints[field]?.subfields)}
                                    size={size}
                                    allowRemoveKey={ isFieldDict(field)}
                                    onValueChange={newVal=>setValue(field, newVal)}
                                    />
                                    {isFieldDict(field)&&(
                                    <Box>
                                    <FieldValue
                                            size={size}
                                            
                                            field_ui_hints={{new_val_placeholder:"Add new key"}}
                                            onEditStart={() => setEditedField(field)}
                                            onEditFinish={(val)=>{
                                                val && setValue(field+"."+val,"")
                                                setEditedField(undefined)
                                            }}
                                            isEdited={editedField == field}
                                        />
                                    
                                    </Box>
                                    )}
                                </Box>
                            </td>
                        </tr>
                        ):
                        (
                            <tr key={i}>
                                <td>
                                    <Box margin="0px 10px 0px 0px">

                                        <TipIfContent content={ui_hints && ui_hints[field] ? ui_hints[field]["tip"] : null}>
                                            <Text weight={600} size={size || "small"} truncate>
                                                {(ui_hints && ui_hints[field] && ui_hints[field]["label"]) || field}
                                            </Text>
                                            
                                        </TipIfContent>
                                    </Box>
                                </td>
                                <td>

                                        <Box direction="row" align="center">
                                            <FieldValue
                                                size={size}
                                                fieldValue={ valueState[field]}
                                                field_ui_hints={ui_hints && ui_hints[field]}
                                                onEditStart={() => setEditedField(field)}
                                                onEditFinish={(val)=>{
                                                    setEditedField(undefined)
                                                    if (val!=valueState[field]){
                                                        modifyValue(state=>state[field]=val)
                                                    }
                                                }}
                                                isEdited={editedField == field}
                                            />
                                            {allowRemoveKey&& (
                                                    <Box focusIndicator={false} onClick={()=>{
                                                        modifyValue((val)=>{
                                                            delete val[field]
                                                        })
                                                    }}><Close color="red"/></Box>
                                                )}
                                        </Box>
                                </td>
                            </tr>
                        )
                        
                ))}
                </tbody>
            </table>
        </>
    )
}