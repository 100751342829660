import { Link, useParams, useSearchParams } from 'react-router-dom';
import { Box, Button, Heading, Collapsible, Text, Form, FormField, TextInput, Select, Spinner, ThemeContext, Grommet, DropButton, Meter } from 'grommet'
import { Add, CirclePlay, CodeSandbox, FormClose, More, Play, Refresh, Stop, Trash } from "grommet-icons"
import { useState, useEffect, useMemo } from "react";

import { getApi } from '../ApiService';
import { NewModelSidebar } from '../components/NewModelSidebar';
import { BetterButton } from '../components/BetterButton';
import { NodeInfo } from '../ViewModel/NodeInfo';
import { modifyState } from '../helpers/helperFunctions';
import { ModelSettings, NodeSettings, RoutingSetting } from '../ViewModel/NodeSettings';
import { ModelAutocomplete } from '../components/ModelAutocomplete';
import { NodeSettingsDialog } from '../dialogs/NodeSettingsDialog';
import { NodeClient } from '../ServingNodeClient/client';
import { BetterDropButton } from '../components/BetterDropButton';
import { ModelSettingsAnalysisDialog } from '../dialogs/ModelSettingsAnalysisDialog/ModelSettingsAnalysisDialog';
import { type } from '@testing-library/user-event/dist/type';
import { TipIfContent } from '../components/TipIfContent';


const NewNodeForm = ({ defaultValue, onSubmit }: {
    defaultValue?: NodeInfo,
    onSubmit: () => any
}) => {
    const [wait, setWait] = useState(false);
    const [nodeInfo, setNodeInfo] = useState<NodeInfo>(defaultValue);
    const submit = () => {
        
        setWait(true);

        getApi().createNode(nodeInfo).then(() => {
            setWait(true)
            onSubmit && onSubmit()
        })
    }
    return (
        <Form
            value={nodeInfo}
            onChange={newState => setNodeInfo(newState)}
            onSubmit={submit}
        >
            <FormField label="Node name" >
                <TextInput name="node_name"  disabled={wait} />
            </FormField>


            <FormField label="Deployment type" >
                <Select   name="deployment_type" options={["managed", "self-hosted"]} disabled={wait||false  } />
                
            </FormField>
            {nodeInfo.deployment_type == "managed" && (
                
                    <FormField label="Node type">
                        <Select name="node_type" options={["CPU" , "GPU"]} disabled={wait} />
                    </FormField>
                
                
            )
            }
            <Box pad="0px 10px">
                <Text size="xsmall" textAlign='end'>
                    {nodeInfo.deployment_type == "managed" ?
                        (
                            nodeInfo.node_type == "GPU" ? "Fast - use if you have lots of data to process. 12$/day (0.5$/hour)"
                                : "Cost effective - 2.4$/day (0.1$/hour) "
                        )
                        :
                        "You must deploy and manage the node on your own."}
                </Text>
            </Box>
            <Box justify='end' direction='row' margin="15px 0px">

                <BetterButton
                    primary
                    label={!wait ? 'Create' : "Creating ..."}
                    onClick={() => !wait && submit()}

                    icon={wait ? (<Spinner size="2px" />) : undefined}
                />
            </Box>
        </Form>)
}



const NodeBox = ({ nodeInfo, onChange }: {
    nodeInfo: NodeInfo,
    onChange:(newNodeInfo:NodeInfo|undefined)=>any
}) => {

    const defaultSettings: NodeSettings = {
        project_id:"",
        default_model: "",
        authorization:{
            enable_public_access:false,
            auth_method:"API_KEY",
            api_key:undefined
        },
        models: [{
            project_id:"",
            model_name: "",
            task_type: "",
            default_handling: "model-auto",
            routing: [],
            min_prediction_score: 0.5
        }]
    }

    const [statusReloading, setStatusReloading] = useState<any>();

    const [dialog, setDialog] = useState<any>();
    const [tipText, setTipText] = useState<string>("");
    const [nodeSettings, setNodeSettings] = useState<NodeSettings>(defaultSettings);
    useEffect(() => {
        if (nodeInfo ) {
            getApi().getNodeSettings(nodeInfo.node_name).then(data => {
                 setNodeSettings(data)
            })
        }
    }, [nodeInfo])

    const isReady =useMemo(()=>nodeInfo.status==="READY" && nodeSettings.models, [nodeInfo, nodeSettings])

    const getColorForNodeStatus = (status:string)=>{
        if (status=="OFFLINE"){
            return "#D5D5D5"
        }
        else if (status=="READY"){
            return "#5DDC00"
        }
        else if (status=="PENDING"){
            return "#A8FFED"
        }
        else if (status=="ERROR"){
            return "#FF5733"
        }
        else if (status=="CONFIG_OUT_OF_DATE"){
            return "#FFB833"
        }
        else {
            return "black"
        }
    }

    const openNodeDialog=(nodeSettings:NodeSettings)=>{
        setDialog(
            <NodeSettingsDialog
                nodeInfo={nodeInfo}
                caption={`${nodeInfo.node_name} configuration`}
                nodeSettings={nodeSettings}
                onOK={(newValues) => {
                    setNodeSettings(newValues.nodeSettings)
                    setDialog(null)
                    
                    let promises:Promise<any>[]=[]
                    setStatusReloading(true)
                    if (JSON.stringify(newValues.nodeSettings)!=JSON.stringify(nodeSettings)){

                        getApi().saveNodeSettings(nodeInfo.node_name,newValues.nodeSettings).then(()=>{
                            if (onChange){
                                promises.push( getApi().getNodeInfo(nodeInfo.node_name).then(newNodeInfo=> onChange(newNodeInfo)))
                            }
                            if (newValues.nodeInfo.deployment_type!="self-hosted" &&  JSON.stringify(newValues.nodeInfo)!=JSON.stringify(nodeInfo)){
                                promises.push( getApi().patchNodeInfo(nodeInfo.node_name, newValues.nodeInfo).then(newNodeInfo=> onChange(newValues.nodeInfo)))
                                
                            }
                            if (promises.length){
                                Promise.all(promises).finally(()=>setStatusReloading(false))
                            }
                            else{
                                setStatusReloading(false)
                            }
                        })
                    }
                    else if (JSON.stringify(newValues.nodeInfo)!=JSON.stringify(nodeInfo)){
                        getApi().patchNodeInfo(nodeInfo.node_name, newValues.nodeInfo).then(newNodeInfo=> {
                            onChange(newValues.nodeInfo)
                            setStatusReloading(false)
                        })
                    }

                    
                    
                    
                }}
                onClose={() => setDialog(null)}
            />)
    }

    const loadingOperations=[{label:"Loading...", disabled:true}]
    const [operations,setOperations]=useState<{
        icon?: any;
        label: string;
        permissions?: string;
        onClick?: () => any;
        disabled?: boolean;
    }[]>()
    const onDropOpen=()=>{
        setOperations(loadingOperations);
        getApi().getNodeInfo(nodeInfo.node_name).then((newNodeInfo)=>{
            let ops = [];
            if (newNodeInfo.deployment_type=="managed"){
                if (newNodeInfo.status=="READY" || newNodeInfo.status=="CONFIG_OUT_OF_DATE"|| newNodeInfo.status=="ERROR"){
                    ops.push({label:"Stop", icon:(<Stop/>), onClick:()=>
                    {
                        setStatusReloading(true)
                        getApi()
                        .stopNode(nodeInfo.node_name)
                        .then(()=>{
                                    if (onChange){
                                        getApi().getNodeInfo(nodeInfo.node_name).then(newNodeInfo=> onChange(newNodeInfo)).then(()=>setStatusReloading(false))
                                    }
                                }     
                        )
                    }
                })  
                }
                else if  (true || newNodeInfo.status=="OFFLINE" ){
                    ops.push({label:"Start", icon:(<Play/>), onClick:()=>getApi().startNode(nodeInfo.node_name).then(()=>{
                        if (onChange){
                            setStatusReloading(true)
                            getApi().getNodeInfo(nodeInfo.node_name).then(newNodeInfo=> onChange(newNodeInfo)).then(()=>setStatusReloading(false))
                        }
                    }     
                )

                 })  
                }
            }
            ops.push({label:"Delete", icon:(<Trash/>), onClick:()=>{
                setStatusReloading(true);
                getApi().deleteNode(nodeInfo.node_name).then(()=>onChange&&onChange(undefined))
            } })
            setOperations(ops);
            onChange&&onChange(newNodeInfo);
        })
        
    }

    nodeInfo.stats && nodeSettings&& nodeInfo.stats[nodeSettings.models[0].model_name]&& console.log(nodeInfo.stats)
    
    return (<Box pad="10px" margin="10px" border round="xsmall" elevation='small' background="light-1" flex={{shrink:0}}>
        {dialog && (dialog)}
        <Box direction='row' gap="5px" justify='between' >
            <Box direction='row' gap="20px">
            <CodeSandbox />
            <Text>{nodeInfo.node_name}</Text>
            <Box direction='row' gap="5px" justify='end' align='start'>
                <Box align='center' pad="0px 10px" direction='row' gap="5px" round  border ><Text size="small">{nodeInfo.deployment_type}</Text></Box>    
                {nodeInfo.node_type && <Box align='center' pad="0px 10px" direction='row' gap="5px" round border > <Text  size="small">{nodeInfo.node_type}</Text></Box>}
            </Box>
            
            </Box>
             <Box direction='row' gap="2px">
            
                
             {statusReloading?(<Spinner/>):(
                <Box round pad="2px 10px" background={getColorForNodeStatus(nodeInfo.status)}>{nodeInfo.status}</Box>
             )}
            <Box>
                
                <BetterDropButton icon={<More/>} content={operations} onDropOpen={onDropOpen} onDropClose={()=>setOperations(loadingOperations)}/>
            </Box>
            </Box>
        </Box>
        {nodeInfo.message?<Box pad="small" >Status info:<Box  background="light-4" round="xsmall" pad="xsmall" margin="small"><Text size='small'>{nodeInfo.message}</Text></Box></Box>:<></>}
        <Box>
            <Text size="small">Deployed models:</Text>
        </Box>
        <Box direction='row' justify='between' align='end'>

            <Box pad="5px 15px" >
                {nodeSettings?.models?.length > 0 ? (
                    <Box > 
                        {nodeSettings.models.map((model: ModelSettings, i:number) => (
                            <Box key={i} align='start'>
                                <Box>{model.model_name || "-- none --"}</Box>
                                <Box direction='row' gap="small">
                                    <Box round background="orange" pad="5px 12px" onClick={() => openNodeDialog(nodeSettings)}>
                                        <Text weight={700} size="small">Modify configuration</Text>
                                    </Box>

                                {nodeInfo && nodeInfo.stats &&nodeInfo.stats[model.model_name] && (
                                    <Box direction='row' gap="small" round border align='center' justify='center' pad="2px 5px">
                                        {nodeInfo.stats[model.model_name] && nodeInfo.stats[model.model_name].by_handling &&
                                            <TipIfContent content={tipText}>
                                            <Box>
                                            <Meter type='bar'
                                                size="small"
                                                thickness='small'
                                                round
                                                values={
                                                    Object.keys(nodeInfo.stats[model.model_name].by_handling)
                                                        .map(metric => (
                                                            {
                                                                value: nodeInfo.stats[model.model_name].by_handling[metric],
                                                                color: metric == "model-auto" ? "lightgreen" : (metric == "model-review" ? "orange" : (metric == "manual" ? "gray" : "pink")),
                                                                onHover:(over)=> over&& setTipText(`${metric}: ${ nodeInfo.stats[model.model_name].total ? Math.round(100*nodeInfo.stats[model.model_name].by_handling[metric] / nodeInfo.stats[model.model_name].total):"inf"} %`)
                                                            }
                                                        ))
                                                }
                                                max={nodeInfo.stats[model.model_name].total} />
                                            </Box>
                                            </TipIfContent>
                                        }
                                        {nodeInfo.stats[model.model_name] && nodeInfo.stats[model.model_name].total && nodeInfo.stats[model.model_name].timestamp ? (

                                            <TipIfContent content={`${nodeInfo.stats[model.model_name].total} / ${Math.round((new Date(nodeInfo.last_heartbeat).getTime() - new Date(nodeInfo.stats[model.model_name].timestamp).getTime()) /( 1000 * 60))} min`}>
                                                <Box>
                                            <Text>
                                                {Math.round(nodeInfo.stats[model.model_name].total / ((new Date(nodeInfo.last_heartbeat).getTime() - new Date(nodeInfo.stats[model.model_name].timestamp).getTime()) /( 1000 * 60 * 60))*10)/10} requests / h
                                            </Text>

                                            </Box>
                                            </TipIfContent>
                                        ):(
                                            <Text size="xsmall">no stats yet</Text>
                                        )}
                                </Box>
                            )}
                            </Box>
                            </Box>
                        ))}
                    </Box>
                ) : (
                    <Box   align='start'>
                    <Box> -- none --</Box>
                    <Box round background="orange" pad="0px 10px" onClick={() => {
                        //setNodeSettings(defaultSettings)
                        openNodeDialog(defaultSettings)
                    }}>
                        <Text weight={700} size="small">Deploy a model</Text>
                    </Box>
                    </Box>
                )
            }
            </Box>
            <a className='noUnderline' href={`/serving/test/${nodeInfo.node_name}`} target="_blank">
                <Box align='center' pad="5px 10px" direction='row' gap="5px" round background={isReady ? "brand":"#D5D5D5"} ><CirclePlay color='white'/><Text color="white">Test me</Text></Box>    
            </a>
        </Box>
    </Box>);
}

function ServingPage() {
    let { project_id } = useParams();
    const [data, setData] = useState<NodeInfo[]>()
    const [wait, setWait] = useState<boolean>()

    const [dialog, setDialog] = useState<any>()
    
    const [newNodeSidebar, setNewNodeSidebar] = useState<boolean>(false);
    const [ searchParams,setSearchParams ]= useSearchParams();
    //const [newModelSourceModel, setNewModelSourceModel] = useState();

    let refresh = () => {
        setWait(true)
        getApi().getNodes().then((nodes: NodeInfo[]) => {
            setData(nodes)
        }).finally(()=>setWait(false))
    };

    useEffect(() => {
        
            refresh()
        
    }, [project_id])

    useEffect(()=>{
        let model_settings_analysis_task= searchParams.get("model_settings_analysis_task")
        if(model_settings_analysis_task){
            setDialog(<ModelSettingsAnalysisDialog taskId={model_settings_analysis_task} onClose={()=>{
                setDialog(undefined)
                searchParams.delete("model_settings_analysis_task")
                setSearchParams(new URLSearchParams(searchParams))
            }}/>)
        }
      },[searchParams])



    function getNewNodeName(){
        if (data){
            let maxNum = data.reduce((maxNum:number, n:any)=>{
                let nodeMax = n.node_name.match(/Node-(\d+)/);
                    let newNum = nodeMax?  parseInt(nodeMax[1]):1
                    return  newNum && newNum>maxNum ? newNum:maxNum;
            },0 ) || 0;
            return `Node-${(maxNum>data.length?maxNum:data.length)+1}`

        }else{
            return "None-1"
        }
    }

    return (
        <Box direction="row" width="100%">
            {dialog}
            <Box fill style={{ overflow: "auto" }}>
                <Box direction='row' justify="between" align='center' >
                    
                    <Box direction='row' flex={false}>

                        <BetterButton primary onClick={() => setNewNodeSidebar(!newNodeSidebar)} label="Creat new node" margin="medium" icon={<Add />} permission="RUN_TRAINING" />
                    </Box>
                    <Box pad="15px" focusIndicator={false} onClick={()=> refresh()} ><Refresh size="22px"/></Box>
                </Box>
                <Heading level={4} margin="small">Serving nodes</Heading>
                <Box>
                    {(wait ||!data)? (
                        <Box align='center'><Spinner size='large'/></Box>
                    )
                    :
                    data && data.map(node => (

                        <NodeBox key={node.node_name} nodeInfo={node} onChange={(newNodeInfo)=>{
                            if (newNodeInfo){
                                var index = data.map(t=>t.node_name).indexOf(newNodeInfo.node_name);

                                if (index !== -1) {
                                    let newData = [...data]
                                    newData[index] = newNodeInfo;
                                    setData(newData);
                                }
                            }else{
                                refresh()
                            }
                        
                        }} />

                    ))}

                </Box>
            </Box>
            {newNodeSidebar && 
            <Collapsible open={newNodeSidebar} direction="horizontal">
                <Box width="700px" background="light-5" height="100%" pad="20px">
                    <Box direction='row' align='center' justify='between'>
                        <Text size="xlarge">Create a new node </Text>
                        <Box onClick={() => setNewNodeSidebar(false)}><FormClose size="25px" /></Box>
                    </Box>
                    <Text size="xsmall">Nodes are servers where you can deploy your models and manage them</Text>
                    <Box margin="20px 0px">
                        <NewNodeForm defaultValue={{
                            node_name: getNewNodeName(),
                            node_type: "CPU",
                            deployment_type: "self-hosted",
                            message:undefined
                        }}
                            onSubmit={() => {
                                setNewNodeSidebar(false)
                                refresh()
                            }}
                        />
                    </Box>

                </Box>
            </Collapsible>}
        </Box>


    );
}


export { ServingPage };
