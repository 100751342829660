import { Box } from "grommet";
import { ProjectCard } from "./ProjectCard";

import { getApi } from "../ApiService";
import { useEffect, useState } from "react";


export const Projects = ({projectsInfo})=> {
    const [projects, setProjects] = useState([])
    useEffect(()=>setProjects(projectsInfo), [projectsInfo])
    function projectOnChange(id){
        
        getApi().getProject(id, projectData => {
            console.log(projectData)
            let newProjects = projects.map(p=> projectData&& p.id===projectData.id? {id: projectData.id, name:projectData.name}: id===p.id?null:p).filter(p=>p!==null)
            console.log(newProjects)
            setProjects(newProjects);
        })
        
        
    }
    return (
        <Box   flex overflow="auto" gap="1px" pad="5px">
        {
            projects.map((proj)=>(

                <ProjectCard key={proj.id} projectInfo={proj} onChange={projectOnChange} />
                

            ))
        }
        </Box>
    );
}