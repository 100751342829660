import {Accordion, AccordionPanel, Box, Button, Header, Select, Spinner, Text, TextInput, Tip} from "grommet"
import { useParams,useNavigate } from 'react-router';



import axios from 'axios';
import { useEffect, useMemo, useState } from "react";
import { Checkmark, Close, Code, Copy, FormDown, FormPreviousLink, FormUp, MoreVertical, Validate } from "grommet-icons";
import { TipIfContent } from "../components/TipIfContent";
import { ModelSettings, NodeAuthorization, NodeSettings } from "../ViewModel/NodeSettings";
import { getApi } from "../ApiService";
import { NodeInfo } from "../ViewModel/NodeInfo";
import { NodeClient } from "../ServingNodeClient/client";
import { PredictionResponse } from "../ServingNodeClient/model";
import { Switch } from "../components/Switch";
import { useQuery } from "../components/_customHooks";
import { useSearchParams } from "react-router-dom";
import { Badge } from "../components/Badge";
import { CodeGenerationDialog } from "../dialogs/CodeGenerationDialog";
import { AutoUI } from "../components/AutoUI/AutoUI";





const  ModelTestPage = ({})=>{
    const {node_name, model_name} = useParams()
    
    const [testQuery, setTestQuery] = useState("");
    const [wait, setWait] = useState(false);
    const [authToken, setAuthToken] = useState(false);
    const [results, setResults] = useState<{ query:string, error?:string, response?:PredictionResponse}[]>([]);
    const navigate=useNavigate();
    const [query, setQueryParams] = useSearchParams();
    const [mode, setMode] = useState<string>("test");
    const [client, setClient] = useState<NodeClient>(null);
    const [models, setModels] = useState<string[]>(null);
    const [defaultModel, setDefaultModel] = useState<string>(null);
    const [dialog, setDialog] = useState<any>();
    
    const [nodeInfo, setNodeInfo] = useState<NodeInfo>(null);
    const [top_k, setTop_k] = useState<number>(1);
    const [queryData, setQueryData] = useState<object>({});
    const [nodeSettings, setNodeSettings] = useState<NodeSettings>(null);

    const model_settings = useMemo(()=>nodeSettings && (model_name||nodeSettings.default_model?nodeSettings.models.find(m=>m.model_name=(model_name||nodeSettings.default_model)): nodeSettings.models[0] ),[nodeSettings,model_name] )
    function onTestClick(query:undefined|string=null){
        query=query||testQuery
        if (!query)
            return;
        setWait(true)
        if (!model_settings){
            return
        }
        let promise=null;
        if (model_settings.task_type=="QuestionAnswering"){
            promise=client.getAnswer([{text:query,...queryData}],top_k ,true, mode=="test").then(result=>{
                setWait(false)
                setResults([{
                    query:query,
                    response:result
                },...results])
                setTestQuery("")
            })
        }
        else{
            promise=client.predict([{text:query,...queryData}],model_name ,true, mode=="test").then(result=>{
                setWait(false)
                setResults([{
                    query:query,
                    response:result
                },...results])
                setTestQuery("")
            })
        }
        
        promise.catch((err)=>{
            setWait(false)
            setResults([{
                query:query,
                error:  
                err.message + 
                (err.response?.data?(
                    "\n" + JSON.stringify(err.response?.data, null,4)
                    ):"")

            },...results])
        })
    }

    
    
    
    useEffect(() => {
        if (query.get("prompt")){
            setTestQuery(query.get("prompt"))
        }
        if (node_name) {
            getApi().getNodeInfo(node_name).then((newNodeInfo)=>{
                setNodeInfo(newNodeInfo)
                getApi().getNodeSettings(node_name).then((nodeData:NodeSettings )=> {
                    setModels(nodeData.models.map((t:ModelSettings)=>t.model_name))
                    setDefaultModel(nodeData?.default_model||nodeData?.models[0]?.model_name)
                    setNodeSettings(nodeData)
                    let  host_url=query.get("host_url") || newNodeInfo.host_url
                    if (host_url&& query.get && nodeData.authorization){
                        let authHeaders = null
                        if (nodeData.authorization.auth_method=="API_KEY" && nodeData.authorization.api_key){
                            authHeaders={access_token: nodeData.authorization.api_key}
                        }
                        else if (authToken && nodeData.authorization.auth_method=="OIDC"){
                            authHeaders={Authorization: `Bearer ${authToken}`}
                        }
                        if (nodeData.authorization.enable_public_access || authHeaders){
                            
                            let client = new NodeClient(host_url,authHeaders)
                            setClient(client)
                        }
                    }
                })
            })
        }
    }, [node_name,query])

    function onCodeShow(){
        setDialog(
        <CodeGenerationDialog
            codeOptions={["shell","Python"]}
            caption={`Code example for interaction with you node - ${mode.toUpperCase()} mode`}
            generate={(language:string)=>{
                if(language=="Python"){
                    let method =null;
                    let top_k_param=""
                    if ( model_settings?.task_type=="QuestionAnswering"){
                        method="get_answer"
                        top_k_param=`
    top_k=${top_k},`
                    }
                    else{
                        method="predict"    
                    }
                    return `#!pip install labelatorio

from labelatorio.serving import NodeClient, PredictionRequestRecord

node_api_key = "${nodeSettings.authorization.api_key}"
host_url = "${query.get("host_url") || nodeInfo.host_url}"

nodeClient = NodeClient(access_token=node_api_key, url=host_url)

response = nodeClient.${method}(query=[
    PredictionRequestRecord(
        text="This a a document with metadata", 
        key=None, #you might want to define your custom unique id
        contextData=None #you might want do pass some metadata alongside the query ( as key-value Dict[str,str] )
        ) ], ${top_k_param}
    test=${mode=="test"?"True":"False"}, #in test mode the query prompt that is handled in manual or model-review mode is not added to the project for review
    explain=${mode=="test"?"True":"False"} #if true, additional explanations of the decision will be provided
)`
                }
                else if(language=="shell"){
                    let method =null;
                    let top_k_param=""
                    if ( model_settings?.task_type=="QuestionAnswering"){
                        method="get-answer"
                        top_k_param=`top_k=${top_k}&`
                    }
                    else{
                        method="predict"    
                    }
                    var authHeader=""
                    if (nodeSettings.authorization.auth_method=="API_KEY"){
                        authHeader=`-H 'access_token: ${nodeSettings.authorization.api_key}' \\`
                    }
                    else if (nodeSettings.authorization.auth_method=="OIDC"){
                        authHeader=`'Authorization: Bearer <insert your auth token>' \\`
                    }
                    else if (nodeSettings.authorization.enable_public_access){
                        authHeader=""
                    }
                    return `curl -X 'POST' \\
  '${query.get("host_url") || nodeInfo.host_url}/${method}?${top_k_param}explain=${mode=="test"}&test=${mode=="test"}' \\
  -H 'accept: application/json' \\
  -H 'Content-Type: application/json' \\
  ${authHeader}
  -d '{
  "texts": [
    {
      "key": null,
      "text": "${testQuery}",
      "contextData": {
      }
    }
  ]
}'`
                }
            }}
            onClose={()=>setDialog(null)}
        />)
    }

    const [nodeUrlInputState, setNodeUrlInputState] = useState<string>()
    const [moreOptions, setModeOptions] = useState(false)

    useEffect(()=>setNodeUrlInputState(query.get("host_url")),[query])
    return (
    <Box fill overflow="auto" pad="20px">
        {dialog}
        <Box flex="grow" gap="5px">
            <Box direction="row" justify="between">
                <Text size="20pt">Test node deployment</Text>
                <Switch size="large" active={mode=="test"?"Test":"Live"} options={["Test","Live"]} onActiveChange={(v)=>setMode(v=="Test"?"test":"live")}/>
            </Box>
            {mode=="live"&&
                <TipIfContent content={<Text>In live mode, when you run the query, if the resulting handling type will be 'manual' or 'model-review', the prompt text will be added into proejct as new record, as it would during production query</Text>}>
                    <Text alignSelf="end" size="xsmall" weight={900}>Query prompts might be added into project<sup>?</sup></Text>
                </TipIfContent>}
        <Box pad="0px 2px" direction="row-responsive">
            <Box  direction="row">
                <Box width="150px">Node:</Box>
                 {node_name}
             </Box>
            
        </Box>
        <Box pad="0px 2px" direction="row-responsive">
            
            <Box  direction="row">
                <Box width="150px">Host url:</Box>
                <Box direction="row" width="630px">
                 {!nodeUrlInputState ? nodeInfo?.host_url :
                 (
                    <TextInput 
                    value={nodeUrlInputState||""} // }
                    placeholder="https://  ... enter the host url" 
                    size="small"
                    style={{padding:"2px"}}
                    onChange={e=>{setNodeUrlInputState(e.target.value )}
                    } 
                    onBlur={()=>setQueryParams({host_url:nodeUrlInputState})}
                    />
                 )}
                 <Box margin="0px 10px" onClick={()=>navigator.clipboard.writeText(query.get("host_url") || nodeInfo?.host_url)} focusIndicator={false}><Copy /> </Box>
                 </Box>
             </Box>
        </Box>
        <Box pad="0px 2px" direction="row-responsive">
            <Box direction="row-responsive" >
            <Box width="150px">Authorization:</Box>
             {nodeSettings?.authorization &&(
            nodeSettings.authorization?.enable_public_access?(
                <Box round background="white" border pad="0px 10px"> 🌍 Publicly available</Box>
            ):
            (
                nodeSettings.authorization?.auth_method=="API_KEY"?(
                    <Box round background="brand" border pad="0px 10px" direction="row" gap="5px" align="center"><Text color="white">🔒 Api key: {nodeSettings.authorization?.api_key && new Array(nodeSettings.authorization?.api_key.length).join( "*" ) }</Text> 
                    <Box onClick={()=>navigator.clipboard.writeText(nodeSettings.authorization?.api_key)} focusIndicator={false}><Copy /> </Box>
                    </Box>
                ):
                (//OIDC
                    <Box round background="pink" pad="0px 10px">🔒 OIDC</Box>
                )
            ))
            }</Box>
            
        </Box>
        <Box pad="0px 2px" direction="row-responsive" align="center">
        <Box width="150px">Model:</Box>
            {models && nodeSettings &&
            <Select  
                style={{padding:"5px", width:"550px", maxWidth:"60vw"}} 
                options={models} 
                // options={models?.map((m:string)=>({value:m, label:`${m}${m==nodeSettings.default_model?" (default)":""}`}))} 
                // valueKey="value" 
                // labelKey="label" 
                value={model_name||defaultModel} 
                onChange={(e, option)=>{
                    
                if (e.target.value==defaultModel){
                    navigate("/serving/test/"+encodeURIComponent(node_name))
                }
                else{
                    navigate("/serving/test/"+encodeURIComponent(node_name)+"/"+encodeURIComponent( e.target.value))
                }
                }} />}
                {defaultModel ==(model_name||defaultModel)&& <Text margin="0px 10px" size="small">(Default)</Text>}
        </Box>
        <Box pad="3vh 5vw">
            <form onSubmit={(e)=>{
                client && onTestClick()
                e.preventDefault()
                }}>
            <Box direction="row" align="start">

                <Box width="80%">

                    <Box border={{color:"brand",size:"2px"}} pad="0.5px"  >
                        <TextInput focusIndicator={false} style={{border:"0px"}} placeholder="Enter your test query" value={testQuery||""} onChange={(e:any)=>setTestQuery(e.target.value as string)}/>
                    </Box>
                    {moreOptions&&(
                        <Box margin="2px" pad="8px" border>
                            <AutoUI value={{top_k:top_k, ...queryData}} onValueChange={(val:any)=>{
                                if (val.top_k){

                                    setTop_k(val.top_k)
                                    delete val.top_k
                                }
                                setQueryData(val)
                            }} size="small" ui_hints={{
                                "key":{
                                    label:"key" ,
                                    tooltip:"Number of top answers that should be returned"
                                },
                                "contextData":{
                                    label:"Context data",
                                    type:"Dict<string,string>",
                                    tooltip:"Context data that will be added to query"
                                },
                                "reviewProjectId":{
                                  label:"reviewProjectId"  
                                },
                                ...(model_settings?.task_type=="QuestionAnswering"?{
                                    top_k:{
                                        type:"int",
                                        label:"top_k",
                                        tooltip:"Number of top answers that should be returned"
                                    }
                                }:{

                                })
                            }}/>
                            
                        </Box>
                    )}
                </Box>
                <Button disabled={!client} margin="0px 0px 0px -2px" secondary style={{borderRadius:"0px", width:"90px"}} label={<Box align="center" gap="2px" direction="row">{moreOptions?<FormUp/>:<FormDown/>}<Text size="xsmall">more</Text></Box>} onClick={()=>setModeOptions(!moreOptions)}/>
                <Button disabled={!client} margin="0px 0px 0px -2px" secondary style={{borderRadius:"0px", width:"90px"}} label={<Box align="center" gap="xsmall" direction="row"><Code/><Text size="xsmall">Code</Text></Box>} onClick={()=>onCodeShow()}/>
                <Button disabled={!client} primary style={{borderRadius:"0px  20px 20px 0px", width:"80px", borderWidth:"3px"}} label={mode=="test"?"Test":"Run"} onClick={()=>onTestClick()}/>
            </Box>
                </form>
                {!(query.get("host_url") || nodeInfo?.host_url)&&<Text alignSelf="center" color="red">No url set for this node... unable to test node </Text>}
                {!nodeSettings?.authorization&&<Text alignSelf="center" color="red">No authorization set... unable to test node</Text>}
        </Box>
        {wait && <Box align="center" flex={false}><Spinner size="large"/></Box>}
        <Box pad="medium">
            {results?.length ? <Text>Results</Text>:<></>}
            {results.map((result,i)=>(
                <Box key={i}>
                {result.response?(
                    <Box  border round="small" margin="5px" pad="10px" align="start" gap="5px" >
                        <Box pad="0px 0px 10px 0px" direction="row" justify="between" width="100%" align="start">
                        <Text  style={{maxWidth:"80vw", wordBreak:"break-all",textOverflow:"ellipsis", fontFamily:"monospace" }}>
                                {result.query}
                            {/* <pre style={{maxWidth:"80vw", wordBreak:"break-all",textOverflow:"ellipsis" }}>
                            </pre> */}
                            </Text>
                        <Badge value="Retry" onClick={()=>{
                            onTestClick(result.query)
                        }}/>
                        </Box>
                        
                        <Box direction="row" gap="small" align="center">
                            <Text weight={600} size="small">Predictions: </Text>
                            {result.response.predictions && result.response.predictions[0]?.predicted ?(
                                <>
                                    {result.response?.predictions[0]?.predicted[0]?.label &&result.response.predictions[0].predicted.map((p,i)=>(
                                        <Box key={i} pad="2px 5px" round border background={`rgba(0,0,0,${p.score})`}> {p.label}:{Math.floor(p.score*100)}% </Box>
                                    ))}
                                     <TipIfContent content="To better understand the predictions, you try to reevaluate labels on most similar data that the model was trained on. If there are not enough supporting examples with correct label, the model will not give you correct result for this kind of records.">
                                                <Box align="start"  margin="-2px 5px">
                                                    <Text size="xsmall">

                                                        <a href="/" onClick={(e) => {

                                                            let project_id =( ((nodeSettings.models).find(m => m.model_name == (model_name || defaultModel))) || nodeSettings.models[0]).project_id
                                                            window.open(`${window.location.origin}/${project_id}/texts?similar_to_phrase=` + encodeURIComponent(result.query), '_blank');
                                                            e.preventDefault()

                                                        }
                                                        }>Help me understand the results</a>
                                                    </Text>

                                                </Box>
                                            </TipIfContent>
                                    </>

                            ):(<Badge value="null"/>) }
                        </Box>
                        {result.response?.predictions && result.response?.predictions[0].predicted && result.response?.predictions[0].predicted[0]?.answer && <Box>
                            {result.response?.predictions[0].predicted?.map(p=>(
                                <Box direction="row" gap="small" margin="5px">
                                    <Box flex={false} width="45px" alignSelf="start" align="center" key={i} pad="2px 5px" round border background={`rgba(0,0,0,${p.score})`}> <Text size="small">{Math.floor(p.score*100)}% </Text></Box>
                                    {p.answer}
                                </Box>
                            ))}
                        </Box>
                        }
                        <Box direction="row" gap="small" align="center">
                            
                            <Text weight={600} size="small">Handling: </Text>
                             <Box pad="2px 8px" background="brand" round border >                 
                                <Text>{result.response.predictions[0].handling}</Text>
                            </Box>
                        </Box>
                        
                        {result.response.predictions[0].explanations && (
                            <Accordion width="100%">
                            <AccordionPanel label="Routing explanation">
                            {result.response.predictions[0].explanations.map((route_explanation:any, i:number)=>(
                                route_explanation.retrieved_total?(
                                    <Box>
                                          <Text>Retrieved total: {route_explanation.retrieved_total}</Text>
                                          <Box>
                                          {route_explanation.matched_data?.map((example:any)=>(
                                            <Box direction="row" align="start" margin="5px 2px" gap="small">
                                                <Badge value={<Text size="small">{Math.floor(example.similarity_score*100)}-&gt;{Math.floor(example.relevancy_score*100)}</Text> }/>
                                                <Box>
                                                <Text>Q: {example.text}</Text>
                                                <Text>A: {example.answer}</Text>
                                                </Box>
                                            </Box>
                                          ))}
                                          </Box>
                                    </Box>
                                ):(

                                <Box key={i} flex="shrink" margin="10px" gap="2px" justify="center">
                                    <Box direction="row" gap="small"><Text><b>Route-{route_explanation.route_id+1}</b></Text>
                                    {route_explanation.matched && <Box border background={route_explanation.used?"brand":undefined} flex={false} round pad="0px 5px">Matched</Box>}
                                    
                                    </Box >
                                    <Box direction="row" gap="small"  margin="0px 20px">
                                        <Text>Type: <b>{route_explanation.route_type}</b></Text>
                                        <Text>Handling: <b>{route_explanation.route_handling}</b></Text>

                                    </Box>
                                    <Text>{route_explanation.matched}</Text>
                                    {/* <Text>{route_explanation.matched_similar_example?.text}</Text> */}
                                    {(route_explanation.matched_regex!==null &&route_explanation.matched_regex!==undefined) && 
                                        <Box direction="row" flex={false} justify="start"  gap="5px" margin="0px 20px">
                                            <Badge  
                                            value={"`Regex match: " + route_explanation.matched_regex} 
                                            background={route_explanation.matched_regex?(route_explanation.matched?"brand":"orange"):undefined} 
                                            />
                                        </Box>
                                        }
                                    {route_explanation?.matched_similar_examples?.length&&(
                                    <Box direction="row" gap="5px" pad="2px 25px">
                                        <Box>
                                            <Text size="small">Similar</Text>
                                        </Box>
                                    <Box>
                                        {route_explanation?.matched_similar_examples.map((sim_example:{text:string,score:number,similarity_score:number},i:number) =>
                                        <Box key={i} direction="row" flex={false} justify="start"  gap="5px" margin="0px 20px">
                                            <Badge  
                                                value={`${!route_explanation?.matched_similar? "Closest:":"Match:"} ${Math.floor((sim_example.score||sim_example.similarity_score)*100)} % `} 
                                                background={route_explanation.matched_similar?(route_explanation.matched?"brand":"orange"):undefined} />
                                            {/* <Box border flex={false} background={route_explanation.matched?"brand":undefined} round pad="0px 5px">{!route_explanation.matched_similar? "Closest: ":"Match: " }{Math.floor(route_explanation.matched_similarity_score*100)} % </Box>: */}
                                            <TipIfContent content={<Text size="small">{sim_example.text}</Text>}>
                                                <Text size="small" style={{textOverflow:"ellipsis", overflow:"hidden", whiteSpace: "nowrap" }}>{sim_example.text}</Text>
                                            </TipIfContent>
                                            {/* {route_explanation?.matched_similar_example?.labels?.map((exampleLabel:string, i:number)=>
                                                <Badge  
                                                    key={i}
                                                    icon={route_explanation?.matched_similar_example?.correctly_predicted?<Checkmark/>:<Close/>}
                                                    tooltip={route_explanation.matched_similar_example?.correctly_predicted?"Predicted correctly":"Labels were not predicted correctly during model evaluation"}
                                                    value={exampleLabel} 
                                                    //background={route_explanation.matched_similar_example.correctly_predicted?"brand":undefined}
                                                />
                                            )} */}
                                        </Box>
                                        )}
                                    </Box>
                                    </Box>
                                    )}
                                    {route_explanation.matched_prediction && route_explanation.matched_prediction.filter&&(
                                        <Box direction="row" gap="5px" pad="2px 25px">
                                            <Box>
                                                <Text size="xsmall">Predictions</Text>
                                            </Box>
                                            <Box direction="row" gap="5px" pad="2px 25px" wrap>
                                                {route_explanation.matched_prediction.filter((p:any)=>p.prediction.score>0.05).map((p:any,i:number)=>(
                                                    <Badge key={i} value={`${p.prediction.label} ${Math.round(p.prediction.score*100)}%`} background={p.matched?(route_explanation.matched?"brand":"orange"):undefined}/>
                                                    ))}

                                            </Box>  
                                        </Box>
                                    )}

                                </Box>
                                )
                            ))}
                            </AccordionPanel>
                            </Accordion>
                        )} 
                    </Box>
                    ):
                    (<Box  background="lightgray" margin="5px" pad="5px" round="xsmall"><Text size="small" weight={700}>Query: {result.query}</Text> <Text size="small">{<pre>{result.error}</pre>}</Text></Box>)
                    }
                </Box>
            ))}
        </Box>
        
        </Box>
    </Box>)
}

export {ModelTestPage}