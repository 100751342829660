import { Spinner,Box,Text, Button } from "grommet"
import { FormUp } from "grommet-icons";
import { useMemo, useState,forwardRef, useEffect } from "react";
import { Link } from 'react-router-dom';
import "./scrollToTopButton.css";


const ScrollToTopButton= ({reference})=>{
    const [visible, setVisible] =useState(false)
    function scrollToTop(){
        if (reference?.current){
            reference.current.scroll({
                top:0,
                behavior: 'smooth'
              })
        }
    }
    useEffect(()=>{
        if (reference){

            reference.current?.addEventListener('scroll', (e) => {
                if (reference.current){
                    setVisible(reference.current.scrollTop > 400)
                }
                
            })
        }
    },[reference])
    
    return (
        <Box border background="white" elevation="medium" className={`scrollToTopBtn ${visible?"scrollToTopBtnVisible":"scrollToTopBtnHidden"}`} onClick={()=>scrollToTop()}>
            <FormUp  size="35px"/>   
        </Box>
    )

}

export default ScrollToTopButton;