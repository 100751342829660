import { Box, TextInput, Text,  DropButton, Tip, Button } from 'grommet'
import { Search, Filter, FormClose  } from "grommet-icons";
import { useState, useEffect } from 'react'
import { useDebounce } from "../_customHooks"
import { useNavigate, useSearchParams } from "react-router-dom";

import { Badge } from '../Badge';
import { SpecialTokenTag } from './specialTokenTag';
import { SearchBarParams } from './searchBarParams';
import { FilterDropButton } from './filterDropButton';
import { ExpandingButton } from '../ExpandingButton/expandingButton';
import { AiSearch, Keyword } from './searchTypeIcon';
import { TipIfContent } from '../TipIfContent';
import "./searchBarStyles.css"


function SearchBar({ onChange, placeholderText, specialTokensRegexParsers, preddefinedFilters,suggestTokenValue, disableSyncWithUrl, semanticSearchFilterKey, margin="15px", size=undefined }: SearchBarParams) {

    const [specialTokens, setSpecialTokens] = useState< { [key: string]: string }>({});
    
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();    
    
    const [inputText, setInputText] = useState<string|undefined>();


    const [filterNotActual, setFilterNotActual] = useState(false);
    const [semanticSearchActive, setSemanticSearchActive] = useState(false);
    

    const [tokenInEditMode, setTokenInEditMode] = useState<string>();

    const debouncedValue = useDebounce(inputText, 500);


    //#region suggestions
    const allSuggestions=specialTokensRegexParsers? Object.getOwnPropertyNames(specialTokensRegexParsers).map(s=>s) : [];
    const [suggestions, setSuggestions] = useState<any>(allSuggestions);
    //useEffect(()=>setSuggestions(allSuggestions),[allSuggestions])
    function suggest(currentValue:string){
        if(!currentValue&&allSuggestions){
            setSuggestions(allSuggestions)
        }
        else{

            setSuggestions(allSuggestions.filter(s=>s.startsWith(currentValue) && specialTokens[s] ===undefined))
        }
    }
    //#endregion
    

    useEffect(()=>{
        if (!disableSyncWithUrl){
            let newTokens:any = {};
            let _semanticSearchActive=false;
            if (semanticSearchFilterKey){
                _semanticSearchActive= (!! searchParams.get(semanticSearchFilterKey))
                setSemanticSearchActive(_semanticSearchActive)
                
            }
            let searchTerm=searchParams.get("q");
            if (_semanticSearchActive){
                searchTerm=searchParams.get(semanticSearchFilterKey)
                newTokens[semanticSearchFilterKey]=searchTerm
            }
            if (specialTokensRegexParsers){
                let knownFilters = new Set(Object.getOwnPropertyNames(specialTokensRegexParsers))
                searchParams.forEach((value,key)=>{

                    if (knownFilters.has(key) || key.startsWith("@")){
                        newTokens[key] =  value
                    }
                })

                
                
            }  

            let wasChange=false
            if ((searchTerm||"")!==(inputText||"")){
                wasChange=true
                setInputText(searchTerm||"")
            }
            if (JSON.stringify(newTokens) !== JSON.stringify(specialTokens))
            {
                setSpecialTokens(newTokens);
                wasChange=true
            }
            if (wasChange||inputText===undefined /*this mean its first initialization*/ ){
                if (inputText===undefined) setInputText(searchTerm||"")
                applySearch(searchTerm, newTokens, _semanticSearchActive);
            }
        }
            
    },[searchParams])

    

    useEffect(()=>{disableSyncWithUrl && applySearch()},[])

    //parsing text input
    useEffect(() => {
        let newTokens = Object.assign({}, specialTokens);

        
            let searchTerm=debouncedValue;
            if (specialTokensRegexParsers){
                for (let key of Object.getOwnPropertyNames(specialTokensRegexParsers)){

                //Parsing special expression from searchText:
                    let re = specialTokensRegexParsers[key]
                    let found = re.exec(searchTerm)
                    if (found){
                        searchTerm=searchTerm.replace(found[0],"");
                        newTokens[key] = found[1];
                        //console.log("found:" + found[1]);
                        setTokenInEditMode(key);
                    }
                }
            }
            if (searchTerm!==debouncedValue){
                setInputText(searchTerm||"")
            }
            if (new URLSearchParams(newTokens).toString() !==new URLSearchParams(specialTokens).toString() ){
                setSpecialTokens(newTokens);
            }
            
            setFilterNotActual(getSearchParms().toString()!=(new URLSearchParams(document.location.search)).toString())
        
    
    }, [debouncedValue, specialTokens]);

    // useEffect(()=>{
    //     if ( getSearchParms().toString()!=(new URLSearchParams(document.location.search)).toString() ){
    //         applySearch()
    //     }
    // },[semanticSearchActive])

    const [hasFocus, setHasFocus] = useState(false)
    function onLeave(){
        if (disableSyncWithUrl || (inputText||"")!=(searchParams.get("q")||""))
        {
            applySearch()
        } 
    }

    function addOrUpdateSpecialToken(token:string,value:string){
        let newTokens = Object.assign({}, specialTokens)
        newTokens[token] = value;
        setSpecialTokens(newTokens)
        applySearch(inputText,newTokens)
    }

    function removeSpecialToken(token:any){
        let newTokens = Object.assign({}, specialTokens)
        delete newTokens[token]
        if (!disableSyncWithUrl && searchParams.has(token)){
          let newUrlQueryParams = new URLSearchParams(document.location.search)
          newUrlQueryParams.delete(token);
          !disableSyncWithUrl && setSearchParams(newUrlQueryParams);
          applySearch(inputText,newTokens)
        } 
        setSpecialTokens(newTokens)
        
    }

    function getSearchParms(searchTerm:string|undefined = undefined, filterTokens:any= undefined,isSemanticSearchActive:boolean|undefined=undefined):URLSearchParams{
        /// set new url
        searchTerm = searchTerm==undefined?inputText:searchTerm
        filterTokens = filterTokens||specialTokens||{}
        isSemanticSearchActive=isSemanticSearchActive===undefined?semanticSearchActive:isSemanticSearchActive
        let newUrlQueryParams = new URLSearchParams(document.location.search)
        
        Object.getOwnPropertyNames(filterTokens).forEach(key=> newUrlQueryParams.set(key,filterTokens[key]));
        //console.log("urlQueryParams:" + urlQueryParams  )
        //console.log("newUrlQueryParams" + newUrlQueryParams  )
        newUrlQueryParams.delete("q")
        semanticSearchFilterKey && newUrlQueryParams.delete(semanticSearchFilterKey)
        if (searchTerm){
            if (!isSemanticSearchActive){
                newUrlQueryParams.set("q", searchTerm)
            }
            else{
                newUrlQueryParams.set(semanticSearchFilterKey, searchTerm)
            }
        }
        
        return newUrlQueryParams;
    }
    function applySearch(searchTerm:string|undefined = undefined, filterTokens:any= undefined, isSemanticSearchActive:boolean|undefined=undefined){
        searchTerm = searchTerm===undefined? inputText:searchTerm;
        isSemanticSearchActive=isSemanticSearchActive===undefined?semanticSearchActive:isSemanticSearchActive

        const newUrlQueryParams=getSearchParms(searchTerm,filterTokens,isSemanticSearchActive);
        if (!disableSyncWithUrl && searchParams.toString()!==newUrlQueryParams.toString()){
          //console.log("goto: "+newUrlQueryParams.toString());
          navigate("?"+newUrlQueryParams.toString());
        }
        if (isSemanticSearchActive && searchTerm){
            if (!filterTokens){
                filterTokens={}
            }
            filterTokens[semanticSearchFilterKey]=searchTerm
            searchTerm=""
        }
        filterTokens && setSpecialTokens(filterTokens);

        onChange(searchTerm, filterTokens||specialTokens);
        setFilterNotActual(false);
      }
    
    useEffect(()=>suggest(inputText),[inputText])

    return (
        <Box margin={margin} >
        <Box
            round="18px"
            wrap
            elevation='small'
            background={{ color: "light-1", opacity: "strong" }}
            direction="row"
            align="center"
            //pad={{ horizontal: "medium", vertical: "xxsmall" }}
            pad="2px 4px"
            //height="35px"
            gap="2px"
            
        >
            <Box round direction='row'
             background={filterNotActual?"brand":undefined} focusIndicator={false} onClick={() => applySearch()} pad="2px" align='center'>
                <Search color={!filterNotActual?"brand":"white"} />
                {(filterNotActual && !hasFocus)?(<Text size="xsmall">Apply</Text>):(<></>)}
            </Box>
                
            <Box flex= {{"grow":10000}} direction="row" align='center' >
                    {(semanticSearchFilterKey&& inputText)?(
                        
                        (semanticSearchActive?(
                        <TipIfContent content={<Text>AI powered seamntic search</Text>} ><Button onClick={()=>applySearch(inputText, specialTokens,false)}><AiSearch/></Button></TipIfContent>)
                        :
                        <TipIfContent content={<Text>Clasical sub-text and keyword search (like: *)</Text>} ><Button onClick={()=>applySearch(inputText, specialTokens,true)}><Keyword/></Button></TipIfContent>)
                        
                    ):<Box pad={semanticSearchFilterKey?"8px":undefined}></Box>}
                    <TextInput plain placeholder={placeholderText||"Search"} type="search" height="auto" size={size}
                        className='searchInput'
                        onFocus={()=>setHasFocus(true)} 
                        onBlur={(e)=>e.relatedTarget?.tagName!=="BUTTON" && setHasFocus(false)} 
                        focusIndicator={false}
                        
                        onKeyDown={(e)=> {
                            if (e.key=="Enter" ){
                                applySearch(inputText, specialTokens)
                            }
                            else if (e.code === 'Tab') {
                                    if (suggestions?.length){
                                        setInputText(suggestions[0]+":")
                                    }
                                    e.preventDefault()
                            }
                            

                        }}
                        value={inputText||""}
                        onChange={event => {
                                setInputText(event.target.value as string||"") 
                                
                            }
                        } 
                        //onBlur={onLeave}
                        //onSuggestionSelect={e => setInputText(e.suggestion.value + ":")}
                        //suggestions={suggestions?.map((s:any)=>({label:(<Box  direction='row' pad="2px 5px"><Badge icon={<Filter size="small"/>} size="xsmall" value={s+": ..."}/></Box>),value:s}))} 
                    />

                    {inputText?( 
                    <Button onClick={()=>{
                        setInputText("")
                        applySearch("",specialTokens)
                    }}>
                        <FormClose/>
                    </Button>
                    ):(
                        <></>
                    )}
                    
            </Box>
               
               
            <Box flex="grow" justify="end" >

            {preddefinedFilters &&
                (
                    <FilterDropButton 
                    preddefinedFilters={preddefinedFilters} 
                    filterSuggestions={allSuggestions}
                    onFilterSelected={(filterObj:any)=>{
                        setTimeout(()=>{

                        
                            let newTokens = Object.assign({},specialTokens); //copy current falue of tokens
                            newTokens = Object.assign(newTokens,filterObj); //Assing values of selected filterS
                            setSpecialTokens(newTokens);
                            let undefinedFilterKey = Object.keys(filterObj).find(k=>!filterObj[k])
                            if (!undefinedFilterKey){
                                applySearch(inputText, newTokens);
                            }
                            else{
                                setTokenInEditMode(undefinedFilterKey)
                                
                            }
                        },50)
                    }} 
                    count={specialTokens&&Object.getOwnPropertyNames(specialTokens).filter(t=>specialTokensRegexParsers && Object.getOwnPropertyNames(specialTokensRegexParsers).includes(t)).length}
                    >
                        <Box overflow="hidden" flex="shrink" direction="row"  >

                            {Object.getOwnPropertyNames(specialTokens).filter(t=>specialTokensRegexParsers && Object.getOwnPropertyNames(specialTokensRegexParsers).includes(t)).map(key =>
                                <SpecialTokenTag 
                                    key={key}   
                                    name={key} 
                                    disableSyncWithUrl={disableSyncWithUrl}
                                    value={specialTokens[key]} 
                                    editMode={tokenInEditMode==key}
                                    suggestTokenValue={suggestTokenValue}
                                    onEditFinished ={(value,key)=>{
                                        if(!value?.length){
                                            removeSpecialToken(key)
                                        }
                                        else{
                                            addOrUpdateSpecialToken(key,value);
                                            setTokenInEditMode(undefined)
                                        }
                                    }}
                                    onRemove={() => removeSpecialToken(key)} />)}

                            </Box>
                    </FilterDropButton>
                    )
                }
            </Box>

        </Box>
        {inputText&& semanticSearchFilterKey && (
            !semanticSearchActive?(
                    <Box direction='row' margin="2px 0px -5px 38px" gap="xsmall">
                        <Text size='xsmall' color="brand">Hint:</Text>
                        <Box onClick={()=>{applySearch(inputText, specialTokens,true)}} focusIndicator={false}>
                            <Text size='xsmall' color="brand" style={{textDecoration:"underline"}}>Try 🧠 AI powered semantic search</Text>
                        </Box>
                    </Box>
        ):(
            <Box direction='row' margin="2px 0px -5px 38px" gap="xsmall">
                        <Text size='xsmall' color="brand">Using 🧠 AI powered semantic search</Text>
                        
                            <ExpandingButton
                            border={false}
                            height="10px"
                            margin="-4px 0px"
                            
                            onClick={()=>{applySearch(inputText, specialTokens,false)}}
                            collapsedContent={
                                <Text size='xsmall' color="brand" style={{textDecoration:"underline"}}>Switch back</Text>
                            }
                            expandedContent={
                                <Text size='xsmall' color="brand" style={{textDecoration:"underline"}}>Switch back to traditional search </Text>
                            }
                            />
                        
                    </Box>
        )
        )
                }
        </Box>



    );
}


export {SearchBar};