import {React, useState, useEffect} from "react";
import { Box, Text, Meter, Stack, Button, Spinner} from "grommet";
import HumanizeDuration from 'react-humanize-duration';
import { Checkmark ,FormClose, Stop} from "grommet-icons";
import Moment from 'react-moment';
import { getApp } from "firebase/app";
import { getApi } from "../ApiService";
import { useAppContext } from "../AppContext";
import { useNavigate, useParams } from "react-router-dom";

export const TaskBox = ({task, onDeleted, onRefreshRequested}) => {

    function downloadURI(uri, name) {
        let link = document.createElement("a");
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    const navigate = useNavigate();
    
    const {project_id} = useParams();
    function handleAction(actionId, parameters){
        if (actionId=="download"){
            downloadURI(parameters.download_url)
        }
        else if (actionId=="view"){
            if (parameters.model_id){
                
                navigate(`/${parameters.project_id}/models/${parameters.model_id}`)
            }
            else if (parameters.project_id){
                
                navigate(`/${parameters.project_id}/texts/`)
            }
        }
        else if (actionId=="view_model_settings_analysis"){
            navigate(`/serving?model_settings_analysis_task=${parameters.task_id}`)

        }
    }
    const {projectInfo, testPermission }= useAppContext();
    function getStateColor(state){
        if (state==="RUNNING") return "accent-3"
        if (state==="SUCCESS") return "status-ok"
        if (state==="FINISHED") return "status-ok"
        if (state==="ERROR" ||state=== "FAILURE") return "status-error"
        return "status-unknown"
    }
    
    const [mouseOver,setMouseOver] = useState();
    const [updating,setUpdating] = useState();
    useEffect(()=>setUpdating(false),[task])
    return(
        // <Box dir="column">
     <Stack anchor="top-right" onMouseEnter={()=>setMouseOver(true)} onMouseLeave={()=>setMouseOver(false)}>
        <Box>
        <Box margin="small" pad="5px" round="small" background="dark-3" direction="row"  style={{display:"inline"}} >
            <Box   alignSelf="end" style={{float:"right",  fontSize:"9pt", whiteSpace:"nowrap", marginBottom:"-2px", paddingLeft:"5px", paddingRight:"5px"}} border round="small" background={{color:getStateColor(task.state)}} > {task.state}  </Box>
            <Text size="small">{task.task_name}</Text> 
        <Box margin={{top:"5px"}}>
            <Box justify="between" direction="row"   style={{fontSize:"8pt", marginBottom:"-5px"}}  pad="0px" >
                {task.result || task.current_subtask}
            </Box>
            <Box pad="10px">
            {task.result_actions && task.result_actions.slice(0,1).map((action,i)=>(
                <Box>
                    <Button key={i} size="small" primary label={ action.caption} onClick={()=>handleAction(action.actionId, action.parameters)}/>
                </Box>
            ))}
            </Box>
        
        {
            task.error_msg?(<Text size="xsmall" truncate="tip" >{task.error_msg}</Text>):
            task.duration_sec>0  &&(
                <Box justify="between" direction="row"   style={{fontSize:"8pt", marginBottom:"-5px"}}  pad="0px" >
                    <Moment utc fromNow>{task.start_time}</Moment>
                    <Box direction="row" ><HumanizeDuration duration={ task.duration_sec*1000}/></Box>
                    
                </Box>
                )
            }
            
            {
        task.progress_total &&  task.progress_current!== task.progress_total ? (
            <Meter thickness='5px' background="dark-2" values={[{
                            value: 100 * task.progress_current / task.progress_total,
                            }]}> </Meter>

        ):(<></>)
        } 
    </Box>
    </Box>
    </Box>
        {updating&&(
             <Box border round background="lightgray" >
                <Spinner  size="3px" />
            </Box>
        )}
        {mouseOver &&!updating && task.state!=="RUNNING" && testPermission("MODIFY_PROJECT") && <Box border round background="brand" onClick={()=>{
            getApi().deleteTask(task.task_id).then(()=>onDeleted && onDeleted() )
        }}><FormClose/></Box>}
         {mouseOver && !updating && ( task.state=="RUNNING" ) && testPermission("MODIFY_PROJECT") && 
         <Box border round background="brand" direction="row" align="center" justify="center" pad="0px 5px"
          onClick={()=>{
            
            getApi().stopTask(task.task_id).then(t=>{
                setUpdating(true)
                onRefreshRequested()
            })
        }}><Stop/><Text size="small">Stop</Text></Box>}
    </Stack>
        )
};


