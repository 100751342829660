

import { useState, useEffect, useMemo } from 'react';
import { Box, Form, TextInput, FormField, Button, Heading, Grommet, grommet, Text, Tip, CheckBox, Select, Spinner, Layer } from 'grommet';
import {Play,Clone,LinkPrevious, FormClock, Copy, FormClose, StatusGood, Clear, Edit, Close, Alert } from "grommet-icons"
import moment from 'moment';
import { getApi } from '../../ApiService';
import { useAppContext } from '../../AppContext';
import { ExpandingButton } from '../ExpandingButton/expandingButton';

import { Badge } from '../Badge';
import Moment from 'react-moment';
import { DateRangePicker } from '../DateRangePicker/dateRangePicker';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    TimeSeriesScale,
    Tooltip
  } from 'chart.js';
import 'chartjs-adapter-moment';
ChartJS.register(TimeSeriesScale, Tooltip);


export const UsersSessions=({user_id})=>{

    const [dateRange, setDateRange] = useState()
    const [data, setData] = useState()
    function load(){
        if (dateRange){
            getApi().getUserSessions(user_id, dateRange.date_from, dateRange.date_to)
            .then(newData=>setData(newData.map(current=>{
                current.startDate = current.start?new Date(current.start):undefined
                current.endDate = current.end?new Date(current.end):undefined
                current.labeledPerHour =  Math.round(current.startDate&&current.endDate && current.labeled_count ? (current.labeled_count )/ (Math.max(current.endDate - current.startDate,60*1000) /1000/60/60) : current.labeled_count ||0 )
                return current
            })))
        }
    }

    const chartData = useMemo(()=>{
        if(data){
            let items = data.reduce((previous,current)=>{
                let startDate = current.startDate;
                let endDate = current.endDate;
                let labeledPerHour = current.labeledPerHour;
                if (previous[0]){
                    return [
                        ...previous,
                        {x:startDate, y:labeledPerHour},
                        {x: new Date(endDate.setMilliseconds(0)), y: labeledPerHour},
                        {x:new Date(endDate.setMilliseconds(0)+1), y: null}
                    ]
                }
                else{
                    return [  
                        {x:new Date(startDate.setMilliseconds(0)-1), y: null},
                        {x:startDate, y:labeledPerHour},
                        {x: new Date(endDate.setMilliseconds(0)), y: labeledPerHour},
                        {x:new Date(endDate.setMilliseconds(0)+1), y: null}
                    ]
                }
            },[]);
            let total = data.reduce((previous,current)=> {
                let startDate = new Date(current.start)
                let endDate = new Date(current.end)
                return ({ 
                duration: (previous.duration + (endDate - startDate) /1000/60/60),
                labeled: previous.labeled+current.labeled_count
            })},{duration:0,labeled:0})
            return {items,total};
        }
    },[data])

    useEffect(()=>{
        if(user_id && dateRange){
            load()
        }
    },[user_id, dateRange])

    return (<Box width="100%">
        <Box direction='row'>
        <DateRangePicker onRangeChanged={({since,until})=>{
            setDateRange({date_from:since,date_to:until})
        }} />
        </Box>
       { 
       chartData && <Line
       height="50px"
       width="300px"
        data={{ datasets: [
            {
                data:chartData.items,
                //stepped: "after",
                pointRadius: 0,
                pointHoverRadius: 0,
                fill:"origin",
                borderWidth:10,
                borderColor:"red"
                //backgroundColor:"rgb(200,200,255)"
            }
        ] }}
        options={
            { 
                responsive: true,
                interaction: {
                    intersect: false,
                    axis: 'x'
                },
                scales: {
                    x: {
                        type: 'time',
                        time: {
                            // Luxon format string
                            //tooltipFormat: 'DD T',
                            //unit: 'day'
                        
                          },
                        ticks: {
                            autoSkip: false,
                            maxRotation: 0,
                            major: {
                              enabled: true
                            },
                            // color: function(context) {
                            //   return context.tick && context.tick.major ? '#FF0000' : 'rgba(0,0,0,0.1)';
                            // },
                            font: function(context) {
                              if (context.tick && context.tick.major) {
                                return {
                                  weight: 'bold',
                                };
                              }
                            
                          }
                        },
                    },
                    y:{
                        title: {
                            display: true,
                            text: 'Labeled / hour'
                          }
                    }
                }
            }
        }
        />}
        <Box margin="10px 0px">
            <Text>Sessions</Text>
        <Box style={{maxHeight:"30vh"}} align='start' overflow="auto">
            {data?.map((rec,i)=>(
               
                    <Box  key={i} direction='row' gap="xsmall" round margin="2px" border pad="0px 10px" flex={false} >
                     <Moment local format="YYYY-MM-DD HH:mm">{rec["start"]}</Moment>- <Moment local format="HH:mm">{rec["end"]}</Moment>
                     {rec["start"]!==rec["end"] ?  (<>(
                         {/* { moment.duration(rec.end.diff(rec.start)).humanize()}) */}
                         <Moment duration={rec["start"]} date={rec["end"]}/>)
                         </>) :"0:00"  }
                     <Text>{rec.labeled_count} records labeled </Text>
                     {rec.labeled_count ? <Text>({rec.labeledPerHour} / h)</Text>:<></>}
                     {rec.client_ip_conflict&&(
                        <Box margin="0px 5px">
                        <Tip content="Client IP conflict">
                            <Alert color="red"/>
                        </Tip>
                        </Box>
                     )}
                    </Box>
               
            ))}
        </Box>
        </Box>
        {chartData&&
        <Box>
        
            <Text>Total labeled {chartData.total.labeled}</Text>
            <Text>Total duration {parseInt(chartData.total.duration) }h {Math.round((chartData.total.duration-parseInt(chartData.total.duration))*60).toString().padStart(2,"0")}min</Text>
        </Box>}
    </Box>)
}



export const UsersSessionsDialog =({  user, onClose})=>{

    

     return (<Layer>
    <Box pad="large" align='start'  gap='medium' width="900px" style={{maxWidth:"90vw"}}>
    
    <Box direction="row" justify='between' width="100%">
      
        <Text weight={800} size="15pt">{user.login} sessions</Text>
      <Close style={{float:"right"}} onClick={onClose}/>
    </Box>
        <UsersSessions user_id={user.id}/>

    </Box>
</Layer>)
}