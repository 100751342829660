import { Link, useParams, useSearchParams} from 'react-router-dom';
import {  Box, Button,Text, Heading ,NameValueList,NameValuePair, Tag, TextInput, DataTable, Spinner} from 'grommet'

import {Add, FormClose, Grommet} from "grommet-icons"
import { useState, useEffect } from "react";
import zoomPlugin from 'chartjs-plugin-zoom';
import { getApi } from '../ApiService';
import {MetricCard} from "../components/MetricCard"
import {
  CategoryScale,
  Chart as ChartJS,
  
  LineElement,
  
  Tooltip,
  
} from 'chart.js';

import { Line } from 'react-chartjs-2';
import {randomColor} from "randomcolor";

import { useAppContext } from '../AppContext';
import ModelEvaluation from '../components/ModelEvaluation/ModelEvaluation';
import { ModelAutocomplete } from '../components/ModelAutocomplete';
ChartJS.register( CategoryScale, LineElement, Tooltip);


function CompareWithBadge({projectInfo, model, onChange}){
  const [editMode,setEditMode] = useState();
  

  

  return model&&!editMode ? ( 
    <Box flex={false} direction="row" gap="5px"  round alignSelf='start' pad="5px 10px" background="brand"
    onClick={editMode ?undefined:()=>setEditMode(true)}
    >  
      <Text weight={700}>Compared with: </Text>
      <Text  ><a style={{color:"white"}} href={document.location.href.split("/models/")[0]+'/models/'+model.id}>{model.model_name}</a></Text>
    </Box>
  ):(
    <Box flex={false} 
    focusIndicator={false}
    direction="row" gap="5px" border={{color:"brand"}}  round alignSelf='start' pad="5px 10px" background="white"  
    onClick={editMode ?undefined:()=>setEditMode(true)}
    onBlur={(e)=>{
      if(e.target.tagName!=="INPUT"){
        setEditMode(false)
      }
    }} 
    >  
      {editMode?(
        <Box direction='row'flex="grow" gap='small'>
          <Text weight={700} style={{whiteSpace:"nowrap"}}>Compare to: </Text>
          <ModelAutocomplete 
            style={{ padding: "2px", width: "" }} size="small" width="500px" 
            projectInfo={projectInfo} 
            modelValue={model}
            onModelChange={(val)=>{
              onChange&&onChange(val)
              setEditMode(false)
            }} 
            suggestPublicModels={false} 
            lockModelsOnProejct={true}
            autoFocus
          />
          <Box onClick={()=>{
            onChange&&onChange(null)
            setEditMode(false)
            }}><FormClose/></Box>
          </Box>
        ):(
          <Text weight={700}>Compare to other model</Text>
        )}
      
    </Box>
    
  )
}



function ModelPage() {
  let { project_id,model_id } = useParams();
  let [searchParams, setSearchParams ] = useSearchParams();
  const [modelInfo, setModelInfo] = useState()
  const [compareToModelInfo, setCompareToModelInfo] = useState()
  const [selectedMetrics, setSelectedMetrics] = useState([])
  const [metricSuggestions, setMetricSuggestions] = useState([])
  const [metrics, setMetrics] = useState()
  const [textInputValue, setTextInputValue] = useState('');
  const {projectInfo} =useAppContext();
  

  //const {projectInfo} =  useAppContext();

  let load = ()=>{
    if (project_id && model_id){
      getApi().getModelInfo(project_id, model_id, (data)=> {
        
        if (data.logs && data.logs.training_logs && data.logs.training_logs.length>0){
          const combined_log_example = {}
          // if (data.metrics?.final_confusion_matrix){
          //   setConfusionMatrixData(data.metrics.final_confusion_matrix)
          //   delete data.metrics.final_confusion_matrix
          // }

          for(let log of data.logs.training_logs){
            
              for (let m of Object.getOwnPropertyNames(log)){
                if (!combined_log_example[m] && !m.startsWith("final_")){
                  combined_log_example[m] = log[m]
                }
              }
          }
          let allMetrics = Object.getOwnPropertyNames(combined_log_example)
            .sort((a,b)=> (((typeof combined_log_example[a]) ==="object") - ((typeof combined_log_example[b]) ==="object")))
            .flatMap(m=> ((typeof combined_log_example[m]) !=="object") ? m :  Object.getOwnPropertyNames(combined_log_example[m]).map(subMetric=>`${m}.${subMetric}`) )
          setMetrics(allMetrics);
          setMetricSuggestions(allMetrics);
          if(data.task_type.includes("Classification")){
            setSelectedMetrics(["loss","eval_loss","avg.f1-score"].filter(t=>allMetrics.includes(t)) )
          }
          else{
            setSelectedMetrics(["loss"])
          }
            
           ;
        }
        
        setModelInfo(data);

      });
      
    }
  };
  
  
  useEffect(() => {
    
    load()
  }, [project_id,model_id])

  useEffect(() => {
    if (searchParams){

      let compare_to_model_id = searchParams.get("compare_to")
      if (compare_to_model_id && compare_to_model_id!=compareToModelInfo?.id){
        getApi().getModelInfo(project_id, compare_to_model_id).then(data=>{
          setCompareToModelInfo(data)
        })
      }
    }
    
  }, [searchParams])

  function prepareChartData(modelInfo, metrics){
    if (metrics && modelInfo && modelInfo.logs && modelInfo.logs.training_logs){

      //console.log(modelInfo.logs.training_logs);
      let data = metrics.map(m=>{
        let path= m.split(".")
        return {
          label:m, 
          data:modelInfo.logs.training_logs.map(log=> {
                      path.forEach(prop=>log = log && log[prop]);
                      return log;
                    }),
            borderColor:randomColor({seed:"x"+m}),
            spanGaps: true,
          };
        }
      );
      
      return {
        labels: modelInfo.logs.training_logs.map(l=>"epoch:" +l.epoch),
        datasets: data
      }
    }
  }



  return modelInfo?(
    
    <Box fill  style={{overflow:"auto"}}  pad="small" >

      <Heading level={2} margin="small">Model: {modelInfo.model_name}</Heading>
      <Box direction="row" margin={{left:"medium",bottom:"large"}} flex={false}>
        
        <NameValueList nameProps={{ width: 'auto'}} valueProps={{width:"auto"}} >
            <NameValuePair  name="Model origin">{modelInfo.model_origin} </NameValuePair>
            <NameValuePair  name="Type">{modelInfo.task_type} </NameValuePair>
            <NameValuePair  name="Created">{modelInfo.created_at} </NameValuePair>
          
        </NameValueList>
        
      </Box>
      
      

          <Heading level={3} margin="small">Final evaluation: </Heading>
          <CompareWithBadge alignSelf="end" projectInfo={projectInfo} model={compareToModelInfo} onChange={(val)=>{
            if (val){

              getApi().getModelInfo(project_id, val.id).then(data=>{
                setSearchParams(new URLSearchParams({compare_to:val.id}))
                setCompareToModelInfo(data)
              })
            }
            else{
              setCompareToModelInfo(null)
            }
              
          }} /> 
         
         {<ModelEvaluation modelInfo={modelInfo} compareWithModelInfo={compareToModelInfo} />}
 
        <Heading level={3} margin="small">Training: </Heading>
        <Box id="chart" flex={false}>
      <Box direction="row" flex={false} gap="small" >
          <Box direction="row" wrap align="center">
            {selectedMetrics.map(metric=>(<Tag key={metric}  size="small" name={(<><Grommet size="small" color={randomColor({seed:"x"+metric})}/> {metric}</>)}  onRemove={()=>setSelectedMetrics( selectedMetrics.filter(m=>m!==metric))} />))}

          </Box>
          <Box>
          <TextInput
              plain
              placeholder="add metric"
              icon={<Add/>}
              value={textInputValue}
              suggestions={metricSuggestions}
              //onSuggestionOpen={e=>setMetricSuggestions(metrics)}
              onChange={
                (e)=>{
                  setTextInputValue(e.target.value);
                  setTimeout(()=> setMetricSuggestions(e.target.value? metrics.filter(m=>m.includes(e.target.value)):metrics),50);
                }
              }
              onSuggestionSelect={(event,v) =>{

                  setSelectedMetrics( [...selectedMetrics,event.target.textContent])
                  setTextInputValue("")
                }}
            />
            </Box>
          </Box>
          {modelInfo && modelInfo.logs && modelInfo.logs.training_logs && (
            <Box  flex={false} >
            
            
            <Line style={{maxHeight:"250px"}} 
            plugins={[zoomPlugin]}
            options={{
              cubicInterpolationMode:true,
              plugins: {
                zoom: {
                  pan: {
                      enabled: true,
                      mode: 'x',
                    },
                zoom: {
                
                  wheel: {
                    enabled: true,
                    modifierKey:"alt"
                  },
                  pinch: {
                    enabled: true
                  },
                  mode: 'xy',
               
                  }
              },
                }
              }} data={prepareChartData(modelInfo, selectedMetrics)} />
          </Box>)}
      </Box>
      
    
      <Box flex={false}>
        <MetricCard metricData={modelInfo.train_params} metricName="Train parameters"/>
      </Box>
    </Box>
    

   

  ):<Box fill align='center' pad="large"><Spinner size='large'/></Box>;
}


export {ModelPage};
