import { Link, useParams } from 'react-router-dom';
import { Box, Button, Heading, Collapsible, Text, TextInput, FormField, Form, Select } from 'grommet'
import { Add, CloudUpload, Package } from "grommet-icons"
import { useState, useEffect } from "react";

import { getApi } from '../ApiService';

import {ProjectModuleStatus} from "../ViewModel/ModuleInfo";
import { Badge } from '../components/Badge';


const ModuleBox = ({project_id,moduleStatus}:{
    project_id:string
    moduleStatus:ProjectModuleStatus
})=>{

    function openModuleDetail(){
        window.location.href= `/integration/${moduleStatus.module.module_id}?project_id=${project_id}`
    }
    return (
        <Box margin="10px" border round="small" pad="small" width="300px"  align='center' elevation='medium' justify='between' 
        
            onClick={moduleStatus.is_availible  ?( ()=>openModuleDetail()):undefined}
            focusIndicator={false}
        >
            <Box align='end' width="100%">
            {moduleStatus.active ?
                <Badge value="Active" background="lightgreen" />:(
                    moduleStatus.is_availible ? <Badge value="Availible"  />:  <Badge value="Unavailible" background="transparent" textColor="gray"/>
                )
            }
            </Box>
            <Box align='center' justify='center'>
                {moduleStatus.module.icon_url?(
                    <img width="125px" height="125px"  src={moduleStatus.module.icon_url.startsWith("#")? "/imgs/modules/"+moduleStatus.module.icon_url.slice(1)+".png" : moduleStatus.module.icon_url }/>

                ):(<Package size="100px"/>)}

            </Box>
            
            <Text weight={800}>
                {moduleStatus.module.name}
            </Text>
             <Box align='center' height="50px">

            <Text size="12px" textAlign='center'>
                {moduleStatus.module.description}
            </Text>
             </Box>
        </Box>
    )
}


function IntegrationsPage() {
    let { project_id } = useParams();
    
    const [data,setData] =useState<ProjectModuleStatus[]>();
    let refresh = () => {

        getApi().getIntegrationModules(project_id).then( (newData:ProjectModuleStatus[] )=> {

            setData(newData);
        });
    };

    useEffect(() => {

        refresh()
    }, [project_id])

    return (
        <Box direction="row" width="100%">

            <Box fill style={{ overflow: "auto" }}>

                <Heading level={4} margin="small">Integration modules</Heading>
                <Box direction='row'>
                    {data&&data.map((m,i)=> <ModuleBox key={i} project_id={project_id} moduleStatus={m}/>)}

                </Box>
            </Box>

        </Box>


    );
}


export { IntegrationsPage };
