import { Box, Layer, Spinner, Form, TextInput, FormField, Button, Text, Select, Accordion, AccordionPanel } from 'grommet'

import { useState, useEffect } from "react";
import { Analytics, Close, Down, FormClose, Up } from "grommet-icons"
import { Switch } from "../Switch"
import { NodeInfo } from '../../ViewModel/NodeInfo';
import { ModelSettings, RoutingSetting } from '../../ViewModel/NodeSettings';
import { getApi } from '../../ApiService';
import { modifyState } from '../../helpers/helperFunctions';
import { Autocomplete } from '../Autocomplete';
import { ModelAutocomplete } from '../ModelAutocomplete';
import { debouncePromise } from '../_customHooks';
import { RangeSelector } from '../RangeSelector';
import { LabelArea } from '../LabelArea';
import { Badge } from '../Badge';


export const ModelSettingsBox = ({ nodeInfo, modelSettings, onChange, readonly=false }: {
  nodeInfo:NodeInfo,
  modelSettings: ModelSettings,
  onChange: (newVal: ModelSettings) =>any,
  readonly?:boolean
}) => {

  const defaultRoutingSetting: RoutingSetting = {
    anchors: [],
    rule_type: "true-predictions",
    similarity_range: { min: 70, max: 100 },
    prediction_score_range: { min: 0.5, max: 1 },
    handling: "model-auto"
  }

 

  function modifyStateIfNotReadOnly(func:(val:any)=>any, currentVal:any, setVal:(val:any)=>any){
    if (!readonly){
      modifyState(func, currentVal,setVal)
    }
  }

  const [selectedProject, setSelectedProject] = useState<any>();

  const [allLabels, setAllLabels] = useState<string[]>();
  const [currentRoute, setCurrentRoute] = useState<number>();
  const [currentAccordionPanel, setCurrentAccordionPanel] = useState<number>();


  useEffect(()=>{
    setAllLabels(selectedProject?.labels)
  }, [selectedProject])

  useEffect(()=>{
    if (modelSettings?.project_id  && modelSettings?.project_id !=selectedProject?.id ){
      getApi().getProjectInfo(modelSettings.project_id).then(proj=>{
        if (proj){
          setSelectedProject({id:proj.id, name:proj.name, labels:proj.labels})
        }
      } );
    }
  },[modelSettings])

  const onNewAnchorFinish = (value:any, routeIndex: number) => {
    if (value) {
      modifyStateIfNotReadOnly((n: any) => n.routing[routeIndex].anchors.push(value), modelSettings, onChange)
      setCurrentNewAnchorText("")
     
    }
  }

  const searchExample = (searchTerm:string):Promise<string>|string[]=>{
    if (searchTerm){

      return selectedProject  && searchTerm?.length>0 && getApi().queryTexts(selectedProject.id, {Or:[
        {"text":searchTerm},
        {"similar_to_phrase":searchTerm}
      ]}, 0, 10).then(results=> results.map((t:any)=> t.doc.text))
    }
    else{
      return []
    }

  }
  const searchProject = (searchTerm:string)=>{

    return getApi().searchProjects(searchTerm).then(results=> results.map((proj:any)=>({id:proj.id, name:proj.name,  labels:proj.labels})))
  }

  function move(array:Array<any>, currentIndex:number, direction:number){
    
    if ((currentIndex+direction<0) || ((currentIndex+direction)>=array.length)){
      return array
    }
    else{
      let item = array[currentIndex]
      let backup=array[currentIndex+direction]
      array[currentIndex+direction]=item
      array[currentIndex]=backup
      setCurrentRoute(currentIndex+direction)
      return array
    }
    
  }
   
  const [currentNewAnchorText, setCurrentNewAnchorText] = useState<string>("")

  const ROUTE_TYPES_FRIENDLY =  {  
    "anchors": "Examples" ,
    "true-predictions": "Correctly predicted" 
  }

  return (
    <Box  gap="xsmall" >
       <Box direction='row' gap="medium" align="center">
       <Text size="xsmall">Project</Text>
        <Autocomplete
                placeholder="Search for project"
                size='small' style={{ padding: "2px" }}
                disabled={readonly}  
                searchFunc={searchProject}
                value={selectedProject}
                onValueChange={(val)=>{
                  modifyStateIfNotReadOnly((n: any) => n.project_id = val.id as string, modelSettings, onChange)
                  setSelectedProject(val)
                }}
                labelProperty="name"
                />
      </Box>
      <Box direction='row' gap="medium" align="center">
        <Text size="xsmall">Model</Text>
        <ModelAutocomplete
          projectInfo={selectedProject}
          style={{ padding: "2px", width: "" }}
          size="small"
          width="100%"
          disabled={readonly}  
          suggestPublicModels={false}
          lockModelsOnProejct={true}
          value={modelSettings.model_name}
          onChange={model_name => {
            modifyStateIfNotReadOnly((n: any) => n.model_name = model_name, modelSettings, onChange)
          }} />
      </Box>
      
        <Box direction='row' justify="between" flex="grow" >
          <Text size="small">Predictions routing</Text>
          {!readonly&&(

            <Box round background="orange" pad="0px 10px" onClick={() => {
              modifyStateIfNotReadOnly((n: any) => n.routing.push(Object.assign({}, defaultRoutingSetting)), modelSettings, onChange)
            }}>
            <Text weight={700} size="small">+Add</Text>
          </Box>
            )}
        </Box>
      <Box pad="10px 0px" width="100%" flex="shrink" style={{maxHeight:"50vh"}} overflow="auto">
        <Box flex="grow">
        {modelSettings.routing ? modelSettings.routing.map((route, routeIndex) => (
          <Box key={routeIndex} gap="small" pad="5px" margin="5px" border round="5px" onMouseEnter={()=>setCurrentRoute(routeIndex)}>
            
                <Box justify='between' direction='row'>
                  <Box direction='row'>
                  <Text size="small" weight={currentRoute == routeIndex ? 900 : undefined}>Routing rule {routeIndex + 1}</Text>

                  {!readonly &&(currentRoute == routeIndex )&&(
                    <Box direction='row' margin="0px 0px 0px 10px">
                  <Box focusIndicator={false} onClick={() => {
                      modifyStateIfNotReadOnly((n: any) => move(n.routing, routeIndex, -1), modelSettings, onChange)
                    }}>
                      <Up />
                  </Box>
                  <Box focusIndicator={false} onClick={() => modifyStateIfNotReadOnly((n: any) => move(n.routing, routeIndex, 1), modelSettings, onChange)}><Down /></Box>
                  </Box>
                  )}
                </Box>
                {!readonly && (
                <Box
                  onClick={() => {
                    modifyStateIfNotReadOnly((n: any) => {
                      n.routing.splice(routeIndex,1)
                    }, modelSettings, onChange)
                  }
                  }>
                  <FormClose />
                </Box>
                )}

              </Box>

            <Box>
              <Accordion activeIndex={routeIndex==currentRoute?currentAccordionPanel:null} onActive={(index:number[])=>{
                console.log(index)
                setCurrentRoute(routeIndex)
                setCurrentAccordionPanel(index[0])
              }}>
                <AccordionPanel label={
                  <Box direction='row' justify='between' width="100%" margin="2px">
                    <Text>
                      Similarity based routing:
                    </Text>
                      <Badge background="lightgray" size="xsmall" value={`${(ROUTE_TYPES_FRIENDLY as any)[route.rule_type ]} : ${route.similarity_range.min} - ${route.similarity_range.max} %`}/>
                  </Box>
                  }>
                  <Text size="xsmall">Routing type</Text>
                  <Switch
                    options={Object.getOwnPropertyNames(ROUTE_TYPES_FRIENDLY).map((t)=>({key:t, label:(ROUTE_TYPES_FRIENDLY as any)[t] }))  }
                    active={route.rule_type}
                    onActiveChange={(val) => modifyStateIfNotReadOnly((n: any) => n.routing[routeIndex].rule_type = val, modelSettings, onChange)}
                  />
                  {route.rule_type =="true-predictions" && (
                    <Box pad="5px 10px">
                    <Text size="10pt">Matches query prompt with any corrently predicted record  </Text>
                    <Text size="7pt">Please be aware that this option is significantly slower since for each query prompty we need to do request into database for similar examples </Text>
                    </Box>
                  )}
                  {route.rule_type == "anchors" && (
                    <Box>
                      <Text size="8pt">Route documents similar to these examles</Text>
                      {route.anchors && route.anchors.map((a, i) => (
                        <Box key={i} direction='row' align='center' gap="3px">{i + 1}:
                          <TextInput size='small' style={{ padding: "2px" }}
                            value={a}
                            disabled={readonly}
                            onChange={(e) =>
                              modifyStateIfNotReadOnly((n: any) => n.routing[routeIndex].anchors[i] = e.target.value, modelSettings, onChange)
                            }
                          />
                          {!readonly && (
                            <Box
                              onClick={() => modifyStateIfNotReadOnly((n: any) => n.routing[routeIndex].anchors.pop(i), modelSettings, onChange)}>
                              <FormClose />
                            </Box>
                            )}
                        </Box>
                      ))}
                      {!readonly && (
                        <Box direction='row' align='center' gap="3px" margin="10px 0px"> +
                          <TextInput size='small' style={{ padding: "2px", margin:"2px 8px" }}
                            value={currentNewAnchorText}
                            placeholder="write longer example of text that should be matched by similarity"
                            disabled={readonly}
                            onChange={(e) =>
                             setCurrentNewAnchorText(e.target.value as string)
                            }
                            onKeyDown={(e: any) => e.key === "Enter" && onNewAnchorFinish(e.target.value, routeIndex)}

                          />
                          {/* <Autocomplete
                            placeholder="write or search for an example"
                            size='small' style={{ padding: "2px" }}
                            //searchFunc={debouncePromise(searchExample, 500)}
                            onValueChange={(value: any) => {
                              onNewAnchorFinish(value, routeIndex)
                            }}

                            // onBlur={(e: any) => {
                            //   e.relatedTarget?.type != "button" && onNewAnchorFinish(e.target.value, routeIndex)
                            // }}
                            onKeyDown={(e: any) => e.key === "Enter" && onNewAnchorFinish(e.target.value, routeIndex)}
                          /> */}
                           <Box
                           flex="grow"
                           border round="5px"
                           margin="0px 20px 0px 5px"
                           pad="2px 5px"
                           background="orange"
                              onClick={() => onNewAnchorFinish(currentNewAnchorText, routeIndex)}>
                              <Text size="small">Add</Text>
                            </Box>
                        </Box>
                      )}
                      <Box>

                    </Box>
                      </Box>
                  )}
                  <RangeSelector minMaxRange={[0, 100]} label="Similarity range"
                  rangeStart={route.similarity_range.min}
                  rangeEnd={route.similarity_range.max}
                  disabled={readonly}
                  onChange={(start, end) => modifyStateIfNotReadOnly((n: ModelSettings) => n.routing[routeIndex].similarity_range = { min: start, max: end }, modelSettings, onChange)} />
                </AccordionPanel>


                <AccordionPanel  label={
                  <Box direction='row' justify='between' width="100%" margin="2px">
                    <Text>
                      Prediction based routing:
                    </Text>
                      <Badge background="lightgray" size="xsmall" value={`${(route.predicted_labels&& route.predicted_labels?.length)?(route.predicted_labels.join(",")):"any label"} : ${Math.round((route.prediction_score_range?.min||0.5)*100)} - ${Math.round((route.prediction_score_range?.max||1)*100)} %`}/>
                  </Box>
                  }>
                <Box>
                <Text size="8pt">Match only labels</Text>
                    <LabelArea
                      labelsValue={(route.predicted_labels || [])}
                      allLabels={allLabels}
                      disabled={readonly}
                      onUpdateLabels={(val: string, addOrRemove:boolean) => modifyStateIfNotReadOnly((n: ModelSettings) => {
                        if (addOrRemove){

                          if (!n.routing[routeIndex].prediction_score_range){
                            n.routing[routeIndex].prediction_score_range = { min: 0.5, max: 1} //default!
                          }
                          n.routing[routeIndex].predicted_labels = [...(n.routing[routeIndex].predicted_labels||[]), val]
                        }
                        else{
                          n.routing[routeIndex].predicted_labels  = (n.routing[routeIndex].predicted_labels ||[]).filter(l=>l!==val)
                        }
                      }, modelSettings, onChange)}
                      allowMultiple={true}

                    />
                    <RangeSelector 
                    minMaxRange={[0, 100]} 
                    label="Prediction threshold"
                    disabled={readonly}
                      rangeStart={(route.prediction_score_range?.min || 0.5) * 100}
                      rangeEnd={(route.prediction_score_range?.max || 1) * 100}
                      onChange={(start, end) => modifyStateIfNotReadOnly((n: ModelSettings) =>{
                         n.routing[routeIndex].prediction_score_range = { min: Math.round(start*100)/10000, max: Math.round(end*100)/10000 }
                         }, modelSettings, onChange)} />
                  </Box>
                </AccordionPanel>
                <AccordionPanel label={
                  <Box direction='row' justify='between' width="100%" margin="2px">
                    <Text>
                      Other conditions
                    </Text>
                    {route.regex &&
                      <Badge background="lightgray" size="xsmall" value={`regex: ${route.regex} `}/>
                    }
                  </Box>
                  }>
                    <Box direction='row' gap="medium" align="center" margin="10px">
                      <Box flex="grow">

                        <Text size="xsmall" className='nowrap' >Regex rule</Text>
                      </Box>
                        <TextInput size='small' style={{ padding: "2px" }}
                            value={route.regex ||""}
                            disabled={readonly}
                            onChange={(e) =>
                              modifyStateIfNotReadOnly((n: any) => n.routing[routeIndex].regex = e.target.value, modelSettings, onChange)
                            }
                          />
                          </Box>
                </AccordionPanel>
              </Accordion>


            </Box>
           

           
            <Box>
              <Text size="xsmall">Handling type</Text>

              <Switch
                options={[{ key: "manual", label: "Manual" }, { key: "model-review", label: "Predict + manual review" }, { key: "model-auto", label: "Full auto" }]}
                active={route.handling}
                onActiveChange={(val) => modifyStateIfNotReadOnly((n: ModelSettings) => n.routing[routeIndex].handling = val as any, modelSettings, onChange)}
              />
            </Box>
          </Box>
        )
        )
          :
          (
            <Box align='center' pad="10px 30px">
              <Text size="xsmall" color="gray">Add a route to define which records would be predicted automaticaly, or manualy</Text>
            </Box>
          )}
        </Box>
      </Box>
      <Box  direction='row' justify="between" align='end' flex={false}>
        <Box align='start'>
          <Text size="xsmall">Default handling</Text>
          <Switch
                options={[{ key: "manual", label: "Manual" }, { key: "model-review", label: "Predict + manual review" },{ key: "model-auto", label: "Full auto" }]}
                active={modelSettings.default_handling}
                onActiveChange={(val) => modifyStateIfNotReadOnly((m: ModelSettings) =>m.default_handling = val as any, modelSettings, onChange)}
              />
        </Box>
      
      </Box>
      
    </Box>
  )
}