
import { Box,  Text,  DropButton } from 'grommet'
import {  Filter,  } from "grommet-icons";
import { useState, useEffect } from 'react'
import { Badge } from '../Badge';




const FilterDropButton = ({preddefinedFilters, filterSuggestions, onFilterSelected, children, count}:any)=>{
    const [isDropOpen, setIsDropOpen] = useState(false);
    function onSelectPreddefinedFilter(filterObj:any){
        onFilterSelected(filterObj)
        setIsDropOpen(false);
      }


    return (
    <Box round="large" border pad="2px" direction='row' flex="shrink" justify='end' >
       {children}
            <DropButton
            
            dropProps={{ align: { top: 'bottom', right: 'right' } }}
            open={isDropOpen}
            onClose={() => setIsDropOpen(false)}
            onOpen={() => setIsDropOpen(true)}
            dropContent={
                <Box>
                <Box pad="xsmall" align="end" direction='row' wrap background={{ opacity: "weak", color: "light-1" }} style={{maxWidth:"300px"}}>
                    {
                        Object.getOwnPropertyNames(preddefinedFilters).map((label, i) => (
                            <Box margin="2px"  key={i} >
                                <Badge background="brand" value={label}  onClick={() => onSelectPreddefinedFilter(preddefinedFilters[label])} />
                                </Box>
                            // <Box key={i} border background="light-3" hoverIndicator={{ background: { "color": "light-2" } }} round pad="xxsmall" onClick={() => onSelectPreddefinedFilter(preddefinedFilters[label])} >
                            //     {/* <Box pad="xsmall" direction="row" align="center" gap="xsmall" round><Text size="10pt">{label}</Text></Box> */}
                            // </Box>
                        )
                        )
                    }
                </Box>
                    <Box border="top">
                    {filterSuggestions?.map((s:string)=>(<Box key={s} className='suggestion' onClick={()=>{
                        let val:any={}
                        val[s]=null
                        onSelectPreddefinedFilter(val)
                        }} direction='row' pad="2px 5px"><Badge icon={<Filter size="small"/>} size="xsmall" value={s+": ..."}/></Box>))}
                    </Box>
                </Box>

            }>
                <Box direction='row' flex="grow" align='center' gap="3px" >
                 
                    <Filter color='brand'/>
                    <Text size="small">Filters</Text>
                    {count?(
                    <Box background="brand"  round margin="-8px 5px 0px -4px" pad="1px 2px" flex={false}> <Text size='xsmall'>{count||10}</Text></Box>
                    ):(<></>)}
                </Box>

        </DropButton>
</Box>
)
}

export {FilterDropButton}