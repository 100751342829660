import {Button, Text} from "grommet";
import { useAppContext } from "../AppContext";
import { TipIfContent } from "./TipIfContent";


const BetterButton= (
    {label,nowrap, icon, tooltip, primary,secondary, permission, onClick,size, ...rest}:{
        label:string, 
        icon?:any, 
        nowrap?:boolean,
        tooltip?:string,
        primary?:boolean|undefined,
        secondary?:boolean|undefined,
        permission?:string|undefined, 
        onClick:()=>any,
        size?:string,
        [x:string]: any;
    })=>{
    const {testPermission} = useAppContext();
    const disabledStyle={opacity:0.5, minWidth:0}
    const normalStyle=size!="xsmall"? (size=="small"?{ padding:"3px 5px" }:{minWidth:0}): {minWidth:0, padding:"2px 5px"}
    
    
    const errTip = permission ? testPermission(permission,true):undefined;
    return (
        <TipIfContent content={errTip||tooltip}>
            <Button 
                className={nowrap?"nowrap":undefined}
                margin="2px"
                label={nowrap?(<div className={nowrap?"nowrap":undefined}>{label}</div>):label} 
                icon={icon} 
                primary={primary} 
                secondary={secondary} 
                size={(size && size.startsWith("x"))?size.substring(1):size as any}
                style={errTip?disabledStyle:normalStyle}
                onClick={errTip?undefined:onClick}
                {...rest}
            />
        </TipIfContent>
    );

}

export {BetterButton}