import { Box, Button, CheckBox, Layer, Text, TextArea, TextInput } from "grommet";
import { useState } from "react";
import { getApi } from "../../ApiService";
import { useAppContext } from "../../AppContext";
import { AutoUI } from "../AutoUI/AutoUI";
import { LabelArea } from "../LabelArea";



export const NewDocument =({ onSaved, onCancel, defaultContextData, defaultText})=>{
    const NEW_STATE={ contextData:defaultContextData ||{}}
    const [key,setKey] = useState("")
    const [text,setText] = useState(defaultText)
    const [contextDataState, setContextDataState] = useState(NEW_STATE)
    const [labels, setLabels] = useState([])
    const [createAnother,setCreateAnother]=useState()
    const [error,setError]=useState()
    const {projectInfo,setProjectInfo} =useAppContext();
    function onOk(contiue){
        let msg ={
            text:text,
            key:key,
            contextData:contextDataState.contextData,
            labels:labels
        }
        if (!text){
            setError("Text cant be empty")
        }
        getApi().addDocument(projectInfo.id, msg)
        setKey("")
        setError("")
        setText(defaultText)
        setContextDataState(NEW_STATE)
        setLabels([])
        
        onSaved&&onSaved(contiue)

    }

    function addLabel(label){
        if(!projectInfo)
            return

        if (!projectInfo.labels.includes(label )){

            
            //setAllLabels(newLabels);
            return getApi().putLabel(projectInfo.id, label,()=>{
                //refresh(); why is this here?
                const newProjInfo = Object.assign({},projectInfo);
                newProjInfo.labels = [...projectInfo.labels, label]
                setProjectInfo(newProjInfo)
                setLabels([...labels,label])
            });
        }
        else{
            setLabels([...labels,label])
        }
    }

    

     return (<Layer>
    <Box pad="large" align='start'  width="900px" style={{maxWidth:"90vw"}}>
    
        <Text weight={800} size="15pt">Add new document</Text>
  
        <Box width="80vW">
            <Box  direction="row" margin="2px 0px" align="center" gap="xsmall" justify="end" >
                
                <Box align="end">
                <TextInput placeholder="key:" style={{padding:"2px", width:"300px"}} size="12px" value={key} onChange={(e)=>setKey(e.target.value)}/>
                </Box>
            </Box>
            
            <TextArea
            size="15px"
            placeholder="text:"
                value={text}
                onChange={(e)=>{
                    setText(e.target.value)
                }}
                
            />
            <AutoUI
                value={contextDataState}
                onValueChange={val=>setContextDataState(val)}
                ui_hints={
                    {
                        "contextData":{
                            label:"Context data",
                            type:"Dict<string,string>"

                        }
                    }
                }
            />
  
        
             <LabelArea 
             allLabels={projectInfo?.labels}
            labelsValue={labels} 
            onUpdateLabels={(label,state)=>{
                if(state){
                    setLabels([...labels, label])
                }
                else{
                    setLabels(labels.filter(t=>t!=label))
                }
            }} 
            onAddLabel={(label)=>addLabel(label)}
            allowMultiple={projectInfo?.task_type=="MultiLabelTextClassification"}
            labelsSettings={projectInfo?.label_settings}
            />
        </Box>
        {error && <Text color="red">{error}</Text>}
        <Box direction="row" gap="small" justify="between" width="100%">
            <Box direction="row" gap="small">
                <CheckBox
                    checked={createAnother}
                    label="Continue with another"
                    onChange={(event) => setCreateAnother(event.target.checked)}
                />
            </Box>
            <Box direction="row" gap="small">
                <Button primary label="OK" onClick={()=>onOk&&onOk(createAnother)}/>
                {/* <Button secondary label="OK and Add another" onClick={()=>onOk&&onOk(createAnother)}/> */}
                <Button secondary label="Cancel" onClick={()=>onCancel&&onCancel()}/>
            </Box>
        </Box>
        <Box margin="5px -10px -40px 0px" alignSelf="center">
            <Text size="9pt">Note: Adding new record can take up to 60s ... please be patient</Text>
        </Box>
    </Box>
</Layer>)
}