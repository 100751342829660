import { Link, useParams} from 'react-router-dom';
import {  Box, Button, Heading ,Collapsible, Text, TextInput, FormField, Form, Select } from 'grommet'
import {Add, CloudUpload, Refresh} from "grommet-icons"
import { useState, useEffect } from "react";

import { getApi } from '../ApiService';
import { Projects } from '../components/Projects';
import { ModelCard } from '../components/ModelCard';
import { NewModelSidebar } from '../components/NewModelSidebar';
import { BetterButton } from '../components/BetterButton';
import { FileUploadDialog } from '../components/FileUploadDialog';
import { Constants } from '../contants';



function ModelsPage() {
  let { project_id } = useParams();
  const [data, setData] = useState([])
  const [modelUploadData, setModelUploadData] = useState({model_name:"",model_type:""})
  const [newModelTemplate, setNewModelTemplate] = useState([])
  const [newModelSidebar, setNewModelSidebar] = useState();
  const [showImportDialog, setShowImportDialog] = useState(false);

  const submitModelUpload= (files)=>{  
    if (modelUploadData.error)
      delete modelUploadData.error

    if (!modelUploadData.model_name ){
      setModelUploadData(Object.assign({error:"Model name is required" },modelUploadData)) 
      return false;
    }
    if (!/^[a-z|A-Z|_|-]+$/.test(modelUploadData.model_name))
    {
      setModelUploadData(Object.assign({error:"Model name must not have white spaces and special charcters. only '-' and '_' are alowed" },modelUploadData)) 
      return false;
    }
    if (!modelUploadData.task_type){
      setModelUploadData(Object.assign({error:"Model type is required" },modelUploadData)) 
      return false;
    }

    return getApi().uploadModelFile(project_id,files[0], modelUploadData.model_name, modelUploadData.task_type ).then(t=>setShowImportDialog(false))
  }
  

  let refresh = ()=>{
    
    getApi().getModels(project_id, (newData)=> {
      
      setData(newData);
    });
  };

  useEffect(() => {
    
    refresh()
  }, [project_id])

  function onTrainingRequested(mode, modelInfo){
    if (mode=="retrain"){
      newModelTraining( { task_type:modelInfo.task_type, training_params:modelInfo.train_params}, modelInfo.model_origin )
    }
    else if (mode=="continue"){
      newModelTraining(  { task_type:modelInfo.task_type, training_params:modelInfo.train_params}, modelInfo.model_name )
    }
    else
      console.error("invalid onTrainingRequested mode:" + mode)
  }

  function newModelTraining(newModelTemplate, sourceModel){
    if (!newModelTemplate){
      getApi().getModelTrainTemplate(project_id,null, (data) => {
        if (sourceModel){
          data.from_model=sourceModel
        }
        setNewModelTemplate(data)
        //setNewModelSourceModel(sourceModel)
        setNewModelSidebar(true);
      })
    }
    else{
      //setNewModelSourceModel(sourceModel);
      newModelTemplate.from_model=sourceModel
      setNewModelTemplate(newModelTemplate)
      setNewModelSidebar(true);
    }
  }

  return (
    <Box direction="row" width="100%">

    <Box fill  style={{overflow:"auto"}}>
      
    <Box  direction='row'  justify='between' pad="15px 10px" flex={false}> 
        
        <Box  direction='row'  gap="small" >

          <BetterButton primary onClick={()=>newModelTraining()} label="Train new model"  icon={<Add/>} permission="RUN_TRAINING"/>
          <BetterButton secondary label="Import model" icon={<CloudUpload/>} onClick={()=>setShowImportDialog(true)} permission="RUN_TRAINING"/>
        </Box>
        <Box onClick={()=>    refresh()} align="center" justify='center' focusIndicator={false}>
          <Refresh size="25px"/>
        </Box>
      </Box>
      <Heading level={4} margin="small">Models</Heading>
      <Box>
      {data.map(m=> (<ModelCard key={m.id} modelInfo={m} onTrainingRequested={onTrainingRequested} />))}

      </Box>
    </Box>
    {showImportDialog &&
      (<FileUploadDialog 
        caption={"Upload new model"} 
        accept=".zip"
        onClose={()=>setShowImportDialog(false)}
        onFile={submitModelUpload }
        onOK={submitModelUpload }
        error={modelUploadData.error}
        >
          <Box>
            <Form
             value={modelUploadData}
             onChange={nextValue => setModelUploadData(nextValue)}
             onReset={() => setModelUploadData({"model_name":"","task_type":""})}
            >



              <FormField label="Model name" validate={/[a-z|A-Z|_|-]+/} >
                <TextInput name='model_name' placeholder="Unique name of the model" />
              </FormField>
              <FormField label="Model type" >
                <Select
                name="task_type" 
                options={Constants.TRAINING_TASK_TYPES}
                />
              </FormField>
            </Form>
            <Text size="small">Upload model as a single .zip file (compress the model folder if needed)</Text>
          </Box>
        </FileUploadDialog>
      )}
    <Collapsible open={newModelSidebar} direction="horizontal">
                <NewModelSidebar onClose={()=>setNewModelSidebar(false)}  newModelTemplate={newModelTemplate}/>
                </Collapsible>
    </Box>
   

  );
}


export {ModelsPage};
