import React, { useMemo } from 'react'
import { useContext, createContext,useState, useEffect } from "react";
import {auth,firebaseApp} from "./firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import {  signInWithPopup, sendSignInLinkToEmail,GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";
import { getApi } from './ApiService';


export interface ProjectStatus{
  id:string;
  name:string;
  label_settings:{[label:string]:object}
  labels:string[] 
  task_type:string,
  total_count:number|undefined
  labeled_count:number|undefined
}



export interface ProjectStats{
    total_count:number;
    labeled_count:number;
    by_label_count:{[key:string]:number}
}
 
const AppContext = createContext(undefined);


export function useAppContext(){
    return useContext(AppContext);
}

export function AppContextProvider({children}:any){

   
    const [currentUser, setCurrentUser] = useState<any>();
    
    const [isRunningTask, setIsRunningTask] = useState<boolean|undefined>();
    const [projectInfo,_setProjectInfo] = useState<ProjectStatus>();

    
    
    const setProjectInfo=(proj:ProjectStatus)=>{
      _setProjectInfo(proj)
    }
    
    useEffect(()=>{
        getApi().errorHandler=setError;
        //getApi().loadingHandler=(isLoading)=>isLoading?setInfo("Loading ..."):setInfo(null);
    },[])

    const [projectStatistics, _setProjectStatistics] = useState<ProjectStats>();
    const setProjectStatistics=(stats:ProjectStats)=>{
      _setProjectStatistics(stats)
      const newProjectStatus = Object.assign({}, projectInfo)
      if (stats){
        newProjectStatus.total_count=stats.total_count
        newProjectStatus.labeled_count=stats.labeled_count
        if (stats.by_label_count){
          // this is here because setProjectStatistics is beeing called in a context when projectInfo might not be updated yet...so lets restore labels from stats
          let labels_from_stats=Object.getOwnPropertyNames(stats.by_label_count)
          let newLabels = labels_from_stats.filter(l=>!newProjectStatus.labels?.includes(l))
          newProjectStatus.labels = [...(newProjectStatus.labels||[]),...newLabels]
        }
        
      }
      setProjectInfo(newProjectStatus)
    }


    const refreshStatistics = (callback:any|undefined=undefined)=>{
     
    if (projectInfo?.id){
      
       getApi().getProjectStatus(projectInfo.id, (data:any)=> {
        setProjectStatistics(data.stats)
        
         callback && callback(data.stats);
       })
      
    }
    
  }   


    const applyLabelChanges = (changes:{added:{[label:string]:number},removed:{[label:string]:number}}, totalLabeledChange:number)=>{

        if (projectStatistics){
                
          _applyLabelChanges(projectStatistics,changes,totalLabeledChange);
        }
        else{
          const newProjectStatus = Object.assign({}, projectInfo)
          if (changes.added){

            for(let label of Object.getOwnPropertyNames(changes.added) ){ //this is workaround because this is called in onUpdateLabels and projectInfo is not yet updated in react state (although called before this event)
              if (! newProjectStatus.labels.includes( label ))
              newProjectStatus.labels.push(label)
            }
          }
          newProjectStatus.labeled_count=newProjectStatus.labeled_count+totalLabeledChange;
          setProjectInfo(newProjectStatus)
          //setTimeout(()=>refreshStatistics(),1000) //pospone refresh becasue this refresh is not waiting for the label update requests finish
            
        }
  
      }
  
      function _applyLabelChanges(statistics:ProjectStats,changes:{added:{[label:string]:number},removed:{[label:string]:number}}, totalLabeledChange:number){
      
        let newStats: any=Object.assign({}, statistics);
        newStats.stamp =null
        if (statistics.labeled_count+totalLabeledChange<0){
          console.error("statistics.labeled_count+totalLabeledChange<0 ... labeled count cant be negative!")
        }
        else{ 
          //only update total state if changed number is greater than records from change that were already labeled
          let oldLabeledTotal=newStats.labeled_count||0;
          newStats.labeled_count=oldLabeledTotal+totalLabeledChange;
        }
  
        if (newStats.by_label_count){
          for(let label of Object.getOwnPropertyNames(changes.added)){
            let oldBylabelVal = newStats.by_label_count[label] || 0;
            newStats.by_label_count[label] = oldBylabelVal + (changes.added[label]||0)
          }
          for(let label of Object.getOwnPropertyNames(changes.removed)){
            let oldBylabelVal = newStats.by_label_count[label] || 0;
            newStats.by_label_count[label] = oldBylabelVal - (changes.removed[label]||0)
          }
        }
        setProjectStatistics(newStats);
      
      }
  

    const [permssions, setPermssions] = useState<string[]>(undefined);
    const [userStatus, setUserStatus] = useState<any>();
    const tennant_id = userStatus?.tennant_id;
    const resetUserStatus=()=>{
        getApi().getAuthStatus().then(status=>status&&setUserStatus(status));
    }

    useEffect(()=>{
        return auth.onAuthStateChanged((user:any)=>{
            if (currentUser && currentUser.uid!==user?.uid){
                setPermssions(undefined);
                setUserStatus(undefined);
                setProjectInfo(undefined);
                window.location.href = window.location.href.split('?')[0]; 
            }
            setCurrentUser(user)
        });
    },[]);


    
    const [userProfile, setUserProfile] = useState<string>(undefined);
    useEffect(()=>{
        if (currentUser && permssions===undefined){
            getApi().getPermissions((thePermissions:{[role:string]:string[]})=> {
                let theProfile = Object.getOwnPropertyNames(thePermissions)[0] 
                setPermssions(thePermissions[theProfile])
                setUserProfile(theProfile)
            })
        }
        if (currentUser && userStatus==undefined){
            resetUserStatus();
        }
    },[currentUser])

    

    function logout(){
        return auth.signOut()
    }

    function testPermission(permission:string, returnMessage:boolean=false){
        if(!permssions) return;
        if (returnMessage){
            return permssions?.includes(permission)?null:
                (<span>{`Sorry but as a user with ${userProfile} profile, you are not allowed to perform this action.`}</span>)
        }
        else{
            return permssions?.includes(permission)
        }
    }

    //const [projectInfo,setProjectInfo] = useState();
    const [error,setError] = useState<string>();
    const [info,_setInfo] = useState<string>();

    const [lastTaskTimestamp, setLastTaskTimestamp] = useState<any>();

    function newTaskScheduler(){
        setIsRunningTask(true);
        setLastTaskTimestamp(new Date())
    }

    // const setInfo=(text:string)=>{
    //     _setInfo(text);
    //     setTimeout(()=>{
    //         if (text==info){
    //             _setInfo(undefined);
    //         }
    //     },5000)
    // }

    const value = {
        currentUser,
        permssions,
        userProfile,
        userStatus,
        tennant_id,
        lastTaskTimestamp,
        newTaskScheduler,
        projectStatistics,
        refreshStatistics,
        resetUserStatus,
        testPermission,
        projectInfo,
        setProjectInfo,
        isRunningTask, 
        setIsRunningTask,
        applyLabelChanges,
        error,
        setError,
        info,
        //setInfo,
        logout
    };

    return (
        <AppContext.Provider value={value}>
        {children}
        </AppContext.Provider>
        
        );
}