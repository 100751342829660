import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { Add, Refresh, Edit,Lock, Tag as Label, MoreVertical, Configure, FormClose, More, Validate, Alert, Checkmark, Code, Download, Time, Trash, DocumentMissing} from "grommet-icons"
import { Box, Form, TextInput, FormField, Button, Text, Heading, Select, FileInput, TextArea, Tip, Tag, CheckBox, DropButton, AccordionPanel, Accordion, Layer, Spinner, Keyboard} from 'grommet'
import { useState, useEffect } from "react";
import { getApi } from "../ApiService"
import { Autocomplete } from '../components/Autocomplete';
import {ApplyLabelsActionDialogComponent} from "../dialogs/ApplyLabelsActionDialogComponent"
import { Constants } from '../contants';
import { LabelSettingsDialog } from '../components/LabelSettingsDialog';
import { TipIfContent } from '../components/TipIfContent';

import {PermissionTypes} from "../contants"
import { BetterButton } from '../components/BetterButton';
import { useAppContext } from '../AppContext';
import { WaitPanel } from '../components/WaitPanel';
import { ModelAutocomplete } from '../components/ModelAutocomplete';
import { YamlEditDialog } from '../dialogs/YamlEditDialog';
import { BetterDropButton } from '../components/BetterDropButton';
import { ConfirmDialog } from '../components/ConfirmDialog';
import { BulkDeleteDocsDialog } from '../dialogs/BulkDeleteDocsDialog/BulkDeleteDocsDialog';

const LabelTag = ({label,value, onRemove, onSettingsChange, tabIndex})=>{
    const [dialogVisible,setDialogVisible] = useState(false)
    const defaultSettings=({
        "label":label,
        "icon":"",
        key_bindings:"",
        color:"",
        keywords:null
       })
    const [labelSettings,_setLabelSettings] = useState(defaultSettings)
    useEffect(()=>{
        if (value){
            _setLabelSettings(value)
        }
    },[value])
    
    const setLabelSettings = (val)=>{
        _setLabelSettings(val)
        onSettingsChange && onSettingsChange(val)
        setDialogVisible(false)
    }
    
    return (
        <div key={label}>
            {dialogVisible && <LabelSettingsDialog caption={label} onClose={()=>setDialogVisible(false)} labelSettings={labelSettings} onOK={(val)=>setLabelSettings(val)} visible={dialogVisible} />}
        <Box  border round pad="0px 5px" key={label} background={labelSettings.color}  direction="row" align='center'>
            <Box direction='row' gap="5px" align='center'  tabIndex={tabIndex} >
            <Text  style={{userSelect: "none"}}>{labelSettings.icon}</Text>
                <Keyboard >
               <Text > {label}</Text>
                </Keyboard>
            </Box>
            <Box round focusIndicator={false} onClick={()=>setDialogVisible(true)}><MoreVertical /></Box>
            <Box focusIndicator={false} onClick={() => onRemove(label)}><FormClose/></Box>
        </Box>
        </div>
        
        
    )
  
  
}

const ErrorIndicator =({error})=>{
    const [className,setClassName] = useState(undefined)
    useEffect(()=>{
        setClassName("blink");
        setTimeout(()=>{
            setClassName(undefined);
            
        },2000)
    },[error]);
    return error?( 
        <Tip content={error}><Alert className={className} color="red"/></Tip>
    ):(<></>)
}

const LabelWithIndicators = ({label, error, help})=>(
<Box direction='row' gap="2px">
    <TipIfContent content={help}>
    
        <Text>{label}</Text>
    
    </TipIfContent>
        {help&&<Text size="13px">?</Text>}
    <ErrorIndicator error={error}/>
</Box>
)


function ProjectSettingsPage() {
    const newProject = {
        name: "",
        labels: [],
        source: "",
        current_model_name:"",
        task_type:"",
        query:"",
        bigquery_project_id:""
    };
    let { project_id } = useParams();
    
    const {testPermission, setProjectInfo,  userStatus, resetUserStatus,newTaskScheduler, isRunningTask} = useAppContext();
    const navigate=useNavigate();
    const [projectData, _setProjectData] = useState(project_id?undefined:newProject);
    const [dialog, setDialog] = useState();
    const [editMode, setEditMode] = useState(false);
    const [preserveExistingData, setPreserveExistingData] = useState(false);
    
    const setProjectData = (data)=>{
        if (projectData===undefined ||  data.id != projectData.id){
            navigate(`/${data.id}/settings`)
            
            data.projectImportedSuccesfully =  !(data.data_import_state && data.data_import_state == "ERROR")
        }
        else{
            //force to reload project info
            if (data.id && data.data_import_state==="PREPARING" ){
                setTimeout(()=>{
                    getApi().getProjectInfo(data.id , (data)=> {
                        if(data.data_import_state!=="PREPARING"){
                            setProjectInfo(data);
                            navigate(`/${data.id}/texts`)
                        }
                        
                    });
                },5000)
            }
            // else{
            //     getApi().getProjectInfo(data.id , (data)=> {
            //         setProjectInfo(data);
                    
            //     });
            // }
            
        }
        _setProjectData(data);
    }
    const modifyProjectData = (modifyFunc)=>{
        const newProjectData=Object.assign({},projectData);
        modifyFunc(newProjectData);
        setProjectData(newProjectData);
      }
    const isExistingProject = project_id && project_id !== "new";
    

   

    const validateProject =()=>{
        const newErrors ={}
        if (projectData){
            if (!projectData.name){
                newErrors["name"]="Name is required"
            }
            if (!projectData.task_type){
                newErrors["task_type"]="Task type is required"
            }
            if (!projectData.current_model_name){
                newErrors["current_model_name"]="Embeddings model is required"
            }
            setErrors(newErrors)
            if (Object.getOwnPropertyNames(newErrors).length>0){
                return false;
            }
            else{
                return true;
            }
        }
    }


    const [errors, setErrors] = useState({});
    
    useEffect(()=>{
        validateProject();
        if (projectData && !projectData.id &&projectData.name){
            getApi().searchProjects(projectData.name,false,(res)=>{
                 if (res && res.length && res[0].name==projectData.name){
                    const newErrors=Object.assign({},errors)
                    newErrors["name"]="Project with this name already exists"
                    setErrors(newErrors);
                 }
            })
        }
    },[projectData])

    
    useEffect(()=>{
        if (projectData && projectData.data_import_state==="PREPARING"){
                newTaskScheduler()
            if (isRunningTask==false){
                setTimeout(()=>
                    getApi().getProject(project_id, (data)=>setProjectData(data))
                ,[1000])
            }
        }
        if (!project_id){
            return;
        }
        if (projectData===undefined || (projectData.id !== project_id)){
            getApi().getProject(project_id, (data)=>data.id ==project_id && setProjectData(data))
        }
    },[projectData, project_id,isRunningTask])


    function removeLabel(label) {
        let index = projectData.labels.indexOf(label);
        let newProjData = Object.assign({}, projectData)
        console.log(index)
        if (index > -1) {
            newProjData.labels.splice(index, 1); // 2nd parameter means remove one item only
        }
        setProjectData(newProjData);
    }

    function onLabelTextInputChange(textBox, forceSplit = false) {
        console.log(textBox);
        let value = textBox.value
        
        if (value.endsWith(" ") || value.endsWith(";") || value.endsWith(",") || forceSplit) {
            let newProjData = Object.assign({}, projectData)
            let matches = value.match(/([^\s]+)/gm);
            matches&& matches.forEach(m=> {
                
                if (!newProjData.labels){
                    newProjData.labels=[m]
                }  
                else if (!newProjData.labels.includes(m)) {
                    newProjData.labels.push(m)
                }
            })
            
            //if (newProjData.labels.includes(value))
            //newProjData.labels.push(value)
            textBox.value = "";
            setProjectData(newProjData);
        }
    }

    const [wait,setWait] = useState(false);
    function save(value, processData){
        setErrors({});
        if (!validateProject()) return;
        setWait(true)
        let dataToSend=Object.assign({},value)
        let files;
        if (dataToSend.files){
            if (dataToSend.files.length){
                files = dataToSend.files;
            }
            else{
                files = [dataToSend.files];
            }

            dataToSend.files = null
        }
        
        
        if (files){
            //If I have files, post project first... then upload and then save one more time... since I have purged files already from dataToSend.files, is wont come here next time
            getApi().postProject(dataToSend, (savedProject)=> {
                setProjectData(savedProject)

                getApi().uploadFiles(savedProject.id, files,(res)=>{
                    setProjectData(res)
                    getApi().postProject(res, (data)=> {
                        setProjectData(data)
                        processData && newTaskScheduler()
                     },processData ,preserveExistingData)
                     .finally(()=>setWait(false));
                    })
                    .catch(()=>setWait(false))

            },false,false)
        
        }
        else{

            getApi().postProject(dataToSend, (data)=> {
                setProjectData(data);
                
                processData && newTaskScheduler()
            },processData ,preserveExistingData)
            .finally(()=>setWait(false));
        }
    }




    

    function handleChange(event){
        const {name,value} = event.target
        let path = name.split(".")
        let newProjectData = {...projectData}
        let current=newProjectData;
        for(let i=0;i<path.length;i++){
            if (i<path.length-1){
                if (!current[path[i]]){
                    current[path[i]]={}
                }
                current=current[path[i]]
            }
            else{
                current[path[i]]=value;
            }

        }
        setProjectData(newProjectData);
    }

    function downloadURI(uri, name) {
        let link = document.createElement("a");
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }




      function onDownload(){
        getApi().scheduleProjectExport(project_id).then(()=>{
          newTaskScheduler()
        })
        .finally(()=>setWait(false))
          
      }
  
      function authenticateGoogle(project, reprocess){
        getApi().getGoogleAuthUrl(project_id, document.location.href.toString()+"?close=true", reprocess).then(url=>{
            window.open( url, "_blank")//, 'noreferrer')
            window.addEventListener('callbackFinished', ()=>{
                resetUserStatus();
            })
            return;
        } )
      }


      if (projectData===undefined){
          return <Box align='center' fill justify='center'><Spinner size='large'/></Box>
      }
      else{
        return (
        <Box fill style={{overflow:"auto"}} >
             {wait && (
              <WaitPanel caption="Saving ... please wait"/>)
            }
            
            <Box direction="row"  justify="between" style={{minHeight:"100px"}}>

                <Box pad="medium" direction='column' flex="grow">
                    <Heading>{project_id&&project_id.toLowerCase() !== "new" ? "Project settings" : "New project"}</Heading>
                    <Text size="8pt">{project_id&&project_id.toLowerCase() !== "new" ? project_id : ""}</Text>
                </Box>
                <Box pad="medium">
                    <BetterDropButton
                    icon={<MoreVertical/>} 
                    label="Actions"
                    primary
                    content={[
                        {
                            label:"Modify as YAML"  ,
                            icon:<Code/>,
                            permission:"MODIFY_PROJECT",
                            onClick:()=>{
                                setDialog(
                                <YamlEditDialog
                                    value={projectData}
                                    caption="Modify project as YAML"
                                    onOK={(val)=>{
                                        setDialog(null)
                                        setProjectData(val)
                                    }}
                                    onClose={()=>setDialog(null)}
                                />
                                )
                            }
                        },
                        {
                            label:"Export"  ,
                            icon:<Download/>,
                            permission:"EXPORT_DATA",
                            onClick:()=> onDownload(),
                            disabled:!project_id
                        },
                        {
                            label:"Apply labels from other project"    ,
                            icon:<Label/>,
                            permission:"MODIFY_PROJECT",
                            disabled:!project_id,
                            onClick:()=>{
                                setDialog(
                                <ApplyLabelsActionDialogComponent
                                    project_id={projectData.id}
                                    caption="Apply labels from project"
                                    onClose={()=>setDialog(null)}
                                />
                                )
                            }
                        },
                        {
                            label:"Delete some documents from project"    ,
                            icon:<DocumentMissing/>,
                            permission:"DELETE_PROJECT",
                            disabled:!project_id,
                            onClick:()=>{
                                setDialog(
                                <BulkDeleteDocsDialog
                                    project_id={projectData.id}
                                    caption="Delete documents from project"
                                    visible={true}
                                    onOK={()=>{
                                        setDialog()
                                        newTaskScheduler()
                                    }}
                                    onClose={()=>setDialog(null)}
                                />
                                )
                            }
                        }
                    ]}
                    />
                   

                </Box>
            </Box>

            {
                 (projectData && projectData.id && projectData.data_import_state==="PREPARING") ?
                 (
                     isRunningTask?(
                            <Box flex="grow" align='center' justify='center' pad="20px" gap="medium">
                                <Spinner size='xlarge'/>
                                <Text>
                                Your data for this project are being imported. This might take several minutes.
                                </Text>
                                <Text size="small">You may safely close this window now. Everything is beeing handled.</Text>
                                    <Button  icon={<Refresh/>} label="Refresh" onClick={()=> getApi().getProject(project_id, (data)=>setProjectData(data))}/>
                            </Box>
                        ):(
                            
                            <Box flex="grow" align='center' justify='center' pad="20px" gap="medium">
                            <Time size="xlarge"/>
                            <Text>
                            Ouch... something went wrong
                            </Text>
                            <Box direction='row'>
                                <BetterButton secondary label="Retry" icon={<Refresh/>} onClick={()=>setDialog(<ConfirmDialog text="Please try this only if you are sure that the process ended up in timeout" onOk={()=>{
                                    setDialog(null)
                                    save(projectData,true)
                                    }} onCancel={()=>setDialog(null)}/>)}  permission={"MODIFY_PROJECT"}/>
                                
                            </Box>
                        </Box>
                        )
                    
                    
                    ):(
                        <Box margin="small" >


                        <Form
                            value={projectData}
                            onChange={nextValue => setProjectData(nextValue)}
                            onReset={() => setProjectData(newProject)}
                            onSubmit={( {value} ) =>save(value, !isExistingProject )}
                        >



                            <FormField label={<LabelWithIndicators label="Name"  error={errors.name}/>} style={{ maxWidth: "550px" }}>
                                <TextInput id="text-input-name" name="name" /> 
                            </FormField>
                            <Box direction="row" align="center">

                                
                                
                            </Box>
                            
                            <FormField  label={<LabelWithIndicators label="Task type" help={
                                <Text size="12pt" margin="5px">Type of training/labeling task
                                    <ul>
                                        <li>Text classificaiton is for anotating documents by one of many labels</li>
                                        <li>Multilabel text classificaiton is the same, but one document can have multiple labels</li>
                                        <li>Text similarity is for anotating similar docuemnts pairs</li>
                                        </ul> 
                                    </Text>} 
                                    error={errors.task_type}/>}  style={{ maxWidth: "300px" }}>
                                <Select
                                name="task_type" 
                                options={Constants.TRAINING_TASK_TYPES}
                                />
                                
                            </FormField>

                            <FormField label={<LabelWithIndicators label="Embeddings model" help={
                                <Text size="12pt" margin="5px">Model that will be used to generate sematical vectors (embeddings) from imported texts.<br/> 
                                                                You can choose from the list, or pick any model pytorch from huggingface hub.<br/>
                                                                If you want to use language different from english, choose multilingual model, or find pretrained model for your language on huggingface hub.
                                    </Text>} 
                                    error={errors.current_model_name}/>}  style={{ maxWidth: "550px" }}>
                                {/* <Autocomplete 
                                id="text-input-model-name" 
                                name="current_model_name" 
                                searchFunc={val=> suggestHuggingfaceHubModel(val)} 
                                value={projectData.current_model_name}
                                onValueChange={val=>modifyProjectData((proj)=>proj.current_model_name=val)}
                                defaultSuggestions={commonModelNames}
                                disabled={!editMode && isExistingProject && projectData.projectImportedSuccesfully}
                                /> */}
                                <ModelAutocomplete 
                                
                                    projectInfo={projectData.id?projectData:undefined} 
                                    value={projectData.current_model_name} 
                                    onChange={val=>modifyProjectData((proj)=>proj.current_model_name=val)}
                                    disabled={!editMode && isExistingProject && projectData.projectImportedSuccesfully}
                                    />
                            </FormField>

                            {projectData&&(projectData.task_type==="TextClassification" || projectData.task_type==="MultiLabelTextClassification") &&(
                            <FormField name="name" htmlFor="text-input-labels" label="Labels" 
                            // onBlur={e=>onLabelTextInputChange(e.target, true)} 
                            >
                                <Box direction="row" flex wrap tabIndex={-1} onKeyUp={event=>{
                                    if ( event.code=="Backspace" || event.code=="Delete" ){
                                        let selectedTexts = window.getSelection().toString().split(/\s/);
                                        let newLabels= projectData.labels.filter(l=>!selectedTexts.includes(l));
                                        modifyProjectData(p=>p.labels=newLabels);

                                    }
                                }}>

                                    {projectData && projectData.labels && projectData.labels.map((l,i) => (
                                        <LabelTag 
                                            key={l} 
                                            label={l} 
                                            value={projectData.label_settings?projectData.label_settings[l]:undefined}
                                            onRemove={removeLabel} 
                                            onSettingsChange={labelSettings=> modifyProjectData((proj)=> { 
                                                    proj.label_settings=proj.label_settings||{} ; 
                                                    proj.label_settings[l]=labelSettings
                                                })}
                                        
                                        />
                                        )
                                    )
                                    }
                                    <Tip content="write down list of labels, separated by space, comma or semicolon">
                                        <TextInput style={{minWidth:"200px"}} id="text-input-labels" placeholder="add label"  onChange={e => onLabelTextInputChange(e.target)} onKeyPress={e =>  e.key === 'Enter' ? e.preventDefault() || onLabelTextInputChange(e.target, true) : null} />
                                    </Tip>
                                </Box>
                            </FormField>
                            )}

                            {/* <Accordion>
                                <AccordionPanel label="Webhooks">
                                    <Box pad="small">
                                    <Text>Hooks</Text>
                                
                                        <FormField label="After ingestions hoook url" size="xsmall" name={"callbacks.after_document_ingested" } component={TextInput} value={projectData.callbacks && projectData.callbacks.after_document_ingested || ""} onChange={(e)=>handleChange(e)} />
                                        <FormField label="After update hoook url" size="xsmall" name={"callbacks.after_document_modified" } component={TextInput} value={projectData.callbacks && projectData.callbacks.after_document_modified || ""} onChange={(e)=>handleChange(e)}/>
                                        <FormField label="After delete/exclude hoook url" size="xsmall" name={"callbacks.after_document_excluded" } component={TextInput} value={projectData.callbacks && projectData.callbacks.after_document_excluded || ""}  onChange={(e)=>handleChange(e)}/>
                                    
                                    
                                    </Box>
                                </AccordionPanel>
                            </Accordion> */}
                                    <Box border={projectData.source} pad="5px">
                                        <FormField label="Import initial data from" style={{ maxWidth: "400px" }}>
                                            <Select
                                                disabled={!editMode && isExistingProject && projectData && projectData.projectfimportedSuccesfully}
                                                options={['-none-','files', 'bigquery']}
                                                value={projectData.source}
                                                onChange={({ option }) => {
                                                    let newProjData = Object.assign({}, projectData)
                                                    newProjData.source = option!="-none-"?option:null

                                                    //setFileInputVisible(projectData.source === "file")
                                                    //setQueryInputVisible(projectData.source === "bigquery")

                                                    setProjectData(newProjData)
                                                }}
                                                valueLabel={<Box margin="0px 10px" ><Text>{projectData.source||"-none-"}</Text></Box>}
                                                />

                                        </FormField>

                                        {(projectData.data_source_files || projectData.source === "files") && (
                                            <Box pad="5px">
                                                <Text>Files</Text>
                                                {projectData.data_source_files && projectData.data_source_files.map(fileName => (
                                                    <Box direction='row' justify='between'>
                                                    <Box onClick={() => {
                                                        getApi().getDownloadFileUrl(projectData.id, fileName, (url) => downloadURI(url, fileName))
                                                    }}>
                                                        <Text style={{textDecoration:"underline"}} color="blue">{fileName}</Text>
                                                    </Box>
                                                    <Box onClick={()=> {
                                                        modifyProjectData(p=>p.data_source_files=p.data_source_files.filter(t=>t!=fileName))
                                                    } }><FormClose/></Box>
                                                    </Box>
                                                ))}
                                                {(projectData.source === "files" ? <FileInput multiple name="files"></FileInput> : <></>)}
                                            </Box>
                                        )}
                                        {projectData.source === "bigquery" ?
                                            (
                                                <Box gap="5px">
                                                    <Box direction='row' justify='start' align='end'>
                                                        <FormField label="Google Cloud Plaform - Project Id" style={{ minWidth: "300px" }}>
                                                            <TextInput name="bigquery_project_id" disabled={!editMode && isExistingProject && projectData.projectImportedSuccesfully}
                                                                placeholder=""
                                                                value={projectData.bigquery_project_id}
                                                            />
                                                        </FormField>

                                                        <Box pad="10px 40px">
                                                            {userStatus?.credentials?.big_query_credentials &&
                                                                (<Box direction='row'>
                                                                    <Checkmark color="green" />
                                                                    <Text size="xsmall">Authenticated to Google BigQuery</Text>
                                                                </Box>
                                                                )}
                                                            <Button
                                                                size="small"
                                                                label={!userStatus?.credentials?.big_query_credentials ? "Authenticate to Google BigQuery" : "Re-authenticate"}
                                                                onClick={() => authenticateGoogle()}
                                                            />
                                                        </Box>
                                                    </Box>

                                                    <TextArea name="query" disabled={!editMode && isExistingProject && projectData.projectImportedSuccesfully}
                                                        placeholder="GQL query"
                                                        value={projectData.query}
                                                    />
                                                </Box>
                                            )
                                            :
                                            <></>
                                        }
                                        {projectData.source  && isExistingProject &&
                                            (<Box direction="row" gap="small">

                                                {testPermission("MODIFY_PROJECT") ? (
                                                    <Box direction="row" gap="small">
                                                        <Button size='small' label="Reimport data" onClick={() => save(projectData, true)} />
                                                        <Tip content={<Box>
                                                            <Text>If true, all the existing data would be preserverd. All the data in the {projectData.source} will be imported again. </Text>
                                                            <Text weight={800}>Having a 'key'column is very recomented in this case  </Text>
                                                            <Text>(Othervise duplicates could occur in the result if you have some of the data already imported) </Text>
                                                        </Box>}><Box><CheckBox checked={preserveExistingData} onChange={e => setPreserveExistingData(e.target.checked)} label="Preserve existing data" /></Box></Tip>
                                                    </Box>
                                                ) : <></>
                                                }
                                            </Box>
                                            )
                                        }
                                    </Box>
                                
                             

                            <Box direction="row" gap="small" style={{ marginTop: "30px" }}>
                                
                                
                                <BetterButton type="submit" primary label="Save" permission={"MODIFY_PROJECT"}/>
                                {!isExistingProject && (<Button type="reset" label="Cancel" />)}
                            </Box>
                        </Form>

                        </Box>
                    )
            }
            
            {dialog && (dialog)}
        </Box>
        )
        }
    
}


export { ProjectSettingsPage };
