import {  Box, Button, Heading, Text } from 'grommet'
import { SearchBar } from "../components/SearchBar/searchBar"
import { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  
} from 'chart.js';
import { Bubble } from 'react-chartjs-2';
import { Topics } from '../components/Topics';
import {getApi} from "../ApiService"
import { BetterButton } from '../components/BetterButton';
import { useAppContext } from '../AppContext';
import { TopicsExplore } from '../components/TopicsExplore/TopicsExplore';
import { useParams, useSearchParams } from "react-router-dom";

function TopicsPage() {
  ChartJS.register(LinearScale, PointElement, Tooltip);
  let { project_id } = useParams();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  
  const [hasNextPage, setHasNextPage] = useState(true);
  const [lastQuery, setLastQuery] = useState(null);
  const {newTaskScheduler} = useAppContext();
  
  const [statsLoadingProgress, setStatsLoadingProgress] = useState(null);
  const [statsCache, setStatsCache] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();

  const [isDetail,setIsDetail]=useState()
  const [selectedTopic,_setSelectedTopic]=useState()
  const setTopicSelected=(topic)=>{
    
      if (searchParams.get("topic_id")!=topic?.topic_id ){

        if (topic){
          searchParams.set("topic_id", topic.topic_id)
        }
        else if (searchParams.get("topic_id")) {
          searchParams.delete("topic_id")
        }
        setSearchParams(new URLSearchParams(searchParams))
      }
      if (topic!=selectedTopic){
        _setSelectedTopic(topic)
      }
  }

  
  useEffect(()=>{
    if(data?.length){
      let urlTopicId = searchParams.get("topic_id")
      if (urlTopicId>=0 && urlTopicId!=selectedTopic?.topic_id ){
        let topicToShowFromUrl = data.find(t=>t.topic_id==parseInt(urlTopicId))
        if (topicToShowFromUrl)
          _setSelectedTopic(topicToShowFromUrl)
      }
      
    }
  },[searchParams,data])


  let search = (searchTerm, page=1)=>{
    if (page==currentPage && lastQuery == searchTerm) return;

    const pageSize = 5000;
    console.log("page "+page);
    let skip= 0 //|| data&&data.length>0?(page-1)*pageSize:0;
    let take=pageSize;
    getApi().searchTopics(project_id,searchTerm, 0,take, newData=>{ 
            setCurrentPage(page);
            setLastQuery(searchTerm);
            if (!(newData &&newData.length>0)  ){
              console.log("last page")
              setHasNextPage(false);
            }
            if (data && data.length>0 && lastQuery === searchTerm)
              newData = data.concat(newData)
              

                setData(newData);
            
            

            
            
            //loadStats(newData, 0,10)
            
          }
        );
    
    // let query= searchTerm ? "?keyword="+searchTerm : "";
    // axios.get("http://localhost:4000/api/projects/4085e81c-cd55-430a-9fd4-1c36107bdbc5/topic/search"+query)
    // .then((response) => {
      
    //   setData(response.data);
    // });
  };

  function loadStats(data, start, take, preloadedData){
    let api=getApi()
    setStatsLoadingProgress(start)
    let subSet = data.slice(start,Math.min(start+take, data.length))
    Promise.all( subSet.map(topic=>
      api.getTopicStats(project_id, topic.topic_id)
                      .then(stats=> {
                        
                        let res={topic_id:topic.topic_id}
                        
                        res.stats = stats
                        return res
                      })
        ))
        .then((results)=>{
          setStatsLoadingProgress(take)
          preloadedData=preloadedData ||{}
          results.forEach((res) => preloadedData[res.topic_id] = res.stats)
        
          if (start+take>=data.length){


            let newData = data.map(topic=>{
              topic["stats"] = preloadedData[topic.topic_id]
              return topic
            })
            setData([...newData])
            setStatsLoadingProgress(undefined)
          }
          else
          {
            loadStats(data, start+take, take,preloadedData)
          }
        })
        
  }


  const loadData = ()=>{
    
    setData(undefined)
    search(lastQuery, currentPage+1);
    return hasNextPage;
  }

  useEffect(() => {
    loadData()
  }, [])

  
    


  const handleTopicModified=(topic)=>{
    let newTopics = [...data]
    let result=null;
    if (Array.isArray(topic)){
      result=[]
      for(let t of topic){
        let topic_index = data.indexOf(t)
        let newTopic=Object.assign({},t)
        newTopics[topic_index] = newTopic
        result.push(newTopic)
      }
    }
    else{
      let topic_index = data.indexOf(topic)
      let newTopic=Object.assign({},topic)
      newTopics[topic_index] = newTopic
      result=newTopic
    }
    setData(newTopics)
    return result
  }


  return (
    
    <Box fill >
      <SearchBar onChange={searchTerm=>search(searchTerm)}/>
     

      <Box pad="0px 20px"direction='row' justify="between">
        <Text size="20px">Topics</Text>
        
        <BetterButton size="xsmall" primary label="Regenerate" secondary permission="REGENERATE_TOPICS" onClick={()=>getApi().regenerateTopics(project_id, ()=>newTaskScheduler())}/>
      </Box>
      <TopicsExplore searchQuery={lastQuery} topics={data}  hasNextPage={hasNextPage} onTopicSelected={setTopicSelected} selectedTopic={selectedTopic} 
      showDetail={isDetail}
      onEnterDetail={t=>{

          setTopicSelected(t||selectedTopic)
          setIsDetail(!!t)
        }} 
        onTopicModified={handleTopicModified}/>
      {data && statsLoadingProgress &&
      <Box round border margin="5px">
        <Box background="brand" round width={`${Math.round(100*statsLoadingProgress/data.length)}%`} pad="5px" align='cetner' justify='center'>
        <Text size='8pt'> {`${Math.round(100*statsLoadingProgress/data.length)}%`} </Text>
        </Box>

      </Box>}
    </Box>
    
   

  );
}


export {TopicsPage};
