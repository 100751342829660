import { React, useState, useEffect, useMemo } from "react";
import { Box, Text, Grid, Meter,Stack,Tip } from "grommet";

const TopicStatsControl = ({stats,totalCount, label_settings})=>{
    const [hoverLabel,setHoverLabel]=useState()
    const [showTotal,setShowTotal]=useState(false)
    const label_stats=useMemo(()=>stats.by_label_stats && Object.getOwnPropertyNames(stats.by_label_stats)
        .filter(label=>stats.by_label_stats[label]>0)
        .sort((l1,l2)=>stats.by_label_stats[l2] -stats.by_label_stats[l1])
    ,[stats])

    return (
        <Box  width="100px" >
            <Box flex={false} align="center" >
            {/* <Tip content={(stats.labeled_count/totalCount)*100 + " %"} > */}
             <Stack anchor="bottom" >
                <Meter size="xsmall" type="semicircle" thickness="small"  background="light-2" 
                values={label_stats.map(label=>( {
                                  value:stats.by_label_stats[label],
                                  color:((label_settings&&label_settings[label]?.color) || "orange"),
                                   onHover: (over) => {
                                    over?
                                     setHoverLabel(label):
                                     setHoverLabel(undefined)
                                   }
                                //   },
                                //   onClick: () => {
                                //     setHighlight(() => !highlight);
                                //   },
                                //   highlight,
                                }),
                                {}
                            ).concat(
                                {
                                    value:totalCount-stats.labeled_count,
                                    color:"white"
                                }
                            )
                            }
                //value={stats.labeled_count} 
                max={totalCount} />
                <Box align="center" onMouseEnter={()=>setShowTotal(true)} onMouseLeave={()=>setShowTotal(false)}>

                <Text size="large" weight="bold">
                  { stats.by_label_stats && stats.by_label_stats[hoverLabel] ?(stats.by_label_stats[hoverLabel] ) : (!showTotal?`${Math.round(100*stats.labeled_count/totalCount)}%` : stats.labeled_count)} 
                </Text>
                <Text size="xsmall" weight="bold">
                    {hoverLabel||"labeled"}
                </Text>
                </Box>
                
             </Stack>
             {/* </Tip> */}
             </Box>

             {  stats.by_label_stats &&
                 Object.getOwnPropertyNames(stats.by_label_stats)
                 .filter(label=>stats.by_label_stats[label]>0).sort((l1,l2)=>stats.by_label_stats[l2] -stats.by_label_stats[l1])
                 .map(label=>
                    (
                    <Box key={label}  flex={false} margin="2px 0px">
                    {/* <Text size="small">{label}</Text> */}
                    {/* <Meter size="xsmall" thickness="small" background="light-2" label={label} value={stats.by_label_stats[label]} /> */}
                    <Box height="18px" width="100%" background="light-4">
                        <Tip content={<Text size="small">Count: {stats.by_label_stats[label]}</Text>}> 
                            <Stack>
                                <Box background={(label_settings&&label_settings[label]?.color) || "orange"} height="18px" width={`${Math.round(100*(stats.by_label_stats[label]/totalCount))}%`}></Box>
                                <Text weight={700} size="xsmall">{label} {`${Math.round(100*(stats.by_label_stats[label]/totalCount))}%`}</Text>
                            </Stack>
                        </Tip> 
                    </Box>
                    </Box>
                    )
                 )
             }
        </Box>
    );
}

export {TopicStatsControl}