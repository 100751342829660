import { useState, useEffect } from 'react';
import { Box, Form, TextInput, FormField, Button, Heading, Grommet, grommet, Text, Tip, CheckBox, Select } from 'grommet';
import {Play,Clone,LinkPrevious, FormClock, Copy, FormClose } from "grommet-icons"
import { 
    GoogleAuthProvider, 
    getAuth,
    signInWithPopup, signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut
} from "firebase/auth";
import {firebaseApp} from "../firebase"
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getApi } from '../ApiService';
import { useAppContext } from '../AppContext';
import RequestAccessButton from '../components/RequestAccessButton';
import { TipIfContent } from '../components/TipIfContent';
import { Users } from '../components/UsersManagement/Users';
import { UserInfoCard } from '../components/UserInfoCard/UserInfoCard';
import {HumanizeDate} from "@videsk/humanize-date"
const auth = getAuth(firebaseApp);




const TennantInfo = ({tennantInfo})=>{
    

    const tennantInfoFriendlyNames={
        license:"License",
        project_limit:"Project total data limit",
        user_limit:"Active users limit",
        total_documents_limit:"Total documents limit",
        daily_active_users_count:"Active users",
        training_credits:"Training credits",
        valid_until:"Valid until"
    }

    return (
        <Box>
            {tennantInfo&& Object.keys(tennantInfoFriendlyNames)
            .filter(k=>tennantInfoFriendlyNames[k])
            .map(k=>(
                <Box key={k} direction='row' gap="small">
                        <Text>
                        { tennantInfoFriendlyNames[k]||k}
                    </Text>
                    <Text weight={800}>
                        { tennantInfo[k]&&k=="valid_until"?((new Date(tennantInfo[k])).toLocaleDateString()
                            
                            ):tennantInfo[k]}
                    </Text>
                </Box>
            ))}
        </Box>
    )
}


export const LoginPage = ()=>{

    

   
    const defaultState={login:"", password:"", confirmPassword:""};
    const [loginInfo, setLoginInfo] =useState(defaultState);
    const [register, setRegister] =useState(false);
    const [activationCode,setActivationCode] = useState()
    const [organization_id,setOrganizationId] = useState()
    const [inviteOrganization_id,seInviteOrganizationId] = useState()
    const [userExtendedStatus,setUserExtendedStatus] =useState();
    const [error, _setError] =useState();

    function setError(message){

        if (message){
            _setError(message.replace("Firebase:",""))
        }
        else{
            _setError()
        }
    }
    const [apiToken,setApiToken] =useState();


    const [tennantInfo, setTennantInfo] =useState()

    
    const navigate = useNavigate();
    let { currentUser, testPermission,userProfile } = useAppContext();



    useEffect(()=>
        currentUser && getApi().getTennantInfo().then(data=>{
            setTennantInfo(data)
        }
    ),[currentUser])
 
     
    useEffect(()=>{
        if (currentUser && (!userProfile||userProfile=="DEMO_USER") ){
            getApi().getAuthStatus((res)=>{
                if (!res.status){
                    setError(res.message || "Unknown error")
                }
                else{
                    setError(null);
                    setUserExtendedStatus(res.user)
                }
            })
        }
    },[currentUser]);

    const validatePassword = () => {
        let isValid = false
        if (loginInfo.password  ){
            if (register){
                if (loginInfo.password === loginInfo.confirmPassword) {
                    isValid = true;
                }
                else{
                    setError('Passwords does not match')
                }
            }
            isValid=true;
        }
        else{
            setError('Please set the password')
        }
        return isValid;
    }


    const registerOrLogin = e => {
        e.preventDefault()
        setError(null);
        let invite_id = searchParams.get("invite_id")
        let invite_check = searchParams.get("invite_check")
        const activate=()=>{

                getApi().activateInvite(invite_id,invite_check, inviteEmail|| loginInfo.login, organization_id)
                .then((data)=>{
                    setTimeout(()=>{
                        if (data.redirect_url){
                            window.location.replace(data.redirect_url)
                        }
                        else{
                            window.location.reload()
                        }
                    },1000)
                })
                .catch(e=>{
                    setError(e.message)
                })
            
        }

        if(validatePassword()) {
            if (register){
                // Create a new user with email and password using firebase
                
                createUserWithEmailAndPassword(auth, inviteEmail|| loginInfo.login, loginInfo.password)
                .then(()=>{
                    setSearchParams({})
                    if(invite_id){
                        activate()
                    }
                }
                )
                .catch(err => {
                    if (err.code==='auth/email-already-in-use'){
                        signInWithEmailAndPassword(auth, inviteEmail|| loginInfo.login, loginInfo.password)
                        .then(()=>{
                            if(invite_id){
                                activate()
                            }
                        })
                        .catch(ex=>{
                            setError("User already exist. Trying to login failed: "+ex.message)

                        })
                    }
                    setError(err.message)

                })
            }
            else{
                signInWithEmailAndPassword(auth,loginInfo.login, loginInfo.password)
                .catch(err => setError(err.message))
            }
        }
        //setLoginInfo(defaultState);
    }

    const [searchParams, setSearchParams] = useSearchParams();  
    const cyrb53 = function(str, seed = 0) {
        let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
        for (let i = 0, ch; i < str.length; i++) {
            ch = str.charCodeAt(i);
            h1 = Math.imul(h1 ^ ch, 2654435761);
            h2 = Math.imul(h2 ^ ch, 1597334677);
        }
        h1 = Math.imul(h1 ^ (h1>>>16), 2246822507) ^ Math.imul(h2 ^ (h2>>>13), 3266489909);
        h2 = Math.imul(h2 ^ (h2>>>16), 2246822507) ^ Math.imul(h1 ^ (h1>>>13), 3266489909);
        return 4294967296 * (2097151 & h2) + (h1>>>0);
    };

    const [inviteEmail, setInviteEmail]=useState(undefined);
    useEffect(()=>{
        if (searchParams.has("demo") && searchParams.has("checksum") ){
            let demo_par=searchParams.get("demo")
            let checksum=searchParams.get("checksum")
            let inviteId=searchParams.has("inviteId")&& searchParams.get("inviteId")
            let hash=cyrb53(demo_par,(new Date()).getDate())
            let hash_invite=inviteId && cyrb53(demo_par,inviteId)
            if (checksum ==hash || (hash_invite &&hash_invite == checksum)){
                signInWithEmailAndPassword(auth,"demo@labelator.io", "demo.pass.of.the_1day")
                .catch(err => setError(err.message))
                .then(()=>{
                    setSearchParams(new URLSearchParams())
                })
            }   
        }
        else if (searchParams.has("invite_id") && searchParams.has("invite_check") ){
            localStorage.setItem("invite_id",searchParams.get("invite_id"))
            localStorage.setItem("invite_check",searchParams.get("invite_check"))
            let _inviteEmail=searchParams.get("invite_id").split(":")[1];
            setInviteEmail(_inviteEmail)
            setLoginInfo({email:_inviteEmail,password:"",confirmPassword:""})
            setRegister(true);
        }
    },[searchParams]);

    function validateInvite(){
        if (inviteEmail && localStorage.getItem("invite_id")){
            let invite_id=localStorage.getItem("invite_id")
            let invite_check=localStorage.getItem("invite_check")

            
            return getApi().validateInvite(invite_id,invite_check, inviteEmail, organization_id)
            .then(data=>{
                if (data.valid){
                    if (data.organization){
                        setOrganizationId(data.organization)
                    }
                    seInviteOrganizationId(data.organization)
                    setError("")
                }
                else{
                    setError("Invite validation: " + (data.message||"Invite validation failed"))
                }
            })
            .catch(e=>{
                setError("Invite validation: " + e.message)
            })
        }
        return Promise.resolve()
    }

    useEffect(()=>{ 
        if (inviteEmail) validateInvite()
    },[inviteEmail])

    function tryToActivateWithInvite(){
        if (localStorage.getItem("invite_id")){
            let invite_id=localStorage.getItem("invite_id")
            let invite_check=localStorage.getItem("invite_check")
            localStorage.removeItem("invite_id")
            localStorage.removeItem("invite_check")
            setSearchParams({});
            return getApi().activateInvite(invite_id,invite_check, inviteEmail, organization_id).catch(e=>{
                setError(e.message)
            })
        }
        return Promise.resolve()
    }

    function loginWithGoogle(){
        setError(null);
        const googleProvider = new GoogleAuthProvider();
        googleProvider.setCustomParameters({
            login_hint:inviteEmail||loginInfo.email
        })
        signInWithPopup(auth, googleProvider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            let credential = GoogleAuthProvider.credentialFromResult(result);
            localStorage.setItem('user', JSON.stringify(result.user));
            localStorage.setItem('googleCredentials',  JSON.stringify(credential));

            tryToActivateWithInvite()

            // ...
        }).catch((error) => {
            setError(error.message)
        });
    }

    

    function logout(){
        
        localStorage.setItem('user', null);
        auth.signOut().then(function() {
            
          }).catch(function(error) {
            setError(error.message)
          });
    }



    //return (<Box>Test</Box>)
    return currentUser ? (
    <Box fill  align="start" pad="35px 10px"  flex={false} overflow="auto">
        <Button  plain label="Go to Projects" icon={<LinkPrevious/>} onClick={()=>navigate("/projects/search")}/>
        <Box pad="30px" gap="small" align="start"  flex="grow">

        {/* <Text>Signed as {currentUser.displayName}</Text>
        <Text>{currentUser.email}</Text>
        <Text>Tennant id: {userExtendedStatus && userExtendedStatus.tennant_id}</Text> */}
        <UserInfoCard/>
        <TennantInfo tennantInfo={tennantInfo}/>
       {error &&
        <Text color="red">{error}</Text>
       }
       
       <Button secondary label="Logout" onClick={()=>logout()} pad="medium" margin={{ horizontal: 'xsmall' }}/>
       {!error && testPermission("EXPORT_DATA") && userExtendedStatus&&
       (
            <>
                {!apiToken  ? (
                        <TipIfContent content={ userExtendedStatus.hasApiToken?"If you regenerate your API token, the old one will be invalidated":undefined}>
                            <Button secondary label={ userExtendedStatus.hasApiToken?"Regenerate API token":"Get API token"} onClick={()=>getApi().getApiToken(data=>{setApiToken(data["api_token"])})} pad="medium" margin={{ horizontal: 'xsmall' }}/>
                        </TipIfContent>
                ):(
                        <Box direction="row" gap="medium"><Text size="8pt">Api token</Text><Text size="8pt" >{apiToken}</Text><Button plain icon={<Clone/>} onClick={()=>navigator.clipboard.writeText(apiToken)}/></Box>
                )} 
            </>
       )}
    
        {!error && testPermission("MANAGE_USERS") && tennantInfo && 
            (<Users show_tennant={userExtendedStatus?.tennant_id=="1"} users_left={tennantInfo?.user_limit-tennantInfo?.daily_active_users_count }/>)}

       {error==="User is not enabled"&&(
            <Box margin="20px 0px" align="start" gap="small">
               <Text>Access to Labelator.io is currently availible only with invitation link. <br/>If you want to request access, please do so here:</Text>
               <RequestAccessButton label="Request full access" email={currentUser.email} />

               <Text>Have an activation code?</Text>
               <Box direction='row' flex="grow" width="100%">
                    <Box width="50%">
                    <TextInput  onChange={e=>setActivationCode(e.target.value)}/>
                    </Box>
                    <Button
                    label="Activate"
                     onClick={()=>{
                        if (activationCode){
                            getApi().activateAccount(activationCode).then(()=>document.reload()).catch((e)=>{
                                setError(e.message)
                            })
                            
                        }
                    }}/>
               </Box>
            </Box>
       )}
        </Box>
    </Box>
    ):
    (
        <Box fill justify="center" align="center" pad="xlarge" gap="medium">
            <Box align="center" pad='medium' width="medium"  round className='loginBox' flex="grow">
            {inviteEmail &&(
                            <Box pad="20px" align='center' justify='center'>
                                <Text size='large' weight={800}>Activate your account with invite</Text>
                            </Box>
                        )}
                <Form 
                    onSubmit={registerOrLogin} 
                    value={loginInfo}
                    onChange={newVal=> setLoginInfo(newVal)}
                    onReset={()=>setLoginInfo(defaultState)}
                >
                    <Box gap="small"  flex="grow">
                       
                            {!inviteEmail? 
                             <FormField name="login" label="Login" validate={{ regexp: /^[a-z|A-Z|-|_|\d|\.]+@[a-z|A-Z|-|_|\d]+\.[a-z|A-Z]+/i }}>
                            <TextInput name="login"  /> 
                            </FormField>
                            : 
                            <Box pad="0px 10px" align='start'>
                                <Text>Login</Text>
                            <Box  direction='row' round border background="rgba(0,0,0,0.1)" pad="0px 10px" margin="5px" align='center'>

                                <Text>{inviteEmail}</Text>
                                <Tip content="Removing Email login field will remove the invite code from sign up process. The invite link will still work if you'll change your mind later. ">
                                <Box onClick={()=>{
                                        setInviteEmail(undefined)
                                        localStorage.removeItem("invite_id")
                                        localStorage.removeItem("invite_check")
                                        setSearchParams({})
                                    }
                                }><FormClose/></Box>
                                </Tip>
                            </Box>
                            </Box>
                            }
                        
                        <FormField name="password" label="Password" >
                            <TextInput name="password" type="password" />
                        </FormField>
                        
                        {register && (
                            <FormField name="confirmPassword" label="Confirm password" >
                                <TextInput name="confirmPassword" type="password" />
                            </FormField>
                        )}
                        
                        {inviteEmail &&(
                            <>
                            <FormField label="Name of your organization" >
                                {!inviteOrganization_id ?( 
                                    <TextInput  type="text" value={organization_id||""} onChange={e=>setOrganizationId(e.target.value)} onBlur={()=>validateInvite()} placeholder="auto generated"/> 
                                    ):(
                                    <Box round background="lightgray" pad="2px 5px"><Text size="small">{inviteOrganization_id}</Text></Box>
                                )}
                                
                            </FormField>
                            <Box margin="-9px 0px 0px">
                                <Text size="xsmall">This field can not be changed after signing up</Text>
                            </Box>
                            </>
                        )}
                        <Box margin={{ top: "medium" }}>
                            {register ?
                                (
                                    <Box  gap="medium"  align="center">
                                    <Button type="submit" primary label="Sign up" pad="medium" margin={{ horizontal: 'xsmall' }}/>
                                    <Button secondary label="Sign Up with google" onClick={()=>loginWithGoogle()} pad="medium" margin={{ horizontal: 'xsmall' }}/>
                                    <Button plain label="Back to login" onClick={()=>{
                                        setRegister(false)
                                        setInviteEmail()
                                        localStorage.removeItem("invite_id")
                                        localStorage.removeItem("invite_check")
                                        setSearchParams({})
                                        }} pad="medium" margin={{ horizontal: 'xsmall' }}/>
                                    </Box>
                                )
                                :
                                (
                                    <Box  gap="medium" align="center" >
                                    <Button type="submit" primary label="Login"  pad="medium" margin={{ horizontal: 'xsmall' }}/>
                                    <Button secondary label="Login with google" onClick={()=>loginWithGoogle()} pad="medium" margin={{ horizontal: 'xsmall' }}/>
                                    <Button plain label={<Text style={{textDecoration:"underline"}}>Create a new account</Text>} onClick={()=>setRegister(true)} pad="medium" margin={{ horizontal: 'xsmall' }}/>
                                    </Box>
                                )
                                
                            }
                        </Box>
                    
                        <Text color="red">{error}</Text>
                    </Box>
                </Form>
  		  	</Box>
        </Box>

    );
  
}