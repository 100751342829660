import { Box, TextInput, Text } from 'grommet'
import {  FormClose, FormEdit } from "grommet-icons";
import { useState, useEffect } from 'react'
import {  useSearchParams } from "react-router-dom";
import * as React from 'react';



const SpecialTokenTag=({name,value,onEditFinished, editMode,onRemove, suggestTokenValue, disableSyncWithUrl}:
    {   
        name:string,
        disableSyncWithUrl:boolean
        value:string
        onEditFinished:(value:string,name:string)=>any
        editMode:boolean
        onRemove: (name:string)=>any,
        suggestTokenValue:(tokenType:string,currentValue:string)=>string[]|undefined|null
    }
    )=>{
    const textValueRef = React.useRef();
    const [searchParams, setSearchParams] = useSearchParams();   
    const [isActive, setIsActive] = useState<Boolean>();
    useEffect(()=>{
        !disableSyncWithUrl && setIsActive(searchParams.has(name))
    },[searchParams])
    const [textWidth, setTextWidth] = useState();
    const [textValue, setTextValue] = useState<string>(value||"");
    const [editModeInternal,setEdidModeInternal] = useState<boolean>();
    useEffect(()=>setEdidModeInternal(editMode),[editMode])

    const [suggestions, setSuggestions] = useState<string[]|undefined>();

    const setEdidMode=(val:boolean)=>{
        if (textValueRef?.current)
            setTextWidth((textValueRef.current as any).clientWidth+10);
        setEdidModeInternal(val)
       
    }

    useEffect(()=>{
        if (suggestTokenValue){
            let suggested = suggestTokenValue(name,value)
            if (suggested?.length==1 && suggested[0]==textValue ){
                suggested = suggestTokenValue(name,"")
            }
            
            setSuggestions(suggested)
        }
    },[])

    const [onOver,setOnOver] = useState(false);

    function onValueChange(value:string){
        setTextValue(value)
        if (suggestTokenValue){
            setSuggestions(suggestTokenValue(name,value))
        }
    }
    //useEffect(()=>suggestTokenValue ?? setSuggestions(suggestTokenValue(name,value)),[])

    

    return (
        <Box 
            alignSelf='center' 
            direction='row' 
            border round 
            flex={false}
            align='center'
            pad="0px 2px 0px 5px" 
            height="22px" 
            background={isActive?undefined:"white"}
            overflow="hidden"
            
        >
            
            <Box direction='row' gap="5px" align='center'>
            <Text size="11px" style={{cursor:"default"}} >{name}:</Text>
            <Box  
            
            onMouseLeave={()=>setOnOver(false)}>
            {editModeInternal ? (
                <form onSubmit={(e)=>{
                    if (textValue){
                        onEditFinished(textValue, name)
                    }
                    e.preventDefault()
                }}>
                <TextInput autoFocus size='12px' style={{padding:"1px",  maxHeight:"23px"}} value={textValue||""} 
                className="searchBarTokenInput"
                tabIndex={1}
                onChange={e=>onValueChange( e.target.value as string)}
                suggestions={suggestions?.map(t=>({value:t,label:(<Box className="suggestion" pad="5px"><Text>{t}</Text></Box>)}))}
             
                onSuggestionSelect={(e)=>{
                    onValueChange(e.suggestion.value as string)
                    setEdidModeInternal(false)
                    onEditFinished(e.suggestion.value as string, name)
                }
            }
                onBlur={(e)=> {
                    if(e.relatedTarget ==null || (!e.relatedTarget?.querySelector(".suggestion") )){
                        setEdidModeInternal(false)
                        onEditFinished(e.target.value, name)
                    }
                    setOnOver(false)
                    // if (e.target.value?.length){
                    // }
                    // else{
                    //     setOnOver(false)
                    //     //onRemove(name);
                    //     //setEdidModeInternal(false)
                    //     //onEditFinished(e.target.value, name)
                    // }
                }}/>
                </form>
            ):
            <Box background="brand" pad="1px 5px" round onMouseEnter={()=>setOnOver(true)} >
            <Text ref={textValueRef} size="12px" onClick={()=>setEdidMode(true)}>{value}</Text>
            </Box>
            }

            </Box>
            </Box>
            <Box background="white">
            {onOver  ? 
                <FormEdit/>
            : 
                <FormClose onClick={() => onRemove(name)} />
            }
            </Box>
        </Box>
        // <Tag  size="10px" name={name} value={value} onRemove={() => onRemove(name)} />
    )
}

export {SpecialTokenTag}