import * as React from "react"
import {Box, Button, DropButton, Form, FormField, Layer, Spinner, Tag, Text, TextInput} from "grommet"
import { useEffect,useState } from "react"
import { Close, FormClose } from "grommet-icons"
import { getApi } from "../ApiService"
import Picker, { SKIN_TONE_MEDIUM_DARK } from 'emoji-picker-react';
import { CirclePicker, SwatchesPicker } from "react-color"


const EmojiPicker = ({label, value, onValueChanged}:any)=>{

  const [dropOpen, setDropOpen] = useState(false);

  

  const selectValue=(event:any,emojiObject:any)=>{
    onValueChanged(emojiObject.emoji)
    setDropOpen(false)
  }

  return (
    <Box  direction="row" flex="grow">
    <DropButton 
    margin="0px 15px"
      open={  dropOpen}
      size="small"
      
      onOpen={()=>setDropOpen(true)}
      onClose={()=>setDropOpen(false)}
      label={
        <Box gap="small" direction="row" align="center" margin="-5px -15px" height="30px">
          <Text wordBreak="keep-all" size="15pt">{value||"..."}</Text>
          <Text weight={500} size="10pt">{label||"change"}</Text>
        </Box>
      } 
      dropContent={
        <Picker 
          onEmojiClick={selectValue}
          // disableAutoFocus={true}
          //skinTone={SKIN_TONE_MEDIUM_DARK}
          groupNames={{ smileys_people: 'PEOPLE' }}
          native
        />
      }
    />
    </Box>
)
}

const ColorPicker = ({label, value, onValueChanged}:any)=>{

  const [dropOpen, setDropOpen] = useState(false);

  const [advandecMode,setAdvancedMode]=useState<boolean>()
  const selectValue=(event:any,value:any)=>{
    if (/^#([0-9a-fA-F]{2}){3}$/i.test(value.hex)){
      onValueChanged(value.hex)
    }
    //setTimeout(()=>setDropOpen(false),100)
  }

  return (
    <Box  direction="row" flex="grow">
    <DropButton 
    margin="0px 15px"
      open={dropOpen}
      size="small"
      
      onOpen={()=>setDropOpen(true)}
      onClose={()=>setDropOpen(false)}
      label={
        <Box gap="small" direction="row" align="center" margin="0px -15px">
          <Box border round background={value} pad="10px"/>
          <Text weight={500} size="10pt">{label||"change"}</Text>
        </Box>
      } 
      dropAlign={{top:"bottom", left:"left"}}
      dropContent={
        <Box pad="15px 25px 15px 15px">
        
          <CirclePicker

          onChangeComplete={(color,event)=>selectValue(event,color)}
          />
        
          
        
        
          <Box onClick={()=>setAdvancedMode(!advandecMode)} margin="10px 5px" gap="small" focusIndicator={false} >
          <Text size="small"  style={{textDecoration:"underline"}}> {advandecMode?"Less options":"More options"}</Text>
          { advandecMode&& (
             <CirclePicker
             colors={[
              "#000000", "#333333", "#666666", "#999999", "#CCCCCC", "#eFeFeF",
              "#F44336", "#EF5350", "#E57373", "#EF9A9A", "#FFCDD2", "#FFEBEE",
             "#E91E63", "#EC407A", "#F06292", "#F48FB1", "#F8BBD0", "#FCE4EC",
             "#9C27B0", "#AB47BC", "#BA68C8", "#CE93D8", "#E1BEE7", "#F3E5F5",
             "#3F51B5", "#5C6BC0", "#7986CB", "#9FA8DA", "#C5CAE9", "#E8EAF6",
             "#2196F3", "#42A5F5", "#64B5F6", "#90CAF9", "#BBDEFB", "#E3F2FD",
             "#03A9F4", "#29B6F6", "#4FC3F7", "#81D4FA", "#B3E5FC", "#E1F5FE",
             "#00BCD4", "#26C6DA", "#4DD0E1", "#80DEEA", "#B2EBF2", "#E0F7FA",
             "#009688", "#26A69A", "#4DB6AC", "#81C784", "#B2DFDB", "#E0F2F1",
             "#4CAF50", "#66BB6A", "#81C784", "#A5D6A7", "#C8E6C9", "#E8F5E9",
             "#8BC34A", "#9CCC65", "#AED581", "#C5E1A5", "#DCEDC8", "#F1F8E9",
             "#CDDC39", "#D4E157", "#DCE775", "#E6EE9C", "#F0F4C3", "#F9FBE7",
             "#FFEB3B", "#FFEE58", "#FFF176", "#FFF59D", "#FFF9C4", "#FFFDE7",
             "#FFC107", "#FFCA28", "#FFD54F", "#FFE082", "#FFECB3", "#FFF8E1",
             "#FF9800", "#FFA726", "#FFB74D", "#FFCA28", "#FFD54F", "#FFE082",
             "#FF5722", "#FF7043", "#FF8A65", "#FFAB91", "#FFCDD2", "#FFECB3"]}
             onChangeComplete={(color,event)=>selectValue(event,color)}
             />
          )}
          </Box>
          </Box>
        
      }
    />
    </Box>
)
}


const KeyBindingInput = ({value, onValueChanged}:any)=>{
  function processKeyDown(event:React.KeyboardEvent){
    if (event.key.trim() && !event.key.startsWith("Arrow") && !(event.key.startsWith("F") && event.key.length>1) ){
      onValueChanged(event.key)
    }
    else{
      (event.target as any).value=""
    }
  }
  return (
    <Box direction="row" margin="0px 15px">
      {value? 
      <Tag size="small" name="key" value={value} onRemove={()=>onValueChanged(null)} />
      :
      <TextInput size="small" style={{padding:"5px"}} placeholder="click here and press the key" onKeyDown={(e:React.KeyboardEvent)=>processKeyDown(e)}/>}
    </Box>
    )
}

const LabelSettingsDialog = ({
  caption, 
  visible,
  labelSettings, 
  onOK, 
  onClose 
}:{
  caption:string,
  visible:boolean,
  labelSettings:LabelSettings,
  onOK:(labelSettings:LabelSettings)=>any
  onClose:()=>any
}
)=>{

  

  const [labelSettingsInternal, setLabelSettingsInternal] = useState(labelSettings);
  const modifySettings = (modifyFunc:(labelSettings:LabelSettings)=>any )=>{
    const newLabelSettings=Object.assign({},labelSettingsInternal);
    modifyFunc(newLabelSettings);
    setLabelSettingsInternal(newLabelSettings);
  }

  const [wait, setWait] = useState(false);
  const [_visible, setVisible] = useState(true);
  const _onClose=(e:any)=>{
    setVisible(false);
    onClose();
  }

  useEffect(()=>{
      setVisible(visible);
  }, [visible]);

  function submit(){
    onOK(labelSettingsInternal)
    setVisible(false);
    
  }




useEffect(()=>{},[]);
const [state, _setState] = useState<LabelSettings>();
function setState(val:LabelSettings){
    
    _setState(val);
}


function onLabelTextInputChange(textBox:any, forceSplit:boolean = false) {
  
  let value = textBox.value
  if (value.endsWith(" ") || value.endsWith(";") || value.endsWith(",") || forceSplit) {
      
      let matches = value.match(/([^\s|,|;]+)/gm);
      let newKeywords= labelSettingsInternal.keywords?Array.from(new Set([...labelSettingsInternal.keywords,...matches||[]])):matches;
      modifySettings(sett=>sett.keywords=newKeywords)
      textBox.value = "";
      
  }
}
function removeKeyword(word:string){
  modifySettings(sett=>sett.keywords=sett.keywords.filter(t=>t!=word))
}

return (
    _visible && labelSettingsInternal?(
<Layer onClickOutside={onClose} onEsc={onClose}>
  <Box  style={{minWidth:"500px"}} margin="small" gap="small">
    <Box direction="row" justify='between' width="100%">
      <Text size="large">{caption} </Text>
      <Close style={{float:"right"}} onClick={_onClose}/>
    </Box>
    {
      wait?
      (<Spinner size="large" alignSelf="center"/>)
      :
      ( <Box>
        <Form 
            value={state}
            onChange={val=>setState(val)}
        >
          <FormField label="Label icon">
          <EmojiPicker value={labelSettingsInternal.icon} onValueChanged={(val:string)=> modifySettings((settings)=>settings.icon=val)}/>
          </FormField>
            <FormField label="Keywords">
              <Box direction="row" margin="0px 10px" wrap style={{maxHeight:"50px", overflow:"auto"}}>
              {labelSettingsInternal.keywords &&labelSettingsInternal.keywords.map((kw,i)=>(<Box key={i} pad="2px 5px" border round direction="row"><Text size="10pt">{kw}</Text><Box onClick={(e=>removeKeyword(kw))}><FormClose/></Box></Box>) )}

              </Box>
            <TextInput style={{minWidth:"200px"}} id="text-input-labels" placeholder="add keyword"  onChange={(e:any) => onLabelTextInputChange(e.target)} onKeyPress={(e:any) =>  e.key === 'Enter' ? e.preventDefault() || onLabelTextInputChange(e.target, true) : null} />
            </FormField>

                
            <FormField label="Color">
                <ColorPicker value={labelSettingsInternal.color} onValueChanged={(val:string)=> modifySettings((settings)=>settings.color=val)}/>
            </FormField>
            <FormField label="Keyboard binding">
                <KeyBindingInput value={labelSettingsInternal.key_bindings} onValueChanged={(val:string)=> modifySettings((settings)=>settings.key_bindings=val)}/>
            </FormField>
        </Form>
    </Box>)
    }
    <Box direction="row" justify="end" gap="xsmall">
        <Button primary label="OK" onClick={submit}/>
        <Button secondary label="Cancel" onClick={_onClose}/>
    </Box>
  </Box>
</Layer>)
:(<></>)
)
}

export {LabelSettingsDialog}