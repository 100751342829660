import { Layer,Spinner,Text,Box } from "grommet";

const WaitPanel=({caption, secondCaption})=>(
    <Layer>
        <Box pad="large" align='center' gap='medium'>
        <Spinner size='large'/>
            <Text>{caption}</Text>
            {secondCaption && <Text>{secondCaption}</Text>}
        </Box>
    </Layer>
)

export {WaitPanel};