import React from "react";
import { Box, Text, Grid, Button,  Tag, NameValueList, NameValuePair } from "grommet";
import { Link } from "react-router-dom";
import moment from 'moment';



export const MetricCard = ({metricName, metricData})=>{

function formatValue(val){
    return val?.toLocaleString(navigator.language, { maximumFractionDigits: 6 } )
}

if (metricData&&typeof(metricData)==="object"){
  return (<Box round ="small" border  margin="small" pad="small" flex={false} background="white">
    <Text size="large"  weight="bold">{metricName}</Text>
      <NameValueList nameProps={{ width: 'small' }}>
        {Object.getOwnPropertyNames(metricData).map(p=> (
          
        <NameValuePair key={p} name={p}>{formatValue(metricData[p])}</NameValuePair>
        ))}
      </NameValueList>
  </Box>);
}
else if (metricData){
  return (
      <Tag size="xsmall"  name={metricName} value={formatValue(metricData)} />
    
  );
}
else{
  return (<></>)
}
}