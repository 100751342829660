import { Box, Button, DropButton, Spinner, Text } from "grommet"
import { CircleQuestion, FormCheckmark, Inspect, StatusGood } from "grommet-icons"
import { useState } from "react"
import { getApi } from "../../ApiService"
import { BetterButton } from "../BetterButton"
import { ExpandingButton } from "../ExpandingButton/expandingButton"

const ReviewButton = ({project_id,textDocument, onTextDocumentModified})=>{

    const [wait, setWait] =useState()
    const [reviewState, setReviewState] =useState(textDocument.review_state)

    function callApi(func, newReviewState){
        setWait(true)
        func().then(()=>{
            let newDoc = {...textDocument};
            newDoc.review_state=newReviewState
            textDocument.review_state=newReviewState
            setReviewState(newReviewState)
            onTextDocumentModified && onTextDocumentModified(newDoc)
            setWait(false)
        })
    }

    if (wait)
        return <Spinner/>
    else
        return !reviewState?(

        <ExpandingButton
        border={false}
        onClick={()=>callApi(()=>getApi().requestReview(project_id, [textDocument.id]),"review_requested" )}
        collapsedContent={
            <Inspect/>
        }
        expandedContent={
            <Box direction="row" gap="5px" align="center" border round pad="1px 2px">
                <Inspect/>
                <Text className="nowrap"  size="xsmall">Request review </Text>
            </Box>
        }
        />
        ):(
            <>
            {reviewState=="reviewed"&&
            <DropButton round background="green" dropAlign={{top:"bottom", "left":"right"}}  dropContent={
                <Box align="center">
                    <Box pad="small">

                    {textDocument.reviewed_by && <Text className="nowrap" >Reviewed by: {textDocument.reviewed_by}</Text>}
                    </Box>
                <BetterButton 
                    size="small"
                    onClick={()=>callApi(()=>getApi().requestReview(project_id, [textDocument.id]),"review_requested" )}
                
                    icon={<Inspect/>}
                    label="Request review (again)"
                    />
                </Box>
                
            }>
                <Box direction="row" gap="5px" align="center" background="green" round pad="1px">
                <StatusGood color="white"/>
                </Box>
            </DropButton>}
            {reviewState=="review_requested"&& 
            
            (textDocument.labels||textDocument.answer?
                <BetterButton 
                    primary 
                    color="rgb(0,160,0)" 
                    size="small" 
                    onClick={()=>callApi(()=>getApi().reviewDocLabels(project_id, [textDocument.id],null),"reviewed" )}
                    style={{padding:"0px 5px"}} 
                    icon={<FormCheckmark/>} 
                    label="Confirm review"
                />
                :
                <Box direction="row" gap="5px" align="center" background="brand" round pad="1px 2px">
                    <Inspect/><Text className="nowrap" size="small">Review requested</Text>
                </Box>

            )
            }
            </>
        )

}

export {ReviewButton}