
import { Box, Form, TextInput, FormField, Button, Heading, Grommet, grommet, Text } from 'grommet';



export const NotFound404 = ()=>{

   
 
    return(
        <Box justify="center">
            <Heading level={1}>404</Heading>
            <Heading level={3}>page not found</Heading>
        </Box>
    );
  
}