import {  Box,  Layer,   Spinner, Form, TextInput, FormField, Button, Text, Select } from 'grommet'
import { TextInputWithSuggestions } from '../components/TextInputWithSuggestions';
import { parse, stringify } from 'yaml'
import {Close} from "grommet-icons"
import { useState, useEffect } from "react";
import { getApi } from '../ApiService';
import Editor from "@monaco-editor/react";


export const YamlEditDialog= ({caption, value, onOK, onClose }:
  {
    caption:string,
    value:string|object,
    onOK:(value:string|object)=>any,
    onClose:()=>any
  })=>{
  const [wait, setWait] = useState(false);

  const [originalValueType, setOriginalValueType] = useState<string>();
  const [yamlValue, setYamlValue] = useState<string>();
  const [errorMsg, setErrorMsg] = useState<string>();

  const _onClose=()=>{
    onClose();
  }


function submit(){

  if (onOK){
    if (originalValueType==="string"){
      onOK(yamlValue);
    }
    else{
      try{
        let parsed=parse(yamlValue)
        onOK(parsed)

      }catch (error)
      {
        setErrorMsg(error.message)
      }
    }
  }
  
}



    useEffect(()=>{
      if (value){
        let valueType = typeof(value)
        setOriginalValueType(valueType)
        if (valueType==="object"){
          setYamlValue(stringify(value))
        }
        else{
          setYamlValue(value.toString())
        }
      }

    },[value]);
    

return (
    
<Layer onClickOutside={onClose} onEsc={onClose}>
  <Box style={{minWidth:"65vw"}} margin="small" gap="small">
    <Box direction="row" justify='between' width="100%">
      <Text size="large">{caption} </Text>
      <Close style={{float:"right"}} onClick={_onClose}/>
    </Box>
    {
      wait?
      (<Spinner size="large" alignSelf="center"/>)
      :
      ( <Box>
        
        <Editor
          height="80vh"
          defaultLanguage="yaml"
          onChange={(value:string)=>setYamlValue(value)}
          value= {yamlValue}
        />
        </Box>
      )
    }
    {errorMsg&&<Text size="small" color="red">{errorMsg}</Text>}
    <Box direction="row" justify="end" gap="xsmall">
        <Button primary label="OK" onClick={submit}/>
        <Button secondary label="Cancel" onClick={_onClose}/>
    </Box>
  </Box>
</Layer>

)
}
