import { React, useState, useEffect, useMemo } from "react";
import { Box, Text, Grid, Meter,Stack,Tip } from "grommet";
import { useParams,  useSearchParams} from "react-router-dom"
import { useInView } from 'react-intersection-observer';
import { TagCloud } from "react-tagcloud";
import { useDebounce } from "./_customHooks";
import { getApi } from "../ApiService";
import { useAppContext } from "../AppContext";
import { TopicStatsControl } from "./TopicStats/topicStatsControl";



export const TopicCard = ({  topic }) => {
    
    let {project_id}=useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const {projectInfo} =  useAppContext();

    const { ref, inView, entry } = useInView({
        /* Optional options */
        threshold: 0,
    });
    const inViewDebounced = useDebounce(inView, 500);

    const [stats, setStats] = useState(null);
    useEffect(() => {
        
        if (inView) {
            const abortContoller = new AbortController();
            getApi().getTopicStats(project_id, topic.topic_id, res => setStats(res));
            return ()=>abortContoller.abort();
        }
    }, [inViewDebounced]);
    
    return (
        <div ref={ref}  >
        <Box flex="grow" className="topicDocumentCard" pad="small"  >

        
            <>
                <Box >
                    <Box direction="row" justify="between" gap="small" >
                        <Box align="end">
                    <Text size="xsmall">    id    </Text>
                    <Text size="xsmall" weight="bold">   {topic.topic_id}    </Text>
                    </Box>
                    <Box>
                    <Text size="xsmall">    size    </Text>
                    <Text size="25px" weight="bold">   {topic.size}    </Text>
                    </Box>
                    </Box>
                </Box>
                <Box gridArea="name" align="end">
                    <Text size="25px" weight="bold">   {topic.topic_name}    </Text>
                </Box>
                {topic.topic_id>=0 && (
                <Box gridArea="info" pad="medium">
                    <TagCloud
                        className="tagCloud"
                        disableRandomColor={true}
                        colorOptions={{luminosity: 'dark'}}
                        randomSeed={42}
                        minSize={8}
                        maxSize={45}
                        tags={(topic.topic_keywords
                            && topic.topic_keywords.map((kw) => {
                                let res = { value: kw.word, count: (100 * kw.score) }
                                if (searchParams.get("q") ===kw.word)
                                    res["props"]={style: {backgroundColor: "yellow"}};
                                return res;
                            })
                        ) || []} />
                </Box>
                
                )}
                <Box direction="row" flex={{grow:4}}>
                        {topic.representative_samples && (
                            <Box gap="xsmall" >
                                <Text size="15px">    Examples    </Text>
                                {
                                    topic.representative_samples.map(doc => (
                                        <Box
                                            background="white"
                                            key={doc.id}
                                            elevation="small"
                                            round="2px" 
                                             pad="xsmall" overflow="hidden"
                                            style={{ maxHeight: "40px" }}
                                        > 
                                            <Text size="8pt" key={doc.id}>{doc.text}</Text> 
                                        </Box>


                                    )
                                    )
                                }
                            </Box>
                        )}

                        <Box flex={{grow:1}} pad="small" style={{minWidth:"120px"}} align="end">
                        {inViewDebounced && stats && (<TopicStatsControl stats={stats} totalCount={topic.size} label_settings={projectInfo?.label_settings} />)}

                        </Box>
                    </Box>
                </>
                
                
            
        
        </Box>
  </div>
)
};
