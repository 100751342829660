import { React, useState, useEffect, useRef, useMemo } from "react";
import { Box, Stack, Text, Tip} from "grommet";
import { Like,Dislike, FormClose, Close } from 'grommet-icons'

import { getApi } from "../../ApiService";
import { useAppContext } from "../../AppContext";
import { ExpandingButton } from "../ExpandingButton/expandingButton";


const IconWrapper = ({overlayIcon, focus,children})=>{

    const body = overlayIcon?(
        <Stack >
            {children}
            

            {overlayIcon}
            
        </Stack>
    ):(children)

    return (<Box>
        {body}
    </Box>)
}


export const SimilarityScoreBadge = ({score, onLike, onDislike, liked, unliked})=>{
    const [mouseOver, setMouseOver] = useState(false);
    const {projectInfo} = useAppContext();
    const sim_threshold=useMemo(()=>Math.floor( 100 * (projectInfo?.labeling_preferences?.similarity_threshold||0.8)), [projectInfo])

    return (
    
    <Box className="disabledSelectionChange similarityScoreBadge"
    margin="xsmall" border pad="xsmall" size="xsmall"  round  background={Math.floor( 100*score) >=sim_threshold ? "brand" : "light-3"} 
    onMouseEnter={()=>setMouseOver(true)} onMouseLeave={()=>setMouseOver(false)}
    >
         
        {
            mouseOver && (onLike || onDislike)?(
                <Box gap="small" direction="row">
                <Text size="12px">{Math.floor(score * 100) + "% "}</Text>
                    {onLike&& (
                    <Box className="disabledSelectionChange likeBtn"  onClick={(e)=> onLike(!!!liked)} round="small"  hoverIndicator={false}> 
                    <Tip content={liked?"Delete mark-as-similar link":"Mark as good similarity match for query"}>
                        <Box direction="row" >
                            <Like  size={liked?"10px":"15px"}/>
                            {liked?<Close size="15px"/>:<></> }
                        </Box>
                        </Tip></Box>)}
                    {onDislike&& (
                        <Box className="disabledSelectionChange dislikeBtn"  onClick={(e)=>onDislike(!!!unliked)} round="small" hoverIndicator={false}> 
                            <Tip content={unliked?"Delete mark-as-NOT-similar link":"Mark as not similar"}>
                                <Box direction="row" >
                                    <Dislike  size={unliked?"10px":"15px"}/>
                                    {unliked?<Close size="15px"/>:<></> }
                                </Box>
                            </Tip>
                            
                        </Box>)}
                    
                </Box>
            ):
            (
                <Box gap="4px" direction="row">
                
                            <Text size="12px">score: {Math.floor(score * 100) + "%"}</Text> 
                            {liked ? (<Like size="15px" />) : (<></>)}
                            {unliked ? (<Dislike size="15px" />) : (<></>)} 
                        </Box>
            )
        }
            
            
            </Box>
            
            )
}

/*<Tip content="Mark as good similarity match for query">*/ 

