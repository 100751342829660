import {  Box,  Layer,   Spinner, Form, TextInput, FormField, Button, Text, Select } from 'grommet'
import { TextInputWithSuggestions } from '../components/TextInputWithSuggestions';
import { parse, stringify } from 'yaml'
import {Close} from "grommet-icons"
import { useState, useEffect } from "react";
import { getApi } from '../ApiService';
import Editor from "@monaco-editor/react";
import { Switch } from '../components/Switch';
import { CopyButton } from '../components/CopyButton';


export const CodeGenerationDialog= ({caption, codeOptions,generate,  onClose }:
  {
    caption:string,
    codeOptions:string[],
    generate:(option:string)=>string
    onClose:()=>any
  })=>{
  const [wait, setWait] = useState(false);

  const [codeOption, setCodeOption] = useState<string>(codeOptions[0]);
  const [codeValue, setCodeValue] = useState<string>();
  
  useEffect(()=>{
    setWait(true);
    (new Promise((resolve)=>{
      let val = generate(codeOption)
      resolve(val)
    }))
    .then((value:string)=>
      {
        setCodeValue(value)
      }
    ).finally(()=>
      setWait(false)
    )
  },[codeOption])

  const _onClose=()=>{
    onClose();
  }


return (
    
<Layer onClickOutside={onClose} onEsc={onClose}>
  <Box style={{minWidth:"65vw"}} margin="small" gap="small">
    <Box direction="row" justify='between' width="100%">
      <Text size="large">{caption} </Text>
      <Close style={{float:"right"}} onClick={_onClose}/>
    </Box>
    {
      wait?
      (<Spinner size="large" alignSelf="center"/>)
      :
      ( <Box>
        
        <Switch active={codeOption} onActiveChange={(o)=>setCodeOption(o as string)} options={codeOptions} />
        <CopyButton value={codeValue} caption="Copy" border round="xsmall" pad="2px" background="brand" alignSelf="end" margin="0px 15px 5px"/>
        <Editor
          height="50vh"
          options={{ 
            //readOnly:true,
            wordWrap:"on",
            minimap: {
              enabled: false
            },
          }}
          defaultLanguage={codeOption.toLowerCase()}
          value= {codeValue}
        />
        </Box>
      )
    }
    
    <Box direction="row" justify="end" gap="xsmall">
        
        <Button secondary label="Cancel" onClick={_onClose}/>
    </Box>
  </Box>
</Layer>

)
}
