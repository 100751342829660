import {Box,Text} from "grommet"

const Switch=({options, active, size, onActiveChange}:{
    options:string[]|{key:string, label:any}[],
    active:string|boolean,
    size?:"xsmall"|"small"|"medium"|"large"|undefined
    onActiveChange:(val:string|boolean)=>any
})=>{

    const getActiveKey=(option:any)=> (typeof option === "string")? option:(option as any).key
    return (
        <Box
            focusIndicator={false} 
            onClick={!options? (()=>onActiveChange(!!!(active))):undefined}
        > 
            <Box align='start'>
                <Box direction='row'  border round  pad="2px" gap="5px"> 
                {options?(

                    options.map((option,i)=>(
                        <Box key={i} border={getActiveKey(option)!==active} round pad="1px 10px" focusIndicator={false}
                        background={active===getActiveKey(option) ?"orange":undefined}
                        onClick={()=>onActiveChange(getActiveKey(option) )}> 
                                <Text  size={size||"small"} weight={getActiveKey(option)==active? 700:500 }>{(typeof option === "string")? option:(option as any).label}</Text>
                            </Box>

                    ))
                ):(
                    <Box background={active?"orange":"gray"} round pad="10px" margin={active?"0px 25px 0px 0px":"0px 0px 0px 25px"}></Box>
                )}
                </Box>
            </Box>
        </Box> 
    )
}

export {Switch}