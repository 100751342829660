import { Link, useNavigate} from 'react-router-dom';
import {  Box, Button, DropButton, Heading, Layer, FileInput, Text, Spinner } from 'grommet'
import { SearchBar } from "../components/SearchBar/searchBar"
import {Add,CloudUpload, Close, Refresh} from "grommet-icons"
import { useState, useEffect } from "react";

import { getApi } from '../ApiService';
import { Projects } from '../components/Projects';
import { TipIfContent } from '../components/TipIfContent';
import { useAppContext } from '../AppContext';
import { BetterButton } from '../components/BetterButton';

function FileUploadDialog({caption, onFile, onClose}){
  const [wait, setWait] = useState(false);
  
return (<Layer onClickOutside={ onClose} onEsc={onClose}>
  <Box width="500px"  margin="small" gap="small">
    <Box direction="row" justify='between' width="100%">
      <Text size="large">{caption} </Text>
      <Close style={{float:"right"}} onClick={onClose}/>
    </Box>
    {
      wait?(<Spinner size="large" alignSelf="center"/>):
    <FileInput maxSize={1} onChange={
      (e)=>{
        if (e.target.files){
          setWait(true);
          let res = onFile(e.target.files[0]).then(()=>onClose());
         
        }
      }
    }/>}
  </Box>
</Layer>)
}

function ProjectsPage() {
  
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState()
  const [searchTerm, setSearchTerm] = useState()
  const [showImportDialog, setShowImportDialog] = useState(false);
  const {testPermission }  = useAppContext();


  let search = (searchTerm)=>{
    setSearchTerm(searchTerm)
    setIsLoading(true)
    getApi().searchProjects(searchTerm, false, (data)=> setData(data)).finally(()=>setIsLoading(false));
  };

  // useEffect(() => {
  //   search()
  // }, [])

  const navigate=useNavigate();
  return (
    
    <Box fill background="light-3">
      <Box direction='row' align='center' justify='stretch'>

      <Box alignSelf='stretch' flex="grow">
      <SearchBar onChange={searchTerm=>search(searchTerm)}/>

      </Box>
      
      <Box alignSelf='center' margin="0px 5px 0px -10px">

        {isLoading?(<Box pad="10px" ><Spinner size="4px"/></Box> ):(
          <Box focusIndicator={false} flex="grow" pad="10px" onClick={()=> search(searchTerm)} >
                  <Refresh size="22px"/></Box>
              )}
          </Box>
      </Box>
      <Box direction='row' gap="small" margin="5px 20px"> 
        <BetterButton primary label="New project" icon={<Add/>} onClick={()=>navigate("/projects/new")} permission="CREATE_PROJECT"/>
        <BetterButton secondary label="Import project" icon={<CloudUpload/>} onClick={()=>setShowImportDialog(true)} permission="CREATE_PROJECT"/>
      </Box>
      <Heading level={4} margin="0px 15px">Projects</Heading>
      {data===undefined? 
      (<Box justify="center" align="center"><Spinner size="large"/></Box>)
      :
      (<Projects projectsInfo={data}/>)}
      
      {showImportDialog &&
      (<FileUploadDialog 
        caption={"Upload file with project data"} 
        onClose={()=>setShowImportDialog(false)} 
        onFile={  (file)=>{  return getApi().uploadImportFiles(file) }
        }/>
      )}
    </Box>
    
   

  );
}


export {ProjectsPage};
