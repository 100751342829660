import React, { useState } from "react";
import { Box, Text, Grid, Button, DropButton } from "grommet";
import { Link } from "react-router-dom";
import { Trash, MoreVertical, Download, Configure, Performance } from "grommet-icons"
import { getApi } from "../ApiService";
import { BetterButton } from "./BetterButton";
import { WaitPanel } from "./WaitPanel";
import { ConfirmDialog } from "./ConfirmDialog";
import { useAppContext } from "../AppContext";
import { Badge } from "./Badge";
import { humanizeNumber } from "../helpers/helperFunctions";

export const ProjectCard = ({ projectInfo, onChange }) => {
  const [wait,setWait] =useState();

  const {tennant_id} = useAppContext();

  const [prompt, setPrompt] = useState();
  function onDelete(){
    setPrompt({
      text:"Are you sure you want to delete " + projectInfo.name + " project?",
      onOk:()=>{
        
          setPrompt()
          setWait(true);
          getApi().deleteProject(projectInfo.id).then(()=>onChange(projectInfo.id)).finally(()=>setWait(false))
      },
      onCancel:(()=>{
        setPrompt()
      })
    })
    
  }
  const {newTaskScheduler} = useAppContext();
  function onDownload(){
    getApi().scheduleProjectExport(projectInfo.id).then(()=>{
      newTaskScheduler()
    })
    .finally(()=>setWait(false))
      
  }

  const [dropOpen,setDropOpen] = useState(false);
  function dropWrapper(innerFunc){
    setDropOpen(false)
    setWait(true)
    innerFunc()
    
  }

  // function downloadURI(uri, name) {
  //   let link = document.createElement("a");
  //   link.download = name;
  //   link.href = uri;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // }
  return (
    <Box className="projectDocumentCard" margin="small" round="8px" pad="10px 20px" direction="row" elevation="xsmall" background="white" flex={false} border >
 {wait && (
              <WaitPanel caption=" ... please wait ..."/>
            )}
      <Grid width="100%"

        rows={['auto', 'auto']}
        columns={['auto', 'auto']}
        gap="xsmall"
        areas={[
          { name: 'name', start: [0, 0], end: [0, 0] },
          { name: 'actions', start: [1, 0], end: [1, 1] },
          { name: 'id', start: [0, 1], end: [0, 1] },
        ]}
      >
        <Box gridArea="name:"  >
          <Text size="10px">    name    </Text>
          <Link to={`/${projectInfo.id}/texts`}>
            <Text size="25px" weight="bold">   {projectInfo.name}    </Text>
          </Link>
          {tennant_id&&!window.location.search.includes("no-tennant")?(
          <Box gridArea="id" direction="row" gap="xsmall">
          <Text size="10px" >    tennant_id:    </Text>
          <Text weight={900}>   {projectInfo.tennant_id}    </Text>

        </Box>
        ):<></>}
        </Box>
       

        <Box gridArea="id" direction="row" gap="xsmall">
          <Text size="10px" >    id:    </Text>
          <Text size="10px">   {projectInfo.id}    </Text>

        </Box>
        <Box gridArea="actions" align="end"  justify="between" >
          <Box align="end" >
          <DropButton
            open={dropOpen}
            onOpen={()=>setDropOpen(true)}
            onClose={()=>setDropOpen(false)}
            dropProps={{ align: { top: 'bottom' } }}
            dropContent={
              <Box pad="small" gap="medium">
                <BetterButton plain onClick={()=>onDelete()} icon={<Trash/>} label="Delete" permission="DELETE_PROJECT"/>
                
               
              
              </Box>
            }>
            {prompt && <ConfirmDialog text={prompt.text} onOk={prompt.onOk} onCancel={prompt.onCancel}/>}
            <MoreVertical />
          </DropButton>
          <Box direction="row" gap="5px">
          {(projectInfo.total_count-projectInfo.labeled_count) ? <Link  to={`/${projectInfo.id}/backlog`}><Badge textStyle={{textDecoration:"none"}} background="brand" tooltip={`Backlog: ${projectInfo.total_count-projectInfo.labeled_count}`} value={"Todo:"+ humanizeNumber(projectInfo.total_count-projectInfo.labeled_count)} /></Link>:(<></>)}
          <Badge tooltip={`Total count: ${projectInfo.total_count}`} value={"Size:"+ humanizeNumber(projectInfo.total_count)} />
            </Box>
            </Box>
              <Link to={`/${projectInfo.id}/settings`}> <BetterButton plain size="small" icon={<Performance/>} label="Settings" /></Link>
        </Box>
      </Grid>


    </Box>
  );
};
