import React, { useContext, useEffect, useState } from 'react';

import { Box, ResponsiveContext,Sidebar } from 'grommet';
import { TooltipButton } from './TooltipButton.js';
import { useParams, useSearchParams } from 'react-router-dom';
import {Note, Flows, User, AppsRounded, Test, Performance, Nodes, FormPrevious, FormNext, ServerCluster, Resources, Inherit} from 'grommet-icons'
import { useAppContext } from '../AppContext';
import { humanizeNumber } from '../helpers/helperFunctions.js';

export const AppSidebar = ({minimized,onMinimizedChange}) => {
  const size = useContext(ResponsiveContext);
  //let { project_id } = useParams();
  // const [trueProjectId, setTrueProjectId] = useState();
  // const project_id_re=/([0-9a-f]+-)+([0-9a-f]+)/gm;
  
  //const { projectInfo, currentUser } = useSharedProjectState();
  const {projectInfo, currentUser, testPermission, permssions} = useAppContext();
  const [ searchParams,setSearchParams ]= useSearchParams();
  //const projectsMenuItem = (<TooltipButton key="projects" icon={<AppsRounded/>} name="Projects" index={1} linkTo="/projects/search" />);
  //console.log(currentUser);
  
  const [menuItems, setMenuItems] = useState([]);
  const [isMinimized, setIsMinimized] = useState(minimized || size === 'medium');
  const setMinimized=(val)=>{
    setIsMinimized(val);
    onMinimizedChange && onMinimizedChange(val);
  }
  
  

  

  useEffect(()=>{
    
    if (projectInfo&& (projectInfo.data_import_state!=="PREPARING" || projectInfo.data_import_state ==null)){
      
       setMenuItems([
         
       <TooltipButton key="projects" icon={<AppsRounded/>} name="Projects" index={1} linkTo={`/projects/search?project_id=${projectInfo.id}`} matchPattern={/projects/ }/>, 

          <TooltipButton key="backlog" style={{width:"100%"}} icon={<Resources/>} name="Backlog" index={4} linkTo={`/${projectInfo.id}/backlog`} matchPattern={/backlog/}
              badge={ projectInfo.total_count >projectInfo.labeled_count ?  humanizeNumber(projectInfo.total_count -projectInfo.labeled_count): undefined}  />,
          <TooltipButton key="docs" style={{width:"100%"}} icon={<Flows/>} name="Documents" index={3} linkTo={`/${projectInfo.id}/texts`}  matchPattern={/texts/} />,
       
       <TooltipButton key="topics" icon={<Nodes/>} name="Microtopics" index={5} linkTo={`/${projectInfo.id}/topics`} />, 
       <TooltipButton key="models" style={{width:"100%"}} icon={<Test/>} name="Models" index={6} linkTo={`/${projectInfo.id}/models`}  matchPattern={/models/} />,
       <TooltipButton key="serving" disabled={permssions && !testPermission("SERVING_MANAGEMENT")}  style={{width:"100%"}} icon={<ServerCluster/>} name="Serving" index={7} linkTo={`/serving?project_id=${projectInfo.id}`}   matchPattern={/serving/} />,
       <TooltipButton key="integration" disabled={permssions && !testPermission("INTEGRATION_SETTINGS")}  style={{width:"100%"}} icon={<Inherit/>} name="Integration" index={6} linkTo={`/${projectInfo.id}/integration`}  matchPattern={/integration/} />,
      ])
    }
    else if (currentUser){
        setMenuItems([
        <TooltipButton key="projects" icon={<AppsRounded/>} name="Projects" index={1} linkTo="/projects/search"  matchPattern={/projects/} />,
        <TooltipButton key="serving" style={{width:"100%"}} icon={<ServerCluster/>} name="Serving" index={3} linkTo={`/serving`}  matchPattern={/serving/} />
      ])
    }
  }, [projectInfo])
  return (
    <Sidebar background="dark-2" pad="0px"
    footer={ 
      <>
      {/* <TooltipButton key="login" icon={<User/>} name="Login" index={10} linkTo={`/login`}/> */}
      {(projectInfo) && (<TooltipButton key="setttings" icon={<Performance/>} name="Project settings" index={10} linkTo={`/${projectInfo.id}/settings`}  matchPattern={/settings/} />)}
      </>
    }
    >

        <Box align='end' width="100%" onClick={()=>setMinimized(!minimized) }> 
        {/* {!minimized?<FormNext/>:<FormPrevious/>} */}
        </Box>
      <Box align="center">
        {menuItems
        }
      </Box>
      <Box flex />
     
    </Sidebar>
  );
};
